<script>
import { VStepperStep } from 'vuetify/lib';

export default {
  // Can't name the component "h-stepper-step"
  // Ask PLP
  // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VStepper/VStepper.ts#L105
  name: 'v-stepper-step',
  extends: VStepperStep,
  computed: {
    classes() {
      return {
        ...VStepperStep.options.computed.classes.call(this),
        'h-stepper-step': true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__step--active .v-stepper__step__step {
  box-shadow: 0px 0px 0px 4px rgba(173, 175, 255, 1), 0px 0px 6px 8px rgba(173, 175, 255, 0.6);
}

.v-stepper__step--complete .v-stepper__step__step {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-ultra-light);
    opacity: 0.4;
  }
}

.v-stepper__step.v-stepper__step--editable {
  border-radius: 10px;
}
</style>
