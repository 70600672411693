import _pick from 'lodash/pick';
import errorTypes from '@/utils/error/error-types';
import { secureStorage } from '@/utils/secure-storage';

export default {
  name: 'fake-firebase',

  init: async ({ Vue }) => {
    let authStateChangedCallback;
    let fakeCurrentUser = loadCurrentUser();

    function fakeOnAuthStateChanged(callback) {
      console.warn(`OVERRIDING METHOD $firebaseAuth.onAuthStateChanged`); // eslint-disable-line no-console
      authStateChangedCallback = callback;
      return callback(fakeCurrentUser);
    }

    async function doAuthStateChanged() {
      return new Promise((resolve, reject) => {
        try {
          authStateChangedCallback(fakeCurrentUser);
          resolve();
        } catch {
          reject();
        }
      });
    }

    function buildUserFromEmail(email) {
      return {
        email,
        getIdToken: () => email,
      };
    }

    function loadCurrentUser() {
      const currentUserInStorage = secureStorage.getItem('hello::fakeCurrentUser') || null;
      const isNull = currentUserInStorage === 'null' || currentUserInStorage === null;
      const currentUser = isNull ? null : buildUserFromEmail(currentUserInStorage);
      return currentUser;
    }

    async function updateCurrentUser(user) {
      fakeCurrentUser = user;
      secureStorage.setItem('hello::fakeCurrentUser', user ? user.email : null);
      return doAuthStateChanged();
    }

    async function fakeSignInWithEmailAndPassword(email) {
      console.warn(`OVERRIDING METHOD $firebaseAuth.signInWithEmailAndPassword`); // eslint-disable-line no-console
      if (email.startsWith('test_invalid')) {
        const err = new Error('Invalid Email');
        err.code = 'auth/invalid-email';
        throw err;
      }
      if (email.startsWith('test_too_many')) {
        const err = new Error('Too Many Requests');
        err.code = 'auth/too-many-requests';
        throw err;
      }
      if (email.startsWith('test_disabled')) {
        const err = new Error('User Disabled');
        err.code = 'auth/user-disabled';
        throw err;
      }
      if (email.startsWith('test_not_found')) {
        const err = new Error('User Not Found');
        err.code = 'auth/user-not-found';
        throw err;
      }
      if (email.startsWith('test_wrong_password')) {
        const err = new Error('Wrong Password');
        err.code = 'auth/wrong-password';
        throw err;
      }

      const user = buildUserFromEmail(email);
      return updateCurrentUser(user);
    }

    function fakeSignOut() {
      console.warn(`OVERRIDING METHOD $firebaseAuth.signOut`); // eslint-disable-line no-console
      return updateCurrentUser(null);
    }

    function fakeSetPersistence(persistence) {
      // all this is assuming local persistence (current user stored in localStorage)
      console.warn(`OVERRIDING METHOD $firebaseAuth.setPersistence`); // eslint-disable-line no-console
      if (persistence !== 'local') {
        console.error(`UNHANDLED FIREBASE PERSISTENCE TYPE - ${persistence}`); // eslint-disable-line no-console
        throw new errorTypes.HttpError(400, {
          error: {
            code: 'attempting_unhandled_firebase_persistence',
          },
        });
      }
    }

    function fakeLanguageCode() {
      console.warn(`OVERRIDING METHOD $firebaseAuth.languageCode`); // eslint-disable-line no-console
    }

    const fakeFirebase = {
      signInWithEmailAndPassword: fakeSignInWithEmailAndPassword,
      setPersistence: fakeSetPersistence,
      signOut: fakeSignOut,
      languageCode: fakeLanguageCode,
      onAuthStateChanged: fakeOnAuthStateChanged,
      get currentUser() {
        return fakeCurrentUser;
      },
    };

    console.warn('WARNING - LOADING FAKE FIREBASE'); // eslint-disable-line no-console
    Vue.prototype.$firebaseAuth = fakeFirebase;
  },
};
