<template>
  <div class="payment-detail-item">
    <span class="h-text-3">{{ text }}</span>
    <span class="h-text-3">{{ amount | currency_full | currencySymbol }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'item',
    },
    amount: {
      type: Number,
      default: 10.0,
    },
  },
  name: 'payment-detail-item',
};
</script>

<style lang="scss" scoped>
.payment-detail-item {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
}

.h-text-3 {
  color: var(--color-text-secondary);
  font-weight: 600;
}
</style>
