<template>
  <div class="verify-email-view">
    <div v-if="working" class="text-center">
      <v-progress-circular :size="100" :width="7" color="primary" indeterminate />
    </div>
    <hello-content-error />
    <h-success-box v-if="success" :text="$t('components.verify_email_view.success_message')" />
    <recently-visited-space-widget />
    <div class="text-center">
      <h-btn to="home">{{ $t('components.verify_email_view.back_action') }}</h-btn>
    </div>
  </div>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { ref } from '@vue/composition-api';
import RecentlyVisitedSpaceWidget from '../space/RecentlyVisitedSpaceWidget.vue';

export default {
  components: { RecentlyVisitedSpaceWidget },
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    const success = ref(false);
    executeContent(
      {
        name: 'verifyEmailToken',
        action: 'security/verifyEmailToken',
        success: () => {
          success.value = true;
        },
        beforeError: (error) => {
          if (error.status_code === 400 && error.code === 'errors.http.system.bad.request') {
            error.code = 'components.verify_email_view.error_message';
          }
          if (error.status_code === 400 && error.code === 'errors.http.system.bad.request') {
            error.code = 'components.verify_email_view.error_message';
          }
          if (error.status_code === 400 && error.code === 'errors.http.email.verification.jwt.invalid') {
            error.code = 'components.verify_email_view.error_message';
          }
          if (error.status_code === 400 && error.code === 'errors.http.email.validation.expired') {
            error.code = 'components.verify_email_view.expired_message';
          }
          if (error.status_code === 400 && error.code === 'errors.http.email.validation.not.found') {
            error.code = 'components.verify_email_view.error_message';
          }
        },
      },
      root.$route.query['email-verification-token'],
    );
    return {
      success,
      executeContent,
      working,
    };
  },
  name: 'verify-email-view',
};
</script>

<style lang="scss" scoped>
.verify-email-view {
  height: 100%;
  padding: var(--gap) var(--gap-page-side);
  background-color: var(--color-ultra-light);
}
</style>
