import Vue from 'vue';

/*
// NOTE: Debug
function sleep(ms = 3000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
} */

const service = {
  async registerAccount(account) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/register`, account);
    return response.data.data;
  },
  async accountInfo() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account`);
    return response.data.data;
  },
  async accountInfoV2() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v2/account`);
    return response.data.data;
  },
  async accountPaymentsMethods() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v2/account/payment-methods/card`);
    return response.data.data;
  },
  async accountDetachPaymentMethod(paymentMethodId) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/v1/account/payment-methods/${paymentMethodId}`);
    return response.data.data;
  },
  async accountActiveSubscription() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/active-subscription`);
    return response.data.data;
  },
  async cardholderInfo() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/cardholder`);
    return response.data.data;
  },
  async applicationInfo() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/application`);
    return response.data.data;
  },
  async createCardApplicationContext(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/application/context`, payload);
    return response.data.data;
  },
  async getAcquiringCardContext() {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/cards/acquiring-context`, {});
    return response.data.data;
  },
  async activateCard(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/card/activate`, payload);
    return response.data.data;
  },
  async renewCard(card_public_token, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/card/${card_public_token}/renew`, payload);
    return response.data.data;
  },
  async voucherHistory(voucherid) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/vouchers/${voucherid}/history`);
    return response.data.data.items;
  },
  async accountEmailStatus() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/email-status-verified`);
    return response.data.data;
  },
  async resetVerificationCode(payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/v1/account/email/reset-verification-code`, payload);
    return response.data.data;
  },
  async verifyEmailByCode(payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/v1/account/email/code-verify`, payload);
    return response.data.data;
  },
};

export default service;
