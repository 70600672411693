<template>
  <table class="card-summary-desktop">
    <colgroup>
      <col />
      <col class="classic-card-wrap" />
      <col />
    </colgroup>

    <thead>
      <tr>
        <td></td>
        <th scope="col" class="classic-header">
          <p class="h-label-4-med-italic" style="font-weight: 500">{{ $t('components.card_summary_desktop.classic_card_label') }}</p>
          <h2 class="h-label-1" style="text-transform: uppercase">{{ $t('components.card_summary_desktop.classic_card_title') }}</h2>
        </th>
        <th scope="col" rowspan="1" valign="bottom">
          <div class="instant-header">
            <h2 class="h-label-1" style="text-transform: uppercase">{{ $t('components.card_summary_desktop.instant_card_title') }}</h2>
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(item, index) in classicProperties" :key="item.name">
        <th scope="row" class="h-text-3">
          {{ $t(item.name) }}
          <open-wallet-hint-modal :text="$t(item.hint)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="item.hint" v-on="on" v-bind="attrs" small right>mdi-help-circle-outline</v-icon>
            </template>
          </open-wallet-hint-modal>
        </th>
        <td>
          <v-icon v-if="item.value === 'Y'" color="success" :aria-label="accessibilityLabel(item)" role="img" aria-hidden="false">mdi-check</v-icon>
          <v-icon v-else-if="item.value === 'N'" color="#BDBDBD" :aria-label="accessibilityLabel(item)" role="img" aria-hidden="false">mdi-close</v-icon>
          <div v-else-if="typeof item.value === 'number'">
            <span style="display: none" :aria-label="accessibilityLabel(item)">{{ item.name }}</span>
            {{ item.value | currency | currencySymbol }}
          </div>
          <div v-else>
            <span style="display: none" :aria-label="accessibilityLabel(item)">{{ item.name }}</span>
            {{ item.value }}
          </div>
        </td>
        <td>
          <v-icon v-if="instantProperties[index].value === 'Y'" color="success" :aria-label="accessibilityLabel(instantProperties[index])" role="img" aria-hidden="false">mdi-check</v-icon>
          <v-icon v-else-if="instantProperties[index].value === 'N'" color="#BDBDBD" :aria-label="accessibilityLabel(instantProperties[index])" role="img" aria-hidden="false">mdi-close</v-icon>
          <div v-else-if="typeof instantProperties[index].value === 'number'">
            <span style="display: none" :aria-label="accessibilityLabel(instantProperties[index])">{{ instantProperties[index].name }}</span>
            {{ instantProperties[index].value | currency | currencySymbol }}
          </div>
          <div v-else>
            <span style="display: none" :aria-label="accessibilityLabel(instantProperties[index])">{{ instantProperties[index].name }}</span>
            {{ instantProperties[index].value }}
          </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td></td>
        <td>
          <h-btn class="btn-choose" :loading="contextReady" rounded @click="chooseClassic">{{ $t('components.card_summary_desktop.classic_card_submit') }}</h-btn>
          <p class="h-label-4-med-italic fine-print">{{ $t('components.card_summary_desktop.classic_card_fine_print') }}</p>
        </td>

        <td>
          <h-btn class="btn-choose" rounded outlined @click="chooseInstant">{{ $t('components.card_summary_desktop.instant_card_submit') }}</h-btn>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import OpenWalletHintModal from './OpenWalletHintModal.vue';

export default {
  name: 'card-summary-desktop',
  components: { OpenWalletHintModal },
  props: {
    classicProperties: {
      type: Array,
      default: () => [],
    },
    instantProperties: {
      type: Array,
      default: () => [],
    },
    contextReady: {
      type: Boolean,
    },
  },
  data() {
    return {
      showOpenWalletHintModal: false,
    };
  },
  methods: {
    accessibilityLabel(name, value) {
      return `${name}: ${value}`;
    },
    chooseClassic() {
      this.$emit('chooseClassic');
    },
    chooseInstant() {
      this.$emit('chooseInstant');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-summary-desktop {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  background-color: var(--color-ultra-light);

  thead th {
    min-width: 260px;

    &.classic-header {
      background: rgba(46, 49, 145, 0.1);
      padding: var(--gap-xxsmall);
      padding-bottom: var(--gap-small);
    }
    .instant-header {
      border-right: var(--border-thin) solid var(--color-border);
      border-top: var(--border-thin) solid var(--color-border);
      border-bottom: 0;
      padding: var(--gap-small);
    }
  }

  tbody {
    td {
      padding: 10px var(--gap);
      border: var(--border-thin) solid var(--color-border);
      text-align: center;
    }
    th {
      background-color: #f2f2f2;
      border: var(--border-thin) solid var(--color-border);
      font-weight: 500;
      text-align: left;
      padding-left: var(--gap-small);
    }
  }
  tfoot td:not(:first-child) {
    text-align: center;
    vertical-align: top;
    padding: 30px 10px;

    border: var(--border-thin) solid var(--color-border);
  }
}

.classic-card-wrap {
  text-align: center;
  border: 2px solid var(--color-primary) !important;
  padding-bottom: var(--gap-xlarge);
}

.fine-print {
  max-width: 200px;
  margin: var(--gap) auto 0 auto;
}
</style>
