<template>
  <h-dialog :title="$t('components.account_info_bar_card.modal_title')">
    <template v-slot:activator="{ on, attrs }">
      <h-btn v-on="on" v-bind="attrs" inverted color="secondary" class="manage-btn">{{ $t('components.user_profile.manage_btn') }}</h-btn>
    </template>

    <p>{{ $t('components.account_info_bar_card.modal_sub_title') }}</p>

    <ul>
      <li>{{ $t('components.account_info_bar_card.modal_list_1') }}</li>
      <li v-if="showMyMoney">
        {{ $t('components.account_info_bar_card.modal_list_2') }}
      </li>
      <li>{{ $t('components.account_info_bar_card.modal_list_3') }}</li>
      <li>{{ $t('components.account_info_bar_card.modal_list_4') }}</li>
      <li>{{ $t('components.account_info_bar_card.modal_list_5') }}</li>
    </ul>

    <app-store-badges />

    <p>{{ $t('components.account_info_bar_card.modal_store') }}</p>
  </h-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppStoreBadges from '@/components/utilities/AppStoreBadges';

export default {
  props: {
    showMyMoney: {
      type: Boolean,
      default: () => false,
    },
  },
  name: 'manage-card-modal',
  components: { AppStoreBadges },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    cashUrl() {
      if (this.currentLocale === 'fr') {
        return this.$urlProvider.HelloSupportMyMoneyFr;
      }
      return this.$urlProvider.HelloSupportMyMoneyEn;
    },
  },
};
</script>
