<script>
import { VCheckbox } from 'vuetify/lib';

export default {
  name: 'h-checkbox',
  extends: VCheckbox,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    onIcon: {
      type: String,
      default: 'mdi-checkbox-intermediate',
    },
    offIcon: {
      type: String,
      default: 'mdi-checkbox-blank-outline',
    },
    topAlign: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ...VCheckbox.options.computed.classes.call(this),
        'h-checkbox--top': this.topAlign,
        'h-checkbox': true,
      };
    },
    computedColor() {
      const color = VCheckbox.options.computed.computedColor.call(this);
      return color || this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.h-checkbox--top {
  .v-input__slot {
    align-items: flex-start;
  }
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}

.v-messages {
  display: none;
}
</style>
