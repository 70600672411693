<template>
  <big-card-base
    :offer-title="currentOfferPublisherName"
    :offer-label="currentOfferPublisherCategory | formatCategoryRegion(currentOfferPublisherRegion)"
    :offer-description="currentOfferPublisherDescription"
    :carousel-images="currentOfferPublisherShowcasesImage"
    compact-layout
    :show-panier-bleu="currentOfferPublisherBrandingFeaturePanierBleu"
    :restricted-to-members="currentOfferRestrictedToMembers"
  >
    <template #chip>
      <h-chip-big
        v-if="$vuetify.breakpoint.mdAndUp"
        compact
        :pay="amount | currency | currencySymbol"
        :obtain="amountObtained | currency | currencySymbol"
        :percentage="currentOfferIncentiveBonusDisplay | bonusSymbol"
        :plus-action="plus"
        :minus-action="minus"
        :disable-plus="disablePlus(amount, currentOfferIncentiveStep, currentOfferIncentivePurchaseLimit, amountLeft)"
        :disable-minus="disableMinus(amount, currentOfferIncentiveStep, currentOfferIncentiveMinimumPayment, amountLeft)"
      />
    </template>

    <template #actionSection>
      <h-btn v-if="currentOfferCtaActivation" :disabled="!currenOfferAvailable" color="var(--color-branding)" block large @click="openOverlayForPayment">{{ $t('components.bonus_offer_publisher_big_card.call_to_action') }}</h-btn>

      <p class="offer-label" v-if="currenOfferAvailable">{{ $t('components.bonus_offer_publisher_big_card.progress_left', { currentNumber: formattedCurrentNumber }) }}</p>

      <big-card-base-status-label v-else :status="currentStatus" :start="currentOfferStartDate" :expiration="currentOfferEndDate" />

      <offer-card-error-modal v-model="errorModalActive" @close="closeErrorModal" :error-message-code="errorMessageCode" />
    </template>
  </big-card-base>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';
import { showcaseImageHelper } from '@/composables/image-helper';
import { addAmount, determineStatus, disableMinus, disablePlus, remainingAmount, removeAmount, isStatusEqual } from '@/composables/offer-publisher-helper';
import BigCardBaseStatusLabel from '@/components/cards/big/BigCardBaseStatusLabel.vue';
import OfferCardErrorModal from '@/components/cards/components/OfferCardErrorModal.vue';

export default {
  name: 'bonus-offer-publisher-detail',
  components: { BigCardBase, BigCardBaseStatusLabel, OfferCardErrorModal },
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
      showcaseImageHelper,
      remainingAmount,
      determineStatus,
      isStatusEqual,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
    };
  },
  data() {
    return {
      amount: 0,
      errorModalActive: false,
      errorMessageCode: null,
    };
  },
  mounted() {
    // Used to init if using the same offer
    this.amount = this.paymentAmount;
  },
  watch: {
    paymentAmount() {
      // Used to init if change between offer
      this.amount = this.paymentAmount;
    },
  },
  computed: {
    ...mapGetters('payment', ['paymentAmount']),
    ...mapGetters('space', ['currentPartnerId']),
    formattedCurrentNumber() {
      if (this.isStatusEqual(this.currentOfferStatus, this.currentOfferPublisherStatus, 'sold_out') || this.isStatusEqual(this.currentOfferStatus, this.currentOfferPublisherStatus, 'closed')) {
        return this.$options.filters.currencySymbol(this.$options.filters.currency(0));
      }
      return this.$options.filters.currencySymbol(
        this.$options.filters.currency(this.remainingAmount(this.currentOfferTotalInventory, this.currentOfferUsedInventory, this.currentOfferIncentiveBonusDisplay, this.currentOfferPublisherTotalInventory, this.currentOfferPublisherUsedInventory)),
      );
    },
    amountLeft() {
      return this.remainingAmount(this.currentOfferTotalInventory, this.currentOfferUsedInventory, this.currentOfferIncentiveBonusDisplay, this.currentOfferPublisherTotalInventory, this.currentOfferPublisherUsedInventory);
    },
    ...mapGetters('offer', [
      'currentOfferId',
      'currentOfferPublisherName',
      'currentOfferPublisherDescription',
      'currentOfferPublisherCategory',
      'currentOfferPublisherRegion',
      'currentOfferPublisherBrandingFeaturePanierBleu',
      'currentOfferPublisherShowcasesImage',
      'currentOfferRestrictedToMembers',
      'currentOfferPublisherStatus',
      'currentOfferStatus',
      'currentOfferPublisherPublisherId',
      'currentOfferPublisherOfferId',
      'currentOfferEndDate',
      'currentOfferStartDate',
      'currentOfferCtaActivation',
      'currentOfferIncentiveStep',
      'currentOfferIncentiveBonusDisplay',
      'currentOfferIncentiveBonusPercent',
      'currentOfferIncentivePurchaseLimit',
      'currentOfferIncentiveMinimumPayment',
      'currentOfferPublisherUsedInventory',
      'currentOfferPublisherTotalInventory',
      'currentOfferTotalInventory',
      'currentOfferUsedInventory',
    ]),
    amountObtained() {
      return (1 + this.currentOfferIncentiveBonusPercent) * this.amount;
    },
    currentStatus() {
      return this.determineStatus(this.currentOfferStatus, this.currentOfferPublisherStatus);
    },
    currenOfferAvailable() {
      return this.isStatusEqual(this.currentOfferStatus, this.currentOfferPublisherStatus, 'available');
    },
  },
  methods: {
    async openOverlayForPayment() {
      this.executeContent(
        {
          action: 'overlay/openHeaderDrawerForPayment',
          afterError: (err) => {
            if (err.errorType === 'http') {
              this.errorModalActive = true;
              this.errorMessageCode = err.code;
            }
          },
        },
        {
          payload: {
            partner_id: this.currentPartnerId,
            offer_id: this.currentOfferId,
            offer_restricted_to_member: this.currentOfferRestrictedToMembers,
            publisher_id: this.currentOfferPublisherPublisherId,
          },
        },
      );
    },
    plus() {
      this.amount = this.addAmount(this.amount, this.currentOfferIncentiveStep, this.paymentAmount);
    },
    minus() {
      this.amount = this.removeAmount(this.amount, this.currentOfferIncentiveStep, this.paymentAmount);
    },
    async refreshCurrentOfferAndPublisher() {
      return this.executeContent(
        {
          action: 'offer/refreshCurrentOfferAndPublisher',
        },
        {
          offer_id: this.currentOfferPublisherOfferId,
          publisher_id: this.currentOfferPublisherPublisherId,
        },
      );
    },
    closeErrorModal() {
      this.errorMessageCode = null;
      this.refreshCurrentOfferAndPublisher();
    },
  },
};
</script>
