<template>
  <div class="home-offers-by-status">
    <home-offers-grid
      :category="category"
      :offers="focusOffers.items"
      :show-list-all="showListAll(focusStatus)"
      :show-empty-offer-message="hasNoCurrentOrComingSoonOffers"
      :list-all-action="listAllByStatus"
      :list-all-message="focusStatus"
      :list-all-label="$t(`components.home_offers_by_status.list_all.${focusStatus}`, { numItems: focusOffers.item_count })"
      :show-stay-tuned-card="showStayTunedCard(focusStatus)"
      :title="$t(`components.home_offers_by_status.${focusStatus}`)"
    >
      <template #backButton>
        <h-btn v-if="!currentlyOnHomePage" @click="returnHome" color="ultra-light" class="back-btn">
          <v-icon>mdi-menu-left</v-icon>
          {{ $t('components.home_offers_by_status.return') }}
        </h-btn>
      </template>

      <template #action>
        <div v-if="!maxFocusedOffersReached" class="show-more-focused">
          <v-progress-circular v-if="loadMoreLoading" :style="{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="40" :width="2" indeterminate color="primary" />
          <h-link v-else class="load-more" bold large @click="loadMoreAction()" color="primary"> {{ $t('components.home_offers_by_status.show_more') }} <v-icon color="primary">mdi-arrow-down</v-icon> </h-link>
        </div>
      </template>
    </home-offers-grid>

    <div class="not-focus-section">
      <home-offers-grid
        v-for="status in unfocusedStatusOffers"
        v-show="!((status === 'missed' || status === 'available') && _get(offersByStatus, `${status}.item_count`, 0) === 0)"
        :key="status"
        :offers="_get(offersByStatus, `${status}.items`, [])"
        :title="$t(`components.home_offers_by_status.statuses.${status}`)"
        :list-all-action="listAllByStatus"
        :list-all-message="status"
        :list-all-label="$t(`components.home_offers_by_status.list_all.${status}`, { numItems: _get(offersByStatus, `${status}.item_count`, 0) })"
        :show-stay-tuned-card="showStayTunedCard(status)"
        :show-list-all="showListAll(status)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import HomeOffersGrid from '../home-offers-list/HomeOffersGrid.vue';

export default {
  name: 'home-offers-by-status',
  props: {
    offersByStatus: {
      type: Object,
      required: true,
    },
    region: {
      type: String,
    },
    category: {
      type: String,
    },
    origin: {
      type: String,
    },
    loadMoreAction: {
      type: Function,
    },
    loadMoreLoading: {
      type: Boolean,
    },
  },
  components: {
    HomeOffersGrid,
  },
  title: (args) => args.$t('page_title.home'),
  computed: {
    ...mapGetters('offer', ['statusSectionBaseOfferLimit']),
    hasNoCurrentOrComingSoonOffers() {
      return _get(this.offersByStatus, 'available.item_count', 0) <= 0 && _get(this.offersByStatus, 'coming_soon.item_count', 0) <= 0;
    },
    focusStatus() {
      // If available status as items we use available has focus status.
      if (_get(this.offersByStatus, 'available.item_count', 0) <= 0 && _get(this.offersByStatus, 'coming_soon.item_count', 0) > 0) {
        return 'coming_soon';
      } else {
        return 'available';
      }
    },
    maxFocusedOffersReached() {
      return this.focusOffers && this.focusOffers.items && this.focusOffers.items.length >= this.focusOffers.item_count;
    },
    focusOffers() {
      return this.offersByStatus[this.focusStatus] || { items: [], item_count: 0 };
    },
    unfocusedStatusOffers() {
      return Object.keys(_omit(this.offersByStatus, [this.focusStatus]));
    },
    currentlyOnHomePage() {
      return this.origin === 'home';
    },
  },
  methods: {
    _get,
    showStayTunedCard(status) {
      const baseNumItems = this.focusStatus === status ? this.statusSectionBaseOfferLimit : 3;
      return status === 'coming_soon' && _get(this.offersByStatus, `${status}.item_count`, 0) < baseNumItems;
    },

    showListAll(status) {
      const baseNumItems = this.focusStatus === status ? this.statusSectionBaseOfferLimit : 3;
      return _get(this.offersByStatus, `${status}.item_count`, 0) > baseNumItems;
    },

    returnHome() {
      this.$router.pushNoDuplicate({ name: 'home', query: { category: this.category } });
    },

    listAllByStatus(status) {
      this.$router.pushNoDuplicate({ name: 'homeStatus', params: { region: this.region, status }, query: { category: this.category, origin: this.origin } });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.back-btn {
  z-index: 1;
  min-width: 0 !important;
  padding: 12px;
  color: var(--color-text);
}

.show-more-focused {
  text-align: center;
  margin-top: var(--gap);
}

.not-focus-section .home-offers-grid {
  margin-bottom: var(--gap-xxlarge);
}

.load-more.v-btn.v-btn--text {
  text-transform: uppercase;
}
</style>
