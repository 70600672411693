<template>
  <div class="corpo-company-step frame">
    <div class="frame-content">
      <hello-target-error target="companyStep" style="margin-bottom: var(--gap)" use-specific-error-message />

      <v-progress-circular v-if="companiesLoading" :style="{ marginTop: '50px', marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" indeterminate color="primary" />

      <corpo-company-form ref="form" v-else-if="isFirstCompany" @success="nextStepAfterFirstBusinessAccessCreation">
        <template v-slot="{ valid, working }">
          <h-btn type="submit" :block="$vuetify.breakpoint.smAndDown" :disabled="!valid || working" :loading="working">{{ $t('components.corpo_company_step.proceed_text') }}</h-btn>
        </template>
      </corpo-company-form>

      <corpo-company-manager ref="form" v-else @next="getCompaniesAndSelectDefault" />

      <first-business-access-info-modal v-model="firstBusinessAccessInfoModalIsOpen" @proceedToPayment="getCompaniesAndSelectDefault"></first-business-access-info-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CorpoCompanyForm from '@/components/corpo/order-process/CorpoCompanyForm';
import CorpoCompanyManager from '@/components/corpo/order-process/CorpoCompanyManager';
import safeExecute from '@/composables/safe-execute';
import FirstBusinessAccessInfoModal from '@/components/corpo/order-process/FirstBusinessAccessInfoModal.vue';

export default {
  name: 'corpo-company-step',
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  data() {
    return {
      firstBusinessAccessInfoModalIsOpen: false,
    };
  },
  components: { FirstBusinessAccessInfoModal, CorpoCompanyForm, CorpoCompanyManager },
  computed: {
    ...mapGetters('corpo', ['companyList', 'companiesLoading']),
    isFirstCompany() {
      return this.companyList.length < 1;
    },
  },
  methods: {
    async nextStepAfterFirstBusinessAccessCreation() {
      this.firstBusinessAccessInfoModalIsOpen = true;
    },
    async getCompaniesAndSelectDefault() {
      await this.executeTarget({
        action: 'corpo/getCompaniesAndSelectDefault',
        target: 'companyStep',
        success: () => {
          this.$emit('next');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
