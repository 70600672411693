import Vue from 'vue';
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2';

export default {
  name: 'fingerprint',
  init: async ({ Vue }) => {
    if (process.env.VUE_APP_FINGERPRINT_API_KEY) {
      console.log(`Loading Fingerprint with API KEY "${process.env.VUE_APP_FINGERPRINT_API_KEY}" on "${process.env.VUE_APP_FINGERPRINT_ENDPOINT}"`); // eslint-disable-line no-console
      Vue.use(fpjsPlugin, {
        loadOptions: {
          apiKey: process.env.VUE_APP_FINGERPRINT_API_KEY,
          endpoint: process.env.VUE_APP_FINGERPRINT_ENDPOINT,
          scriptUrlPattern: process.env.VUE_APP_FINGERPRINT_URL_PATTERN,
        },
      });
    } else {
      console.log('Skip Fingerprint initialization'); // eslint-disable-line no-console
    }
  },
};
