import { render, staticRenderFns } from "./RestrictedToMembersBadge.vue?vue&type=template&id=289301e2&scoped=true"
import script from "./RestrictedToMembersBadge.vue?vue&type=script&lang=js"
export * from "./RestrictedToMembersBadge.vue?vue&type=script&lang=js"
import style0 from "./RestrictedToMembersBadge.vue?vue&type=style&index=0&id=289301e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289301e2",
  null
  
)

export default component.exports