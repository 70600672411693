<template>
  <table class="card-summary-mobile" :class="{ 'classic-card-summary': type === 'instant', 'instant-card-summary': type === 'classic' }">
    <thead>
      <tr>
        <!-- Classic title -->
        <th v-if="type === 'classic'" colspan="2" scope="col">
          <p class="h-label-4-med-italic" style="font-weight: 500">{{ $t('components.classic_card_summary.label') }}</p>
          <h2 style="text-transform: uppercase">{{ $t('components.classic_card_summary.title') }}</h2>
        </th>

        <!-- Instant title -->
        <th v-else-if="type === 'instant'" colspan="2" scope="col">
          <h2 style="text-transform: uppercase">{{ $t('components.instant_card_summary.title') }}</h2>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in properties" :key="item.name">
        <th scope="row" class="h-text-3">
          {{ $t(item.name) }}
          <open-wallet-hint-modal v-if="item.hint" :text="$t(item.hint)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" small right>mdi-help-circle-outline</v-icon>
            </template>
          </open-wallet-hint-modal>
        </th>
        <td>
          <v-icon v-if="item.value === 'Y'" color="success" :aria-label="accessibilityLabel($t(item.name), item.value)" role="img" aria-hidden="false">mdi-check</v-icon>
          <v-icon v-else-if="item.value === 'N'" color="#BDBDBD" :aria-label="accessibilityLabel($t(item.name), item.value)" role="img" aria-hidden="false">mdi-close</v-icon>
          <div v-else-if="typeof item.value === 'number'">
            <span style="display: none" :aria-label="accessibilityLabel($t(item.name), item.value)">{{ $t(item.name) }}</span>
            {{ item.value | currency | currencySymbol }}
          </div>
          <div v-else>
            <span style="display: none" :aria-label="accessibilityLabel($t(item.name), item.value)">{{ $t(item.name) }}</span>
            {{ item.value }}
          </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <!-- Classic Action -->
        <td v-if="type === 'classic'" colspan="2">
          <h-btn rounded :loading="contextReady" @click="$emit('choose')">{{ $t('components.classic_card_summary.submit') }}</h-btn>
          <p class="h-label-4-med-italic fine-print">{{ $t('components.classic_card_summary.fine_print') }}</p>
        </td>

        <!-- Instant Action -->
        <td v-else-if="type === 'instant'" colspan="2">
          <h-btn rounded outlined @click="$emit('choose')">{{ $t('components.instant_card_summary.submit') }}</h-btn>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import OpenWalletHintModal from './OpenWalletHintModal.vue';

export default {
  name: 'card-summary-mobile',
  components: {
    OpenWalletHintModal,
  },
  props: {
    type: {
      type: String,
    },
    properties: {
      type: Array,
      default: () => [],
    },
    contextReady: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    accessibilityLabel(name, value) {
      return `${name}: ${value}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-summary-mobile {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  border: 2px solid var(--color-primary);
  text-align: center;
  background-color: var(--color-ultra-light);

  thead th {
    padding: var(--gap-xlarge);
    height: 100px;
  }
  &.instant-card-summary {
    thead th {
      background: rgba(46, 49, 145, 0.1);
    }
  }

  tbody {
    td {
      padding: 10px var(--gap);
      border: var(--border-thin) solid var(--color-border);
    }
    th {
      font-weight: 500;
      text-align: left;
      padding-left: var(--gap);
      border: var(--border-thin) solid var(--color-border);
    }
  }

  tfoot td {
    text-align: center;
    vertical-align: top;
    padding: 30px 10px;
  }
}

.fine-print {
  max-width: 200px;
  margin: var(--gap) auto 0 auto;
}
</style>
