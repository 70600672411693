<script>
import { VBtn } from 'vuetify/lib';

export default {
  name: 'h-link',
  extends: VBtn,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    text: {
      type: Boolean,
      default: true,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    extraBold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ...VBtn.options.computed.classes.call(this),
        'h-link--bold': this.bold,
        'h-link--extra-bold': this.extraBold,
        'h-link': true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.h-link.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.v-btn.v-btn--text {
  padding: 0;
  height: auto;
  min-width: 0;
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
  text-indent: 0;
  line-height: normal;

  // This is to specify some behaviours when the h-link is used inside a checkbox label
  label & {
    font-size: inherit;
    vertical-align: baseline;
  }

  &:before {
    content: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover:after,
  &:focus:after {
    transform: scaleY(1);
  }

  i.v-icon {
    font-size: 14px;
    height: 14px;
    width: 14px;
    margin-left: 2px;
    margin-top: 3px;

    &:first-child {
      margin-left: 0;
      margin-right: 2px;
    }
  }

  &.v-size--large {
    i.v-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
    }
  }

  &.h-link--bold {
    font-weight: 600;

    &:after {
      height: 2px;
    }
  }

  &.h-link--extra-bold {
    font-weight: 700;

    &:after {
      height: 2px;
    }
  }

  // This is specifically to style <i> or <b> tags that are used in the translation files
  ::v-deep i,
  ::v-deep b {
    line-height: normal;
  }
}
</style>
