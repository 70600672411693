<template>
  <div class="h-label-box h-heading-h2" :style="{ color: textColor }">{{ text }}</div>
</template>

<script>
export default {
  name: 'h-label-box',
  props: {
    textColor: {
      type: String,
      default: 'var(--color-text-primary)',
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.h-label-box {
  padding: var(--gap-small) var(--gap-page-side);
  background-color: color-mix(in srgb, var(--color-branding) 20%, white);
}
</style>
