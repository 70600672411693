import _get from 'lodash/get';

function isBonusOffer(offer) {
  return offer.type === 'bonus';
}

function isGiftCertificateOffer(offer) {
  return offer.type === 'gift_certificate';
}

function isSinglePublisherOffer(offer) {
  return offer.is_single_publisher;
}

function isMultiplePublisherOffer(offer) {
  return !offer.is_single_publisher;
}

function offerStatusIsOneOf(offer, status) {
  return status.includes(offer.status);
}

function consumerIsEnabled(offer) {
  return offer.consumer.enabled;
}

function activationButtonIsHidden(offer) {
  return !_get(offer, 'cta.activation', false);
}

function purchaseButtonIsHidden(offer) {
  return !_get(offer, 'cta.purchase', false);
}

function isConsumerInventoryDepleted(offer) {
  return !_get(offer, 'consumer.purchasable', false);
}
function noCloseLoop(offer) {
  return _get(offer, 'publisher_count', 0) === 0;
}

export { isBonusOffer, isGiftCertificateOffer, isSinglePublisherOffer, isMultiplePublisherOffer, offerStatusIsOneOf, consumerIsEnabled, activationButtonIsHidden, purchaseButtonIsHidden, isConsumerInventoryDepleted, noCloseLoop };
