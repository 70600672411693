<template>
  <fragment>
    <!-- Spinner -->
    <div class="text-center" v-if="!dataReady">
      <v-progress-circular :size="100" :width="7" color="var(--color-branding)" indeterminate />
    </div>

    <div v-else>
      <component :is="bigCardComponent" style="background-color: var(--color-ultra-light)" />

      <div :style="{ zIndex: '6', marginTop: '12px', position: 'sticky', top: `${$vuetify.application.top}px`, backgroundColor: 'var(--color-ultra-light)' }">
        <div ref="test"></div>
        <h-label-box v-if="currentOfferIsGift" :text="$t('components.space_offer_publisher_view.info_gift_certificate')" />
        <h-label-box v-if="currentOfferIsBonus" :text="$t('components.space_offer_publisher_view.info_bonus')" />
        <category-filter-bar v-if="hasMoreThan10Publishers && offerPublishersCategoriesList.length >= 2" @change="publisherCategoryChange" :category-list="offerPublishersCategoriesList" class="category-filter-bar" />
      </div>

      <grid-small-card v-if="showPublisherList && publishersList.length > 0" class="small-grid">
        <component :is="smallCardType" v-for="(publisher, index) in publishersList" :key="index" :publisher="publisher" :offer="currentOffer" :preview="preview" />
      </grid-small-card>
      <div v-else class="no-store-title">
        <h2>{{ $t('components.offer_publishers_display.title') }}</h2>
        <p>{{ $t('components.offer_publishers_display.sub_title') }}</p>
      </div>
    </div>
  </fragment>
</template>

<script>
import PublisherChoiceBonusOffer from '@/components/offer-details/publisher-choice/PublisherChoiceBonusOffer.vue';
import PublisherChoiceGiftCertificateOffer from '@/components/offer-details/publisher-choice/PublisherChoiceGiftCertificateOffer.vue';

import GridSmallCard from '@/components/cards/small/GridSmallCard.vue';
import BonusOfferPublisherSmallCard from '@/components/offer-details/publisher-choice/BonusOfferPublisherSmallCard.vue';
import GiftCertificateOfferPublisherSmallCard from '@/components/offer-details/publisher-choice/GiftCertificateOfferPublisherSmallCard.vue';
import CategoryFilterBar from '@/components/search/bar/CategoryFilterBar.vue';

import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  props: {
    offer_id: {
      type: String,
      required: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PublisherChoiceBonusOffer,
    PublisherChoiceGiftCertificateOffer,
    GridSmallCard,
    BonusOfferPublisherSmallCard,
    GiftCertificateOfferPublisherSmallCard,
    CategoryFilterBar,
  },
  name: 'publisher-choices',
  data() {
    return {
      dataReady: false,
      hasMoreThan10Publishers: false,
      smallCardType: undefined,
      bigCardComponent: undefined,
    };
  },
  async created() {
    this.dataReady = false;

    if (this.currentOfferIsBonus) {
      this.bigCardComponent = 'publisher-choice-bonus-offer';
      this.smallCardType = 'bonus-offer-publisher-small-card';
    }
    if (this.currentOfferIsGift) {
      this.bigCardComponent = 'publisher-choice-gift-certificate-offer';
      this.smallCardType = 'gift-certificate-offer-publisher-small-card';
    }

    const result = await this.getOfferPublisher(null, this.offer_id);
    this.hasMoreThan10Publishers = result.item_count > 9;

    if (this.hasMoreThan10Publishers) {
      await this.execute(
        { action: 'offer/getOfferPublisherCategoriesList' },
        {
          offer_id: this.offer_id,
        },
      );
    }

    this.dataReady = true;
  },
  computed: {
    ...mapGetters('offer', ['currentOffer', 'currentOfferShowStoreList', 'currentOfferIsBonus', 'currentOfferIsGift', 'currentOfferPublishersList', 'offerPublishersCategoriesList']),
    ...mapGetters('space', ['currentSpaceSlug']),
    showPublisherList() {
      return this.preview || this.currentOfferShowStoreList;
    },
    publishersList() {
      if (this.showPublisherList) {
        return this.currentOfferPublishersList;
      }
      return [];
    },
  },
  methods: {
    async getOfferPublisher(category_to_include, offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferPublishers',
        },
        { offer_id, locale: this.currentLocale, category_to_include },
      );
    },
    async publisherCategoryChange(e) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.test.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 9;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
      await this.getOfferPublisher(e, this.offer_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.back-btn {
  min-width: 0 !important;
  height: 60px;
  padding: 0 24px;
}

.no-store-title {
  text-align: center;
  color: var(--color-text-primary);
  background: var(--color-ultra-light);
  padding: var(--gap) var(--gap-xxlarge);

  p {
    margin: var(--gap) 0 0;
  }
}

.category-filter-bar {
  @media ($md-min) {
    padding: var(--gap) var(--gap-page-side) 0;
  }
}

.small-grid {
  background: var(--color-ultra-light);
  padding: var(--gap) var(--gap-xxlarge);
  @media ($content-min) {
    padding: var(--gap);
  }
}
</style>
