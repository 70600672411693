<template>
  <div class="h-label">
    <div class="label h-text-3" :style="{ color: labelColor }">{{ label }}</div>
    <div class="value h-text-3" :style="{ color: valueColor }">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-label',
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: String,
      default: 'value',
    },
    labelColor: {
      type: String,
    },
    valueColor: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: 600;
  line-height: 1;
}

.value {
  margin-top: 8px;
  line-height: 1;
  opacity: 0.65;
}
</style>
