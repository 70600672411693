<template>
  <fragment>
    <v-app-bar app fixed dark color="secondary" :style="appBarZIndex">
      <v-btn :to="{ name: 'home' }" color="secondary" class="logo-btn" depressed :aria-label="$t('components.navbar.logo_alt_text')" />

      <main-mobile-menu v-if="$vuetify.breakpoint.smAndDown" />

      <main-navigation v-else />
    </v-app-bar>

    <header-drawer @transitioning="setAppBarZIndex" />
  </fragment>
</template>

<script>
import { mapActions } from 'vuex';
import MainMobileMenu from '@/components/navbars/MainMobileMenu';
import MainNavigation from '@/components/navbars/MainNavigation';
import HeaderDrawer from '@/components/navbars/HeaderDrawer';

export default {
  name: 'main-header',
  components: { MainMobileMenu, MainNavigation, HeaderDrawer },
  data() {
    return {
      appBarZIndex: '',
    };
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawer']),
    setAppBarZIndex(transitioning) {
      this.appBarZIndex = transitioning ? 'z-index: 203' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar.v-app-bar--fixed {
  margin: 0 auto;
  max-width: 1200px;
  z-index: 10;
}

.logo-btn.v-btn {
  width: 97px;
  height: 46px;
  margin-left: calc(var(--gap-page-side) / 2);
  margin-right: auto;
  padding-left: calc(var(--gap-page-side) / 2);
  padding-right: calc(var(--gap-page-side) / 2);
  background: url('~@/assets/logo.svg') center center no-repeat;
  background-size: 97px;
  box-sizing: content-box;

  &:not(:hover):not(:focus)::before {
    opacity: 0;
  }
}

.main-navigation {
  display: flex;

  ::v-deep .v-list-item {
    flex-basis: auto;
    font-size: 0.875rem !important;
    font-weight: 600;
    letter-spacing: 0.0892857143em;

    @media ($md-min) {
      padding: 0 var(--gap-xsmall);
      margin: 0 calc(16px - var(--gap-xsmall));

      &:last-child {
        margin-right: calc(var(--gap-page-side) - var(--gap-xsmall));
      }
    }
  }
}

::v-deep .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
