class MemoryStorage {
  constructor() {
    console.log('localStorage is not accessible, fallback to Memory Storage.'); // eslint-disable-line no-console
    this.inMemoryStorage = {};
  }
  clear() {
    this.inMemoryStorage = {};
  }
  getItem(name) {
    this.inMemoryStorage[name] || null;
  }
  key(index) {
    return Object.keys(this.inMemoryStorage)[index] || null;
  }
  removeItem(name) {
    delete this.inMemoryStorage[name];
  }
  setItem(name, value) {
    if (!value) {
      this.inMemoryStorage[name] = null;
      return;
    }
    this.inMemoryStorage[name] = String(value);
  }
  get length() {
    return Object.keys(this.inMemoryStorage).length;
  }
}

function isSupportedSetup(getStorage = () => localStorage) {
  try {
    const testKey = '__test_key_for_support__';
    getStorage().setItem(testKey, testKey);
    getStorage().removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export const secureStorage = isSupportedSetup() ? localStorage : new MemoryStorage();
