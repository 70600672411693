<template>
  <div class="single-publisher-consumer-gift-certificate-offer">
    <div class="big-card">
      <consumer-gift-card-carousel :images-urls="carouselImagesHelpers(currentOfferShowcasesImage[currentLocale])" />
      <div class="card">
        <div v-if="$vuetify.breakpoint.smAndDown" class="anchor-menu-mobile">
          <h-btn v-if="storeCount > 1 && showStore" @click="goToAnchor('where-to-use')" class="anchor-menu-item" color="rgba(var(--color-branding-rgb-values), 0.1)" min-width="50">
            {{ $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.where_to_use_mobile') }}</h-btn
          >
          <h-btn @click="goToAnchor('how-it-works')" class="anchor-menu-item" color="rgba(var(--color-branding-rgb-values), 0.1)" min-width="50">
            {{ $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.how_it_works_mobile') }}</h-btn
          >
          <h-btn v-if="corpoEnabled" @click="goToAnchor('corporate')" class="anchor-menu-item" color="rgba(var(--color-branding-rgb-values), 0.1)" min-width="50">{{
            $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.corporate_mobile')
          }}</h-btn>
        </div>

        <div v-else class="anchor-menu-desktop">
          <div v-if="storeCount > 1 && showStore">
            <h-link @click="goToAnchor('where-to-use')" class="anchor-menu-item" color="text-primary"> {{ $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.where_to_use_desktop') }}</h-link>
          </div>
          <div>
            <h-link @click="goToAnchor('how-it-works')" class="anchor-menu-item" color="text-primary"> {{ $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.how_it_works_desktop') }}</h-link>
          </div>
          <div v-if="corpoEnabled">
            <h-link @click="goToAnchor('corporate')" class="anchor-menu-item" color="text-primary"> {{ $t('components.single_publisher_consumer_gift_certificate_offer.anchor_menu.corporate_desktop') }}</h-link>
          </div>
        </div>

        <h1 class="offer-title">{{ currentOfferName }}</h1>

        <div class="propulsion">
          {{ $t('components.single_publisher_consumer_gift_certificate_offer.powered_by') }}
          <div class="logo" alt="Hello"></div>
        </div>

        <div class="offer-region h-text-1" v-if="currentOfferRegion"><v-icon color="var(--color-text-primary)">mdi-map-marker</v-icon> {{ regionLabel }}</div>

        <div v-if="currentOfferDescription" class="h-text-1">{{ currentOfferDescription }}</div>

        <consumer-gift-card-status :status="currentStatus" :cta-purchase="currentOfferCtaPurchase" :allow-sold-out-display="true" :start="currentOfferStartDate"></consumer-gift-card-status>

        <div v-if="destinationItems.length > 1" class="destination-section">
          <p class="h-label-1" style="color: var(--color-branding); text-transform: uppercase">{{ $t('components.single_publisher_consumer_gift_certificate_offer.destination_list') }}</p>
          <div class="destination-grid">
            <destination-button-with-modal v-for="(item, index) in destinationItems" :key="index" :item="item" @seeMore="openStoreModal"></destination-button-with-modal>
          </div>
        </div>

        <merchants-modal v-if="storeCount > 1 && showStore" ref="merchantModal" :offer_id="currentOfferId" :publisher_id="currentOfferPublisherPublisherId">
          <template #activator="{ on, attrs }">
            <div>
              <h-link color="primary" v-bind="attrs" style="font-size: var(--font-size-label-1)" v-on="on" bold
                >{{ $t('components.single_publisher_consumer_gift_certificate_offer.see_store', { storeCount }) }} <v-icon color="primary">mdi-arrow-right</v-icon></h-link
              >
            </div>
          </template>
        </merchants-modal>

        <consumer-gift-card-amount-selector v-if="consumerAmount.length > 0" :amounts="consumerAmount" @update="updateConsumerAmount" />

        <div class="h-text-4" v-if="(currentOfferConsumerPurchasable || currentOfferConsumerPurchasableSoon) && purchaseLimitNotice">
          <p class="notice">{{ purchaseLimitNotice }}</p>
        </div>

        <h-btn v-if="currentOfferConsumerEnabled" :disabled="!currentOfferConsumerPurchasable" style="margin-bottom: 12px" color="var(--color-branding)" :block="$vuetify.breakpoint.smAndDown" x-large @click="openOverlayForConsumerGiftCard">
          {{ $t('components.single_publisher_consumer_gift_certificate_offer.call_to_action_consumer_gift_card') }}
        </h-btn>

        <div class="call-to-action">
          <div>
            <h-link
              v-if="corpoEnabled"
              :disabled="currentStatus !== 'available' || currentOfferPublisherStatus !== 'available'"
              style="font-size: var(--font-size-label-1)"
              bold
              :to="{ name: 'corpo', params: { space: currentSpaceSlug, offer_id: currentOfferId } }"
            >
              {{ $t('components.single_publisher_consumer_gift_certificate_offer.call_to_action_corporate_gift_card') }}
            </h-link>
          </div>

          <div>
            <h-link v-if="currentOfferCtaActivation" :disabled="currentStatus !== 'available' || currentOfferPublisherStatus !== 'available'" style="font-size: var(--font-size-label-1); padding-top: var(--gap-xsmall)" bold @click="openOverlayForRedeem">
              {{ $t('components.single_publisher_consumer_gift_certificate_offer.call_to_action') }}
            </h-link>
          </div>
        </div>
      </div>
    </div>

    <div class="section marketing-section">
      <consumer-gift-card-marketing-card :title="$t('components.consumer_gift_card_marketing_card.title_1')" :text="$t('components.consumer_gift_card_marketing_card.text_1')" :icon="$t('components.consumer_gift_card_marketing_card.icon_1')" />
      <consumer-gift-card-marketing-card :title="$t('components.consumer_gift_card_marketing_card.title_2')" :text="$t('components.consumer_gift_card_marketing_card.text_2')" :icon="$t('components.consumer_gift_card_marketing_card.icon_2')" />
      <consumer-gift-card-marketing-card :title="$t('components.consumer_gift_card_marketing_card.title_3')" :text="$t('components.consumer_gift_card_marketing_card.text_3')" :icon="$t('components.consumer_gift_card_marketing_card.icon_3')" />
    </div>

    <consumer-gift-card-store-marketing
      id="where-to-use"
      v-if="storeCount > 1 && showStore"
      :category-list="initialCategory"
      :closed-loop-size="storeCount"
      :offer_id="currentOfferId"
      :destination-list="currentOfferDestinations"
      :publisher_id="currentOfferPublisherPublisherId"
      @seeMore="openStoreModal"
    />

    <consumer-gift-card-buy-activate-info
      id="how-it-works"
      :show-activate="currentOfferCtaActivation"
      :show-buy="currentOfferConsumerEnabled"
      :disable-activate="currentStatus !== 'available' || currentOfferPublisherStatus !== 'available'"
      :disable-buy="!currentOfferConsumerPurchasable"
      @activate="openOverlayForRedeem"
      @buy="openOverlayForConsumerGiftCard"
    />
    <div class="section quote-section">
      <consumer-gift-card-quote-card :quote="$t('components.consumer_gift_card_quote_card.quote_1')" :client-name="$t('components.consumer_gift_card_quote_card.name_1')" :client-description="$t('components.consumer_gift_card_quote_card.description_1')" />
      <consumer-gift-card-quote-card :quote="$t('components.consumer_gift_card_quote_card.quote_2')" :client-name="$t('components.consumer_gift_card_quote_card.name_2')" :client-description="$t('components.consumer_gift_card_quote_card.description_2')" />
    </div>

    <consumer-gift-card-enterprise-promotion id="corporate" v-if="corpoEnabled" style="margin-bottom: 0" />
    <consumer-gift-card-faq :show-activate="currentOfferCtaActivation" :disable-activate="currentStatus !== 'available' || currentOfferPublisherStatus !== 'available'" @activate="openOverlayForRedeem" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import { determineStatus, findSuggestedAmount } from '@/composables/offer-publisher-helper';
import ConsumerGiftCardMarketingCard from '@/components/consumer-gift-card/base-components/ConsumerGiftCardMarketingCard.vue';
import ConsumerGiftCardBuyActivateInfo from '@/components/consumer-gift-card/base-components/ConsumerGiftCardBuyActivateInfo.vue';
import ConsumerGiftCardQuoteCard from '@/components/consumer-gift-card/base-components/ConsumerGiftCardQuoteCard.vue';
import ConsumerGiftCardEnterprisePromotion from '@/components/consumer-gift-card/base-components/ConsumerGiftCardEnterprisePromotion.vue';
import ConsumerGiftCardFaq from '@/components/consumer-gift-card/base-components/ConsumerGiftCardFaq.vue';
import ConsumerGiftCardCarousel from '@/components/consumer-gift-card/base-components/ConsumerGiftCardCarousel.vue';
import ConsumerGiftCardAmountSelector from '@/components/consumer-gift-card/base-components/ConsumerGiftCardAmountSelector.vue';
import ConsumerGiftCardStoreMarketing from '@/components/consumer-gift-card/base-components/ConsumerGiftCardStoreMarketing.vue';
import ConsumerGiftCardStatus from '@/components/consumer-gift-card/base-components/ConsumerGiftCardStatus.vue';
import DestinationButtonWithModal from '@/components/destination/DestinationButtonWithModal.vue';

import MerchantsModal from '@/components/modals/MerchantsModal';
import { carouselImagesHelpers } from '@/composables/image-helper';

export default {
  name: 'single-publisher-consumer-gift-certificate-offer',
  components: {
    MerchantsModal,
    ConsumerGiftCardMarketingCard,
    ConsumerGiftCardBuyActivateInfo,
    ConsumerGiftCardQuoteCard,
    ConsumerGiftCardEnterprisePromotion,
    ConsumerGiftCardFaq,
    ConsumerGiftCardCarousel,
    ConsumerGiftCardAmountSelector,
    ConsumerGiftCardStoreMarketing,
    ConsumerGiftCardStatus,
    DestinationButtonWithModal,
  },
  setup(props, { root }) {
    const { execute } = safeExecute(root.$store);

    return {
      determineStatus,
      carouselImagesHelpers,
      findSuggestedAmount,
      execute,
    };
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    const corporateDetails = await this.getOfferCorporateDetails(this.currentOfferId);

    if (corporateDetails.enabled) {
      this.corpoEnabled = true;
    }

    if (this.currentOfferConsumerEnabled && this.currentOfferConsumerPurchasable) {
      await this.execute(
        {
          action: 'consumerGiftCard/acquireConsumerGiftCardContext',
        },
        {
          partner_id: this.currentPartnerId,
          offer_id: this.currentOfferId,
        },
      );

      this.minAmount = this.contextMinAmount;
      this.maxAmount = this.contextMaxAmount;
    }

    if (this.showStore) {
      await this.initOfferCategoriesAndStores();
    }

    try {
      // NOTE (MAA) : Used for page reload. Route to hash only after sections are loaded.
      const hash = this.$route.hash;

      // NOTE (SG) Maybe try the CSS.escape() function to resolve issue "... is not a valid selector"
      if (hash && hash !== '' && hash !== '#') {
        document.querySelector(hash).scrollIntoView({ behavior: 'smooth' });
      }
    } catch (err) {
      // NOTE (SG) - Safe gate if cannot scroll into view.
      console.log(err); // eslint-disable-line no-console
    }
  },

  data() {
    return {
      corpoEnabled: false,
      minAmount: null,
      maxAmount: null,
      initialNumStores: 0,
      initialCategory: [],
      store: undefined,
      lastSelectedAmount: null,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentPartnerId', 'currentSpaceSlug']),
    ...mapGetters('consumerGiftCard', ['contextMaxAmount', 'contextMinAmount', 'consumerPurchaseOverLimitNotice']),
    ...mapGetters('offer', [
      'currentOfferId',
      'currentOfferName',
      'currentOfferDescription',
      'currentOfferDestinations',
      'currentOfferStartDate',
      'currentOfferShowcasesImage',
      'currentOfferCtaActivation',
      'currentOfferCtaPurchase',
      'currentOfferStatus',
      'currentOfferRegion',
      'currentOfferPublisherStatus',
      'currentOfferConsumerEnabled',
      'currentOfferConsumerPurchasable',
      'currentOfferConsumerPurchasableSoon',
      'currentOfferConsumerPurchaseLimit',
      'currentOfferConsumerAvailableAmount',
      'currentOfferPublisherPublisherId',
      'currentOfferShowStoreList',
    ]),

    destinationItems() {
      return (this.currentOfferDestinations || [])
        .reduce((acc, destination) => {
          acc.push({
            buttonText: destination[`label_${this.currentLocale}`],
            buttonImageUrl: destination[`showcase_${this.currentLocale}`],
            destination_id: destination.id,
            store: undefined,
            storeComplementaryInfo: undefined,
            showStoreLink: true,
            modalTitle: destination[`label_${this.currentLocale}`],
          });
          return acc;
        }, [])
        .sort((a, b) => {
          return (a.buttonText || '').toLowerCase().localeCompare((b.buttonText || '').toLowerCase());
        });
    },
    minPurchaseLimitAmountByOffer() {
      if (this.currentStatus === 'coming_soon' || !this.currentOfferCtaPurchase) {
        return this.currentOfferConsumerPurchaseLimit.min;
      }
      return this.minAmount;
    },
    maxPurchaseLimitAmountByOffer() {
      if (this.currentStatus === 'coming_soon' || !this.currentOfferCtaPurchase) {
        return this.currentOfferConsumerPurchaseLimit.max;
      }
      return this.maxAmount;
    },
    consumerAmount() {
      return this.findSuggestedAmount([25, 50, 75, 100, 200, 500, 'other'], this.minPurchaseLimitAmountByOffer, this.maxPurchaseLimitAmountByOffer);
    },
    regionLabel() {
      return this.initialNumStores === 1 ? this.information : this.currentOfferRegion;
    },
    currentStatus() {
      return this.determineStatus(this.currentOfferStatus, this.currentOfferPublisherStatus);
    },
    storeCount() {
      return this.initialNumStores;
    },
    showStore() {
      return this.preview || this.currentOfferShowStoreList;
    },
    information() {
      if (this.store) {
        if (this.store.type === 'place') {
          return `${this.$options.filters.address(this.store.location.address)}`;
        }
        if (this.store.type === 'web') {
          return `${this.$t('components.store_card.online')} (${this.store.location.display_url[this.currentLocale]})`;
        }
        if (this.store.branding.description[this.currentLocale] && this.store.branding.description[this.currentLocale] !== null) {
          return this.store.branding.description[this.currentLocale];
        }
      }

      return undefined;
    },
    purchaseLimitNotice() {
      // (SG) DUPLICATE CODE -> src/components/consumer-gift-card/components/ConsumerGiftCardAmountAndMessage.vue:116
      let notice = '';
      if (this.consumerPurchaseOverLimitNotice[this.currentLocale]) {
        notice = `${this.consumerPurchaseOverLimitNotice[this.currentLocale]}`;

        const amount = this.$options.filters.currencySymbol(this.$options.filters.currency(this.maxPurchaseLimitAmountByOffer));
        notice = notice.replace('{amount}', amount);
      }
      return notice;
    },
  },
  methods: {
    goToAnchor(hash) {
      document.querySelector(`#${hash}`).scrollIntoView({ behavior: 'smooth' });

      history.pushState({}, null, this.$route.path + '#' + encodeURIComponent(hash));
    },
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    ...mapActions('consumerGiftCard', ['setConsumerGiftCardPaymentAmount']),
    openStoreModal({ category, destination_id }) {
      this.$refs.merchantModal.openModal({ category, destination_id });
    },
    updateConsumerAmount(selectedAmount) {
      this.setConsumerGiftCardPaymentAmount(selectedAmount);
      this.lastSelectedAmount = selectedAmount;
    },
    async initOfferCategoriesAndStores() {
      if (!this.currentOfferId) {
        return;
      }

      return this.execute(
        {
          action: 'store/getOfferStoreCategoryIds',
          target: 'store_error',
          success: async (res) => {
            const stores = await this.getOfferStores(this.currentOfferId, this.currentOfferPublisherPublisherId);

            this.initialNumStores = stores.length;
            this.initialCategory = res;
          },
        },
        {
          offer_id: this.currentOfferId,
          publisher_id: this.currentOfferPublisherPublisherId,
        },
      );
    },
    async getOfferStores(offer_id, publisher_id, categories_to_include) {
      return this.execute(
        {
          action: 'store/getOfferStores',
          target: 'store_error',
          success: (res) => {
            if (res.length === 1) {
              this.store = res[0];
            }
          },
        },
        {
          offer_id,
          categories_to_include,
          publisher_id,
        },
      );
    },
    openOverlayForRedeem() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferActivation', params: { space: this.currentSpaceSlug, offer_id: this.currentOfferId } });
    },
    openOverlayForConsumerGiftCard() {
      if (this.lastSelectedAmount) {
        this.setConsumerGiftCardPaymentAmount(this.lastSelectedAmount);
      }
      this.openHeaderDrawerWithParams({
        content: 'purchaseGiftCardOverlay',
        payload: {
          partner_id: this.currentPartnerId,
          offer_id: this.currentOfferId,
        },
      });
    },
    async getOfferCorporateDetails(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferCorporateDetails',
        },
        offer_id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-amount-selector,
.destination-section {
  margin-top: calc(var(--gap) * 3);
  @media ($sm-min) {
    margin-top: var(--gap-xxlarge);
  }
}

.destination-grid {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(74px, 1fr));
  grid-template-rows: 1fr;
  justify-items: center;

  @media ($md-min) {
    grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
  }
}

.notice {
  color: var(--color-text-tertiary);
}

.offer-region {
  display: flex;
  & > .v-icon {
    padding-right: 8px;
  }
}
.single-publisher-consumer-gift-certificate-offer {
  > *:not(:last-child) {
    margin-bottom: var(--gap-xxlarge);
    @media ($sm-min) {
      margin-bottom: calc(var(--gap) * 2.5);
    }
  }
}

.anchor-menu-desktop {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-left: 5px;

  & > * {
    width: min-content;
  }

  .anchor-menu-item {
    font-size: var(--font-size-text-3);

    &.v-btn.v-btn--text:after {
      height: 4px;
      bottom: -8px;
      background-color: var(--color-branding);
      left: -5px;
      width: calc(100% + 10px);
    }
  }
}

.anchor-menu-mobile {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-small);

  .anchor-menu-item {
    font-size: var(--font-size-text-2);
    color: var(--color-secondary);
    text-transform: none;
    padding-left: var(--gap-small);
    padding-right: var(--gap-small);
    font-weight: 500;
  }
}

#how-it-works {
  scroll-margin-top: 100px;
}

#where-to-use {
  scroll-margin-top: 100px;
}

#corporate {
  scroll-margin-top: 100px;
}

.section {
  display: flex;

  flex-wrap: wrap;
  gap: var(--gap);
  padding: 0 var(--gap-page-side);
  justify-content: space-evenly;

  &.quote-section > * {
    max-width: 387px;
  }
  &.marketing-section > * {
    width: 350px;
  }
}

.big-card {
  background-color: var(--color-ultra-light);
  display: block;
  @media ($md-min) {
    padding: var(--gap-xlarge) var(--gap-large);
    display: flex;
    gap: var(--gap-large);
  }
}

.consumer-gift-card-carousel {
  @media ($md-min) {
    padding-top: 50px;
    flex-basis: 45%;
  }
}

.card {
  padding: var(--gap) var(--gap) var(--gap) var(--gap);
  & > * {
    margin-bottom: var(--gap-xlarge);
  }
  @media ($md-min) {
    padding: 0;
    flex-basis: 70%;
    & > * {
      margin-bottom: var(--gap);
    }
  }
}
.offer-title {
  margin-bottom: 0px;
  font-weight: 400;
  color: var(--color-branding);
}
.call-to-action {
  text-align: center;
  @media ($md-min) {
    text-align: start;
  }
}

.propulsion {
  display: flex;
  font-size: var(--font-size-label-3);
  line-height: 150%;
  .logo {
    background-color: black;
    -webkit-mask: url('~@/assets/logo.svg') no-repeat center;
    mask: url('~@/assets/logo.svg') no-repeat center;
    height: auto;
    width: 44px;
    margin-left: 5px;
  }
}
</style>
