const FAKE_PLUGINS = {
  FAKE_FIREBASE: 'fake_firebase',
  FAKE_RECAPTCHA: 'fake_recaptcha',
  FAKE_STRIPE: 'fake_stripe',
};

const FAKE_ENV = {
  [FAKE_PLUGINS.FAKE_FIREBASE]: 'VUE_APP_FIREBASE_CAN_USE_FAKE',
  [FAKE_PLUGINS.FAKE_RECAPTCHA]: 'VUE_APP_RECAPTCHA_CAN_USE_FAKE',
  [FAKE_PLUGINS.FAKE_STRIPE]: 'VUE_APP_STRIPE_CAN_USE_FAKE',
};

const FAKE_QUERY = {
  [FAKE_PLUGINS.FAKE_FIREBASE]: 'useFakeFirebase',
  [FAKE_PLUGINS.FAKE_RECAPTCHA]: 'useFakeRecaptcha',
  [FAKE_PLUGINS.FAKE_STRIPE]: 'useFakeStripe',
  default: 'useFake',
};

function canUseFakeService(service) {
  if (process.env.NODE_ENV === 'production') {
    return false;
  }

  const canUseService = JSON.parse(process.env[FAKE_ENV[service]] || 'false');
  return canUseService;
}

function useFakeService(service) {
  if (!canUseFakeService(service)) {
    return false;
  }

  const parameters = new URLSearchParams(window.location.search);
  const defaultUseFake = JSON.parse(parameters.get(FAKE_QUERY.default) || 'false');
  const serviceUseFake = JSON.parse(parameters.get(FAKE_QUERY[service]) || 'false');

  return defaultUseFake || serviceUseFake;
}

function canUseFakeStripe() {
  return canUseFakeService(FAKE_PLUGINS.FAKE_STRIPE);
}

function useFakeFirebase() {
  return useFakeService(FAKE_PLUGINS.FAKE_FIREBASE);
}

function useFakeRecaptcha() {
  return useFakeService(FAKE_PLUGINS.FAKE_RECAPTCHA);
}

function useFakeStripe() {
  return useFakeService(FAKE_PLUGINS.FAKE_STRIPE);
}

module.exports = {
  canUseFakeStripe,
  useFakeFirebase,
  useFakeRecaptcha,
  useFakeStripe,
};
