<template>
  <div :class="{ compact: compact }" class="h-chip-big">
    <div class="top-section">
      <div class="label">{{ $t('components.h_chip_big.pay') }}</div>
      <div class="main-control">
        <v-btn tabindex="-1" aria-label="-" v-if="!isPlusMinusControlHidden" @click="minusAction" :disabled="disableMinus" icon class="plus-minus-btn">
          <v-icon color="ultra-light" :size="compact ? '40px' : '52px'">mdi-minus-circle-outline</v-icon>
        </v-btn>

        <div class="main-control-middle">
          <div class="value" :class="valueSizeClass">{{ pay }}</div>
          <div class="percent">{{ percentage }}</div>
        </div>

        <v-btn tabindex="-1" aria-label="+" v-if="!isPlusMinusControlHidden" @click="plusAction" :disabled="disablePlus" icon class="plus-minus-btn">
          <v-icon color="ultra-light" :size="compact ? '40px' : '52px'">mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="label">{{ $t('components.h_chip_big.obtain') }}</div>
    <div class="value" :class="valueSizeClass">{{ obtain }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-chip-big',
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    pay: {
      type: String,
      default: 'XXX$',
    },
    obtain: {
      type: String,
      default: 'XXX$',
    },
    percentage: {
      type: String,
      default: '+XX%',
    },
    disablePlus: {
      type: Boolean,
      default: false,
    },
    disableMinus: {
      type: Boolean,
      default: false,
    },
    plusAction: {
      type: Function,
      default: () => {},
    },
    minusAction: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isPlusMinusControlHidden() {
      return this.disablePlus && this.disableMinus;
    },
    valueSizeClass() {
      if (this.pay.length >= 8) {
        return 'xsmall';
      }
      if (this.pay.length >= 7) {
        return 'small';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.h-chip-big {
  width: 262px;
  height: 262px;
  padding-top: 36px;
  border-radius: 50%;
  background-color: var(--color-branding);
  background-image: url('~@/assets/big_chip_bg.svg');
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  box-shadow: 4px 6px 9px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 1;

  &.compact {
    width: 196px;
    height: 196px;
    padding-top: 20px;
    box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.5);
  }
}

.top-section {
  color: var(--color-ultra-light);
}

.main-control {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;

  .compact & {
    padding: 0 10px;
  }
}

.main-control-middle {
  flex-grow: 1;
}

.label {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;

  .compact & {
    font-size: 14px;
  }
}

.value {
  font-size: 46px;
  line-height: 46px;
  font-weight: 600;

  &.small {
    font-size: 40px;
  }
  &.xsmall {
    font-size: 36px;
  }
}

.compact .value {
  font-size: 30px;
  line-height: 30px;

  &.small {
    font-size: 28px;
  }
  &.xsmall {
    font-size: 25px;
  }
}

.percent {
  margin-bottom: 20px;
  margin-top: 8px;
  font-size: 36px;
  font-weight: 500;

  .compact & {
    margin-top: 9px;
    font-size: 27px;
  }
}

.plus-minus-btn.v-btn--disabled {
  .v-icon {
    color: var(--color-ultra-light) !important;
    opacity: 0.5;
  }
}
</style>
