<template>
  <h-dialog :closing-x="false" persistent success v-bind="$attrs" v-on="$listeners" ref="dialog" :title="$t('components.corpo_inform_first_account_business_modal.title')">
    <p>{{ $t('components.corpo_inform_first_account_business_modal.text') }}</p>

    <template #action-footer>
      <h-btn min-width="auto" @click="proceedToPayment">{{ $t('components.corpo_inform_first_account_business_modal.confirm_text') }}</h-btn>
    </template>
  </h-dialog>
</template>
<script>
export default {
  name: 'first-business-access-info-modal',
  methods: {
    proceedToPayment() {
      this.close();
      this.$emit('proceedToPayment');
    },
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>
