<template>
  <div v-if="hasOffers" class="home-offers-grid">
    <div class="grid-title">
      <h2>{{ $t('components.consumer_gift_card_list.title') }}</h2>
      <p class="h-label-1 grid-title-subtitle">{{ $t('components.consumer_gift_card_list.subtitle') }}</p>
    </div>

    <grid-small-card>
      <consumer-gift-card-offer-small-card v-for="offer in offers" :key="offer.id" :offer="offer" />
    </grid-small-card>
  </div>
</template>

<script>
import GridSmallCard from '@/components/cards/small/GridSmallCard.vue';

import ConsumerGiftCardOfferSmallCard from '../cards/small/ConsumerGiftCardOfferSmallCard.vue';

export default {
  name: 'home-consumer-gift-card-list',
  props: {
    offers: {
      type: Array,
      default: () => [],
    },
    showEmptyOfferMessage: {
      type: Boolean,
      default: false,
    },
    showStayTunedCard: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
    },
  },
  components: {
    GridSmallCard,
    ConsumerGiftCardOfferSmallCard,
  },
  computed: {
    hasCategory() {
      return !!this.category;
    },
    hasOffers() {
      return this.offers.length >= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-offers-grid {
  margin-bottom: calc(var(--gap) * 3);
}

.grid-title {
  margin-bottom: var(--gap);
}

.grid-title-subtitle {
  color: var(--color-text-tertiary);
}

.grid-title h2 {
  text-transform: uppercase;
  margin-bottom: 6px;
}
</style>
