<template>
  <h-dialog v-model="model" :title="title">
    <template #pre-header>
      <restricted-to-members-badge />
    </template>
    <p>{{ text }}</p>

    <template #action-footer>
      <h-btn @click="subscribe">{{ actionText }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import RestrictedToMembersBadge from '@/components/utilities/RestrictedToMembersBadge.vue';

export default {
  name: 'restricted-to-members-modal',
  components: { RestrictedToMembersBadge },
  setup(props, { root }) {
    const { executeOverlay } = safeExecute(root.$store);

    return {
      executeOverlay,
    };
  },
  computed: {
    ...mapGetters('space', ['currentBrandingName', 'currentPartnerId']),
    ...mapGetters('overlay', ['restrictedToMembersModalIsOpen']),
    ...mapGetters('security', ['authenticated']),
    model: {
      get() {
        return this.restrictedToMembersModalIsOpen;
      },
      set() {
        this.closeRestrictedToMembersModal();
      },
    },
    title() {
      return this.authenticated ? this.$t('components.restricted_to_members_modal.auth_title') : this.$t('components.restricted_to_members_modal.no_auth_title');
    },
    text() {
      return this.authenticated ? this.$t('components.restricted_to_members_modal.auth_text', { space: this.currentBrandingName }) : this.$t('components.restricted_to_members_modal.no_auth_text', { space: this.currentBrandingName });
    },
    actionText() {
      return this.authenticated ? this.$t('components.restricted_to_members_modal.auth_cta') : this.$t('components.restricted_to_members_modal.no_auth_cta');
    },
  },
  methods: {
    ...mapActions('overlay', ['closeRestrictedToMembersModal', 'openHeaderDrawerWithParams']),
    async subscribe() {
      this.closeRestrictedToMembersModal();

      // Note (PLP): setTimeout is used to give time to the subscription modal to start closing before we start the header drawer
      // This could have been nextTick.  With the subscription delay it is possible that is not needed.
      // This is a timing concerned
      if (!this.authenticated) {
        setTimeout(() => this.openHeaderDrawerWithParams({ content: 'auth', payload: { partner_id: this.currentPartnerId } }), 1);
      }
    },
  },
};
</script>
