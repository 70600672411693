<template>
  <big-card-base
    :offer-title="currentOfferName"
    :offer-label="currentOfferCategory | formatCategoryRegion(currentOfferRegion)"
    :offer-description="currentOfferDescription"
    :carousel-images="currentOfferShowcasesImage"
    :restricted-to-members="currentOfferRestrictedToMembers"
  >
    <template #extraContent>
      <h-progress-bar
        :status="currentOfferStatus"
        :current-number="currentOfferAvailabilityAdjustedAvailPaymentAmount"
        :total-number="currentOfferAvailabilityAdjustedTotalPaymentAmount"
        :min-purchase="currentOfferIncentiveMinimumPayment"
        :max-purchase="currentOfferIncentiveAdjustPurchaseLimit"
        :start="currentOfferStartDate"
        :expiration="currentOfferEndDate"
      />
    </template>
  </big-card-base>
</template>
<script>
import { mapGetters } from 'vuex';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';

export default {
  name: 'publisher-choice-bonus-offer',
  components: { BigCardBase },
  computed: {
    ...mapGetters('offer', [
      'currentOfferName',
      'currentOfferCategory',
      'currentOfferRegion',
      'currentOfferDescription',
      'currentOfferShowcasesImage',
      'currentOfferRestrictedToMembers',
      'currentOfferStatus',
      'currentOfferEndDate',
      'currentOfferStartDate',
      'currentOfferIncentiveMinimumPayment',
      'currentOfferIncentiveAdjustPurchaseLimit',
      'currentOfferAvailabilityAdjustedAvailPaymentAmount',
      'currentOfferAvailabilityAdjustedTotalPaymentAmount',
    ]),
  },
};
</script>
