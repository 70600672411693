export function convertColorToRgb(hex) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 or 4 digits + pound sign, ignore the A (#RGB or #RGBA)
  if (hex.length == 4 || hex.length == 5) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];

    // 6 or 8 digits + pound sign, ignore the AA (#RRGGBB or #RRGGBBAA)
  } else if (hex.length == 7 || hex.length == 9) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  const rgb = +r + ',' + +g + ',' + +b;

  return { hex, rgbString: rgb };
}
