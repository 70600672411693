<template>
  <div id="region-selector-dropdown">
    <v-menu z-index="calc(var(--hello-z-index-filter-bar) - 1)" nudge-bottom="4" transition="slide-y-transition" offset-y dark>
      <template v-slot:activator="{ on, attrs }">
        <h-btn class="menu-btn" v-bind="attrs" v-on="on" color="secondary" text min-width="0">
          <v-icon left>mdi-map-marker</v-icon>
          {{ $t(`components.region_selection.regions.${currentRegion}`) }}
          <v-icon right>mdi-chevron-down</v-icon>
        </h-btn>
      </template>
      <v-list elevation="0" color="secondary" dense>
        <v-list-item-group :value="currentSelectedIndex" @change="updateRegion">
          <v-list-item v-for="(region, index) in regionDisplayOptions" :key="index">
            <v-list-item-title>{{ $t(`components.region_selection.regions.${region}`) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';

export default {
  name: 'region-selector-dropdown',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  computed: {
    ...mapGetters('offer', ['currentRegion', 'regionDisplayOptions']),
    ...mapGetters('ui', ['currentLocale']),
    currentSelectedIndex() {
      return this.regionDisplayOptions.indexOf(this.currentRegion);
    },
  },
  methods: {
    async updateRegion(regionIndex) {
      // Clicking again on selected return undefined
      if (regionIndex !== undefined) {
        const region = this.regionDisplayOptions[regionIndex];
        await this.execute(
          {
            action: 'offer/updateRegion',
          },
          region,
        );
        this.$emit('change', region);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-btn {
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid;
  border-radius: var(--border-radius-input);
}
</style>
