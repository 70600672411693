<template>
  <h-dialog v-bind="$attrs" @open="onOpen" @close="onClose" max-width="600" :title="$t('components.email_verification.title')" class="email-verification">
    <p v-html="$t('components.email_verification.description', { email: accountEmail })"></p>

    <div class="input">
      <v-otp-input v-model="code" length="6" type="number" @finish="submit"></v-otp-input>
    </div>

    <hello-form-error class="error-box" />

    <div v-if="working" class="text-center">
      <v-progress-circular :size="100" :width="7" color="primary" indeterminate />
    </div>
    <div v-else>
      <span>{{ $t('components.email_verification.before_try_again') }}</span>
      <span>
        <a href="javascript:void(0);" @click="resetVerificationCode">{{ $t('components.email_verification.try_again') }}</a
        >.
      </span>
    </div>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'email-verification-by-code-modal',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
    };
  },
  data() {
    return {
      code: '',
    };
  },
  computed: {
    ...mapGetters('account', ['accountEmail']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    ...mapActions('error', ['clearError']),
    async submit() {
      await this.executeForm(
        {
          name: 'verifyEmailByCode',
          action: 'account/verifyEmailByCode',
          success: () => {
            this.$emit('success');
          },
        },
        {
          code: this.code,
        },
      );
    },
    async resetVerificationCode() {
      return this.executeForm(
        {
          action: 'account/resetVerificationCode',
          success: () => {
            this.clear();
          },
        },
        {
          language: this.currentLocale,
        },
      );
    },
    clear() {
      this.code = '';
      this.clearError();
    },
    onOpen() {
      this.clear();
    },
    onClose() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.notice {
  margin-top: var(--gap);
}

.error-box {
  margin-bottom: var(--gap);
}

::v-deep .v-otp-input {
  max-width: 450px;
}
</style>
