import { render, staticRenderFns } from "./DestinationButtonListSlider.vue?vue&type=template&id=122b43b1&scoped=true"
import script from "./DestinationButtonListSlider.vue?vue&type=script&lang=js"
export * from "./DestinationButtonListSlider.vue?vue&type=script&lang=js"
import style0 from "./DestinationButtonListSlider.vue?vue&type=style&index=0&id=122b43b1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122b43b1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VSlideGroup,VSlideItem})
