<template>
  <div class="voucher-base-amount">
    <div class="voucher-info" :class="{ 'amount-style-large': amountStyleLarge }">
      <div v-if="text" class="voucher-text">{{ text }}</div>
      <div v-if="subText" class="voucher-subtext">{{ subText }}</div>
      <voucher-base-amount-modal v-if="!hideHint && hintLinkText" :title-text="hintTitle" :activator-text="hintLinkText" :html-content="hintHtmlMessage" />
    </div>
    <div class="voucher-amount" :class="{ 'amount-style-large': amountStyleLarge }">{{ amount | currency_full | currencySymbol }}</div>
  </div>
</template>

<script>
import VoucherBaseAmountModal from '@/components/vouchers/VoucherBaseAmountModal';

export default {
  name: 'voucher-base-amount',
  components: { VoucherBaseAmountModal },
  props: {
    text: {
      type: String,
      default: undefined,
    },
    subText: {
      type: String,
      default: undefined,
    },
    amount: {
      type: Number,
      default: undefined,
    },
    amountStyleLarge: {
      type: Boolean,
      default: false,
    },
    hideHint: {
      type: Boolean,
      default: false,
    },
    hintLinkText: {
      type: String,
      default: undefined,
    },
    hintTitle: {
      type: String,
      default: undefined,
    },
    hintHtmlMessage: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-amount {
  font-size: var(--font-size-subheader);
  line-height: 1.25;

  font-weight: 600;
  line-height: 1;
  margin-left: 8px;
  text-align: right;
  align-self: flex-start;

  &.amount-style-large {
    font-size: 30px;
    font-weight: 700;
  }

  @media ($sm-min) {
    width: 150px;
    flex: 0 0 auto;
    margin-left: 0;
  }
}

.voucher-base-amount {
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.voucher-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1 1 auto;
  align-items: flex-start;

  &.amount-style-large {
    padding-top: 12px; // Note (PLP): Used for multiline alignment
  }
}

.voucher-text {
  line-height: 1;
}

.voucher-subtext {
  font-size: 11px;
  font-style: italic;
  line-height: 1.1;
}
</style>
