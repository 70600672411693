import { computed } from '@vue/composition-api';

export default function createLocalNavbarItem(store, router, execute, DefaultNavigationItem) {
  function getOppositeLocale() {
    for (let locale of store.getters['ui/locales']) {
      if (store.getters['ui/currentLocale'] !== locale.value) {
        return locale.value;
      }
    }
  }

  const computedLocal = computed(() => `components.navbar.language.${getOppositeLocale()}`);
  function clickAction() {
    const newLocale = getOppositeLocale();
    store.dispatch('ui/changeLocale', newLocale, { root: true });
  }

  return new DefaultNavigationItem(computedLocal, { isEnable: true }, clickAction);
}
