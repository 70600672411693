import { computed } from '@vue/composition-api';
import Vue from 'vue';

export default function createFaqNavbarItem(store, router, execute, DefaultNavigationItem) {
  const currentLocale = computed(() => store.getters['ui/currentLocale']);

  function clickAction() {
    window.open(currentLocale.value === 'fr' ? Vue.prototype.$urlProvider.HelloFaqFr : Vue.prototype.$urlProvider.HelloFaqEn, '_blank');
  }

  return new DefaultNavigationItem('components.navbar.faq', { isEnable: true }, clickAction);
}
