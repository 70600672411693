<template>
  <div class="card-container">
    <v-hover v-slot="{ hover }">
      <small-card-base
        :to-url="urlToOffer"
        :class="{ 'on-hover': hover }"
        :restricted-to-members="offer.features.restricted_to_members"
        :image="offer.offer_branding.images.showcases[currentLocale][0]"
        :show-panier-bleu="offer.offer_branding.features.panier_bleu"
        :url="offerUrl"
        :offer-title="offer.offer_branding.label[currentLocale]"
      >
        <template #content>
          <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />
          <h-progress-bar
            class="progress-bar"
            :font-size="13"
            :status="offer.status"
            :start="offer.availability.start_date"
            :expiration="offer.availability.end_date"
            :current-number="offer.availability.inventory.adjusted_avail_payment_amount"
            :total-number="offer.availability.inventory.adjusted_total_payment_amount"
            :min-purchase="offer.incentive.minimum_payment"
            :max-purchase="offer.incentive.adjusted_user_payment_limit"
          />
        </template>
      </small-card-base>
    </v-hover>
    <h-chip-small
      :plus-action="plus"
      :minus-action="minus"
      :pay="payAmount | currency | currencySymbol"
      :obtain="offerObtain | currency | currencySymbol"
      :disable-plus="disablePlus(payAmount, offer.incentive.step, offer.incentive.user_payment_limit, amountLeft)"
      :disable-minus="disableMinus(payAmount, offer.incentive.step, offer.incentive.minimum_payment, amountLeft)"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount, totalAmount } from '@/composables/offer-publisher-helper';
import _get from 'lodash/get';

export default {
  setup(props, { root }) {
    return {
      remainingAmount,
      totalAmount,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
    };
  },
  components: { SmallCardBase, SmallCardBaseBadge },
  name: 'bonus-offer-single-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
  },
  data() {
    return {
      payAmount: this.offer.incentive.default_payment,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug']),
    offerObtain() {
      return (this.offer.incentive.bonus_percent + 1) * this.payAmount;
    },
    amountLeft() {
      return this.remainingAmount(
        this.offer.availability.inventory.total_amount,
        this.offer.availability.inventory.used_amount,
        this.offer.incentive.bonus_display,
        _get(this.offer, 'publisher_inventory.total_amount', undefined),
        _get(this.offer, 'publisher_inventory.used_amount', undefined),
      );
    },
    offerUrl() {
      if (this.offer.offer_branding.url && this.offer.offer_branding.url[this.currentLocale]) {
        return this.offer.offer_branding.url[this.currentLocale];
      }
      return '';
    },

    urlToOffer() {
      return `/${this.currentSpaceSlug}/offers/${this.offer.id}`;
    },
  },
  methods: {
    plus() {
      this.payAmount = this.addAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    minus() {
      this.payAmount = this.removeAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  position: relative;
}

* {
  text-decoration: none !important;
}

::v-deep .h-chip-small {
  position: absolute;
  right: 15px;
  top: 20px;
}
</style>
