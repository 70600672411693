<script>
import { VStepperHeader } from 'vuetify/lib';

export default {
  // Can't name the component "v-stepper-header"
  // Ask PLP
  // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VStepper/VStepper.ts#L105
  name: 'v-stepper-header',
  extends: VStepperHeader,
};
</script>

<style lang="scss" scoped>
.v-stepper__header {
  margin: var(--gap-xsmall) 0;

  @media ($md-min) {
    justify-content: center;
  }
}

::v-deep .v-divider {
  border-top: 3px solid var(--color-border-light) !important;

  @media ($md-min) {
    flex: 0 0 48px;
  }
}
</style>
