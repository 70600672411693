<template>
  <h-dialog v-model="model" error :title="$t('components.app.uncaught_error_modal_title')">
    <p>{{ $t('components.app.uncaught_error_modal_message') }}</p>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'hello-uncaught-error-modal',
  computed: {
    ...mapGetters('error', ['isUncaughtError']),
    model: {
      get() {
        return this.isUncaughtError;
      },
      set() {
        this.clearError();
      },
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
  },
};
</script>
