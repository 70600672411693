import Vue from 'vue';

/*
// NOTE: Debug
async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
*/

export default {
  namespaced: true,
  state: {
    subscription_required: false,
    current_subscription_plan: {},
  },
  getters: {
    currentSubscriptionPlan: (state) => state.current_subscription_plan,

    isSubscriptionRequired: (state) => state.subscription_required,
  },

  mutations: {
    setSubscriptionRequired(state, required) {
      state.subscription_required = required;
    },
    setCurrentSubscriptionPlan(state, plan = {}) {
      state.current_subscription_plan = plan;
    },

    clear(state) {
      state.subscription_required = false;
      state.subscription_plans = [];
    },
  },

  actions: {
    async getPartnerActiveSubscriptionPlan({}, partner_id) {
      return Vue.prototype.$services.subscription.getPartnerActiveSubscriptionPlan({ partner_id });
    },
    setCurrentSubscriptionPlan({ commit }, subscriptionPlan) {
      commit('setCurrentSubscriptionPlan', subscriptionPlan);
    },
    setSubscriptionRequired({ commit }, subscription) {
      commit('setSubscriptionRequired', subscription);
    },
    async createStripeClientPortalSession() {
      const url = await Vue.prototype.$services.subscription.createStripeClientPortalSession();
      window.location = url.url;
    },
    async createStripeClientPortalSessionCancel({}, subscriptionPlanId) {
      const url = await Vue.prototype.$services.subscription.createStripeClientPortalSession();
      window.location = `${url.url}/subscriptions/${subscriptionPlanId}/cancel`;
    },
    async resetSubscriptionStore({ commit }) {
      commit('clear');
    },
  },
};
