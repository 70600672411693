<template>
  <div>
    <v-menu v-model="menuIsOpen" dark offset-y min-width="160" nudge-bottom="4" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="secondary" small depressed fab width="48" height="48" class="menu-activator" :aria-label="$t('components.navbar.nav_alt_text')">
          <v-icon>{{ activatorIcon }}</v-icon>
        </v-btn>
      </template>

      <main-navigation />
    </v-menu>
  </div>
</template>
<script>
import MainNavigation from '@/components/navbars/MainNavigation';

export default {
  name: 'main-mobile-menu',
  components: { MainNavigation },
  data() {
    return {
      menuIsOpen: null,
    };
  },
  computed: {
    activatorIcon() {
      return this.menuIsOpen ? 'mdi-close' : 'mdi-menu';
    },
  },
};
</script>

<style lang="scss" scoped>
.main-navigation {
  display: flex;
  flex-direction: column;

  ::v-deep .v-list-item {
    font-size: 0.8125rem !important;
    justify-content: flex-end;
  }
}

.menu-activator {
  @media ($sm-min) {
    margin-right: 4px;
  }
}
</style>
