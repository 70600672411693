<template>
  <v-app v-cloak class="justify-center">
    <div v-if="backgroundImageUrl && isWebkit && $vuetify.breakpoint.mdAndDown" style="position: sticky; top: 0px; width: 100%">
      <div class="background-image-container">
        <img class="background-image" v-bind:src="backgroundImageUrl" />
      </div>
    </div>
    <main-header />

    <v-main class="main-container" :style="{ backgroundImage: !isWebkit || !$vuetify.breakpoint.mdAndDown ? backgroundImageHelper(backgroundImageUrl) : 'none' }">
      <overlay-request-card-reminder-modal :redirect-link="cardApplyRedirectLink" />
      <subscription-warning-modal />
      <payment-gateway-offline-modal />
      <restricted-to-members-modal />

      <hello-uncaught-error-modal />

      <div class="page-container">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { backgroundImageHelper } from '@/composables/image-helper';
import MainHeader from '@/components/navbars/MainHeader';
import OverlayRequestCardReminderModal from './components/overlays/components/OverlayRequestCardReminderModal.vue';
import SubscriptionWarningModal from './components/subscription/SubscriptionWarningModal.vue';
import RestrictedToMembersModal from '@/components/subscription/RestrictedToMembersModal.vue';
import PaymentGatewayOfflineModal from '@/components/modals/PaymentGatewayOfflineModal.vue';
import { convertColorToRgb } from '@/utils/color/color-helper';

export default {
  name: 'app',
  setup(props, { root }) {
    const UA = navigator.userAgent;
    const isWebkit = /\b(iPad|iPhone|iPod)\b/.test(UA) && /WebKit/.test(UA) && !/Edge/.test(UA) && !window.MSStream;

    return {
      backgroundImageHelper,
      isWebkit,
    };
  },
  components: {
    PaymentGatewayOfflineModal,
    RestrictedToMembersModal,
    MainHeader,
    OverlayRequestCardReminderModal,
    SubscriptionWarningModal,
  },
  computed: {
    ...mapGetters('space', ['backgroundImageUrl', 'brandingColor']),
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_home_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_home_label', 'en'),
      };
    },
  },
  watch: {
    brandingColor: {
      handler() {
        const { hex, rgbString } = convertColorToRgb(this.brandingColor);

        document.documentElement.style.setProperty('--color-branding', hex);
        document.documentElement.style.setProperty('--color-branding-rgb-values', rgbString);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.background-image-container {
  position: absolute;
  top: 0px;
  height: 110vh;
  width: 100%;
  overflow-y: hidden;
}
.background-image {
  height: 100vh;
  width: auto;
  min-width: 100vw;
  translate: calc(-50% + 50vw);
}
.page-container {
  max-width: var(--page-max-width);
  margin: 0 auto;
  padding-bottom: 20px;
  height: 100%;
}
</style>
