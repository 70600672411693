<template>
  <form class="ElementsApp">
    <input ref="test" :value="value" @input="onInput" type="text" maxlength="7" class="InputElement" :class="{ 'is-empty': value.length === 0, 'is-invalid': !cardValid }" placeholder="H1H 1H1" autocorrect="off" v-maska="{ mask: 'A#A #A#' }" />
  </form>
</template>

<script>
import { validatePostalCode } from '@/composables/validations';
import _toUpper from 'lodash/toUpper';

export default {
  name: 'stripe-like-postal-code',
  setup() {
    return {
      validatePostalCode,
    };
  },
  props: {
    value: {
      type: String,
    },
  },
  watch: {
    value() {
      const validationResult = this.validatePostalCode()(this.value);
      if (validationResult === true) {
        this.cardValid = true;
        this.$emit('change', { complete: true });
      } else if ((validationResult === true && this.value.length !== 7) || this.value.length === 0) {
        this.cardValid = true;
        this.$emit('change', { complete: false, error: false });
      } else {
        this.cardValid = false;
        this.$emit('change', { complete: false, error: { message: validationResult } });
      }
    },
  },
  data() {
    return {
      cardZip: '',
      cardValid: true,
    };
  },
  methods: {
    onInput(e) {
      this.$emit('input', _toUpper(this.$refs.test.value));
    },
  },
};
</script>

<style lang="scss" scoped>
.ElementsApp,
.ElementsApp .InputElement {
  outline: none;
  color: #32325d;
  font-weight: 500;
  font-family: Source Code Pro, Consolas, Menlo, monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  + .InputContainer-placeholder--ie {
    opacity: 1;
    color: #cfd7df;
  }
  &::-webkit-input-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &::placeholder {
    opacity: 1;
    color: #cfd7df;
  }

  &.is-invalid {
    + .InputContainer-placeholder--ie {
      opacity: 1;
      color: #ffcca5;
    }
    &::-webkit-input-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &::-moz-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &::placeholder {
      opacity: 1;
      color: #ffcca5;
    }
  }
}

.ElementsApp:not(.is-autofilled) .InputElement:-webkit-autofill {
  color: #32325d;
  -webkit-text-fill-color: #32325d;
}

.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: #e39f48;
  -webkit-text-fill-color: #e39f48;
}

.ElementsApp .InputElement.is-invalid {
  color: #e25950;
}

.ElementsApp:not(.is-autofilled) .InputElement.is-invalid:-webkit-autofill {
  color: #e25950;
  -webkit-text-fill-color: #e25950;
}

.ElementsApp input {
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 1em;
  line-height: 1.2em;
  height: 1.2em;
}
</style>
