<template>
  <fragment>
    <div class="account-card-required">
      <h3 class="header">{{ $t('components.account_card_required.message') }}</h3>
      <div class="below-header">
        <p class="mb-0">{{ $t('components.account_card_required.sub_message') }}</p>
        <h-btn @click="getAcquiringCardContext" :disabled="working" :loading="working" outlined color="ultra-light" class="btn" to="#">{{ $t('components.account_card_required.action') }}</h-btn>
      </div>

      <card-type-selection-modal v-model="showCardTypeSelectionModal" :display-success-in-card-type-selection-modal="true" />

      <account-card-required-error-modal v-model="errorModalIsOpen" />
    </div>
    <hello-target-error class="mt-5 error-box" target="accountcardrequired" />
  </fragment>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import AccountCardRequiredErrorModal from '@/components/account/components/AccountCardRequiredErrorModal';
import CardTypeSelectionModal from '@/components/account/components/CardTypeSelectionModal';

export default {
  name: 'account-card-required',
  components: { AccountCardRequiredErrorModal, CardTypeSelectionModal },
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  props: {
    redirectLink: {
      url: {
        type: String,
        required: true,
      },
      label_en: {
        type: String,
        required: true,
      },
      label_fr: {
        type: String,
        required: true,
      },
    },
  },
  data() {
    return {
      contextUrl: '',
      routeToMyAccount: false,
      errorModalIsOpen: false,
      showCardTypeSelectionModal: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async getAcquiringCardContext() {
      this.executeTarget({
        target: 'accountcardrequired',
        action: 'account/getAcquiringCardContext',
        success: (cardContext) => {
          if (cardContext.classic.available && !cardContext.instant.available) {
            this.createClassicCardApplicationAndOpenNewTab();
          }
          if (cardContext.classic.available && cardContext.instant.available) {
            this.showCardTypeSelectionModal = true;
          }
        },
      });
    },
    async createClassicCardApplicationAndOpenNewTab() {
      await this.executeTarget(
        {
          target: 'accountcardrequired',
          action: 'account/createCardApplicationContext',
          success: (context) => {
            switch (context.result_code) {
              case 'application':
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              case 'join':
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              default:
                this.errorModalIsOpen = true;
                this.routeToMyAccount = true;
            }
          },
        },
        {
          return_url: this.redirectLink.url,
          return_link_label_fr: this.redirectLink.label_fr,
          return_link_label_en: this.redirectLink.label_en,
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.account-card-required {
  padding: var(--gap) var(--gap-page-side);
  background-color: var(--color-error);
  color: var(--color-ultra-light);
}

.header {
  margin-bottom: var(--gap-small);
}

.below-header {
  @media ($sm-min) {
    display: flex;
  }
}

.btn {
  margin: var(--gap-large) 0 0;

  @media ($sm-min) {
    margin: 0 0 0 var(--gap-large);
  }
}

.error-box {
  background: var(--color-ultra-light);
}
</style>
