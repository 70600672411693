<template>
  <h-link color="var(--color-branding)" v-bind="$attrs" v-on="$listeners" class="small-card-base-cta-link">{{ text }}</h-link>
</template>

<script>
export default {
  name: 'small-card-base-cta-link',
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.small-card-base-cta-link {
  display: block;
  margin-top: 10px;
  text-decoration: none !important;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase !important;
}
</style>
