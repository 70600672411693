<template>
  <h-dialog @close="updateRegion('ca')" v-model="modalIsOpen" center-title persistent max-width="680" :title="$t('components.region_selection.modal.title')">
    <div class="button-section">
      <h-btn class="region-buttons" v-for="(region, index) of filterableRegions" :key="index" x-large @click="updateRegion(region)" :disabled="working" color="text-primary">{{ $t(`components.region_selection.regions.${region}`) }}</h-btn>
    </div>
    <div class="bottom-section">
      <h-link class="select-no-region-button" bold color="text-primary" @click="updateRegion('ca')">{{ $t('components.region_selection.modal.no_region') }}</h-link>
    </div>
  </h-dialog>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';

export default {
  name: 'region-selector-modal',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  mounted() {
    this.modalIsOpen = this.currentRegion === undefined;
  },
  computed: {
    ...mapGetters('offer', ['currentRegion', 'filterableRegions']),
  },
  methods: {
    async updateRegion(region) {
      this.modalIsOpen = false;

      await this.execute(
        {
          action: 'offer/updateRegion',
        },
        region,
      );

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--gap-xsmall);
}

.region-buttons {
  border-radius: var(--border-radius-input);
  font-size: var(--font-size-regular);
}

.bottom-section {
  margin-top: var(--gap);
  display: flex;
  align-items: center;
}

.select-no-region-button {
  font-size: var(--font-size-h4);
  margin: 0 auto;
  flex-shrink: 2;

  ::v-deep .v-btn__content {
    max-width: 100%;
    white-space: normal;
    word-break: break-word;
  }
}
</style>
