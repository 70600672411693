<template>
  <div role="heading" :aria-level="ariaLevel" class="h-subsection-header h-heading-h4">{{ text }}</div>
</template>

<script>
export default {
  name: 'h-subsection-header',
  props: {
    text: {
      type: String,
      default: 'Title',
    },
    ariaLevel: {
      type: Number,
      default: 4,
    },
  },
};
</script>
<style lang="scss" scoped>
.h-subsection-header {
  margin-bottom: var(--gap);
}
</style>
