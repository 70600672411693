<template>
  <ol class="flow-steps">
    <li v-for="(item, index) in infoSteps" :key="index" :style="`flex-basis: ${itemsFlexBasis}`">
      <div class="item-icon-container">
        <img class="item-icon" :src="require('@/assets/' + item.src)" :alt="item.alt" />
      </div>
      <div class="text-container">
        <div class="circle">
          {{ item.step }}
        </div>
        <div class="h-text-3 info-text">
          {{ item.info_text }}
        </div>
        <div class="info-subtext" v-if="showInfoSubtext(item)">
          <div v-if="item.info_subtext" class="h-text-4">
            {{ item.info_subtext }}
          </div>
          <slot :name="`step-${item.step}-subtext`"></slot>
        </div>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'h-flow-info',
  props: {
    infoSteps: {
      type: Array,
      required: true,
    },
  },
  computed: {
    itemsFlexBasis() {
      if (this.infoSteps.length === 0) return '0px';
      return `${100 / this.infoSteps.length - 5}%`;
    },
  },
  methods: {
    showInfoSubtext(item) {
      return item.info_subtext || this.$slots[`step-${item.step}-subtext`];
    },
  },
};
</script>

<style lang="scss" scoped>
.flow-steps {
  text-align: left;
  list-style-type: none;

  max-width: 95%;
  margin: 0 auto;
  padding-left: 0;

  --circle-radius: 10px;

  --circle-diameter: calc(2 * var(--circle-radius));

  @media ($sm-min) {
    --circle-radius: 12px;
  }

  .text-container {
    display: grid;
    grid-template-columns: var(--circle-diameter) 12px 1fr;
    grid-template-rows: var(--circle-diameter) 1fr;

    .info-text {
      grid-column-start: 3;
      grid-row: 1 / span 2;
    }

    .info-subtext {
      margin-top: var(--gap-xxsmall);
      grid-column-start: 3;
      grid-row: 3 / span 2;
      white-space: pre-line;
    }
  }

  .circle {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background: var(--color-text-primary);
    display: inline-block;
    line-height: 1.4;
    font-size: var(--circle-radius);
    margin-right: var(--gap-small);
    margin-left: var(--circle-offset);
    align-content: center;
  }

  .item-icon-container {
    text-align: center;
    align-content: center;
    height: 114px;
    margin-bottom: var(--gap-xsmall);
  }

  @media ($md-min) {
    max-width: none;
    display: flex;
    flex-basis: auto;
    justify-content: space-around;
  }

  li:not(:last-child) {
    margin-bottom: calc(var(--gap) * 3);
    @media ($md-min) {
      margin-bottom: 0;
    }
  }
}
</style>
