export function splitAddress(fullAddress) {
  const addressSegments1 = fullAddress.split(' #');
  const apartmentNumber = addressSegments1[1];
  const addressSegments2 = addressSegments1[0].split(/ (.*)/s);
  const civicNumber = addressSegments2[0];
  const street = addressSegments2[1];

  return {
    street,
    civicNumber,
    apartmentNumber,
  };
}
