<template>
  <div class="consumer-gift-card-marketing-card">
    <v-icon size="32" class="consumer-gift-card-marketing-card-icon">{{ icon }}</v-icon>
    <h3 class="consumer-gift-card-marketing-card-title">{{ title }}</h3>
    <p class="consumer-gift-card-marketing-card-description">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'consumer-gift-card-marketing-card',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.consumer-gift-card-marketing-card {
  --consumer-gift-card-marketing-card-padding: 24px;
  padding: var(--consumer-gift-card-marketing-card-padding);
  background-color: var(--color-ultra-light);
  border-radius: var(--border-radius-cards);
  display: flex;
  flex-flow: column;
  position: relative;
  color: var(--color-text-primary);
  text-align: center;
}

.consumer-gift-card-marketing-card-title {
  margin-bottom: calc(var(--consumer-gift-card-marketing-card-padding) * 0.5);
}

.consumer-gift-card-marketing-card-icon {
  margin: 0 auto var(--consumer-gift-card-marketing-card-padding);
  padding: 15px;
  color: var(--color-ultra-light);
  background-color: var(--color-branding);
  border-radius: 50%;
}
.consumer-gift-card-marketing-card-description {
  font-size: var(--font-size-text-3);
}
</style>
