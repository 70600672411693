<template>
  <h-dialog @open="resetForm" ref="dialog" :title="$t('components.consumer_gift_card_send_by_email_modal.modal_title')">
    <template v-slot:activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <h-form v-if="viewName === 'pending'" ref="form" @submit.prevent="submit" v-model="valid">
      <h-text-field autofocus v-model="name" maxlength="50" :label="$t('components.consumer_gift_card_send_by_email_modal.label_name')" :rules="nameRules" />
      <h-text-field v-model="email" maxlength="255" :label="$t('components.consumer_gift_card_send_by_email_modal.label_address')" :rules="emailRules" />
      <h-text-field v-model="sender_name" :label="$t('components.consumer_gift_card_send_by_email_modal.label_sender_name')" :rules="senderNameRules" maxlength="50" />

      <hello-form-error />

      <p>{{ $t('components.consumer_gift_card_send_by_email_modal.language_radios.title') }}</p>

      <v-radio-group v-model="language" mandatory>
        <v-radio class="language-radios" v-for="(option, index) in languageOptions" :key="index" :value="option.value">
          <template v-slot:label>
            <div class="h-text-1" style="color: var(--color-text-primary)">{{ $t(`components.consumer_gift_card_send_by_email_modal.language_radios.${option.label_key}.label`) }}</div>
          </template>
        </v-radio>
      </v-radio-group>

      <p>{{ $t('components.consumer_gift_card_send_by_email_modal.modal_text_1') }}</p>

      <h-action-layout class="bottom-btn-zone">
        <h-btn type="submit" large :disabled="!valid" :loading="working">{{ $t('components.consumer_gift_card_send_by_email_modal.send_action') }}</h-btn>
        <h-btn @click="closeConsumerGiftCardSendByEmailModal" :disabled="working" large outlined inverted color="primary">{{ $t('components.consumer_gift_card_send_by_email_modal.cancel_action') }}</h-btn>
      </h-action-layout>
    </h-form>

    <div v-if="viewName === 'success'" class="view">
      <v-icon size="40" color="success">mdi-check-circle-outline</v-icon>
      <p style="text-align: left">{{ $t('components.consumer_gift_card_send_by_email_modal.sent_to_message') }}</p>
      <p>{{ name }}</p>
      <p>{{ email }}</p>
    </div>

    <div v-if="viewName === 'error'" class="view">
      <v-icon size="40" color="error">mdi-close-circle-outline</v-icon>
      <p>{{ $t('components.consumer_gift_card_send_by_email_modal.sent_to_message_error') }}</p>
    </div>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { validateInvalidEmail, validateMaxLength, validateRequired } from '@/composables/validations';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'consumer-gift-card-send-by-email-modal',
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      execute,
      executeForm,
      working,
      validateRequired,
      validateMaxLength,
      validateInvalidEmail,
    };
  },
  props: {
    voucherId: {
      type: String,
    },
  },
  data() {
    return {
      success: null,
      valid: false,
      name: '',
      sender_name: '',
      email: '',
      language: '',
      nameRules: [this.validateRequired('name'), this.validateMaxLength(50)],
      senderNameRules: [this.validateRequired('sender_name'), this.validateMaxLength(50)],
      emailRules: [this.validateRequired('email'), this.validateInvalidEmail(), this.validateMaxLength(255)],
      languageOptions: [
        { label_key: 'french', value: 'fr' },
        { label_key: 'english', value: 'en' },
      ],
    };
  },
  computed: {
    viewName() {
      if (this.success === false) {
        return 'error';
      }
      if (this.success === true) {
        return 'success';
      }
      return 'pending';
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    resetForm() {
      this.clearError();
      if (this.$refs.form) {
        this.$refs.form.validationReset();
      }
      this.success = null;
      this.valid = false;
      this.name = '';
      this.sender_name = '';
      this.email = '';
      this.language = '';
    },
    closeConsumerGiftCardSendByEmailModal() {
      this.resetForm();
      this.$refs.dialog.close();
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const res = await this.executeForm(
        {
          name: 'sendByEmail',
          action: 'consumerGiftCard/sendConsumerGiftCardByEmail',
        },
        {
          voucher_id: this.voucherId,
          recipient_first_name: this.name,
          recipient_email: this.email,
          sender_name: this.sender_name,
          language: this.language,
        },
      );

      if (!res || (res && res.errorType)) {
        this.success = false;
        return;
      }

      this.$emit('success');
      this.success = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn-zone {
  margin-top: var(--gap-xxlarge);
}

.view {
  text-align: center;
  margin-top: var(--gap-xlarge);
  & > .v-icon {
    margin-bottom: var(--gap-xlarge);
  }
}
</style>
