<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" error :title="$t('components.overlay_request_card.apply_url_error_modal_title')">
    <p>{{ $t('components.overlay_request_card.apply_url_error_modal_message') }}</p>
  </h-dialog>
</template>

<script>
export default {
  name: 'account-card-required-error-modal',
};
</script>
