<template>
  <h-form ref="form" v-model="valid" @submit.prevent="submit()" class="swap-register-form">
    <h-step-header :text="$t('components.register_form.title')" />

    <h-text-field :label="$t('components.register_modal.email_field')" type="email" maxlength="255" v-model="emailModal" :rules="emailRules" required data-cy="signinEmailField" class="mt-3" autocomplete="email" />

    <h-text-field
      onpaste="return false;"
      ondrop="return false;"
      autocomplete="off"
      :label="$t('components.register_modal.confirm')"
      type="email"
      maxlength="255"
      v-model="emailConfirmationModal"
      :rules="emailRules"
      required
      data-cy="signinEmailFieldConfirm"
      :error-messages="validateTwoEmailMatchErrorMessageBinding(emailModal, emailConfirmationModal)"
    />

    <h-text-field :label="$t('components.register_modal.password_field')" type="password" maxlength="50" data-cy="signinPasswordField" v-model="password" :rules="passwordRules" required />

    <hello-form-error />

    <h-btn class="register-button" block type="submit" :disabled="!valid || working" data-cy="registerSubmitBtn" :loading="working" min-width="160px">{{ $t('components.register_modal.action') }}</h-btn>

    <p class="login-text">
      {{ $t('components.register_form.have_account.1') }}<h-btn @click="goToLogin" text class="login-link">{{ $t('components.register_form.have_account.2') }}</h-btn
      >{{ $t('components.register_form.have_account.3') }}
    </p>
  </h-form>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import { validateInvalidEmail, validateMaxLength, validateInvalidPassword, validateRequired, validateTwoEmailMatchErrorMessageBinding } from '@/composables/validations';

export default {
  name: 'swap-register-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateInvalidPassword,
      validateRequired,
      validateMaxLength,
      validateInvalidEmail,
      validateTwoEmailMatchErrorMessageBinding,
    };
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      emailModal: '',
      emailConfirmationModal: '',
      password: '',
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
      passwordRules: [this.validateRequired('password'), this.validateMaxLength(50), this.validateInvalidPassword()],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('security', ['lastFailedSignInEmail']),
  },
  mounted() {
    if (this.lastFailedSignInEmail) {
      this.emailModal = this.lastFailedSignInEmail;
    }

    this.recaptcha('registerSubmit');
  },
  methods: {
    ...mapActions('error', ['clearError']),
    async goToLogin() {
      await this.clearError();
      this.$emit('swap');
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.executeForm(
          {
            name: 'registerView2',
            action: 'security/userRegister',
            success: () => {
              this.dialog = false;
            },
          },
          {
            email: this.emailModal,
            password: this.password,
            language: this.currentLocale,
            recaptcha_token: await this.recaptcha('registerSubmit'),
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swap-register-form {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 0 var(--gap) var(--gap-xlarge);
}

.register-button {
  align-self: center;
  margin-top: var(--gap);
}

.login-text {
  align-self: center;
  margin: var(--gap-xlarge) 0;
}

.login-link {
  min-width: 0 !important;
  padding: 0 !important;
  height: auto !important;
  font-size: inherit !important;
  text-decoration: underline;
  text-transform: none;
  vertical-align: baseline;
}
</style>
