<script>
import { VSelect } from 'vuetify/lib';

export default {
  extends: VSelect,
  name: 'h-select-branding-color',

  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    menuProps: {
      type: Object,
      default() {
        return {
          transition: 'slide-y-transition',
          offsetY: true,
          contentClass: 'branding-color-menu',
        };
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    shaped: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLabelActive() {
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-text-field {
  // text-transform: uppercase;
  margin-top: 0;
}

.v-input__slot {
  padding: 0 14px;
  margin-bottom: 6px;
  height: 48px;
  transition: border-color 0.3s;
  background-color: color-mix(in srgb, rgba(var(--color-branding-rgb-values), 0.2), white);

  &:before,
  &:after {
    content: none !important;
  }

  .v-label {
    left: 14px !important;
    top: -25px;
    font-weight: 600;
    transform: none;
    max-width: 100%;
    transition: color 0.3s;
    pointer-events: auto;
  }

  .v-input--is-focused & {
    border-width: 2px;
    padding: 0 13px;

    .v-label {
      left: 13px !important;
      top: -26px;
    }
  }

  .v-input__append-inner {
    align-self: center;
    margin-top: 0;
    padding-left: 6px;
  }
}

.v-select__slot {
  position: static;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

.v-messages {
  padding: 0 14px;
}

.v-menu__content {
  box-shadow: var(--card-shadow);
}

::v-deep .v-list-item--link:before {
  background-color: var(--color-branding);
}

::v-deep .v-list-item--active .v-list-item__title {
  color: var(--color-text-primary) !important;
}

::v-deep .v-list-item__title {
  // text-transform: uppercase;
}
</style>
