<template>
  <div class="home-view">
    <account-email-pending-verification v-if="authenticated && emailNeedVerification && !canRequestCard" />
    <account-card-required v-if="authenticated && canRequestCard" :redirect-link="cardApplyRedirectLink" />

    <recently-visited-space-widget :enable-discover="false" />

    <div ref="hashScrollAnchor"></div>

    <category-filter-bar @change="offerCategoryChange" :category-list="homeOfferCategoriesList" dark use-router-params class="category-filter-bar" :style="{ top: `${$vuetify.application.top}px` }" />

    <v-progress-circular v-if="!dataReady" :style="{ marginTop: '50px', marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" indeterminate color="primary" />
    <div v-else class="home-content">
      <div class="region-selector">
        <region-selector-dropdown @change="offerRegionChange" />
      </div>

      <div v-if="offersReady">
        <home-offers-by-region v-if="currentRegionIsFilterable" :list-all-action="listAllFromCurrentRegion" :offers="offers" :category="category" :load-more-loading="working" />
        <home-offers-separated-by-regions :category="category" v-else :offers-by-region="offersByRegion" />
        <home-consumer-gift-card-list :offers="giftCertificateConsumerEnabledOfferList" />
      </div>
    </div>

    <region-selector-modal @close="offerRegionChange" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import CategoryFilterBar from '@/components/search/bar/CategoryFilterBar.vue';
import AccountEmailPendingVerification from '@/components/account/AccountEmailPendingVerification.vue';
import AccountCardRequired from '@/components/account/AccountCardRequired.vue';
import RegionSelectorModal from '../search/region/RegionSelectorModal.vue';
import RegionSelectorDropdown from '../search/region/RegionSelectorDropdown.vue';
import HomeOffersSeparatedByRegions from '../home-offers-list/HomeOffersSeparatedByRegions.vue';
import HomeOffersByRegion from '../home-offers-list/HomeOffersByRegion.vue';
import _map from 'lodash/map';
import RecentlyVisitedSpaceWidget from '../space/RecentlyVisitedSpaceWidget.vue';

import HomeConsumerGiftCardList from '../home-offers-list/HomeConsumerGiftCardList.vue';

export default {
  name: 'home-view',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  components: {
    RecentlyVisitedSpaceWidget,
    CategoryFilterBar,
    AccountEmailPendingVerification,
    AccountCardRequired,
    RegionSelectorModal,
    RegionSelectorDropdown,
    HomeOffersSeparatedByRegions,
    HomeOffersByRegion,
    HomeConsumerGiftCardList,
  },
  title: (args) => args.$t('page_title.home'),
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.home');
    },
  },
  props: {
    openOverlay: {
      type: Boolean,
    },
    openRegister: {
      type: Boolean,
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      offers: [],
      offersByRegion: {},
      offersByStatus: {},
      offersWithConsumerGiftCertificate: [],
      focusStatus: 'available',
      statusOffersExtraFocusedOffersLoaded: 0,
      currentCategory: undefined,
      dataReady: false,
      offersReady: false,
    };
  },
  async created() {
    // MIGRATION (PLP): This is to ensure redirection for old link
    if (this.$route.query['email-verification-token']) {
      return this.$router.pushNoDuplicate({ name: 'email-verification', query: { ...this.$route.query }, params: { _position: { x: 0, y: window.scrollY } } });
    }
    if (this.openOverlay) {
      this.toggleOverlayForAuth();
    }
    if (this.openRegister) {
      this.openRegisterModalWithoutOverlay();
    }

    if (this.category) {
      if (this.homeOfferCategoriesList.includes(this.category)) {
        await this.loadTheOfferList(this.category);
      } else {
        this.$router.replaceNoDuplicate({ query: {} });
        await this.loadTheOfferList(null);
      }
    } else {
      await this.loadTheOfferList(null);
    }

    this.dataReady = true;
  },
  async mounted() {
    await this.listPublicSpaces();
  },
  computed: {
    ...mapGetters('account', ['canRequestCard', 'emailNeedVerification']),
    ...mapGetters('space', ['publicSpaces', 'publicSpaceByPartnerIdSlugGetter']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('offer', ['homeOfferCategoriesList', 'currentRegion', 'filterableRegions', 'currentRegionIsFilterable', 'statusSectionBaseOfferLimit', 'giftCertificateConsumerEnabledOfferList']),
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_home_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_home_label', 'en'),
      };
    },
    focusOffers() {
      return this.offersByStatus[this.focusStatus] || { items: [], item_count: 0 };
    },
    statusOffersLoadMoreLimit() {
      return this.focusOffers.items.length < 3 * this.statusSectionBaseOfferLimit ? this.statusSectionBaseOfferLimit : 2 * this.statusSectionBaseOfferLimit;
    },
  },
  methods: {
    ...mapActions('space', ['listPublicSpaces']),
    ...mapActions('overlay', ['toggleOverlayForAuth', 'openRegisterModalWithoutOverlay']),

    async loadTheOfferList(category) {
      this.offersReady = false;

      if (this.currentRegionIsFilterable) {
        await this.listHomeOffers(category);
      } else {
        await this.listHomeOffersSeparatedByRegions(category);
      }

      await this.listConsumerGiftCertificateOffers(category);

      this.offersReady = true;
    },

    async listConsumerGiftCertificateOffers(category = null) {
      let currentRegion;

      if (this.currentRegion) {
        currentRegion = this.currentRegion.toUpperCase() !== 'CA' ? this.currentRegion.toUpperCase() : undefined;
      }
      await this.execute(
        {
          action: 'offer/listGiftCertificateConsumerEnabledOffers',
          success: (consumerGiftCertificateOffers) => {
            this.consumerGiftCertificateOffers = consumerGiftCertificateOffers;
          },
        },
        { locale: this.currentLocale, category_to_include: category, region_to_include: currentRegion },
      );
    },

    async listHomeOffersSeparatedByRegions(category = null) {
      await this.execute(
        {
          action: 'offer/listHomeOffersSeparatedByRegions',
          success: (offersByRegion) => {
            this.offersByRegion = offersByRegion;
          },
        },
        { locale: this.currentLocale, category_to_include: category, region_to_include: this.currentRegion },
      );
    },

    async listHomeOffers(category = null, focus_items_limit = this.statusSectionBaseOfferLimit) {
      await this.execute(
        {
          action: 'offer/listHomeOffers',
          success: (offers) => {
            this.offers = offers.items;
          },
        },
        { locale: this.currentLocale, category_to_include: category },
      );
    },

    async loadMoreFocusedStatusOffers() {
      const currentNumFocusedItems = this.statusSectionBaseOfferLimit + this.statusOffersExtraFocusedOffersLoaded;
      const page = Math.floor(currentNumFocusedItems / this.statusOffersLoadMoreLimit) + 1;

      await this.execute(
        {
          action: 'offer/loadMoreOffersByStatus',
          success: (offers) => {
            this.statusOffersExtraFocusedOffersLoaded += this.statusOffersLoadMoreLimit;

            const existingOfferIds = _map(this.focusOffers.items, (offer) => offer.id);
            const incomingOfferIds = _map(offers.items, (offer) => offer.id);
            const intersection = existingOfferIds.filter((id) => incomingOfferIds.includes(id));
            if (intersection.length > 0 || offers.item_count !== this.focusOffers.item_count) {
              return this.listHomeRegionOffersGroupByStatus(this.category, currentNumFocusedItems + this.statusOffersLoadMoreLimit);
            }

            this.offersByStatus[this.focusStatus].items.push(...offers.items);
          },
        },
        { locale: this.currentLocale, category_to_include: this.category, region_to_include: this.currentRegion, status_to_include: this.focusStatus, page, limit: this.statusOffersLoadMoreLimit },
      );
    },

    async offerCategoryChange(categoryChangeEvent) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.hashScrollAnchor.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 9;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
      this.statusOffersExtraFocusedOffersLoaded = 0; // Note (PLP): Need to reset the load more loaded set when changing category and clearing the list
      await this.loadTheOfferList(categoryChangeEvent);
    },

    async offerRegionChange() {
      this.loadTheOfferList(this.category);
    },

    listAllFromCurrentRegion() {
      this.$router.pushNoDuplicate({ name: 'homeRegion', params: { region: this.currentRegion }, query: { category: this.category } });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-filter-bar {
  z-index: var(--hello-z-index-filter-bar);
  position: sticky;
}

.region-selector {
  margin-bottom: var(--gap-xxlarge);
  @media ($sm-min) {
    margin-bottom: var(--gap-xlarge);
  }
}

.home-content {
  padding: var(--gap);
  @media ($content-min) {
    padding: var(--gap) 0;
  }
}
</style>
