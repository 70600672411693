<template>
  <div class="change-password-view">
    <div class="change-password-view-content">
      <h-step-header class="h-step-header" :text="$t('components.change_password_view.title')" />
      <h-form ref="form" v-model="valid" @submit.prevent="submit">
        <h-text-field ref="password" :label="$t('components.change_password_view.password_label')" type="password" maxlength="50" required v-model="password" :rules="passwordRules" data-cy="changePasswordPasswordField" />
        <hello-content-error />
        <div class="text-right">
          <h-btn type="submit" :disabled="!valid || working" data-cy="changePasswordSubmitBtn" :loading="working">{{ $t('components.change_password_view.action') }}</h-btn>
        </div>
      </h-form>
    </div>
  </div>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import { validateInvalidPassword, validateMaxLength, validateRequired } from '@/composables/validations';

export default {
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
      validateMaxLength,
      validateInvalidPassword,
      validateRequired,
    };
  },
  name: 'change-password-view',
  title: (args) => args.$t('page_title.change_password'),
  mixins: [recaptcha],
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.change_password');
    },
  },
  props: {
    token: String,
  },
  created() {
    this.recaptcha('changePasswordView');
  },
  data() {
    return {
      valid: false,
      password: '',
      passwordRules: [this.validateRequired('password'), this.validateMaxLength(50), this.validateInvalidPassword()],
    };
  },
  computed: {
    ...mapGetters('space', ['currentSpaceSlug']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    ...mapActions('ui', ['setInfoMessage']),
    async submit() {
      if (this.$refs.form.validate()) {
        await this.executeContent(
          {
            name: 'changePasswordView',
            action: 'security/changePassword',
            success: () => {
              this.setInfoMessage(this.$t('components.change_password_view.success_message'));
              if (this.currentSpaceSlug) {
                this.$router.replaceNoDuplicate({ name: 'spaceHome', params: { space: this.currentSpaceSlug } });
              } else {
                this.$router.replaceNoDuplicate({ name: 'home' });
              }
            },
            failure: (...err) => {
              // FIXME (SG) VERIFY IF REQUIRED HERE
              console.log(err); // eslint-disable-line no-console
            },
          },
          {
            password: this.password,
            token: this.token,
            recaptcha_token: await this.recaptcha('changePasswordSubmit'),
          },
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.change-password-view {
  padding: var(--gap-xlarge) var(--gap-page-side);
  height: 100%;
  background-color: var(--color-ultra-light);
}

.change-password-view-content {
  max-width: 380px;
  margin: 0 auto;
}

.h-step-header {
  margin-bottom: var(--gap);
}

::v-deep .h-error-box {
  margin: var(--gap) 0;
}
</style>
