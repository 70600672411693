var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-filter-bar"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"mobile-bar",style:(_vm.mobileBarStyles),attrs:{"id":"category-filter-mobile-menu-bar"}},[(_vm.dark)?_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","attach":"#category-filter-mobile-menu-bar"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h-btn',_vm._g(_vm._b({staticClass:"mobileBtn",style:(_vm.mobileActivatorStyles),attrs:{"color":"var(--color-branding)","inverted":!_vm.dark,"min-width":"0"}},'h-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(("components.home_search_bar.category." + (_vm.sortedCategoryList[_vm.currentCategory]))))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1845609816)},[_c('v-list',{attrs:{"dark":_vm.dark,"color":_vm.mobileListColor,"dense":""}},[_c('v-list-item-group',{attrs:{"value":_vm.currentCategory,"color":_vm.mobileListItemGroupColor},on:{"change":_vm.categoryChange}},_vm._l((_vm.sortedCategoryList),function(category,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(("components.home_search_bar.category." + category))))])],1)}),1)],1)],1):_c('h-select-branding-color',{attrs:{"hide-details":"","value":_vm.currentCategory,"items":_vm.sortedCategoryList.map(function (e, index) {
          return {
            text: this$1.$t(("components.home_search_bar.category." + e)),
            value: index,
          };
        })},on:{"input":_vm.categoryChange}})],1):_c('v-tabs',{attrs:{"value":_vm.currentCategory,"dark":_vm.dark,"background-color":_vm.desktopTabsBackgroundColor,"color":_vm.desktopTabsColor,"show-arrows":"","icons-and-text":""},on:{"change":_vm.categoryChange}},_vm._l((_vm.sortedCategoryList),function(category,index){return _c('v-tab',{key:index},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.categoryIcon(category)))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t(("components.home_search_bar.category." + category))))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }