<template>
  <div class="flow-info">
    <div class="info-header">
      <div class="outer-icon">
        <v-icon class="inner-icon" color="ultra-light">mdi-account</v-icon>
      </div>
      <h4 class="header-title">{{ $t('components.redeem_flow_info.title') }}</h4>
      <div class="header-subtext">{{ $t('components.redeem_flow_info.subtitle') }}</div>
    </div>

    <h-flow-info :info-steps="infoSteps"></h-flow-info>
  </div>
</template>
<script>
export default {
  name: 'redeem-flow-info',
  computed: {
    infoSteps() {
      return [
        {
          step: 1,
          src: 'login_form.svg',
          alt: this.$t('components.redeem_flow_info.step_1.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_1.info_text'),
        },
        {
          step: 2,
          src: 'redeem_details.svg',
          alt: this.$t('components.redeem_flow_info.step_2.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_2.info_text'),
        },
        {
          step: 3,
          src: 'card_envelop.svg',
          alt: this.$t('components.redeem_flow_info.step_3.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_3.info_text'),
        },
        {
          step: 4,
          src: 'hello_card_pos.svg',
          alt: this.$t('components.redeem_flow_info.step_4.image_alt_text'),
          info_text: this.$t('components.redeem_flow_info.step_4.info_text'),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.flow-info {
  margin: 0 auto var(--gap-xlarge);
  padding: 24px var(--gap);
  border: var(--border-thin) solid var(--color-border);
  text-align: center;
}

.inner-icon {
  background-color: var(--color-primary);
  font-size: 45px;
  clip-path: circle(50%);
  height: 35px;
  width: 35px;
  padding-top: 5px;
}

.outer-icon {
  margin: 0 auto var(--gap-xsmall) auto;
  border: var(--border-thick) solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 50%;
  width: fit-content;
}

.info-header {
  margin-bottom: calc(var(--gap) * 3);
  @media ($sm-min) {
    margin-bottom: var(--gap-xxlarge);
  }

  line-height: 1.5;
  & > *:not(:last-child) {
    margin-bottom: var(--gap-xsmall);
  }
}

.header-subtext {
  color: var(--color-text-secondary);
}
</style>
