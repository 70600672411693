<template>
  <h-form class="auth-form" ref="authform" v-model="valid" @submit.prevent="submit()">
    <h-step-header :text="$t('components.auth_form.title')" />

    <h-text-field autocomplete="email" maxlength="255" class="mt-3" ref="email" :label="$t('components.auth_form.email_field')" type="email" v-model="email" :rules="emailRules" required data-cy="signinEmailField" />

    <h-text-field autocomplete="disable-autofill" maxlength="50" :label="$t('components.auth_form.password_field')" type="password" data-cy="signinPasswordField" v-model="password" :rules="passwordRules" required />

    <hello-overlay-error />

    <h-btn :block="$vuetify.breakpoint.smAndDown" class="login-button" type="submit" :disabled="!valid || working" data-cy="signinSubmitBtn" :loading="working" min-width="200px">{{ $t('components.auth_form.action') }}</h-btn>

    <forgot-password-modal :initial-email="email" />
  </h-form>
</template>
<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { validateInvalidEmail, validateMaxLength, validateInvalidPassword, validateRequired } from '@/composables/validations';
import ForgotPasswordModal from '@/components/authentication/ForgotPasswordModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'auth-form',
  setup(props, { root }) {
    const { executeOverlay, working } = safeExecute(root.$store);
    return {
      executeOverlay,
      working,
      validateRequired,
      validateInvalidEmail,
      validateMaxLength,
      validateInvalidPassword,
    };
  },
  components: { ForgotPasswordModal },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      email: '',
      password: '',
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
      passwordRules: [this.validateRequired('password'), this.validateMaxLength(50)],
    };
  },
  computed: {
    ...mapGetters('security', ['startupPath']),
    ...mapGetters('error', ['isOverlayError']),
  },
  mounted() {
    this.resetLastFailedSignInEmail();
  },
  methods: {
    ...mapActions('security', ['resetLastFailedSignInEmail']),
    async submit() {
      if (this.$refs.authform.validate()) {
        await this.executeOverlay(
          {
            name: 'signinView',
            action: 'security/userLogin',
          },
          {
            email: this.email,
            password: this.password,
          },
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-form {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding-top: 6px;
  height: fit-content;
}

.login-button {
  align-self: flex-end;
}

::v-deep .forgot-password-link {
  margin-top: 12px;
  align-self: flex-end;
}

::v-deep .h-error-box {
  margin-bottom: var(--gap);
}
</style>
