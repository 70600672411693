<template>
  <div class="h-success-box">
    <p class="h-success-box-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'h-success-box',
  props: {
    text: {
      type: String,
      default: 'Success message',
    },
  },
};
</script>

<style lang="scss" scoped>
.h-success-box {
}
.h-success-box-text {
  margin-bottom: 0;
  color: var(--color-success);
  text-align: center;
  font-size: var(--font-size-regular);
  line-height: 1.1;
  border: var(--border-thin) solid var(--color-success);
  padding: var(--gap);
}
</style>
