<template>
  <v-img class="h-hero-banner-big" :src="bigHeroImageHelper(imgSource)"> </v-img>
</template>

<script>
import { bigHeroImageHelper } from '@/composables/image-helper';

export default {
  setup() {
    return { bigHeroImageHelper };
  },
  name: 'h-hero-banner-big',
  inheritAttrs: false,
  props: {
    imgSource: {
      type: String,
    },
  },
};
</script>

<style scoped>
.h-hero-banner-big {
  width: 100%;
  height: 480px;
}
</style>
