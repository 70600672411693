import Vue from 'vue';

const service = {
  async getPrePaymentInfo({ offer_id, publisher_id, partner_id }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/publisher/acquiring-context`, {
      offer_id,
      publisher_id,
      partner_id,
    });
    return response.data.data;
  },
  async getPreRedeemInfo({ offer_id, publisher_id, partner_id }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/publisher/redeem-context`, {
      offer_id,
      publisher_id,
      partner_id,
    });
    return response.data.data;
  },

  async orderPayment({
    postal_code,
    partner_id,
    payment_method,
    merchant_ref_number,
    offer_id,
    amount,
    publisher_id,
    features,
    cardholder_id,
    allow_communication,
    recaptcha_token,
    acquiring_token,
    tracking_link_id,
    visitor_uuid,
    my_cash_amount,
    credit_card_amount,
  }) {
    const offer = { offer_id, amount, publisher_id };
    if (features !== undefined) {
      offer.features = features;
    }
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/orders/purchase`, {
      payment_method,
      merchant_ref_number,
      recaptcha_token,
      postal_code,
      acquiring_token,
      cardholder_id,
      allow_communication,
      offers: [offer],
      tracking_link_id,
      visitor_uuid,
      my_cash_amount,
      credit_card_amount,
    });
    return response.data.data;
  },
};

export default service;
