<template>
  <h-dialog v-model="model" persistent :title="!showApplyInstantCardSuccess ? $t('components.overlay_request_card_reminder_modal.title') : ''">
    <p v-if="!showApplyInstantCardSuccess" v-html="$t('components.overlay_request_card_reminder_modal.message')"></p>

    <hello-form-error />

    <card-type-selection-modal v-model="showCardTypeSelectionModal" v-if="!showApplyInstantCardSuccess" :display-success-in-card-type-selection-modal="false" @success="applyInstantCardSuccess" />

    <template #action-footer v-if="!showApplyInstantCardSuccess">
      <h-btn @click="getAcquiringCardContext" :disabled="working" :loading="working">{{ $t('components.overlay_request_card_reminder_modal.cta_get_card') }}</h-btn>
      <h-btn @click="closeRequestCardReminderModal" outlined>{{ $t('components.overlay_request_card_reminder_modal.cta_not_now') }}</h-btn>
    </template>

    <success-instant-card-application v-if="showApplyInstantCardSuccess" />
  </h-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import HelloFormError from '../../system-store/errors/HelloFormError.vue';
import safeExecute from '@/composables/safe-execute';
import CardTypeSelectionModal from '@/components/account/components/CardTypeSelectionModal';
import SuccessInstantCardApplication from '@/components/instant-card/components/SuccessInstantCardApplication';

export default {
  name: 'overlay-request-card-reminder-modal',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
    };
  },
  components: { HelloFormError, CardTypeSelectionModal, SuccessInstantCardApplication },
  data() {
    return {
      showCardTypeSelectionModal: false,
      showApplyInstantCardSuccess: false,
    };
  },
  props: {
    redirectLink: {
      url: {
        type: String,
        required: true,
      },
      label_en: {
        type: String,
        required: true,
      },
      label_fr: {
        type: String,
        required: true,
      },
    },
  },
  watch: {
    headerDrawerIsOpen(newValue, oldValue) {
      const headerDrawerIsClosing = !newValue && oldValue;

      if (headerDrawerIsClosing && this.canRequestCard && ((this.currentPaymentIsSuccessful && this.headerDrawerContent === 'purchase') || (this.currentRedeemIsSuccessful && this.headerDrawerContent === 'redeem'))) {
        this.openRequestCardReminderModal();
        this.resetPaymentState();
      }
    },
  },
  computed: {
    ...mapGetters('overlay', ['requestCardReminderModalIsOpen', 'headerDrawerIsOpen', 'headerDrawerContent']),
    ...mapGetters('account', ['canRequestCard']),
    ...mapGetters('payment', ['currentPaymentIsSuccessful']),
    ...mapGetters('overlayRedeem', ['currentRedeemIsSuccessful']),
    ...mapGetters('ui', ['currentLocale']),
    model: {
      get() {
        return this.requestCardReminderModalIsOpen;
      },
      set() {
        this.closeRequestCardReminderModal();
      },
    },
  },
  methods: {
    ...mapActions('overlay', ['closeRequestCardReminderModal', 'openRequestCardReminderModal']),
    ...mapActions('payment', ['resetPaymentState']),
    async getAcquiringCardContext() {
      this.executeForm({
        action: 'account/getAcquiringCardContext',
        success: (cardContext) => {
          if (cardContext.classic.available && !cardContext.instant.available) {
            this.createClassicCardApplicationAndOpenNewTab();
          }
          if (cardContext.classic.available && cardContext.instant.available) {
            this.showCardTypeSelectionModal = true;
          }
        },
      });
    },
    async applyInstantCardSuccess() {
      this.showApplyInstantCardSuccess = true;
    },
    async createClassicCardApplicationAndOpenNewTab() {
      await this.executeForm(
        {
          action: 'account/createCardApplicationContext',
          success: (context) => {
            switch (context.result_code) {
              case 'application':
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              default:
                this.title = this.$t('components.overlay_request_card.apply_url_error_modal_title');
                this.text = this.$t('components.overlay_request_card.apply_url_error_modal_message');
                this.modalIsOpen = true;
                this.routeToMyAccount = true;
            }
          },
        },
        {
          return_url: this.redirectLink.url,
          return_link_label_fr: this.redirectLink.label_fr,
          return_link_label_en: this.redirectLink.label_en,
        },
      );
    },
  },
};
</script>
