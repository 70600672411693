import _isEmpty from 'lodash/isEmpty';
import HomeView from '@/components/views/HomeView.vue';
import HomeRegionView from '@/components/views/HomeRegionView';
import HomeStatusView from '@/components/views/HomeStatusView';
import SpaceHomeView from '@/components/views/space/SpaceHomeView';
import PageNotFoundView from '@/components/views/PageNotFoundView';
import VerifyEmailView from '@/components/views/VerifyEmailView';
import MyAccountView from '@/components/views/account/MyAccountView';
import ChangePasswordView from '@/components/views/ChangePasswordView.vue';
import CorpoView from '@/components/views/corpo/CorpoView';
import OfferDetailView from '@/components/views/offer-details/OfferDetailView.vue';
import OfferPublisherDetailView from '@/components/views/offer-details/OfferPublisherDetailView.vue';
import OfferActivationView from '@/components/views/OfferActivationView.vue';

import Vue from 'vue';
import Router from 'vue-router';
import { registerStoreSystemComponent } from './components/system-store';
import { registerSystemComponent } from './components/system';
import store from './store/store';

const { isNavigationFailure, NavigationFailureType } = Router;

Vue.use(Router);

registerSystemComponent(Vue);
registerStoreSystemComponent(Vue);

const prodRoutes = [
  {
    path: '/not-found',
    name: 'not-found',
    component: PageNotFoundView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: (route) => ({ ...route.params, category: route.query.category }),
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView,
    props: (route) => ({ ...route.params, openOverlay: false }),
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: VerifyEmailView,
    props: (route) => ({ ...route.params, openOverlay: true }),
  },
  {
    path: '/register',
    name: 'register',
    component: HomeView,
    props: (route) => ({ ...route.params, openRegister: true }),
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: MyAccountView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/instant',
    name: 'instant-card-activation',
    component: () => import('@/components/views/instant-card/InstantCardActivationView.vue'),
    props: true,
  },
  {
    path: '/region/:region',
    name: 'homeRegion',
    component: HomeRegionView,
    props: (route) => ({ region: route.params.region.toLowerCase(), category: route.query.category }),
  },
  {
    path: '/region/:region/:status',
    name: 'homeStatus',
    component: HomeStatusView,
    props: (route) => ({ status: route.params.status, region: route.params.region.toLowerCase(), category: route.query.category, origin: route.query.origin }),
  },
  {
    path: '/:space/',
    name: 'spaceHome',
    component: SpaceHomeView,
    props: (route) => ({ ...route.params, category: route.query.category }),
  },
  {
    path: '/:space/change-password',
    name: 'space-change-password',
    component: ChangePasswordView,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/:space/login',
    name: 'loginSpace',
    component: SpaceHomeView,
    props: (route) => ({ ...route.params, openOverlay: true }),
  },
  {
    path: '/:space/register',
    name: 'registerSpace',
    component: SpaceHomeView,
    props: (route) => ({ ...route.params, openRegister: true }),
  },
  {
    path: '/:space/instant',
    name: 'instantCardActivationSpace',
    component: () => import('@/components/views/instant-card/InstantCardActivationView.vue'),
    props: true,
  },
  {
    path: '/:space/offers/:offer_id',
    name: 'spaceOfferPublisher',
    component: OfferDetailView,
    props: (route) => ({ ...route.params, ...route.query, preview: castStringToBoolean(route.query.preview) }),
    alias: ['/:space/offers/:offer_id#corporate', '/:space/offers/:offer_id#where-to-use', '/:space/offers/:offer_id#how-it-works'],
  },
  {
    path: '/:space/offers/:offer_id/activation',
    name: 'spaceOfferActivation',
    component: OfferActivationView,
  },
  {
    path: '/:space/offers/:offer_id/publishers/:publisher_id',
    name: 'spaceOfferDetail',
    component: OfferPublisherDetailView,
    props: (route) => ({ ...route.params, preview: castStringToBoolean(route.query.preview) }),
  },
  {
    path: '/:space/offers/:offer_id/c/:publisher_id',
    name: 'spaceOfferDetailSmallUrl',
    component: OfferPublisherDetailView,
    props: (route) => ({ ...route.params, preview: castStringToBoolean(route.query.preview) }),
  },
  {
    path: '/:space/corpo/offers/:offer_id',
    name: 'corpo',
    component: CorpoView,
    props: true,
  },
  {
    path: '/:space/corpo/:slug',
    name: 'corpoBySlug',
    component: CorpoView,
    props: true,
  },
  {
    path: '/:space/:slug',
    name: 'spaceOfferDetailBySlug',
    component: OfferDetailView,
    props: (route) => ({ ...route.params, preview: castStringToBoolean(route.query.preview) }),
  },
  {
    path: '*',
    component: PageNotFoundView,
  },
];

const devRoutes = [];
if (process.env.VUE_APP_DEV_ENDPOINT_ACTIVE === 'true') {
  devRoutes.push({
    path: '/demo',
    name: 'Demo',
    component: () => import('@/components/views/DemoView.vue'),
    props: (route) => ({ ...route.params, category: route.query.category }),
  });
  devRoutes.push({
    path: '/:space/demo',
    name: 'Demo',
    component: () => import('@/components/views/DemoView.vue'),
    props: (route) => ({ ...route.params, category: route.query.category }),
  });
  devRoutes.push({
    path: '/styleguide',
    name: 'Styleguide',
    component: () => import('@/components/views/StyleguideView.vue'),
    props: (route) => ({ ...route.params, category: route.query.category }),
  });
}

let routes = devRoutes.concat(prodRoutes);

// WARNING (plp):  Router routes registration matters
const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // NOTE (SG) Maybe try the CSS.escape() function to resolve issue "... is not a valid selector"
    if (to.hash && to.hash !== '' && to.hash !== '#') {
      // Note (MAA) : Only handles hash while navigating in app. To support page refresh and browser navigation use scrollIntoView(); when you are sure that all data is loaded in view.
      return { selector: to.hash, behavior: 'auto', offset: { y: 100, x: 0 } };
    }
    if (from.hash) {
      return { x: 0, y: 0, behavior: 'auto' };
    }

    if (savedPosition) {
      return savedPosition;
    }

    if (from.params._position) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: from.params._position.x, y: from.params._position.y, behavior: 'smooth' });
        }, 500);
      });
    }

    if (_isEmpty(to.query)) {
      return { x: 0, y: 0, behavior: 'auto' };
    }
  },
  routes,
});

const onAuthRequired = async (to, from, next) => {
  store.dispatch('overlay/closeHeaderDrawer');

  const recaptcha = Vue.prototype.$recaptchaInstance;
  if (recaptcha) {
    recaptcha.hideBadge();
  }

  if (to.name !== from.name) {
    await store.dispatch('error/clearError');
  }

  if (to.params.space) {
    if (store.getters['space/currentSpaceSlug'] !== to.params.space) {
      const spaceChangeSuccess = await store.dispatch('space/setCurrentSpaceSlug', to.params.space);
      if (!spaceChangeSuccess) {
        return next({
          name: 'home',
        });
      }
    }
  } else {
    await store.dispatch('space/setCurrentSpaceSlug', null);
  }

  if (to.matched.some((record) => record.meta.authenticatedForwardName)) {
    if (store.getters['security/authenticated']) {
      return next({
        replace: true,
        name: to.meta.authenticatedForwardName,
      });
    }
  }

  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.getters['security/authenticated']) {
      await store.dispatch('security/setStartupPath', to.name);
      if (to.params.space) {
        next({
          name: 'loginSpace',
          params: { space: store.getters['space/currentSpaceSlug'] },
        });
      } else {
        next({
          name: 'login',
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
};
window.onpopstate = function () {
  store.dispatch('overlay/closeHeaderDrawer');
};

router.beforeEach(onAuthRequired);

router.afterEach((to, from) => {
  rg4js('trackEvent', {
    type: 'pageView',
    path: to.path,
  });
  if (window.hj) {
    hj('stateChange', to.path);
  }
});

router.replaceNoDuplicate = (params) => {
  router.replace(params, undefined, (failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
      // When in navigation guard there is a redirect. ex: next(newLocation)
    } else if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
      // When in the navigation guard the navigation is aborted ex: next(false)
    } else if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
      // When another navigation occurs before the end of the navigation in progress. Ex: load the space home and spam my account before the render is completed
    } else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      // When another navigation target the same destination.  EX: my account to my account
    } else {
      throw failure;
    }
  });
};
router.pushNoDuplicate = (params, onCompleted) => {
  router.push(params, onCompleted, (failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
      // When in navigation guard there is a redirect. ex: next(newLocation)
    } else if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
      // When in the navigation guard the navigation is aborted ex: next(false)
    } else if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
      // When another navigation occurs before the end of the navigation in progress. Ex: load the space home and spam my account before the render is completed
    } else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      // When another navigation target the same destination.  EX: my account to my account
    } else {
      throw failure;
    }
  });
};

function castStringToBoolean(str) {
  return str && String(str).toLowerCase() === 'true';
}

export default router;
