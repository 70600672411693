<template>
  <div class="voucher-consumer-gift-card-amount">
    <div class="balance-section voucher-base-amount">
      <div class="voucher-info amount-style-large">{{ $t('components.account_voucher_consumer_gift_card_amount.actual_balance') }}</div>
      <div class="voucher-amount amount-style-large">{{ calculatedBalance | currency_full | currencySymbol }}</div>
    </div>
    <div class="h-label-4-med-italic status-section">{{ status }}</div>
    <div class="secondary-section">
      <consumer-gift-card-download-pdf-modal :voucher-id="voucher.id" :activation-code-id="voucher.activation_code.activation_code_id">
        <template v-slot:activator="{ on, attrs }">
          <h-btn class="button-style" :disabled="voucherIsCanceled" small text v-on="on" v-bind="attrs"
            ><v-icon left>mdi-wallet-giftcard</v-icon>{{ $t('components.account_voucher_consumer_gift_card_amount.consumer_gift_card_download_pdf_modal_action_title') }}</h-btn
          >
        </template>
      </consumer-gift-card-download-pdf-modal>
      <consumer-gift-card-send-by-email-modal :voucher-id="voucher.id" @success="refreshGiftCardVouchers">
        <template v-slot:activator="{ on, attrs }">
          <h-btn class="button-style" :disabled="voucherIsCanceled" small text v-on="on" v-bind="attrs"
            ><v-icon left>mdi-email-outline</v-icon>{{ $t('components.account_voucher_consumer_gift_card_amount.consumer_gift_card_send_by_email_modal_action_title') }}</h-btn
          >
        </template>
      </consumer-gift-card-send-by-email-modal>
      <h-btn class="button-style" small inverted :loading="working" @click="downloadReceipt">
        <v-icon size="20" left>mdi-open-in-new</v-icon>{{ $t('components.account_voucher_consumer_gift_card_amount.consumer_gift_card_payment_success_download_receipt') }}
      </h-btn>
    </div>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import ConsumerGiftCardSendByEmailModal from '@/components/consumer-gift-card//components/ConsumerGiftCardSendByEmailModal.vue';
import ConsumerGiftCardDownloadPdfModal from '@/components/consumer-gift-card/components/ConsumerGiftCardDownloadPdfModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'voucher-consumer-gift-card-amount-and-links',
  components: { ConsumerGiftCardSendByEmailModal, ConsumerGiftCardDownloadPdfModal },
  setup(props, { root }) {
    const { executeOverlay, working } = safeExecute(root.$store);
    return {
      executeOverlay,
      working,
    };
  },
  props: {
    voucher: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    calculatedBalance() {
      return this.voucher.bonus_amount + this.voucher.payment_amount;
    },
    voucherIsCanceled() {
      return this.voucher.status === 'canceled';
    },
    status() {
      const status_text = `${this.$t('components.account_voucher_consumer_gift_card_amount.status')}`;
      const system_status = this.$t(`components.account_voucher_consumer_gift_card_amount.activation_code_status.${this.voucher.activation_code.system_status}`);
      const send_email_status = this.$t(`components.account_voucher_consumer_gift_card_amount.send_email_status.${this.voucher.activation_code.send_email_status}`);

      return `${status_text}: ${system_status} (${send_email_status})`;
    },
  },
  methods: {
    refreshGiftCardVouchers() {
      this.$emit('refreshGiftCardVouchers');
    },
    async downloadReceipt() {
      await this.executeOverlay(
        {
          action: 'consumerGiftCard/downloadReceipt',
        },
        {
          consumer_gift_card_order_id: this.voucher.consumer.consumer_gift_card_order_id,
          language: this.currentLocale,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-section {
  padding: 0 calc(var(--gap-small) - 3px);
}

// NOTE: SC: styles and classes copied from VoucherBaseAmount.vue
.voucher-amount {
  font-size: var(--font-size-subheader);
  font-weight: 600;
  line-height: 1;
  margin-left: 8px;
  text-align: right;
  align-self: flex-start;

  &.amount-style-large {
    font-size: 30px;
    font-weight: 700;
  }

  @media ($sm-min) {
    width: 150px;
    flex: 0 0 auto;
    margin-left: 0;
  }
}

.voucher-base-amount {
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.voucher-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1 1 auto;
  align-items: flex-start;

  &.amount-style-large {
    padding-top: 12px; // Note (PLP): Used for multiline alignment
  }
}
// NOTE: SC: End of styles and classes copied from VoucherBaseAmount.vue

.status-section {
  padding: var(--gap-xsmall) calc(var(--gap-small) - 3px) var(--gap-small);
}

.secondary-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.button-style {
  font-size: 14px;
}
</style>
