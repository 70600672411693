<template>
  <div class="h-hero-banner-partner">
    <div v-if="$vuetify.breakpoint.smAndDown" class="banner-mobile">
      <img class="image" :src="mobileHeroImageHelper(imgSourceMobile)" />
    </div>
    <v-img v-else class="banner-desktop" :src="smallHeroImageHelper(imgSourceDesktop)" />
  </div>
</template>

<script>
import { mobileHeroImageHelper, smallHeroImageHelper } from '@/composables/image-helper';

export default {
  name: 'h-hero-banner-partner',
  setup(props, { root }) {
    return {
      mobileHeroImageHelper,
      smallHeroImageHelper,
    };
  },
  props: {
    imgSourceMobile: {
      type: String,
    },
    imgSourceDesktop: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  background: var(--color-ultra-light);
}

.image {
  max-height: 94px;
  display: block;
}

.banner-desktop {
  width: 100%;
  height: 240px;
}
</style>
