<template>
  <h-form ref="redeemform" v-model="valid" @submit.prevent="redeem()" class="claim-gift-certificate-redeem">
    <div v-if="showCardSelection">
      <h-step-header :text="$t('components.claim_gift_certificate_redeem.select_card_title')" />
      <div class="card-selection-header">{{ $t('components.pay_and_get_purchase_type.for_yourself.select_card') }}</div>
      <card-selection context="activation" :has-application="accountHasApplication && !hasClassicCard" class="card-selection" :card-list="currentRedeemInformation.cards" @change="(card) => setCurrentCard(card)" />
    </div>

    <h-step-header :text="$t('components.claim_gift_certificate_redeem.activation_title')" />
    <div class="d-flex">
      <h-text-field class="redeem-field" v-maska="{ mask: 'JJJJ-JJJJ', tokens: { J: { pattern: /[0-9a-zA-Z]/, uppercase: true } } }" v-model="code" :label="$t('components.claim_gift_certificate_redeem.label')" :rules="code_rules" maxlength="32" />
    </div>
    <h-checkbox
      v-if="requestConsentCommunication && (!membership || !membership.allow_communication)"
      :disabled="working"
      class="mt-4 mb-4 communication-field"
      top-align
      :label="$t('components.claim_gift_certificate_redeem.allow_communication', { partner: $options.filters.translateI18nObject(space, 'branding.label') })"
      v-model="allowCommunication"
    />
    <hello-form-error />
    <h-btn class="mt-8 redeem-btn" :loading="working" :disabled="!(valid && currentRedeemValidContext)" @click="startRedeem" large>{{ $t('components.claim_gift_certificate_redeem.action') }}</h-btn>
  </h-form>
</template>
<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { validateRequired, validateMaxLength } from '@/composables/validations';
import { mapActions, mapGetters } from 'vuex';
import CardSelection from '@/components/pay-and-get/CardSelection';

import _get from 'lodash/get';
import _find from 'lodash/find';

export default {
  name: 'claim-gift-certificate-redeem',
  components: { CardSelection },
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateMaxLength,
      validateRequired,
    };
  },
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    paymentInfo: {
      type: Object,
    },
    space: {
      type: Object,
    },
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      code: '',
      code_rules: [this.validateRequired('gift_certificate_code'), this.validateMaxLength(32)],
      allowCommunication: true,
    };
  },
  computed: {
    ...mapGetters('account', ['accountHasApplication', 'hasClassicCard', 'accountActiveMemberships']),
    ...mapGetters('overlay', ['headerDrawerContentPayload']),
    ...mapGetters('overlayRedeem', ['currentRedeemValidContext', 'currentCardholderId', 'showCardSelection', 'currentRedeemInformation']),
    requestConsentCommunication() {
      return _get(this.space, 'request_consent_communication', false);
    },
    offerIncentiveBonusAmount() {
      return _get(this.offer, 'incentive.bonus_amount', 0);
    },
    membership() {
      return _find(this.accountActiveMemberships, (activeMembership) => activeMembership.partner_id === this.headerDrawerContentPayload.partner_id);
    },
  },
  methods: {
    ...mapActions('overlayRedeem', ['setCurrentCard']),
    async startRedeem() {
      this.redeem();
    },
    async redeem() {
      const _this = this;
      await this.executeForm(
        {
          action: 'overlayRedeem/redeem',
          beforeError: (err) => {
            if (err.code === 'errors.http.exceed.offer.publisher.inventory.limit' || err.code === 'errors.http.exceed.offer.account.inventory.limit') {
              err.data = {
                offer_branding_label: this.$options.filters.translateI18nObject(offer, 'offer_branding.label'),
                limit_amount: this.$options.filters.currencySymbol(this.$options.filters.currency(this.offer.incentive.user_inventory_limit)),
                remaining_amount: this.$options.filters.currencySymbol(this.$options.filters.currency(this.currentRedeemInformation.user_payment_remaining)),
              };
            }
          },
          success: () => {
            if (window.fbq) {
              fbq('trackCustom', 'ClaimVoucher', {
                partner_id: this.headerDrawerContentPayload.partner_id,
                offer_id: this.offer.id,
                category_id: this.offer.category_id,
                voucher: this.offerIncentiveBonusAmount,
              });
            }
            _this.$emit('completed');
          },
        },
        { code: this.code, recaptcha_token: await this.recaptcha('giftCertificateRedeem'), cardholder_id: this.currentCardholderId, allow_communication: this.allowCommunication },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-gift-certificate-redeem {
  padding-top: var(--gap);
}

.redeem-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card-selection-header {
  margin-top: var(--gap-small);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-secondary);
  text-align: center;
}

.card-selection-section {
  margin-top: var(--gap-xlarge);
}

.redeem-field,
.communication-field {
  max-width: 468px; // Note(PLP): Arbitrary
  margin-left: auto;
  margin-right: auto;
}

.card-selection {
  margin-bottom: var(--gap-xlarge);

  ::v-deep .v-input__slot {
    width: fit-content;
    margin: 10px auto;
  }
}
</style>
