import { render, staticRenderFns } from "./ConsumerGiftCardTermsAndConditionsModal.vue?vue&type=template&id=4cdb9199&scoped=true"
import script from "./ConsumerGiftCardTermsAndConditionsModal.vue?vue&type=script&lang=js"
export * from "./ConsumerGiftCardTermsAndConditionsModal.vue?vue&type=script&lang=js"
import style0 from "./ConsumerGiftCardTermsAndConditionsModal.vue?vue&type=style&index=0&id=4cdb9199&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdb9199",
  null
  
)

export default component.exports