<script>
import { VBtn } from 'vuetify/lib';

export default {
  name: 'h-btn',
  extends: VBtn,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    minWidth: {
      type: [Number, String],
      default: 140,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ...VBtn.options.computed.classes.call(this),
        'h-btn--inverted': this.inverted,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  color: white; // This is for buttons with "branding color" applied.
  text-align: center;
}

.v-btn.v-btn--text.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  .v-icon {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .v-btn__loading {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.v-btn.h-btn--inverted {
  background-color: white !important;
  &.primary {
    color: var(--color-primary);
  }
  &.secondary {
    color: var(--color-secondary);
  }
  &.accent {
    color: var(--color-accent);
  }
  &.error {
    color: var(--color-error);
  }
  &.success {
    color: var(--color-success);
  }
  &.warning {
    color: var(--color-warning);
  }
  &.text-primary {
    color: var(--color-text-primary);
  }
}
.v-btn.v-btn--outlined.v-btn--disabled {
  background-color: transparent;
  color: #cccccc !important;
}
</style>
