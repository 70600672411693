<template>
  <div class="category-filter-bar">
    <!-- Mobile version -->
    <div v-if="$vuetify.breakpoint.smAndDown" class="mobile-bar" :style="mobileBarStyles" id="category-filter-mobile-menu-bar">
      <v-menu v-if="dark" transition="slide-y-transition" offset-y attach="#category-filter-mobile-menu-bar">
        <template v-slot:activator="{ on, attrs }">
          <h-btn v-bind="attrs" v-on="on" color="var(--color-branding)" :inverted="!dark" min-width="0" :style="mobileActivatorStyles" class="mobileBtn">
            {{ $t(`components.home_search_bar.category.${sortedCategoryList[currentCategory]}`) }}
            <v-icon right>mdi-chevron-down</v-icon>
          </h-btn>
        </template>
        <v-list :dark="dark" :color="mobileListColor" dense>
          <v-list-item-group :value="currentCategory" @change="categoryChange" :color="mobileListItemGroupColor">
            <v-list-item v-for="(category, index) in sortedCategoryList" :key="index">
              <v-list-item-title>{{ $t(`components.home_search_bar.category.${category}`) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <h-select-branding-color
        v-else
        hide-details
        :value="currentCategory"
        @input="categoryChange"
        :items="
          sortedCategoryList.map((e, index) => {
            return {
              text: this.$t(`components.home_search_bar.category.${e}`),
              value: index,
            };
          })
        "
      ></h-select-branding-color>
    </div>

    <!-- Desktop version -->
    <v-tabs v-else :value="currentCategory" @change="categoryChange" :dark="dark" :background-color="desktopTabsBackgroundColor" :color="desktopTabsColor" show-arrows icons-and-text>
      <v-tab v-for="(category, index) in sortedCategoryList" :key="index">
        <v-icon x-large>{{ categoryIcon(category) }}</v-icon>
        <span class="text">{{ $t(`components.home_search_bar.category.${category}`) }}</span>
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _set from 'lodash/set';

export default {
  name: 'category-filter-bar',
  props: {
    categoryList: {
      type: Array,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    useRouterParams: {
      type: Boolean,
      default: false,
    },
    initialCategory: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      selectedCategoryNumber: 0,
      CATEGORIES_SORTING_WEIGHT: {
        all: 0,
        mixed: 1,
        food_groceries: 2,
        restaurants_bars: 3,
        leisure_getaways: 4,
        fashion_style: 5,
        home_cars: 6,
        miscellaneous: 7,
      },
    };
  },
  mounted() {
    if (this.initialCategory) {
      const category = this.sortedCategoryList.includes(this.initialCategory) ? this.initialCategory : 'all';
      this.categoryChange(this.categoryNameToSortedIdMap[category]);
    }
  },
  computed: {
    mobileBarStyles() {
      return this.dark ? 'background-color: var(--color-branding);' : 'background-color: white; ';
    },
    mobileActivatorStyles() {
      return this.dark ? '' : 'color: var(--color-branding)';
    },
    mobileListColor() {
      return this.dark ? 'var(--color-branding)' : undefined;
    },
    mobileListItemGroupColor() {
      return this.dark ? undefined : 'var(--color-branding)';
    },
    desktopTabsBackgroundColor() {
      return this.dark ? 'var(--color-branding)' : undefined;
    },
    desktopTabsColor() {
      return this.dark ? undefined : 'var(--color-branding)';
    },
    sortedCategoryList() {
      return _filter(
        _sortBy(['all', ...this.categoryList], (element) => this.CATEGORIES_SORTING_WEIGHT[element]),
        (category) => this.CATEGORIES_SORTING_WEIGHT[category] !== undefined,
      );
    },
    categoryNameToSortedIdMap() {
      return this.sortedCategoryList.reduce((accumulator, categoryName, sortedIndex) => {
        return { ...accumulator, [categoryName]: sortedIndex };
      }, {});
    },
    currentCategory() {
      return this.useRouterParams ? this.getCategoryNumberFromQuery() : this.selectedCategoryNumber;
    },
  },
  methods: {
    categoryChange(categoryNumberEvent) {
      // Possible that categoryNumberEvent is 0 .  Will take the undefined value if it does not change
      if (categoryNumberEvent !== undefined) {
        this.selectedCategoryNumber = categoryNumberEvent;

        const targetCategory = this.sortedCategoryList[this.selectedCategoryNumber];
        let category_to_emit = null;
        if (!targetCategory || targetCategory === 'all') {
          category_to_emit = null;
        } else {
          category_to_emit = targetCategory;
        }
        this.$emit('change', category_to_emit);
        if (this.useRouterParams) {
          const query = { ...this.$router.currentRoute.query };
          _set(query, 'category', category_to_emit === null ? undefined : category_to_emit);
          this.$router.replaceNoDuplicate({ query });
        }
      }
    },
    categoryIcon(category) {
      if (category === 'all') return '$allCategories';
      if (category === 'fashion_style') return '$fashionStyle';
      if (category === 'food_groceries') return '$foodGroceries';
      if (category === 'home_cars') return '$homeCars';
      if (category === 'leisure_getaways') return '$leisureGetaways';
      if (category === 'miscellaneous') return '$miscellaneous';
      if (category === 'mixed') return '$mixedCategories';
      if (category === 'restaurants_bars') return '$restaurantBars';
    },
    getCategoryNumberFromQuery() {
      const queryCategory = this.$route.query.category;
      const category = this.sortedCategoryList.includes(queryCategory) ? queryCategory : 'all';
      return this.categoryNameToSortedIdMap[category];
    },
  },
};
</script>

<style lang="scss" scoped>
.category-filter-bar {
  width: 100%;
}

.mobile-bar {
  display: flex;
  justify-content: space-between;
  padding: var(--gap);
}

.v-btn.mobileBtn {
  border-width: 1px !important;
  border-style: solid;
  border-color: currentColor !important;
}

.v-menu__content {
  box-shadow: var(--card-shadow);
}

::v-deep .v-tabs-bar {
  height: 105px !important;
}

::v-deep .v-tabs-slider-wrapper {
  top: 8px;
  bottom: auto;
}

::v-deep .v-tab {
  padding: 18px var(--gap-xsmall) 0;
  flex-direction: column !important;
  justify-content: flex-start;
  text-transform: none;
}

.text {
  width: 100px;
  letter-spacing: normal;
  line-height: 1.1;
}
</style>
