<template>
  <div aria-live="polite">
    <v-expand-transition>
      <h-error-box v-if="isTargetErrorGetter(target)" v-bind="$attrs" :text="errorTranslation()" />
    </v-expand-transition>
  </div>
</template>

<script>
import errorHandling from '@/composables/error-handling';
import { mapGetters } from 'vuex';

export default {
  setup(props, { root }) {
    const { getGenericErrorMessage, getErrorTranslation } = errorHandling(root.$store, root.$options.filters);
    return {
      getGenericErrorMessage,
      getErrorTranslation,
    };
  },
  props: {
    target: {
      type: String,
      default: 'target',
    },
    code: {
      type: String,
      default: 'unknown',
    },
    useSpecificErrorMessage: {
      type: Boolean,
      default: false,
    },
    useFallback: {
      type: Boolean,
      default: false,
    },
  },
  name: 'hello-target-error',
  inheritAttrs: false,
  computed: {
    ...mapGetters('error', ['isTargetErrorGetter', 'currentError']),
  },
  methods: {
    errorTranslation() {
      if (this.useSpecificErrorMessage) {
        if (this.$te(this.currentError.code) || !this.useFallback) {
          return this.getErrorTranslation(this);
        }
      }
      return this.getGenericErrorMessage(this, this.currentError.code);
    },
  },
};
</script>
