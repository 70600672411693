<template>
  <div v-if="space" class="account-space-header">
    <img class="logo" :src="space.branding.images.horizontal_logo[currentLocale]" :alt="$t('components.account_space_header.img_alt')" />

    <div class="description">
      <p class="text h-text-3">{{ space.branding.description[currentLocale] }}</p>
      <h-link target="_blank" v-if="space.branding.url && space.branding.url[currentLocale]" :href="space.branding.url[currentLocale]">{{ $t('components.account_space_header.website') }}</h-link>
    </div>

    <h-btn class="offer-btn" :to="{ name: 'spaceHome', params: { space: space.slug } }">{{ $t('components.account_space_header.see_offers') }}</h-btn>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';

export default {
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);

    return {
      working,
      executeContent,
    };
  },
  name: 'account-space-header',
  props: {
    partner_id: {
      type: String,
    },
    membership: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    partner_id() {
      if (this.partner_id) {
        const _this = this;
        this.executeContent(
          {
            action: 'space/getSpaceByPartnerId',
            success: (s) => {
              _this.space = s;
            },
          },
          this.partner_id,
        );
      }
    },
  },
  data() {
    if (this.partner_id) {
      const _this = this;
      this.executeContent(
        {
          action: 'space/getSpaceByPartnerId',
          success: (s) => {
            _this.space = s;
          },
        },
        this.partner_id,
      );
    }
    return {
      space: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.account-space-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: var(--gap);

  @media ($sm-min) {
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media ($md-min) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.logo {
  max-width: 160px;
  max-height: 100px;
}

.description {
  @media ($sm-min) {
    width: calc(100% - (160px + var(--gap)));
  }
}

.text {
  margin-bottom: 0;
  color: var(--color-text-secondary);
}
</style>
