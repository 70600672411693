<template>
  <h-dialog max-width="680" @close="closeRenewCardModal" @open="openRenewCardModal" ref="dialog" :title="$t('components.renew_card_modal.title')">
    <template v-slot:activator="{ on, attrs }">
      <h-btn v-on="on" v-bind="attrs" inverted>{{ $t('components.overlay_renew_card.action_renew') }}</h-btn>
    </template>
    <renew-card-form v-if="showRenewCard" :public-token="publicToken" :card-program-type="cardProgramType" @success="successRenewCardForm" @cancel="cancelRenewCardForm"></renew-card-form>
  </h-dialog>
</template>

<script>
import RenewCardForm from '../../cards/components/RenewCardForm.vue';
import safeExecute from '@/composables/safe-execute';

export default {
  components: { RenewCardForm },
  name: 'overlay-renew-card-modal',
  setup(props, { root }) {
    const { executeContent } = safeExecute(root.$store);
    return {
      executeContent,
    };
  },
  data: () => {
    return {
      showRenewCard: false,
    };
  },

  props: {
    publicToken: {
      type: String,
      required: true,
    },
    cardProgramType: {
      type: String,
    },
  },
  methods: {
    closeRenewCardModal() {
      this.showRenewCard = false;
    },
    openRenewCardModal() {
      this.showRenewCard = true;
    },
    cancelRenewCardForm() {
      this.$refs.dialog.close();
    },
    successRenewCardForm(event) {
      this.showRenewCard = false;
      this.$emit('success');
      this.$refs.dialog.close();
    },
  },
};
</script>
