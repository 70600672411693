<template>
  <div class="small-card-voucher-status-label" v-if="text">{{ text }}</div>
</template>

<script>
export default {
  name: 'small-card-voucher-status-label',
  props: {
    start: {
      type: String,
    },
    status: {
      type: String,
    },
    ctaPurchase: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(this.start);
    },
    text() {
      if (!this.ctaPurchase) {
        return this.$t('components.small_card_base_status_label.coming_soon_no_date');
      }

      if (this.status === 'coming_soon') {
        return this.$t('components.small_card_base_status_label.coming_soon', { date: this.formattedStartDateWithHour });
      }
      if (this.status === 'closed') {
        return this.$t('components.small_card_base_status_label.closed');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.small-card-voucher-status-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}
</style>
