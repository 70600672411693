<template>
  <h-dialog ref="dialog" content-class="merchants-modal" :title="modalTitleText || $t('components.corpo_retailers_modal.title')" class="merchants-modal" max-width="1200" v-model="modalIsOpen" @close="onClose">
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <h-link v-bind="attrs" v-on="on" bold class="activator">{{ modalLinkText || $t('components.corpo_retailers_modal.activator') }}</h-link>
      </slot>
    </template>

    <hello-target-error class="mb-2 mt-2" target="store_error" />

    <div class="text-center" v-if="!dataReady">
      <v-progress-circular :style="{ marginTop: '50px', marginBottom: '50px' }" :size="100" :width="7" color="var(--color-branding)" indeterminate />
    </div>
    <store-list in-modal v-if="dataReady"></store-list>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import safeExecute from '@/composables/safe-execute';
import StoreList from '../stores/StoreList.vue';

export default {
  name: 'merchants-modal',
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  data() {
    return {
      modalIsOpen: false,
      dataReady: false,
      category: null,
      destination_id: null,
    };
  },
  components: {
    StoreList,
  },
  props: {
    offer_id: {
      type: String,
      default: undefined,
    },
    publisher_id: {
      type: String,
      default: undefined,
    },
    modalLinkText: {
      type: String,
      default: undefined,
    },
    modalTitleText: {
      type: String,
      default: undefined,
    },
    displayCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async modalIsOpen(isOpen, wasOpen) {
      if (isOpen && !wasOpen) {
        this.dataReady = false;
        await this.updateQuery({ publisher_id: this.publisher_id, offer_id: this.offer_id, category: this.category, destination_id: this.destination_id });
        this.dataReady = true;
      }
    },
  },
  methods: {
    ...mapActions('store', ['resetOfferCategoriesAndStores', 'updateQuery']),
    openModal({ category, destination_id }) {
      this.category = _.defaultTo(category, null);
      this.destination_id = _.defaultTo(destination_id, null);
      this.modalIsOpen = true;
    },
    closeModal() {
      this.modalIsOpen = false;
      this.onClose();
    },
    onClose() {
      this.dataReady = false;
      this.destination_id = null;
      this.category = null;
      this.resetOfferCategoriesAndStores();
    },
  },
};
</script>

<style lang="scss" scoped>
.activator {
  font-size: var(--font-size-subheader);
}
</style>

<style lang="scss">
.merchants-modal {
  max-height: 100% !important;
  height: 100%;

  .h-dialog-container {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    padding: 0 0 0 !important;

    & > .header {
      font-size: var(--font-size-h2);
      background-color: color-mix(in srgb, rgba(var(--color-branding-rgb-values), 0.2), white);
      flex: 0 0 auto;
      padding: var(--gap);
      margin: 0;
    }

    & > .category-filter {
      position: relative;
      top: auto !important;
      flex: 0 0 auto;

      .mobile-bar {
        padding: var(--gap);
        border-bottom: none !important;
      }
    }

    .store-section {
      flex: 1 1 auto;
      overflow-y: auto;
      overscroll-behavior: contain;
    }

    .offer-store-list {
      margin: 0;
      & > .offer-store-item {
        margin: 0;
      }
    }
  }
}
</style>
