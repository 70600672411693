<template>
  <h-form ref="authform" v-model="valid" @submit.prevent="submit()" class="swap-auth-form">
    <h-step-header :text="$t('components.auth_form.title')" />

    <h-text-field autocomplete="email" maxlength="255" class="mt-3" ref="email" :label="$t('components.auth_form.email_field')" type="email" v-model="email" :rules="emailRules" required data-cy="signinEmailField" />

    <h-text-field autocomplete="disable-autofill" maxlength="50" :label="$t('components.auth_form.password_field')" type="password" data-cy="signinPasswordField" v-model="password" :rules="passwordRules" required />

    <hello-form-error />

    <h-btn class="login-button" block type="submit" :disabled="!valid || working" data-cy="signinSubmitBtn" :loading="working" min-width="160px">{{ $t('components.auth_form.action') }}</h-btn>

    <forgot-password-modal :initial-email="email" />

    <p class="register-text">
      {{ $t('components.auth_form.dont_have_account.1') }}<h-btn @click="goToRegisterForm" text class="register-link">{{ $t('components.auth_form.dont_have_account.2') }}</h-btn
      >{{ $t('components.auth_form.dont_have_account.3') }}
    </p>
  </h-form>
</template>
<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { validateInvalidEmail, validateInvalidPassword, validateRequired, validateMaxLength } from '@/composables/validations';
import ForgotPasswordModal from '@/components/authentication/ForgotPasswordModal';
import { mapActions } from 'vuex';

export default {
  name: 'auth-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateRequired,
      validateInvalidEmail,
      validateInvalidPassword,
      validateMaxLength,
    };
  },
  components: { ForgotPasswordModal },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      email: '',
      password: '',
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
      passwordRules: [this.validateRequired('password'), this.validateMaxLength(50)],
    };
  },
  mounted() {
    this.resetLastFailedSignInEmail();
  },
  methods: {
    ...mapActions('error', ['clearError']),
    ...mapActions('security', ['resetLastFailedSignInEmail']),
    async submit() {
      if (this.$refs.authform.validate()) {
        await this.executeForm(
          {
            action: 'security/userLogin',
          },
          {
            email: this.email,
            password: this.password,
          },
        );
      }
    },
    async goToRegisterForm() {
      await this.clearError();
      this.$emit('swap');
    },
  },
};
</script>
<style lang="scss" scoped>
.swap-auth-form {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding: 0 var(--gap) var(--gap-xlarge);
}

.login-button {
  align-self: center;
  margin-top: var(--gap);
}

::v-deep .forgot-password-link {
  margin-top: var(--gap);
  align-self: center;
}

.register-text {
  margin-top: var(--gap-xlarge);
  align-self: center;
}

.register-link {
  min-width: 0 !important;
  padding: 0 !important;
  height: auto !important;
  font-size: inherit !important;
  text-decoration: underline;
  text-transform: none;
  vertical-align: baseline;
}

::v-deep .h-error-box {
  margin: var(--gap) 0;
}
</style>
