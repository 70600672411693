<template>
  <v-card class="stay-tuned-small-card">
    <div class="title-container">
      {{ $t('components.stay_tuned_small_card.title') }}
    </div>
    <div class="description-container">
      {{ $t('components.stay_tuned_small_card.description') }}
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'stay-tuned-small-card',
};
</script>

<style lang="scss" scoped>
.stay-tuned-small-card {
  display: flex;
  flex-flow: column;
  box-shadow: var(--card-shadow) !important;

  background: url('~@/assets/stay_tuned_bg.svg') center center no-repeat;
  background-size: 100% 100%;
  background-color: white;
  text-align: center;
  min-height: 516px;
  align-items: center;
  font-weight: 600;
  line-height: 1.35;

  padding: var(--gap-large);
}

.title-container {
  font-size: var(--font-size-h1);
  margin: 40px 0;
}

.description-container {
  font-size: var(--font-size-h3);
  max-width: 265px;
}
</style>
