<template>
  <h-dialog @open="openDialog" ref="dialog" :title="$t('components.consumer_gift_card_download_pdf_modal.modal_title')">
    <template v-slot:activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <template v-if="viewName === 'pending'">
      <p>{{ $t('components.consumer_gift_card_download_pdf_modal.language_radios.title') }}</p>

      <v-radio-group v-model="language" mandatory>
        <v-radio class="language-radios" v-for="(option, index) in languageOptions" :key="index" :value="option.value">
          <template v-slot:label>
            <div class="h-text-1" style="color: var(--color-text-primary)">{{ $t(`components.consumer_gift_card_download_pdf_modal.language_radios.${option.label_key}.label`) }}</div>
          </template>
        </v-radio>
      </v-radio-group>

      <h-action-layout class="bottom-btn-zone">
        <h-btn @click="download" large :loading="working">{{ $t('components.consumer_gift_card_download_pdf_modal.download_action') }}</h-btn>
        <h-btn @click="close" :disabled="working" large outlined inverted color="primary">{{ $t('components.consumer_gift_card_download_pdf_modal.cancel_action') }}</h-btn>
      </h-action-layout>
    </template>

    <div v-if="viewName === 'success'" class="view">
      <v-icon size="40" color="success">mdi-check-circle-outline</v-icon>
      <p>{{ $t('components.consumer_gift_card_download_pdf_modal.download_success_message') }}</p>
    </div>

    <div v-if="viewName === 'error'" class="view">
      <v-icon size="40" color="error">mdi-close-circle-outline</v-icon>
      <p>{{ $t('components.consumer_gift_card_download_pdf_modal.download_error_message') }}</p>
    </div>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'consumer-gift-card-download-pdf-modal',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  props: {
    voucherId: {
      type: String,
    },
    activationCodeId: {
      type: String,
    },
  },
  data() {
    return {
      success: true,
      valid: false,
      language: null,
      languageOptions: [
        { label_key: 'french', value: 'fr' },
        { label_key: 'english', value: 'en' },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    viewName() {
      if (this.success === false) {
        return 'error';
      }
      if (this.success === true) {
        return 'success';
      }
      return 'pending';
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    close() {
      this.$refs.dialog.close();
    },
    openDialog() {
      this.clearError();
      this.language = this.currentLocale;
      this.success = null;
    },
    async download() {
      const res = await this.execute(
        {
          action: 'consumerGiftCard/downloadGiftCard',
        },
        {
          voucher_id: this.voucherId,
          lang: this.language,
          filename: this.$t('components.consumer_gift_card_download_pdf_modal.filename', this.language, {
            activation_code_id: this.activationCodeId,
          }),
        },
      );

      if (!res || (res && res.errorType)) {
        this.success = false;
        return;
      }

      this.success = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn-zone {
  margin-top: var(--gap);
}

.view {
  text-align: center;
  margin-top: var(--gap-xlarge);
  & > .v-icon {
    margin-bottom: var(--gap-xlarge);
  }
}
</style>
