<template>
  <div class="pay-and-get-offer">
    <restricted-to-members-badge v-if="restricted" class="restricted-to-members-badge-on-top-left" />
    <img class="pay-and-get-offer-image" :src="showcaseImage" alt="" />
    <div class="pay-and-get-offer-content">
      <div class="h-heading-h3">{{ branding | translateI18nObject('label') }}</div>
      <div class="description h-text-3">{{ branding | translateI18nObject('description') }}</div>
      <div v-if="!_isEmpty(supportBranding)" class="publisher-display">
        <img class="publisher-display-image" :src="showcaseImageSupport" />
        <div>{{ supportBranding | translateI18nObject('label') }}</div>
      </div>

      <slot name="extraContent" />
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { spaceShowcaseImageHelper } from '@/composables/image-helper';
import RestrictedToMembersBadge from '@/components/utilities/RestrictedToMembersBadge.vue';

export default {
  props: {
    branding: {
      type: Object,
      default: () => ({}),
    },
    supportBranding: {
      type: Object,
      default: () => ({}),
    },
    restricted: {
      type: Boolean,
    },
  },
  name: 'pay-and-get-offer',
  components: {
    RestrictedToMembersBadge,
  },
  setup() {
    return {
      spaceShowcaseImageHelper,
    };
  },
  computed: {
    showcaseImage() {
      return spaceShowcaseImageHelper(_get(this.$options.filters.translateI18nObject(this.branding, 'images.showcases'), '0'));
    },
    showcaseImageSupport() {
      return _get(this.$options.filters.translateI18nObject(this.supportBranding, 'images.showcases'), '0');
    },
  },
  methods: {
    _isEmpty,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.pay-and-get-offer {
  position: relative;
  @media ($sm-min) {
    display: flex;
  }
}

.pay-and-get-offer-image {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: var(--gap);

  @media ($sm-min) {
    max-height: 170px;
    max-width: 280px;
    margin-right: var(--gap);
    margin-bottom: 0;
    height: fit-content;
    aspect-ratio: 2;
  }

  @media ($md-min) {
    max-width: 340px !important;
  }
}

.description {
  margin-top: var(--gap-small);
  color: var(--color-text-secondary);

  @include cut-after-x-line(4);
}

.publisher-display {
  display: flex;
  align-items: center;
  margin-top: var(--gap);
}

.publisher-display-image {
  margin-right: var(--gap-small);
  max-width: 104px;
  max-height: 56px;
}

.restricted-to-members-badge-on-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
