<template>
  <v-hover v-slot="{ hover }">
    <small-card-base :to-url="urlToOffer" :class="{ 'on-hover': hover }" :image="offer.offer_branding.images.showcases[currentLocale][0]" :offer-title="offer.offer_branding.label[currentLocale]">
      <template #content>
        <small-card-base-badge use-gift-icon :text="offer.incentive.bonus_display" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

        <small-card-base-description :text="offer.offer_branding.description[currentLocale]" />

        <small-card-voucher-status-label :status="offer.status" :start="offer.availability.start_date" />
      </template>
    </small-card-base>
  </v-hover>
</template>
<script>
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseDescription from '@/components/cards/small/SmallCardBaseDescription.vue';
import SmallCardVoucherStatusLabel from '@/components/cards/small/SmallCardVoucherStatusLabel.vue';

export default {
  components: { SmallCardBase, SmallCardBaseDescription, SmallCardBaseBadge, SmallCardVoucherStatusLabel },
  name: 'gift-certificate-offer-multiple-publisher-small-card',
  props: {
    image: {
      type: String,
    },
    offer: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug']),
    urlToOffer() {
      return `/${this.currentSpaceSlug}/offers/${this.offer.id}`;
    },
  },
};
</script>

<style>
* {
  text-decoration: none !important;
}
</style>
