<template>
  <div>
    <success-bonus-offer-no-card v-if="canRequestCard" />
    <success-bonus-offer-with-card v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SuccessBonusOfferNoCard from './success/SuccessBonusOfferNoCard.vue';
import SuccessBonusOfferWithCard from './success/SuccessBonusOfferWithCard.vue';
export default {
  name: 'purchase-success',
  components: { SuccessBonusOfferNoCard, SuccessBonusOfferWithCard },
  computed: {
    ...mapGetters('account', ['canRequestCard']),
  },
};
</script>

<style></style>
