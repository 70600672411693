<template>
  <div class="home-status-view">
    <category-filter-bar @change="offerCategoryChange" :category-list="homeOfferCategoriesList" dark use-router-params class="category-filter-bar" :style="{ top: `${$vuetify.application.top}px` }" />

    <div ref="hashScrollAnchor"></div>
    <div class="home-content">
      <v-progress-circular v-if="!dataReady" :style="{ marginTop: '50px', marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" indeterminate color="primary" />
      <home-offers-grid v-else :title="$t(`components.home_status_view.statuses.${status}`)" :show-list-all="false" :show-empty-offer-message="hasNoCurrentOffers" :offers="offers">
        <template #backButton>
          <h-btn @click="returnHomeRegion" color="ultra-light" class="back-btn home-status-view-content">
            <v-icon>mdi-menu-left</v-icon>
            {{ $t('components.home_status_view.return') }}
          </h-btn>
        </template>
      </home-offers-grid>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import HomeOffersGrid from '../home-offers-list/HomeOffersGrid.vue';

import CategoryFilterBar from '../search/bar/CategoryFilterBar.vue';
import _get from 'lodash/get';

export default {
  name: 'home-status-view',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  props: {
    region: {
      type: String,
    },
    status: {
      type: String,
    },
    category: {
      type: String,
    },
    origin: {
      type: String,
    },
  },
  components: {
    HomeOffersGrid,
    CategoryFilterBar,
  },
  title: (args) => args.$t('page_title.home'),
  async created() {
    this.verifyStatus();

    if (this.category) {
      if (this.homeOfferCategoriesList.includes(this.category)) {
        await this.listHomeOffersByStatus(this.category);
      } else {
        this.$router.replaceNoDuplicate({ query: {} });
        await this.listHomeOffersByStatus();
      }
    } else {
      await this.listHomeOffersByStatus();
    }

    this.dataReady = true;
  },
  async mounted() {
    await this.listPublicSpaces();
  },
  data() {
    return {
      offers: [],
      offersWithConsumerGiftCertificate: [],
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters('offer', ['homeOfferCategoriesList', 'statusOptions', 'regionOptions']),
    ...mapGetters('ui', ['currentLocale']),
    hasNoCurrentOffers() {
      return _get(this.offers, 'length', 0) <= 0;
    },
  },
  methods: {
    ...mapActions('space', ['listPublicSpaces']),
    verifyStatus() {
      if (!this.regionOptions.includes(this.region) || !this.statusOptions.includes(this.status)) {
        this.$router.replaceNoDuplicate({ name: 'not-found', params: { _position: { x: 0, y: window.scrollY } } });
      }
    },

    returnHomeRegion() {
      this.$router.pushNoDuplicate({ name: this.origin || 'homeRegion', params: { region: this.region }, query: { category: this.category } });
    },

    async offerCategoryChange(categoryChangeEvent) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.hashScrollAnchor.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 9;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
      await this.listHomeOffersByStatus(categoryChangeEvent);
    },

    async listHomeOffersByStatus(category = null) {
      await this.execute(
        {
          action: 'offer/listHomeOffersByStatus',
          success: (offers) => {
            this.offers = offers;
          },
        },
        { locale: this.currentLocale, category_to_include: category, region_to_include: this.region, status_to_include: this.status },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.category-filter-bar {
  z-index: var(--hello-z-index-filter-bar);
  position: sticky;
}

.v-btn.back-btn {
  z-index: 1;
  min-width: 0 !important;
  padding: 12px;
  color: var(--color-text);
}

.home-content {
  padding: var(--gap);

  @media ($content-min) {
    padding: var(--gap) 0;
  }
}
</style>
