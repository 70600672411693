<template>
  <div class="auth-info-box">
    <div class="outer-icon">
      <v-icon class="inner-icon" color="ultra-light">mdi-account</v-icon>
    </div>
    <h4>{{ $t('components.auth_info_box.title') }}</h4>
    <p class="subtitle">{{ $t('components.auth_info_box.subtitle') }}</p>
  </div>
</template>

<script>
export default {
  name: 'auth-info-box',
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: var(--color-text-secondary);
}
.auth-info-box {
  padding: var(--gap);
  text-align: center;
  border: var(--border-thin) solid var(--color-border);
}
.inner-icon {
  background-color: var(--color-primary);
  font-size: 45px;
  clip-path: circle(50%);
  height: 35px;
  width: 35px;
  padding-top: 5px;
}

.outer-icon {
  margin: 0 auto var(--gap-xsmall) auto;
  border: var(--border-thick) solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 50%;
  width: fit-content;
}
</style>
