<template>
  <div class="home-offers-list">
    <!-- All region -->
    <home-offers-grid
      v-for="region in regions"
      :key="region"
      :offers="_get(offersByRegion, `${region}.items`, [])"
      :title="$t(`components.region_selection.regions.${region}`)"
      :list-all-action="listAllByRegion"
      :list-all-message="region"
      :list-all-label="$t(`components.home_offers_regions.list_all.${region}`, { numItems: _get(offersByRegion, `${region}.item_count`, 0) })"
      :show-stay-tuned-card="_get(offersByRegion, `${region}.item_count`, 0) < 3"
    ></home-offers-grid>
  </div>
</template>

<script>
import _get from 'lodash/get';
import HomeOffersGrid from './HomeOffersGrid.vue';

export default {
  name: 'home-offers-separated-by-regions',
  props: {
    offersByRegion: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
    },
  },
  components: {
    HomeOffersGrid,
  },
  computed: {
    regions() {
      return Object.keys(this.offersByRegion);
    },
  },
  methods: {
    _get,
    listAllByRegion(region) {
      this.$router.pushNoDuplicate({ name: 'homeRegion', params: { region }, query: { category: this.category } });
    },
  },
};
</script>
