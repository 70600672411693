import Vue from 'vue';

/**
 *  This store is use to handle the error and the various flags that control the error display location
 */

function clearIsState(state) {
  Object.keys(state.is).forEach((errorTypeKey) => {
    Vue.set(state.is, errorTypeKey, false);
  });
}
export default {
  namespaced: true,
  state: {
    is: {
      formError: false,
      overlayError: false,
      contentError: false,
    },
    errorTitle: '',
    errorMessage: '',
    currentError: null,
    errorStack: [],
    targetError: {},
  },
  getters: {
    currentError: (state) => state.currentError,
    isFormError: (state) => state.is.formError && state.currentError,
    isContentError: (state) => state.is.contentError && state.currentError,
    isOverlayError: (state) => state.is.overlayError && state.currentError,
    isUncaughtError: (state) => state.currentError && !Object.keys(state.is).reduce((acc, errorTypeKey) => acc || state.is[errorTypeKey], false),
    isTargetErrorGetter: (state) => (target) => state.is[target] && state.currentError,
  },
  mutations: {
    error(state, error) {
      state.errorTitle = '';
      state.errorMessage = error.msg;
      state.currentError = error;
    },
    displayOverlayError(state) {
      clearIsState(state);
      Vue.set(state.is, 'overlayError', true);
    },
    displayFormError(state) {
      clearIsState(state);
      Vue.set(state.is, 'formError', true);
    },
    displayContentError(state) {
      clearIsState(state);
      Vue.set(state.is, 'contentError', true);
    },
    displayTargetError(state, target) {
      clearIsState(state);
      Vue.set(state.is, target, true);
    },
    clearError(state) {
      clearIsState(state);
      state.errorTitle = '';
      state.errorMessage = '';
      state.currentError = null;
      state.errorStack = [];
    },
  },
  actions: {
    useFormError({ commit }) {
      commit('displayFormError');
    },
    useContentError({ commit }) {
      commit('displayContentError');
    },
    useOverlayError({ commit }) {
      commit('displayOverlayError');
    },
    useTargetError({ commit }, target) {
      commit('displayTargetError', target);
    },
    addError({ commit }, error) {
      console.trace(`CAUGHT ERROR. Error: `, error); // eslint-disable-line no-console
      commit('error', error);
    },
    clearError({ commit }) {
      commit('clearError');
    },
  },
};
