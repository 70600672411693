<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" :title="$t('components.account_email_pending_verification.modal_title')">
    <p>{{ $t('components.account_email_pending_verification.modal_message_part_1', { email: accountEmail }) }}</p>
    <p style="color: var(--color-accent)">{{ $t('components.account_email_pending_verification.modal_message_part_2') }}</p>
  </h-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'account-email-verification-modal',
  computed: {
    ...mapGetters('account', ['accountEmail']),
  },
};
</script>
