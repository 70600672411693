<template>
  <fragment>
    <!-- DEBUG -->
    <!-- <v-btn text @click="step++"> NEXT </v-btn>
    <p>{{ step }}</p>
    <v-btn text @click="step--"> CANCEL </v-btn> -->
    <!-- DEBUG -->

    <!-- Progress -->
    <v-progress-circular v-if="!init" :style="{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" :color="brandingColor" indeterminate />

    <!-- Not Found -->
    <h-page-not-found-box v-else-if="init && !currentCorpoOfferId" />

    <div v-else class="corpo-view">
      <!-- Header -->
      <corpo-intro />

      <div class="below-banner">
        <hello-content-error :style="{ margin: '24px 20px' }" />
        <div class="explanation" v-if="step === STEPS.EXPLANATION">
          <corpo-explanation @done="startProcess"></corpo-explanation>
        </div>

        <!--  Login -->
        <div v-else-if="step === STEPS.AUTH" class="auth-container">
          <auth-info-box class="auth-info" />
          <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" />
          <auth-and-register v-else />
        </div>

        <!--- Step and process--->
        <div v-else-if="isOfferPurchasable">
          <v-progress-circular v-if="working" :style="{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" :color="brandingColor" indeterminate />
          <corpo-email-validation-required v-else-if="step === STEPS.WAITING_EMAIL_VALIDATION" @success="validationSuccess" />
          <corpo-order-process v-else-if="step === STEPS.ORDER_PROCESS" @reset="reset" />
        </div>
      </div>

      <corpo-validating-email @success="validationSuccess" />
    </div>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import AuthAndRegister from '@/components/authentication/AuthAndRegister';
import SwapAuthAndRegister from '@/components/authentication/SwapAuthAndRegister';
import CorpoIntro from '@/components/corpo/CorpoIntro';
import CorpoEmailValidationRequired from '@/components/corpo/CorpoEmailValidationRequired';
import CorpoValidatingEmail from '@/components/corpo/CorpoValidatingEmail';
import CorpoOrderProcess from '@/components/corpo/CorpoOrderProcess';
import CorpoExplanation from '@/components/corpo/CorpoExplanation';
import { showcaseImageHelper } from '@/composables/image-helper';
import AuthInfoBox from '../../authentication/AuthInfoBox.vue';
import { recaptcha } from '@/mixins';

const PAYMENT_GATEWAY_AVAILABLE = process.env.VUE_APP_PAYMENT_GATEWAY_AVAILABLE === 'true';

export default {
  name: 'corpo-view',
  setup(props, { root }) {
    const { execute, working, executeContent } = safeExecute(root.$store);
    return {
      execute,
      executeContent,
      working,
      showcaseImageHelper,
    };
  },
  mixins: [recaptcha],
  components: {
    CorpoIntro,
    CorpoEmailValidationRequired,
    CorpoOrderProcess,
    AuthAndRegister,
    SwapAuthAndRegister,
    AuthInfoBox,
    CorpoExplanation,
    CorpoValidatingEmail,
  },
  title: (args) => args.$t('page_title.corpo'),
  async created() {
    // Load the offer by slug or by id
    let offer;

    if (this.$route.params.offer_id) {
      offer = await this.getOfferById(this.$route.params.offer_id);
    } else {
      offer = await this.getOfferBySlug(this.$route.params.slug);
    }

    // If no offer route to not found
    if (!offer) {
      this.init = true;
      return;
    }

    this.offer_id = offer.id;

    // Check that the offer is corporate enabled
    const corporateDetails = await this.getOfferCorporateDetails(offer.id);

    if (corporateDetails.enabled) {
      this.setOfferId(offer.id);
      this.init = true;
    }

    this.setEmailVerificationContext({
      type: 'corporate',
      offer_id: this.offer_id,
    });

    if (this.$route.query.trk) {
      // NOTE (SG) - Do not track information in a sync wait to reduce the time to render the page
      this.setTrackingInfo({
        tracking_link_id: this.$route.query.trk,
        offer_id: this.offer_id,
        recaptcha_token: await this.recaptcha('trackVisit'),
      });
    }

    if (!this.step) {
      this.step = this.STEPS.EXPLANATION;
    }

    this.init = true;
  },
  data() {
    return {
      init: false,
      step: null,
      STEPS: {
        EXPLANATION: 0,
        AUTH: 1,
        WAITING_EMAIL_VALIDATION: 2,
        ORDER_PROCESS: 3,
      },
      STEPS_NAME: ['explanation', 'auth', 'waiting_email_validation', 'order_process'],
      offer_id: undefined,
    };
  },
  computed: {
    ...mapGetters('space', ['currentPartnerId', 'brandingColor']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('account', ['emailVerified']),
    ...mapGetters('corpo', ['currentCorpoOfferId']),
    ...mapGetters('offer', ['currentOfferStatus', 'currentOfferShowcasesImage']),
    ...mapGetters('ui', ['currentLocale']),
    isOfferPurchasable() {
      return this.currentOfferStatus !== 'closed';
    },
    hasEmailValidationToken() {
      return this.$route.query['email-verification-token'];
    },
  },
  watch: {
    async authenticated() {
      if (this.step !== this.STEPS.EXPLANATION) {
        if (!this.authenticated) {
          await this.reset();
        }
        this.startProcess();
      }
    },
    step(newValue) {
      this.clearError();

      if (window.hj) {
        hj('stateChange', `/corpo/offers/${this.currentCorpoOfferId}/${this.STEPS_NAME[newValue]}`);
      }
    },
  },
  beforeDestroy() {
    this.reset();
    this.resetEmailVerificationContext();
  },
  methods: {
    ...mapActions('security', ['setEmailVerificationContext', 'resetEmailVerificationContext']),
    ...mapActions('corpo', ['resetCorpoStore', 'setOfferId']),
    ...mapActions('error', ['clearError']),
    ...mapActions('tracking', ['setTrackingInfo']),
    ...mapActions('overlay', ['openPaymentGatewayOfflineModalIsOpen']),

    async startProcess() {
      if (!PAYMENT_GATEWAY_AVAILABLE) {
        this.openPaymentGatewayOfflineModalIsOpen();
        return;
      }
      // If not auth need to request auth
      if (!this.authenticated) {
        this.step = this.STEPS.AUTH;
        return;
      }

      return this.executeContent({
        action: 'account/checkEmailVerified',
        success: () => {
          if (this.authenticated && !this.emailVerified && !this.hasEmailValidationToken) {
            this.step = this.STEPS.WAITING_EMAIL_VALIDATION;
          } else if (this.authenticated && this.emailVerified) {
            this.step = this.STEPS.ORDER_PROCESS;
          } else {
            this.step = this.STEPS.AUTH;
          }
        },
      });
    },
    validationSuccess() {
      this.startProcess();
    },
    async getOfferById(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferDetail',
        },
        offer_id,
      );
    },
    async getOfferBySlug(slug) {
      return this.execute(
        {
          action: 'offer/getOfferDetailBySlug',
        },
        { partnerId: this.currentPartnerId, slug },
      );
    },
    async getOfferCorporateDetails(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferCorporateDetails',
        },
        offer_id,
      );
    },

    async reset() {
      await this.resetCorpoStore();
      this.step = this.STEPS.EXPLANATION;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-info {
  margin: 0 var(--gap-page-side);

  @media ($sm-min) {
    margin: 0;
  }
}
.explanation {
  padding: var(--gap-small) var(--gap-page-side) 0;
}

.corpo-view {
  background-color: var(--color-ultra-light);
  padding-bottom: var(--gap-xxlarge);
}

.below-banner {
  background-color: var(--color-ultra-light);
}

::v-deep .frame {
  max-width: 996px;
  margin: 0 auto;
  padding: var(--gap-large) var(--gap);
  border-radius: 10px;
  border: var(--border-thin) solid var(--color-border);

  @media ($md-min) {
    border-radius: 20px;
  }
}

::v-deep .frame-content {
  max-width: 794px;
  margin: 0 auto;

  h3 {
    margin-bottom: var(--gap);
  }
}

.auth-container {
  padding-top: var(--gap);

  @media ($sm-min) {
    padding: var(--gap) var(--gap-page-side) 0;
  }
}
.auth-info-box {
  margin-bottom: var(--gap-xlarge);
}
</style>
