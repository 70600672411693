<template>
  <div class="consumer-gift-card-quote-card">
    <p class="consumer-gift-card-main-quote">{{ quote }}</p>
    <p class="consumer-gift-card-quote-author">
      -- <b>{{ clientName }}</b
      >, {{ clientDescription }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'consumer-gift-card-quote-card',
  props: {
    quote: {
      type: String,
      required: true,
    },
    clientName: {
      type: String,
      required: true,
    },
    clientDescription: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.consumer-gift-card-quote-card {
  --consumer-gift-card-quote-card-padding: 24px;
  padding: calc(var(--consumer-gift-card-quote-card-padding) + 60px) var(--consumer-gift-card-quote-card-padding) var(--consumer-gift-card-quote-card-padding) var(--consumer-gift-card-quote-card-padding);
  background-color: var(--color-ultra-light);
  border-radius: var(--border-radius-cards);
  display: flex;
  flex-flow: column;
  position: relative;
  color: var(--color-text-tertiary);
}
.consumer-gift-card-quote-card::before {
  position: absolute;
  content: '“';
  font-family: Epilogue;
  font-size: 120px;
  line-height: 130%;
  font-style: normal;
  font-weight: 500;
  color: rgba(var(--color-branding-rgb-values), 0.4);
  height: 0px;
  top: 0;
}
.consumer-gift-card-main-quote {
  font-style: italic;
  font-size: var(--font-size-text-2);
  font-weight: 500;
  @include cut-after-x-line(4);
}
.consumer-gift-card-quote-author {
  margin-top: auto;
  margin-bottom: 0;
  font-size: var(--font-size-text-3);
  @include cut-after-x-line(2);
  height: calc(var(--font-size-text-3) * 3);
}
</style>
