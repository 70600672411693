<template>
  <h-dialog max-width="680" @close="closeRenewCardModal" @open="openRenewCardModal" ref="dialog" :title="$t('components.renew_card_modal.title')">
    <template v-slot:activator="{ on, attrs }">
      <h-btn v-on="on" v-bind="attrs" inverted color="primary" style="align-self: center">{{ $t('components.account_info_bar_card.renew_action.title') }}</h-btn>
    </template>

    <renew-card-form v-if="showRenewCard && !renewCardSent" :public-token="publicToken" :card-program-type="cardProgramType" @success="successRenewCardForm" @cancel="cancelRenewCardForm"></renew-card-form>

    <v-expand-transition>
      <h-success-box v-show="renewCardSent" :text="$t('components.user_profile.renew_card_success')" style="margin-top: 14px" />
    </v-expand-transition>
  </h-dialog>
</template>

<script>
import RenewCardForm from '../../cards/components/RenewCardForm.vue';
import safeExecute from '@/composables/safe-execute';

export default {
  components: { RenewCardForm },
  name: 'renew-card-modal',
  setup(props, { root }) {
    const { executeContent } = safeExecute(root.$store);
    return {
      executeContent,
    };
  },
  data() {
    return {
      renewCardSent: false,
      showRenewCard: false,
    };
  },
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    cardProgramType: {
      type: String,
    },
  },
  methods: {
    closeRenewCardModal() {
      this.showRenewCard = false;
      this.executeContent({
        action: 'account/getAccount',
      });
    },
    openRenewCardModal() {
      this.showRenewCard = true;
    },
    cancelRenewCardForm() {
      this.$refs.dialog.close();
    },
    successRenewCardForm() {
      this.renewCardSent = true;
    },
  },
};
</script>
