import Vue from 'vue';

const service = {
  async instantCardApply(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/instant-cards`, payload);
    return response.data.data;
  },
  async findActivatableInstantCard(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/instant-card/activation-context`, payload);
    return response.data.data;
  },
  async activateInstantCard(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/instant-card/activate`, payload);
    return response.data.data;
  },
  async getInstantCardActivationCountryList() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/instant-card/countries`);
    return response.data.data.items;
  },
  async sendSmsActivatedInstantCard(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/instant-card/activated/sms`, payload);
    return response.data.data;
  },
};

export default service;
