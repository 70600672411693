import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import Maska from 'maska';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store';
import vuetify from '@/plugins/vuetify';
import { Fragment } from 'vue-frag';
import '@/scss/main.scss';
import '@/scss/custom-properties.scss';
import pluginFingerprint from './plugins/fingerprint';
import pluginFirebase from './plugins/vue-firebase';
import pluginI18n from './plugins/i18n';
import pluginVuePageTitle from './plugins/vue-page-title';
import pluginRecaptchaV3 from './plugins/recaptcha-v3';
import pluginAxios from './plugins/axios';
import pluginUrlProvider from './plugins/url-provider';
import pluginFakeFirebase from './plugins/fake-firebase';
import pluginFakeRecaptcha from './plugins/fake-recaptcha';
import pluginFakeStripe from './plugins/fake-stripe';
import pluginPaysafe from './plugins/paysafe';
import { useFakeFirebase, useFakeRecaptcha, canUseFakeStripe } from '@/utils/fake-plugin-helper';

import filters from './filters';

import './services';

Vue.use(Maska);
Vue.use(VueCompositionAPI);
Vue.component('Fragment', Fragment);

Vue.config.productionTip = false; // process.env.NODE_ENV !== 'production';

// Dev Tool
if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true;
}

const app = {
  router,
  store,
  vuetify,
  render: (h) => h(App),
};

const plugins = [];
plugins.push(pluginFingerprint);
plugins.push(pluginI18n);
if (useFakeFirebase()) {
  plugins.push(pluginFakeFirebase);
} else {
  plugins.push(pluginFirebase);
}
plugins.push(pluginVuePageTitle);
if (useFakeRecaptcha()) {
  plugins.push(pluginFakeRecaptcha);
} else {
  plugins.push(pluginRecaptchaV3);
}
plugins.push(pluginAxios);
plugins.push(pluginPaysafe);
if (canUseFakeStripe()) {
  plugins.push(pluginFakeStripe);
}
plugins.push(pluginUrlProvider);
plugins.push(filters);

Vue.config.errorHandler = function (err, vm, info) {
  console.log('Error caught in error handler', err); // eslint-disable-line no-console
  if (process.env.VUE_APP_NEWRELIC_APPLICATION_ID) {
    newrelic.noticeError(err, { info });
  }
  rg4js('send', {
    error: err,
    customData: [{ info }],
  });
  if (window.hj) {
    hj('event', 'error_occurred');
  }
};

async function initApplication() {
  console.log('Initializing application'); // eslint-disable-line no-console

  for (const plugin of plugins) {
    console.log(`Initializing "${plugin.name}" plugin`); // eslint-disable-line no-console
    await plugin.init({
      app,
      store,
      router,
      Vue,
    });
  }

  await store.helloInit();
  let vueApp;
  Vue.prototype.$firebaseAuth.onAuthStateChanged((user) => {
    console.log('Authentication state changed', user ? user.email : null); // eslint-disable-line no-console
    store.dispatch('security/setAuth', user);
    if (!vueApp) {
      console.log('Instantiate and mount the application'); // eslint-disable-line no-console
      vueApp = new Vue(app).$mount('#app');
    }
  });
}

initApplication();
