import camelCase from 'lodash/camelCase';

export default {
  name: 'filters',
  init: ({ Vue, app, store }) => {
    const requireModule = require.context('.', true, /^((?!\.unit\.).)*\.js$/);
    const root = { modules: {} };

    requireModule.keys().forEach((fileName) => {
      if (fileName === './index.js') return;

      const modulePath = fileName
        .replace(/^\.\/|\.js$/g, '')
        .split(/\//)
        .map(camelCase);

      console.log(`Loading filter "${modulePath}"`); // eslint-disable-line no-console

      const { modules } = getNamespace(root, modulePath);

      const moduleIndex = modulePath.pop();

      modules[moduleIndex] = {
        namespaced: true,
        ...requireModule(fileName).default({ Vue, app, store }),
      };

      function getNamespace(subtree, path) {
        if (path.length === 1) return subtree;

        const namespace = path.shift();
        subtree.modules[namespace] = {
          modules: {},
          ...subtree.modules[namespace],
        };
        return getNamespace(subtree.modules[namespace], path);
      }
    });
  },
};
