<template>
  <div class="overlay-app" :class="{ large: large }">
    <h2 class="header">{{ $t('components.overlay_app.title') }}</h2>
    <p class="text">{{ $t('components.overlay_app.message') }}</p>
    <app-store-badges />
    <p class="text">{{ $t('components.overlay_app.extra') }}</p>
  </div>
</template>

<script>
import AppStoreBadges from '@/components/utilities/AppStoreBadges';

export default {
  name: 'overlay-app',
  components: { AppStoreBadges },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-app {
  position: relative;
  overflow: hidden;
  padding: var(--gap-xlarge) var(--gap-page-side);
  color: var(--color-ultra-light);
  background-color: var(--color-primary);

  @media ($sm-min) {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 52%;
      background-image: url('~@/assets/i_phone8_overlay.svg');
      background-size: auto 100%;
      background-position: 0 0;
    }
  }

  @media ($md-min) {
    &.large {
      height: 480px;
      padding-top: 80px;
      padding-left: 50%;
      background-image: url('~@/assets/i_phone8.svg');
      background-position: 9% bottom;
      background-size: auto 90%;

      &:before {
        content: none;
      }
    }
  }

  @media ($lg-min) {
    &.large {
      background-position: 12% bottom;
    }

    &:before {
      left: 46%;
    }
  }
}

.header {
  margin-bottom: var(--gap);
}

.text {
  opacity: 0.65;

  @media ($sm-min) {
    max-width: 340px;
  }

  @media ($md-min) {
    max-width: 400px;
  }
}
</style>
