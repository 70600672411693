<template>
  <div class="account-space-consumer-gift-card-header">
    <h2>{{ $t('components.account_space_consumer_gift_card_header.title') }}</h2>
  </div>
</template>

<script>
export default {
  name: 'account-space-consumer-gift-card-header',
};
</script>

<style>
.account-space-consumer-gift-card-header {
  color: white;
  background-color: var(--color-primary);
  padding: var(--gap-large) var(--gap-page-side);
}
</style>
