<template>
  <fragment>
    <component :is="chosenComponent" :offer="offer" @buyNowError="handleBuyNowError" />
    <offer-card-error-modal v-model="errorModalActive" @close="closeErrorModal" :error-message-code="errorMessageCode" />
  </fragment>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import BonusOfferSinglePublisherSmallCard from '@/components/cards/small/BonusOfferSinglePublisherSmallCard.vue';
import BonusOfferMultiplePublisherSmallCard from '@/components/cards/small/BonusOfferMultiplePublisherSmallCard.vue';
import OfferCardErrorModal from './components/OfferCardErrorModal.vue';
import GiftCertificateOfferSinglePublisherSmallCard from '@/components/cards/small/GiftCertificateOfferSinglePublisherSmallCard.vue';
import GiftCertificateOfferMultiplePublisherSmallCard from '@/components/cards/small/GiftCertificateOfferMultiplePublisherSmallCard.vue';

export default {
  setup(props, { root }) {
    const { execute, executeContent, working } = safeExecute(root.$store);
    return {
      execute,
      executeContent,
      working,
    };
  },
  components: { BonusOfferSinglePublisherSmallCard, BonusOfferMultiplePublisherSmallCard, OfferCardErrorModal, GiftCertificateOfferMultiplePublisherSmallCard, GiftCertificateOfferSinglePublisherSmallCard },
  name: 'offer-small-card',
  props: {
    offer: {
      type: Object,
    },
  },
  computed: {
    chosenComponent() {
      if (this.offer.type === 'gift_certificate') {
        return this.offer.is_single_publisher ? 'gift-certificate-offer-single-publisher-small-card' : 'gift-certificate-offer-multiple-publisher-small-card';
      }

      if (this.offer.type === 'bonus') {
        return this.offer.is_single_publisher ? 'bonus-offer-single-publisher-small-card' : 'bonus-offer-multiple-publisher-small-card';
      }

      return '';
    },
  },
  data() {
    return {
      errorModalActive: false,
      errorMessageCode: null,
    };
  },
  methods: {
    handleBuyNowError(err) {
      if (err.errorType === 'http') {
        this.errorModalActive = true;
        this.errorMessageCode = err.code;
      }
    },
    async refreshCurrentOfferAndOfferList() {
      return this.executeContent(
        {
          action: 'offer/refreshCurrentOfferAndOfferList',
        },
        {
          offer_id: this.offer.id,
        },
      );
    },
    closeErrorModal() {
      this.errorMessageCode = null;
      this.refreshCurrentOfferAndOfferList();
    },
  },
};
</script>
