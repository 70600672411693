<template>
  <h-fixed-footer-dialog ref="dialog" :title="$t('components.consumer_gift_card_terms_and_conditions_modal.modal_title')" max-width="680">
    <template v-slot:activator="{ on, attrs }">
      <div class="terms">
        <div role="label" class="terms-label">
          {{ $t('components.consumer_gift_card_terms_and_conditions_modal.consent_text') }}
          <h-link v-on="on" v-bind="attrs" bold class="terms-action">{{ $t('components.consumer_gift_card_terms_and_conditions_modal.consent_text_link') }}</h-link>
        </div>
      </div>
    </template>

    <p class="h-text-1">{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_sub_title') }}</p>
    <ul>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text1') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text2') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text3') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text4') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text5') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text6') }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text7') }}</li>
      <li v-if="contextDormancyEnabled && contextDormancyExtensionInMonths > 0">
        {{
          $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text8_with_extension', {
            dormancyMonths: contextDormancyTrigger,
            dormancyFixedFee: formatContextDormancyFixedFee,
            dormancyExtendedMonths: contextDormancyExtensionInMonths + contextDormancyTrigger,
          })
        }}
      </li>
      <li v-else-if="contextDormancyEnabled">{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text8', { dormancyMonths: contextDormancyTrigger, dormancyFixedFee: formatContextDormancyFixedFee }) }}</li>
      <li>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text9') }}</li>
      <li v-if="offerCashFlowOptimization">{{ $t('components.consumer_gift_card_terms_and_conditions_modal.modal_text10') }}</li>
    </ul>

    <template #action-footer>
      <h-btn @click="closeTermAndConditionsModal" large>{{ $t('components.consumer_gift_card_terms_and_conditions_modal.action_title') }}</h-btn>
    </template>
  </h-fixed-footer-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'consumer-gift-card-terms-and-conditions-modal',
  computed: {
    ...mapGetters('consumerGiftCard', ['contextDormancyEnabled', 'contextDormancyTrigger', 'contextDormancyFixedFee', 'contextDormancyExtensionInMonths', 'offerCashFlowOptimization']),
    ...mapGetters('offer', ['currentOffer']),
    formatContextDormancyFixedFee() {
      const dormancyAmount = this.$options.filters.currency_full(this.contextDormancyFixedFee);
      return this.$options.filters.currencySymbol(this.$options.filters.currency(dormancyAmount));
    },
  },
  methods: {
    closeTermAndConditionsModal() {
      this.$refs.dialog.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--gap-xxlarge);
  margin-bottom: var(--gap-xxlarge);

  @media ($sm-min) {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
  }
}

.terms-label {
  margin-right: 3px;
}

.terms-action {
  font-size: var(--h-text-2);
  margin-top: -5px;
}
</style>
