<template>
  <big-card-base
    :offer-title="currentOfferPublisherName"
    :offer-label="currentOfferPublisherCategory | formatCategoryRegion(currentOfferPublisherRegion)"
    :offer-description="currentOfferPublisherDescription"
    :carousel-images="currentOfferPublisherShowcasesImage"
    compact-layout
    :show-panier-bleu="currentOfferPublisherBrandingFeaturePanierBleu"
  >
    <template #actionSection>
      <h-btn color="var(--color-branding)" v-if="currentOfferCtaActivation" :disabled="!currenOfferClaimable" block large @click="openOverlayForRedeem">{{ $t('components.gift_certificate_offer_publisher_big_card.call_to_action') }}</h-btn>

      <p class="offer-label" v-if="currenOfferAvailable">{{ $t('components.gift_certificate_offer_publisher_big_card.progress_left') }}</p>

      <big-card-voucher-status-label v-else :status="currentStatus" :allow-sold-out-display="true" :start="currentOfferStartDate" />
    </template>
  </big-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';
import { determineStatus, isStatusEqual } from '@/composables/offer-publisher-helper';
import BigCardVoucherStatusLabel from '@/components/cards/big/BigCardVoucherStatusLabel.vue';

export default {
  name: 'gift-certificate-offer-publisher-detail',
  components: { BigCardBase, BigCardVoucherStatusLabel },
  setup() {
    return { determineStatus, isStatusEqual };
  },
  computed: {
    ...mapGetters('space', ['currentPartnerId']),
    ...mapGetters('offer', [
      'currentOfferStartDate',
      'currentOfferCtaActivation',
      'currentOfferStatus',

      'currentOfferPublisherShowcasesImage',
      'currentOfferPublisherName',
      'currentOfferPublisherDescription',
      'currentOfferPublisherCategory',
      'currentOfferPublisherRegion',
      'currentOfferPublisherStatus',
      'currentOfferPublisherBrandingFeaturePanierBleu',
      'currentOfferPublisherOfferId',
      'currentOfferPublisherPublisherId',
    ]),
    currentStatus() {
      return this.determineStatus(this.currentOfferStatus, this.currentOfferPublisherStatus);
    },
    currenOfferClaimable() {
      return isStatusEqual(this.currentOfferStatus, this.currentOfferPublisherStatus, 'available');
    },
    currenOfferAvailable() {
      return isStatusEqual(this.currentOfferStatus, this.currentOfferPublisherStatus, 'available');
    },
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    openOverlayForRedeem() {
      this.openHeaderDrawerWithParams({
        content: 'redeem',
        payload: {
          partner_id: this.currentPartnerId,
          offer_id: this.currentOfferPublisherOfferId,
          publisher_id: this.currentOfferPublisherPublisherId,
        },
      });
    },
  },
};
</script>
