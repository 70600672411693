import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

export default {
  name: 'firebase',
  init: async ({ Vue }) => {
    const config = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    };

    const App = app.initializeApp(config);
    App.analytics();

    Vue.prototype.$firebaseApp = App;
    Vue.prototype.$firebaseAuth = App.auth();
  },
};
