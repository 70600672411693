<script>
import { VTextarea } from 'vuetify/lib';

export default {
  extends: VTextarea,
  name: 'h-textarea',

  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Label Text',
    },
    noResize: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLabelActive() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  margin-top: 0;
  padding-top: 32px;
}

.v-input__slot {
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-input);
  padding: 8px 14px;
  margin-bottom: 6px;
  transition: border-color 0.3s;

  &:before,
  &:after {
    content: none !important;
  }

  .secondary--text & {
    border-color: var(--color-secondary);
  }
  .accent--text & {
    border-color: var(--color-accent);
  }
  .error--text & {
    border-color: var(--color-error);
  }
  .success--text & {
    border-color: var(--color-success);
  }
  .warning--text & {
    border-color: var(--color-warning);
  }
  .v-input--is-disabled & {
    border-color: var(--color-text-secondary);
  }

  .v-label {
    left: 14px !important;
    top: -25px;
    font-weight: 600;
    transform: none;
    max-width: 100%;
    transition: color 0.3s;
    pointer-events: auto;
  }

  .v-input--is-focused & {
    border-width: 2px;
    padding: 7px 13px;

    .v-label {
      left: 13px !important;
      top: -26px;
    }
  }
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  position: static;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

.v-messages {
  padding: 0 14px;
}
</style>
