<template>
  <h-dialog v-model="model" :title="$t('components.payment_gateway_offline_modal.title')">
    <p>{{ $t('components.payment_gateway_offline_modal.text') }}</p>

    <template #action-footer>
      <h-btn @click="closePaymentGatewayOfflineModalIsOpen">{{ $t('components.payment_gateway_offline_modal.action') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'payment-gateway-offline-modal',
  computed: {
    ...mapGetters('overlay', ['paymentGatewayOfflineModalIsOpen']),
    model: {
      get() {
        return this.paymentGatewayOfflineModalIsOpen;
      },
      set() {
        this.closePaymentGatewayOfflineModalIsOpen();
      },
    },
  },
  methods: {
    ...mapActions('overlay', ['closePaymentGatewayOfflineModalIsOpen']),
  },
};
</script>
