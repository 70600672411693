<template>
  <div class="overlay-request-card-wrap">
    <div v-if="!showApplyInstantCardSuccess" class="overlay-request-card">
      <h2 class="header">{{ $t('components.overlay_request_card.title') }}</h2>
      <p class="text" v-html="$t('components.overlay_request_card.description')"></p>
      <h-btn @click="getAcquiringCardContext" :disabled="working" :loading="working" inverted>{{ $t('components.overlay_request_card.action') }}</h-btn>

      <card-type-selection-modal v-model="showCardTypeSelectionModal" :display-success-in-card-type-selection-modal="false" @success="applyInstantCardSuccess" />

      <overlay-request-card-error-modal v-model="errorModalIsOpen" :text="text" :title="title" />
    </div>

    <success-instant-card-application v-if="showApplyInstantCardSuccess" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import OverlayRequestCardErrorModal from '@/components/overlays/components/OverlayRequestCardErrorModal';
import CardTypeSelectionModal from '@/components/account/components/CardTypeSelectionModal';
import SuccessInstantCardApplication from '@/components/instant-card/components/SuccessInstantCardApplication';

export default {
  name: 'overlay-request-card',
  components: { OverlayRequestCardErrorModal, CardTypeSelectionModal, SuccessInstantCardApplication },
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  props: {
    redirectLink: {
      url: {
        type: String,
        required: true,
      },
      label_en: {
        type: String,
        required: true,
      },
      label_fr: {
        type: String,
        required: true,
      },
    },
  },
  data() {
    return {
      routeToMyAccount: false,
      errorModalIsOpen: false,
      showCardTypeSelectionModal: false,
      showApplyInstantCardSuccess: false,
      title: '',
      text: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    reloadAccount() {
      this.execute(
        {
          action: 'account/getAccount',
        },
        null,
      );
    },
    async getAcquiringCardContext() {
      this.execute({
        action: 'account/getAcquiringCardContext',
        success: (cardContext) => {
          if (cardContext.classic.available && !cardContext.instant.available) {
            this.createClassicCardApplicationAndOpenNewTab();
          }
          if (cardContext.classic.available && cardContext.instant.available) {
            this.showCardTypeSelectionModal = true;
          }
        },
      });
    },
    async applyInstantCardSuccess() {
      this.reloadAccount();
      this.showApplyInstantCardSuccess = true;
    },
    async createClassicCardApplicationAndOpenNewTab() {
      await this.execute(
        {
          action: 'account/createCardApplicationContext',
          success: (context) => {
            switch (context.result_code) {
              case 'application':
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              case 'join':
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              case 'pending_application':
                this.title = this.$t('components.overlay_request_card.pending_application_error_title');
                this.text = this.$t('components.overlay_request_card.pending_application_error_message');
                this.errorModalIsOpen = true;
                this.routeToMyAccount = true;
                break;
              case 'active_card':
                this.title = this.$t('components.overlay_request_card.active_card_error_title');
                this.text = this.$t('components.overlay_request_card.active_card_error_message');
                this.errorModalIsOpen = true;
                this.routeToMyAccount = true;
                break;
              default:
                this.title = this.$t('components.overlay_request_card.apply_url_error_modal_title');
                this.text = this.$t('components.overlay_request_card.apply_url_error_modal_message');
                this.errorModalIsOpen = true;
                this.routeToMyAccount = true;
            }
          },
        },
        {
          return_url: this.redirectLink.url,
          return_link_label_fr: this.redirectLink.label_fr,
          return_link_label_en: this.redirectLink.label_en,
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay-request-card-wrap {
  overflow: hidden;
}

.overlay-request-card {
  position: relative;
  padding: var(--gap-xxlarge) var(--gap-page-side);
  color: var(--color-ultra-light);
  background-color: var(--color-primary);

  @media ($sm-min) {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 43%;
      background-image: url('~@/assets/ask_bg.svg');
      background-size: auto 100%;
      background-position: 0 0;
    }
  }

  .header-drawer-content & {
    .text {
      @media ($md-min) {
        max-width: 50%;
      }
    }
  }
}

.header {
  margin-bottom: var(--gap-small);
}

.text {
  margin-bottom: var(--gap-large);
  opacity: 0.65;

  @media ($sm-min) {
    max-width: 50%;
  }

  @media ($md-min) {
    max-width: 400px;
  }
}
</style>
