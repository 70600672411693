<template>
  <div class="auth-overlay">
    <overlay-space-section v-if="space" :space="space" />

    <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" style="width: -webkit-fill-available" />
    <auth-and-register v-else />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import OverlaySpaceSection from './components/OverlaySpaceSection.vue';
import AuthAndRegister from '@/components/authentication/AuthAndRegister';
import SwapAuthAndRegister from '../authentication/SwapAuthAndRegister.vue';

export default {
  setup(props, { root }) {
    const { execute, executeOverlay, working } = safeExecute(root.$store);
    return {
      execute,
      executeOverlay,
      working,
    };
  },
  name: 'auth-overlay',
  components: { OverlaySpaceSection, AuthAndRegister, SwapAuthAndRegister },
  computed: {
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('overlay', ['headerDrawerIsOpen', 'headerDrawerContentPayload']),
  },
  watch: {
    authenticated() {
      if (this.headerDrawerIsOpen && this.authenticated) {
        this.closeHeaderDrawer();
      }
    },
  },
  data() {
    return {
      space: undefined,
    };
  },
  methods: {
    ...mapActions('overlay', ['closeHeaderDrawer']),
    async init() {
      this.$emit('startInitialization');
      if (this.headerDrawerContentPayload.partner_id) {
        this.space = await this.execute(
          {
            action: 'overlayContent/getSpaceForOverlay',
          },
          this.headerDrawerContentPayload.partner_id,
        );
      }
      this.$emit('initialized');
    },
    async clear() {
      this.space = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-overlay {
  display: flex;
  flex-flow: row wrap;
  padding: var(--gap);

  @media ($md-min) {
    flex-flow: row nowrap;
  }
}

.overlay-space-section {
  @media ($md-min) {
    width: 25%;
    padding-right: var(--gap);
    flex-shrink: 0;
  }
}

.auth-and-register {
  flex: 1 1 auto;
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-border);
}
</style>
