<template>
  <div class="restricted-to-members-badge">{{ $t('components.restricted_to_member_badge.text') }}</div>
</template>

<script>
export default {
  name: 'restricted-to-members-badge',
};
</script>

<style lang="scss" scoped>
.restricted-to-members-badge {
  padding: 10px;
  background: linear-gradient(270deg, #f8d836 1.18%, #ff8676 96.46%);
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
</style>
