import Vue from 'vue';

const service = {
  async listPublicSpaces() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/spaces`);
    return response.data.data;
  },

  async getSpaceBySlug(slug) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/spaces/slug/${encodeURIComponent(slug).substr(0, 50)}`);
    return response.data.data;
  },

  async getSpaceByPartnerId(partner_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/spaces/partner-id/${encodeURIComponent(partner_id)}`);
    return response.data.data;
  },
};

export default service;
