export default ({ Vue, app }) => {
  Vue.filter('formatCategoryRegion', (category, region) => {
    if (category && region) {
      return `${category} / ${region}`;
    }
    if (category) {
      return `${category}`;
    }
    if (region) {
      return `${region}`;
    }
    return '';
  });
};
