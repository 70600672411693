<template>
  <h-dialog v-model="dialog" @close="onClose" :title="$t('components.register_modal.title')" class="register-modal">
    <template v-slot:activator="{ on }">
      <h-btn v-on="on" inverted class="register-btn">{{ $t('components.register_form.action') }}</h-btn>
    </template>

    <p>{{ $t('components.register_modal.description') }}</p>

    <h-form ref="form" v-model="valid" @submit.prevent="submit()">
      <h-text-field :label="$t('components.register_modal.email_field')" type="email" maxlength="255" v-model="emailModal" :rules="emailRules" required data-cy="signinEmailField" />

      <h-text-field
        onpaste="return false;"
        ondrop="return false;"
        autocomplete="off"
        :label="$t('components.register_modal.confirm')"
        type="email"
        maxlength="255"
        v-model="emailConfirmationModal"
        :rules="emailRules"
        required
        data-cy="signinEmailFieldConfirm"
        :error-messages="validateTwoEmailMatchErrorMessageBinding(emailModal, emailConfirmationModal)"
      />

      <h-text-field :label="$t('components.register_modal.password_field')" type="password" maxlength="50" data-cy="signinPasswordField" v-model="password" :rules="passwordRules" required />
      <hello-form-error />

      <h-action-layout class="action-zone">
        <h-btn type="submit" :disabled="!valid || working" data-cy="registerSubmitBtn" :loading="working">{{ $t('components.register_modal.action') }}</h-btn>
      </h-action-layout>
    </h-form>
  </h-dialog>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import { validateInvalidEmail, validateMaxLength, validateInvalidPassword, validateRequired, validateTwoEmailMatchErrorMessageBinding } from '@/composables/validations';

export default {
  name: 'register-modal',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateInvalidPassword,
      validateRequired,
      validateMaxLength,
      validateInvalidEmail,
      validateTwoEmailMatchErrorMessageBinding,
    };
  },
  mixins: [recaptcha],
  props: {
    initialEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      emailModal: '',
      emailConfirmationModal: '',
      password: '',
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
      passwordRules: [this.validateRequired('password'), this.validateMaxLength(50), this.validateInvalidPassword()],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    initialEmail() {
      this.emailModal = this.initialEmail;
    },
  },
  mounted() {
    this.recaptcha('registerSubmit');
  },
  methods: {
    ...mapActions('error', ['clearError']),
    onClose() {
      this.clearError();
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.executeForm(
          {
            name: 'registerView2',
            action: 'security/userRegister',
            success: () => {
              this.dialog = false;
            },
          },
          {
            email: this.emailModal,
            password: this.password,
            language: this.currentLocale,
            recaptcha_token: await this.recaptcha('registerSubmit'),
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-zone {
  margin-top: var(--gap);
}
</style>
