<template>
  <div class="home-page-offer-bonus-small-card">
    <v-hover v-slot="{ hover }">
      <small-card-base
        :restricted-to-members="offer.features.restricted_to_members"
        :image="offer.offer_branding.images.showcases[currentLocale][0]"
        :to-url="urlToOffer"
        :class="{ 'on-hover': hover }"
        :show-panier-bleu="currentBrandingPanierBleu"
        :offer-title="offer.offer_branding.label[currentLocale]"
      >
        <template #content>
          <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

          <h-progress-bar
            :font-size="13"
            :status="offer.status"
            :start="offer.availability.start_date"
            :expiration="offer.availability.end_date"
            :current-number="offer.availability.inventory.adjusted_avail_payment_amount"
            :total-number="offer.availability.inventory.adjusted_total_payment_amount"
            :min-purchase="offer.incentive.minimum_payment"
            :max-purchase="offer.incentive.adjusted_user_payment_limit"
            style="margin: 28px 0"
          />
        </template>

        <template #call-to-action>
          <div class="partner-box">
            <span>{{ $t('components.home_page_offer_bonus_small_card.space') }}</span>
            <img class="partner-img" :src="horizontalLogoImageHelper(publicSpaceByPartnerIdLogoGetter(offer.partner_id))" :alt="publicSpaceByPartnerIdBrandingNameGetter(offer.partner_id)" />
          </div>
        </template>
      </small-card-base>
    </v-hover>
    <h-chip-small
      :plus-action="plus"
      :minus-action="minus"
      :pay="payAmount | currency | currencySymbol"
      :obtain="offerObtain | currency | currencySymbol"
      :disable-plus="disablePlus(payAmount, offer.incentive.step, offer.incentive.user_payment_limit, amountLeft)"
      :disable-minus="disableMinus(payAmount, offer.incentive.step, offer.incentive.minimum_payment, amountLeft)"
    />
  </div>
</template>
<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount, totalAmount } from '@/composables/offer-publisher-helper';
import { horizontalLogoImageHelper } from '@/composables/image-helper';

export default {
  name: 'home-page-offer-bonus-small-card',
  setup() {
    return {
      horizontalLogoImageHelper,
      remainingAmount,
      totalAmount,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
    };
  },
  components: {
    SmallCardBase,
    SmallCardBaseBadge,
  },
  props: {
    offer: {
      type: Object,
    },
  },
  watch: {
    offer() {
      this.payAmount = this.offer.incentive.default_payment;
    },
  },
  data() {
    return {
      payAmount: this.offer.incentive.default_payment,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentBrandingHorizontalLogo', 'publicSpaceByPartnerIdLogoGetter', 'publicSpaceByPartnerIdSlugGetter', 'publicSpaceByPartnerIdBrandingNameGetter']),
    currentBrandingPanierBleu() {
      return _get(this.offer, 'offer_branding.features.panier_bleu', false);
    },
    offerObtain() {
      return (this.offer.incentive.bonus_percent + 1) * this.payAmount;
    },
    amountLeft() {
      return this.remainingAmount(
        this.offer.availability.inventory.total_amount,
        this.offer.availability.inventory.used_amount,
        this.offer.incentive.bonus_display,
        _get(this.offer, 'publisher_inventory.total_amount', undefined),
        _get(this.offer, 'publisher_inventory.used_amount', undefined),
      );
    },
    urlToOffer() {
      return `/${this.publicSpaceByPartnerIdSlugGetter(this.offer.partner_id)}/offers/${this.offer.id}`;
    },
  },
  methods: {
    _get,
    action() {},
    routeToOffer() {
      const partner_id = this.offer.partner_id;
      const offer_id = this.offer.id;
      this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { space: this.publicSpaceByPartnerIdSlugGetter(partner_id), offer_id, _position: { x: 0, y: window.scrollY } } });
    },
    plus() {
      this.payAmount = this.addAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    minus() {
      this.payAmount = this.removeAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.home-page-offer-bonus-small-card {
  position: relative;
}

* {
  text-decoration: none !important;
}

.partner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-tertiary);
  font-size: 12px;
  font-weight: 400;
}

.partner-img {
  max-width: 104px;
  max-height: 36px;
  margin-left: 12px;
}

.on-hover {
  cursor: pointer;
  -webkit-filter: brightness(70%);
  transition: all 0.5s ease;
}
.small-card-base:not(.on-hover) {
  cursor: default;
}

::v-deep .h-chip-small {
  position: absolute;
  right: 15px;
  top: 20px;
}
</style>
