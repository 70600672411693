<template>
  <div class="space-home-view">
    <account-email-pending-verification v-if="authenticated && emailNeedVerification && !canRequestCard" />
    <account-card-required v-if="authenticated && canRequestCard" :redirect-link="cardApplyRedirectLink" />

    <!-- Space Header -->
    <h-hero-banner-partner :img-source-mobile="currentBrandingHorizontalLogo" :img-source-desktop="currentBrandingHeroSmall"></h-hero-banner-partner>

    <div ref="test"></div>

    <category-filter-bar v-if="showCategoriesFilter" @change="offerCategoryChange" :category-list="currentSpaceOfferCategoriesList" dark use-router-params class="category-filter-bar" :style="{ top: `${$vuetify.application.top}px` }" />

    <section class="content-zone" :style="{ 'min-height': minSearchHeight }">
      <!-- Spinner -->
      <div class="text-center mt-4" v-if="working">
        <v-progress-circular :size="100" :width="7" color="var(--color-branding)" indeterminate />
      </div>

      <!-- Space Offer List -->
      <grid-small-card v-if="!working" :style="{ paddingTop: spaceHistory && spaceHistory.length > 0 ? '0px' : '15px' }">
        <offer-small-card v-for="(offer, index) in offersList" :offer="offer" :key="index" />
      </grid-small-card>
    </section>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';

import AccountEmailPendingVerification from '@/components/account/AccountEmailPendingVerification.vue';
import AccountCardRequired from '@/components/account/AccountCardRequired.vue';
import GridSmallCard from '@/components/cards/small/GridSmallCard.vue';
import CategoryFilterBar from '../../search/bar/CategoryFilterBar.vue';
import OfferSmallCard from '@/components/cards/OfferSmallCard.vue';

export default {
  name: 'space-home-view',
  components: {
    AccountEmailPendingVerification,
    AccountCardRequired,
    GridSmallCard,
    CategoryFilterBar,
    OfferSmallCard,
  },
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  title: (args) => args.currentBrandingName,
  props: {
    space: String,
    category: String,
    openOverlay: Boolean,
    openRegister: Boolean,
  },
  async created() {
    await this.execute({ action: 'offer/getSpaceOfferCategoriesList' }, { locale: this.currentLocale });
    if (this.category) {
      if (this.currentSpaceOfferCategoriesList.includes(this.category)) {
        await this.loadTheOfferList(this.category);
      } else {
        this.$router.replaceNoDuplicate({ query: {} });
        await this.loadTheOfferList(null);
      }
    } else {
      await this.loadTheOfferList(null);
    }

    await this.loadGiftCertificateConsumerEnabledOffers();

    if (this.openOverlay) {
      this.toggleOverlayForAuth();
    }

    if (this.openRegister) {
      this.openRegisterModal();
    }
  },
  watch: {
    currentLocale() {
      this.$title = this.currentBrandingName;
    },
  },
  computed: {
    minSearchHeight() {
      return `${window.innerHeight + (this.working ? 409 : -(105 + this.stickTopPosition + 15))}px`;
    },
    ...mapGetters('account', ['canRequestCard', 'emailNeedVerification']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentBrandingName', 'currentBrandingHeroSmall', 'currentBrandingHorizontalLogo', 'spaceHistory']),
    ...mapGetters('offer', ['offersList', 'currentSpaceOfferCategoriesList']),
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_space_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_space_label', 'en'),
      };
    },
    hasMoreThan10Offers() {
      return this.offersList && this.offersList.length >= 10;
    },
    showCategoriesFilter() {
      return !!this.verifyCategory(this.category) || (this.hasMoreThan10Offers && this.currentSpaceOfferCategoriesList.length >= 2);
    },
  },
  methods: {
    ...mapActions('overlay', ['toggleOverlayForAuth', 'openRegisterModal']),
    async offerCategoryChange(event) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.test.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 30;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
      await this.loadTheOfferList(event);
    },
    async loadTheOfferList(category) {
      const category_to_include = this.verifyCategory(category);
      return await this.execute(
        {
          action: 'offer/listOffers',
        },
        { locale: this.currentLocale, category_to_include },
      );
    },

    async loadGiftCertificateConsumerEnabledOffers(category) {
      const category_to_include = this.verifyCategory(category);
      return await this.execute(
        {
          action: 'offer/listGiftCertificateConsumerEnabledOffers',
        },
        { locale: this.currentLocale, category_to_include },
      );
    },
    getScrollbarWith() {
      return window.innerWidth - document.documentElement.clientWidth;
    },
    verifyCategory(cat) {
      return cat;
    },
  },
};
</script>

<style lang="scss" scoped>
.category-filter-bar {
  position: sticky;
  z-index: var(--hello-z-index-filter-bar);

  @media ($md-min) {
    margin-top: 30px;
  }
}

.content-zone {
  padding-top: var(--gap);
}

.grid-small-card {
  padding: 0 var(--gap);

  @media ($content-min) {
    padding: 0;
  }
}
</style>
