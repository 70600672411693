<template>
  <h-fixed-footer-dialog ref="dialog" :title="$t('components.corpo_offer_terms_and_conditions_modal.modal_title')" max-width="680">
    <template v-slot:activator="{ on, attrs }">
      <div class="terms">
        <div role="label" class="terms-label">{{ $t('components.corpo_offer_terms_and_conditions_modal.consent_text') }}</div>
        <h-link v-on="on" v-bind="attrs" extra-bold class="terms-action">{{ $t('components.corpo_offer_terms_and_conditions_modal.consent_text_link') }}</h-link>
      </div>
    </template>

    <p class="h-text-1">{{ $t('components.corpo_offer_terms_and_conditions_modal.modal_sub_title') }}</p>
    <ul>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.reserved') }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.non_refundable') }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.activation') }}</li>
      <li v-if="showExpirationToC">{{ expirationTerms }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.untradeable') }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.redeem') }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.taxes') }}</li>
      <li>{{ $t('components.corpo_offer_terms_and_conditions_modal.rights') }}</li>
    </ul>

    <template #action-footer>
      <h-btn @click="closeTermAndConditionsModal" large>{{ $t('components.corpo_offer_terms_and_conditions_modal.action_title') }}</h-btn>
    </template>
  </h-fixed-footer-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'corpo-offer-terms-and-conditions-modal',
  computed: {
    ...mapGetters('offer', ['offerCorporateDetails']),
    ...mapGetters('corpo', ['contextDormancyEnabled', 'contextDormancyTrigger', 'contextDormancyFixedFee', 'contextDormancyExtension', 'contextIncentiveModel', 'contextIncentiveBonusDurationDays', 'contextIncentiveBonusEndDate']),

    formatContextDormancyFixedFee() {
      const dormancyAmount = this.$options.filters.currency_full(this.contextDormancyFixedFee);
      return this.$options.filters.currencySymbol(this.$options.filters.currency(dormancyAmount));
    },

    showExpirationToC() {
      if (this.offerCorporateDetails.voucher_structure === 'payment' && !this.contextDormancyEnabled) {
        return false;
      }
      return true;
    },

    expirationTerms() {
      if (this.offerCorporateDetails.voucher_structure === 'payment' && this.contextDormancyEnabled) {
        const messageParams = {
          dormancy_activity_trigger: this.contextDormancyTrigger,
          dormancy_fee: this.contextDormancyFixedFee.toFixed(2),
          dormancy_activity_extended: this.contextDormancyTrigger + this.contextDormancyExtension,
        };

        if (this.contextDormancyExtension > 0) {
          return this.$t('components.corpo_offer_terms_and_conditions_modal.expiration.payment_dormancy_with_extension', messageParams);
        }

        return this.$t('components.corpo_offer_terms_and_conditions_modal.expiration.payment_dormancy', messageParams);
      }

      if (this.offerCorporateDetails.voucher_structure === 'bonus') {
        if (this.contextIncentiveModel === 'relative') {
          const messageParams = {
            bonus_duration_days: this.contextIncentiveBonusDurationDays,
          };
          return this.$t('components.corpo_offer_terms_and_conditions_modal.expiration.bonus_relative', messageParams);
        }

        if (this.contextIncentiveModel === 'fixed') {
          const messageParams = {
            bonus_end_date: this.contextIncentiveBonusEndDate.local_date,
          };
          return this.$t('components.corpo_offer_terms_and_conditions_modal.expiration.bonus_fixed', messageParams);
        }
      }

      return null;
    },
  },
  methods: {
    closeTermAndConditionsModal() {
      this.$refs.dialog.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.terms-label {
  margin-right: 3px;
}

.terms-action {
  font-size: var(--h-text-2);
}
</style>
