import _get from 'lodash/get';
import router from '@/router';

const PAYMENT_GATEWAY_AVAILABLE = process.env.VUE_APP_PAYMENT_GATEWAY_AVAILABLE === 'true';

/**
 * This store is used to control the multiple overlay on the page (modal and or overlay drawer)
 */
export default {
  namespaced: true,
  state: {
    requestCardReminderModalIsOpen: false,
    subscriptionWarningModalIsOpen: false,
    restrictedToMembersModalIsOpen: false,
    paymentGatewayOfflineModalIsOpen: false,
    headerDrawerIsOpen: false,
    headerDrawerContent: '',
    headerDrawerContentPayload: {},
  },
  getters: {
    paymentGatewayOfflineModalIsOpen: (state) => state.paymentGatewayOfflineModalIsOpen,
    requestCardReminderModalIsOpen: (state) => state.requestCardReminderModalIsOpen,
    subscriptionWarningModalIsOpen: (state) => state.subscriptionWarningModalIsOpen,
    restrictedToMembersModalIsOpen: (state) => state.restrictedToMembersModalIsOpen,
    headerDrawerIsOpen: (state) => state.headerDrawerIsOpen,
    headerDrawerContent: (state) => state.headerDrawerContent,
    headerDrawerContentPayload: (state) => state.headerDrawerContentPayload,
  },
  mutations: {
    openPaymentGatewayOfflineModalIsOpen: (state) => {
      state.paymentGatewayOfflineModalIsOpen = true;
    },
    openRequestCardReminderModal: (state) => {
      state.requestCardReminderModalIsOpen = true;
    },
    openSubscriptionWarningModal: (state) => {
      state.subscriptionWarningModalIsOpen = true;
    },
    openRestrictedToMembersModal: (state) => {
      state.restrictedToMembersModalIsOpen = true;
    },
    closePaymentGatewayOfflineModalIsOpen: (state) => {
      state.paymentGatewayOfflineModalIsOpen = false;
    },
    closeRequestCardReminderModal: (state) => {
      state.requestCardReminderModalIsOpen = false;
    },
    closeSubscriptionWarningModal: (state) => {
      state.subscriptionWarningModalIsOpen = false;
    },
    closeRestrictedToMembersModal: (state) => {
      state.restrictedToMembersModalIsOpen = false;
    },
    openHeaderDrawer: (state, { content, payload = {} }) => {
      state.headerDrawerContent = content;
      state.headerDrawerContentPayload = payload;
      state.headerDrawerIsOpen = true;
    },
    setHeaderDrawerPayload: (state, payload) => {
      state.headerDrawerContentPayload = payload;
    },
    closeHeaderDrawer: (state) => {
      state.headerDrawerIsOpen = false;
    },
  },
  actions: {
    closeHeaderDrawer({ commit }) {
      commit('closeHeaderDrawer');
      if (window.hj) {
        hj('stateChange', router.currentRoute.path);
      }
    },
    openHeaderDrawer({ commit }, content) {
      commit('openHeaderDrawer', { content });
      if (window.hj) {
        hj('stateChange', `${router.currentRoute.path}/overlay/${content}`);
      }
    },
    openHeaderDrawerWithParams({ commit }, { content, payload }) {
      if (!PAYMENT_GATEWAY_AVAILABLE && (content === 'purchaseGiftCardOverlay' || content === 'purchase')) {
        commit('openPaymentGatewayOfflineModalIsOpen');
        return;
      }

      commit('openHeaderDrawer', { content, payload });
      if (window.hj) {
        hj('stateChange', `${router.currentRoute.path}/overlay/${content}`);
      }
    },
    async openHeaderDrawerForPayment({ dispatch, commit, rootState, rootGetters }, { payload = {}, skipOpenModal = false }) {
      if (!PAYMENT_GATEWAY_AVAILABLE) {
        commit('openPaymentGatewayOfflineModalIsOpen');
        return;
      }
      dispatch('payment/setPaymentAmountForPurchase', rootState.payment.current_payment_amount, { root: true });
      const currentSubscriptionPlan = rootGetters['space/currentSpaceSubscriptionPlan'];

      if (rootGetters['subscription/isSubscriptionRequired'] && !!!rootGetters['account/accountSubscriptions'][currentSubscriptionPlan.partner_id]) {
        if (!skipOpenModal) {
          commit('setHeaderDrawerPayload', payload);
          dispatch('openSubscriptionWarningModal');
        }
        return;
      }

      const currentAccountActiveMemberships = rootGetters['account/accountActiveMembershipsPartnerId'] || [];
      const restrictedToMembers = _get(payload, 'offer_restricted_to_member', false);

      if (restrictedToMembers && currentAccountActiveMemberships.indexOf(payload.partner_id) === -1) {
        if (!skipOpenModal) {
          dispatch('openRestrictedToMembersModal');
        }
        return;
      }

      commit('openHeaderDrawer', { content: 'purchase', payload });
      if (window.hj) {
        hj('stateChange', '/overlay/purchase');
      }
    },
    openRequestCardReminderModal({ commit }) {
      commit('openRequestCardReminderModal');
    },
    closeRequestCardReminderModal({ commit }) {
      commit('closeRequestCardReminderModal');
    },
    openSubscriptionWarningModal({ commit }) {
      commit('openSubscriptionWarningModal');
    },
    openRestrictedToMembersModal({ commit }) {
      commit('openRestrictedToMembersModal');
    },
    closeSubscriptionWarningModal({ commit }) {
      commit('closeSubscriptionWarningModal');
    },
    closeRestrictedToMembersModal({ commit }) {
      commit('closeRestrictedToMembersModal');
    },
    openPaymentGatewayOfflineModalIsOpen({ commit }) {
      commit('openPaymentGatewayOfflineModalIsOpen');
    },
    closePaymentGatewayOfflineModalIsOpen({ commit }) {
      commit('closePaymentGatewayOfflineModalIsOpen');
    },
  },
};
