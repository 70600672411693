import Vue from 'vue';

const service = {
  async createStripeClientPortalSession() {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/stripe-session`);
    return response.data.data;
  },

  async createSubscriptionIntent(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/subscriptions/intent`, payload);
    return response.data.data;
  },

  async getPrePaymentSubscriptionInfo({ partner_id }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/subscriptions/acquiring-context`, {
      partner_id,
    });
    return response.data.data;
  },
  async getPartnerActiveSubscriptionPlan({ partner_id }) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/partners/${partner_id}/active-subscription-plan`, {
      partner_id,
    });

    return response.data.data;
  },
};

export default service;
