<template>
  <div class="h-pay-and-get-editable-input">
    <h-text-field
      :class="currentLocale === 'en' ? 'amount-field-en' : 'amount-field-fr'"
      :label="label"
      :hint="hint"
      persistent-hint
      type="number"
      v-maska="'####'"
      ref="payAndGetEditableInput"
      :rules="rules()"
      :value="value"
      @input="input"
      @update="(val) => $emit('update', val)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'h-pay-and-get-editable-input',
  props: {
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    rules: {
      type: Function,
      default: () => [],
    },
  },
  watch: {
    computedAmount() {
      if (this.$el) {
        this.$el.style.setProperty('--input-display-value', `'${this.computedAmount}'`);
      }
    },
  },
  mounted() {
    // Initial setup of value.  The watch does not work because $el need to be in DOM
    this.$el.style.setProperty('--input-display-value', `'${this.computedAmount}'`);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    computedAmount() {
      return this.value === '' ? '$' : this.$options.filters.currencySymbol(this.value);
    },
  },
  methods: {
    input(newValue) {
      this.$emit('input', newValue === '' ? '' : parseInt(newValue, 10));
    },
  },
};
</script>

<style lang="scss" scoped>
// Used for switching the value of pseudo element
.h-pay-and-get-editable-input {
  --input-display-value: '';
}

// Used to hide the btn in the input
::v-deep input[type='number']::-webkit-outer-spin-button,
::v-deep input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

// Used for the bigger look of the input box
::v-deep .v-input__slot {
  height: 75px !important;
}

// Used to center the cursor and the text
::v-deep .v-input input {
  max-height: 75px;
  height: 55px;
  font-size: 52px;
  text-align: center;
  color: transparent;
  font-weight: 600;
  width: 150px;
}

::v-deep .v-text-field__slot {
  justify-content: space-around;

  &:before {
    content: var(--input-display-value);
    color: var(--color-primary);
    font-size: 52px;
    position: absolute;
    font-weight: 600;
    text-align: center;
    line-height: 1;
  }
  .error--text &:before {
    color: var(--color-error);
  }
}

::v-deep .v-input.amount-field-en input {
  padding-left: 30px;
}

::v-deep .v-input.amount-field-fr input {
  padding-right: 30px;
}
</style>
