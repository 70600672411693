import _get from 'lodash/get';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSpaceForOverlay({ dispatch, commit }, partnerId) {
      const space = await dispatch('space/getSpaceByPartnerId', partnerId, { root: true });

      return space;
    },
    async getOfferPublisherForOverlay({}, { offer_id, publisher_id }) {
      const offerPublisher = await Vue.prototype.$services.offer.getOfferPublisher(offer_id, publisher_id);
      return offerPublisher;
    },
    async getOfferForOverlay({}, id) {
      const offerDetail = await Vue.prototype.$services.offer.getOffer(id);
      if (!offerDetail) {
        return null;
      }
      return offerDetail;
    },
  },
};
