<template>
  <v-hover v-slot="{ hover }">
    <small-card-base
      :to-url="urlToOffer"
      :class="{ 'on-hover': hover }"
      :restricted-to-members="offer.features.restricted_to_members"
      :image="offer.offer_branding.images.showcases[currentLocale][0]"
      :offer-title="offer.offer_branding.label[currentLocale]"
    >
      <template #content>
        <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="offer.offer_branding.region_label[currentLocale]" :category_name="offer.category_name[currentLocale]" />

        <h-progress-bar
          class="progress-bar"
          :font-size="13"
          :status="offer.status"
          :start="offer.availability.start_date"
          :expiration="offer.availability.end_date"
          :current-number="offer.availability.inventory.adjusted_avail_payment_amount"
          :total-number="offer.availability.inventory.adjusted_total_payment_amount"
          :min-purchase="offer.incentive.minimum_payment"
          :max-purchase="offer.incentive.adjusted_user_payment_limit"
        />
      </template>
    </small-card-base>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import { remainingAmount, totalAmount } from '@/composables/offer-publisher-helper';

export default {
  setup() {
    return {
      remainingAmount,
      totalAmount,
    };
  },
  components: { SmallCardBase, SmallCardBaseBadge },
  name: 'bonus-offer-multiple-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'publicSpaceByPartnerIdSlugGetter']),
    urlToOffer() {
      return `/${this.currentSpaceSlug}/offers/${this.offer.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  text-decoration: none !important;
}
</style>
