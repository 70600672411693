<template>
  <div class="h-step-header">{{ text }}</div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'h-step-header',
  props: {
    text: {
      type: String,
      default: 'Title',
    },
  },
};
</script>

<style lang="scss" scoped>
.h-step-header {
  padding-top: 6px;
  padding-bottom: 14px;
  border-bottom: var(--border-thin) solid var(--color-border);
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;

  @media ($md-min) {
    font-size: 0.9375rem;
  }
}
</style>
