import { render, staticRenderFns } from "./EmailVerificationByCodeModal.vue?vue&type=template&id=64f6d999&scoped=true"
import script from "./EmailVerificationByCodeModal.vue?vue&type=script&lang=js"
export * from "./EmailVerificationByCodeModal.vue?vue&type=script&lang=js"
import style0 from "./EmailVerificationByCodeModal.vue?vue&type=style&index=0&id=64f6d999&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f6d999",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOtpInput,VProgressCircular})
