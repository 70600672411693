<template>
  <div class="small-card-base-badge">
    <div v-if="!hideBadge && useGiftIcon" class="hello-gift-badge"><v-icon color="ultra-light">mdi-wallet-giftcard</v-icon></div>
    <div v-else-if="!hideBadge" class="hello-bonus-badge">{{ text }}</div>
    <div class="hello-category-label">
      <div class="region-text">{{ region }}</div>
      <div class="category-text">{{ category_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'small-card-base-badge',
  props: {
    text: {
      type: String,
    },
    region: {
      type: String,
    },
    category_name: {
      type: String,
    },
    hideBadge: {
      type: Boolean,
      default: false,
    },
    useGiftIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.small-card-base-badge {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.hello-category-label {
  font-weight: 600;
  font-size: 14px;
}

.hello-gift-badge {
  background-color: var(--color-branding);
  padding: 8px;
  margin-right: 15px;
  min-width: 57.5px;
  text-align: center;
  line-height: 0.9;
}

.hello-bonus-badge {
  background-color: var(--color-error);
  font-weight: bold;
  font-size: 17px;
  line-height: 0.9;
  text-align: center;
  color: var(--color-ultra-light);
  padding: 12px 8px;
  margin-right: 15px;
  min-width: 57.5px;
  min-height: 40px;
}

.region-text {
  margin-bottom: -4px;
  @include cut-after-x-line(1);
}

.category-text {
  @include cut-after-x-line(1);
}
</style>
