import { ref } from '@vue/composition-api';
import { transformGeneralError } from '@/utils/error/error-transformer';

function createDefaultContext(contextOverwrite = {}) {
  function defaultHookFunction() {}

  return {
    beforeAction: contextOverwrite.beforeAction || defaultHookFunction,
    action: contextOverwrite.action || null,
    success: contextOverwrite.success || defaultHookFunction,
    beforeError: contextOverwrite.beforeError || defaultHookFunction,
    afterError: contextOverwrite.afterError || defaultHookFunction,
    finally: contextOverwrite.finally || defaultHookFunction,
  };
}
export default function safeExecute(store) {
  const working = ref(false);

  const internalExecute = async (context, params) => {
    let result;
    let ctx = createDefaultContext(context);
    try {
      await ctx.beforeAction();
      let actionResult = await store.dispatch(ctx.action, params, { root: true });

      // Post success hook
      if (ctx.success) {
        result = (await ctx.success(actionResult)) || actionResult;
      }
    } catch (err) {
      let error;

      //Convert Store Error
      if (!err.errorType) {
        error = transformGeneralError(err, 'store');
      } else {
        error = err;
      }

      // Pre Error Add Hook
      if (ctx.beforeError) {
        result = (await ctx.beforeError(error)) || error;
      }

      await store.dispatch('error/addError', error, { root: true });

      // After Error Add Hook
      if (ctx.afterError) {
        result = (await ctx.afterError(error)) || error;
      }
    } finally {
      // Finally Hook
      if (ctx.finally) {
        result = (await ctx.finally()) || result;
      }
    }
    return result;
  };

  const executeTarget = async (context = { target: 'generic' }, params) => {
    await store.dispatch('error/clearError', { root: true });
    let result;

    try {
      working.value = true;
      await store.dispatch('error/useTargetError', context.target, { root: true });
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('error/addError', err, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  const execute = async (context = {}, params) => {
    await store.dispatch('error/clearError', { root: true });
    let result;

    try {
      working.value = true;
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('error/addError', err, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  const executeForm = async (context = {}, params) => {
    await store.dispatch('error/clearError', { root: true });

    let result;

    try {
      working.value = true;
      await store.dispatch('error/useFormError', { root: true });
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('error/addError', err, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  const executeContent = async (context = {}, params) => {
    await store.dispatch('error/clearError', { root: true });

    let result;

    try {
      working.value = true;
      await store.dispatch('error/useContentError', { root: true });
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('error/addError', err, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  const executeOverlay = async (context = {}, params) => {
    await store.dispatch('error/clearError', { root: true });
    let result;

    try {
      working.value = true;
      await store.dispatch('error/useOverlayError', { root: true });
      result = await internalExecute(context, params);
    } catch (err) {
      result = await store.dispatch('error/addError', err, { root: true });
    } finally {
      working.value = false;
    }

    return result;
  };

  return { working, executeTarget, createDefaultContext, execute, executeForm, executeContent, executeOverlay, internalExecute };
}
