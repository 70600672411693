<template>
  <div class="all-destination-store">
    <div class="information-container">
      <p class="information">
        {{ stores[0] | translateI18nObject('category_name') }} -
        <span v-if="$vuetify.breakpoint.smAndDown"> {{ $tc('components.offer_stores_display.destination', stores.length, { number_stores: stores.length }) }} </span>
        <a v-else @click="toggleDestinationsSection" role="button">{{ $tc(`components.offer_stores_display.${showDestinations ? 'hide_list' : 'show_list'}`, stores.length, { number_stores: stores.length }) }}</a>
        <span v-if="!$vuetify.breakpoint.smAndDown"> ({{ showDestinations ? '-' : '+' }})</span>
      </p>
      <div v-if="franchiseUrl" class="link-container">
        <h-link :href="franchiseUrl" target="_blank">{{ $t('components.offer_stores_display.franchise_website') }}<v-icon>mdi-open-in-new</v-icon></h-link>
      </div>
    </div>
    <destination-button-list-slider v-show="$vuetify.breakpoint.smAndDown" :items="destinationItems" />
    <div class="destination-grid" v-show="showDestinations && !$vuetify.breakpoint.smAndDown">
      <destination-button-with-modal v-for="(item, index) in destinationItems" :key="index" :item="item"></destination-button-with-modal>
    </div>
  </div>
</template>

<script>
import DestinationButtonListSlider from '@/components/destination/DestinationButtonListSlider.vue';
import DestinationButtonWithModal from '@/components/destination/DestinationButtonWithModal.vue';

import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'franchise-all-destination-store',
  props: {
    stores: {
      type: Array,
      required: true,
    },
  },
  components: {
    DestinationButtonListSlider,
    DestinationButtonWithModal,
  },
  data() {
    return {
      showDestinations: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('store', ['currentDestinations', 'currentDestinationList']),
    statusReady() {
      return this.store.transactional_status === 'ready';
    },
    destinationItems() {
      // let currentDestinationIds = this.stores.map((s) => s.destination_id);
      return (this.stores || [])
        .reduce((acc, { destination_id, branding, complementary_info }) => {
          const destination = this.currentDestinations[destination_id];

          acc.push({
            buttonText: destination[`label_${this.currentLocale}`],
            buttonImageUrl: destination[`showcase_${this.currentLocale}`],
            destination_id: destination_id,
            store: this.$options.filters.translateI18nObject(branding, 'label'),
            storeComplementaryInfo: this.$options.filters.translateI18nObject(complementary_info),
            modalTitle: destination[`label_${this.currentLocale}`],
          });
          return acc;
        }, [])
        .sort((a, b) => {
          return (a.buttonText || '').toLowerCase().localeCompare((b.buttonText || '').toLowerCase());
        });
    },
    information() {
      if (this.store.type === 'place') {
        return `${this.$options.filters.address(this.store.location.address)}`;
      }
      if (this.store.type === 'web') {
        return `${this.$t('components.store_card.online')} (${this.store.location.display_url[this.currentLocale]})`;
      }
      if (this.store.branding.description[this.currentLocale] && this.store.branding.description[this.currentLocale] !== null) {
        return this.store.branding.description[this.currentLocale];
      }
      return undefined;
    },
    storeComplementaryInfo() {
      return _get(this.store, `complementary_info[${this.currentLocale}]`, null);
    },
    franchiseUrl() {
      return _get(this.stores[0], `franchise.url[${this.currentLocale}]`, null);
    },
    storeUrl() {
      if (this.store.type === 'web' && this.store.location.url[this.currentLocale]) {
        return this.store.location.url[this.currentLocale];
      }
      if (this.store.branding.url[this.currentLocale]) {
        return this.store.branding.url[this.currentLocale];
      }
      return undefined;
    },
    storeUrlText() {
      if (this.store.type === 'web') {
        return this.$t('components.store_card.visit_web_site_web');
      }
      if (this.store.branding.url[this.currentLocale]) {
        return this.$t('components.store_card.visit_web_site_place');
      }
      return '';
    },
  },
  methods: {
    toggleDestinationsSection() {
      this.showDestinations = !this.showDestinations;
    },
  },
};
</script>

<style lang="scss" scoped>
.destination-grid {
  display: grid;
  margin-top: var(--gap-xsmall);
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
  grid-template-rows: 1fr;
  justify-items: center;
}

.complementary-info {
  margin-bottom: var(--gap);
  font-style: italic;
}

.information-container {
  > * {
    margin-bottom: var(--gap);
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ($md-min) {
    display: flex;
    align-items: flex-end;

    > * {
      margin-bottom: 0 !important;
    }
  }
}

.information {
  font-weight: 600;
  flex-grow: 1;
  padding-right: 12px;

  @media ($md-min) {
    padding-right: var(--gap-xsmall);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.link-container {
  @media ($md-min) {
    flex-shrink: 0;
    width: 280px;
    text-align: right;
  }
}
</style>
