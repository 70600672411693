<template>
  <div>
    <p class="h-text-1">{{ $t('components.instant_card_apply.description') }}</p>
    <ul class="point-list">
      <li class="h-text-1">{{ $t('components.instant_card_apply.point1') }}</li>
      <li class="h-text-1">{{ $t('components.instant_card_apply.point2') }}</li>
      <li class="h-text-1">{{ $t('components.instant_card_apply.point3') }}</li>
    </ul>

    <h-form ref="instantCardApply" v-model="valid" @submit.prevent="submit">
      <div class="instant-card-apply-form">
        <h-text-field class="col-50" v-model="first_name" :label="$t('components.instant_card_apply.label_first_name')" :hint="$t('components.instant_card_apply.hint_first_name')" :rules="firstNameRules" maxlength="20" />
        <h-text-field class="col-50" v-model="last_name" :label="$t('components.instant_card_apply.label_last_name')" :hint="$t('components.instant_card_apply.hint_last_name')" :rules="lastNameRules" maxlength="20" />

        <h-select v-if="genderFieldAllowed" v-model="gender" class="col-50 mb-5" :label="$t('components.instant_card_apply.label_gender')" :items="genderChoices" item-text="name" item-value="abbr" :rules="genderRules" hide-details dense />
        <h-text-field
          v-if="yearOfBirthFieldAllowed"
          v-maska="{ mask: '####' }"
          v-model="year_of_birth"
          :label="$t('components.instant_card_apply.label_year_of_birth')"
          pattern="[0-9][0-9][0-9][0-9]"
          :rules="birthYearRules"
          required
          maxlength="4"
          class="col-right"
        />
        <div v-if="!genderFieldAllowed && yearOfBirthFieldAllowed" class="col-50 mb-5" />
        <div v-if="genderFieldAllowed && !yearOfBirthFieldAllowed" class="col-50 mb-5" />

        <h-text-field class="col-20" v-model="civic_number" :label="$t('components.instant_card_apply.label_civic_number')" type="text" :rules="civicNumberRules" maxlength="10" required />

        <h-text-field class="col-fill" v-model="street" :label="$t('components.instant_card_apply.label_street')" :hint="$t('components.instant_card_apply.hint_street')" type="text" :rules="streetRules" maxlength="30" required />

        <h-text-field class="col-20" v-model="apartment_number" :label="$t('components.instant_card_apply.label_apartment_number')" type="text" maxlength="10" :rules="apartmentNumberRules" />

        <h-text-field class="col-fill" v-model="city" :label="$t('components.instant_card_apply.label_city')" :hint="$t('components.instant_card_apply.hint_city')" type="text" maxlength="50" :rules="cityRules" required />

        <h-select v-model="province" class="col-50 mb-5" :label="$t('components.instant_card_apply.label_province')" :items="provincesChoices" item-text="name" item-value="abbr" :rules="provinceRules" hide-details dense />

        <h-text-field
          class="col-50"
          v-model="postal_code"
          v-maska="{ mask: 'A#A #A#' }"
          :label="$t('components.instant_card_apply.label_postal_code')"
          :hint="$t('components.instant_card_apply.hint_postal_code')"
          type="text"
          maxlength="7"
          :rules="postalCodeRules"
          required
        />

        <h-text-field
          class="col-50 mb-5"
          v-model="mobile"
          :label="$t('components.instant_card_apply.label_mobile_number')"
          :hint="$t('components.instant_card_apply.hint_mobile_number')"
          :rules="mobileRules"
          v-maska="'+1 (###) ###-####'"
          clearable
          type="text"
          maxlength="50"
        />
        <h-checkbox v-model="agreement_consent" :rules="consentRules" class="terms-conditions">
          <template v-slot:label>
            <span v-on:click.stop class="checkbox-label" v-html="$t('components.instant_card_apply.label_consent', { url1: helloCardholderInstantCardAgreement(), url2: helloElectronicCommunicationConsentAgreement() })"> </span>
          </template>
        </h-checkbox>
      </div>

      <hello-form-error class="mb-5" />

      <h-action-layout>
        <h-btn type="submit" :disabled="!valid || working" :loading="working">{{ $t('components.instant_card_apply.action') }}</h-btn>
        <h-btn @click="close" outlined>{{ $t('components.instant_card_apply.cancel_action') }}</h-btn>
      </h-action-layout>
    </h-form>
  </div>
</template>

<script>
import { validateMaxLength, validateRequired, validatePostalCode, validateNeedChecked, validateMobileNumber, validateYearOfBirth } from '@/composables/validations';
import safeExecute from '@/composables/safe-execute';
import { recaptcha } from '@/mixins';
import { mapGetters, mapActions } from 'vuex';
import _map from 'lodash/map';
import _replace from 'lodash/replace';
import _trim from 'lodash/trim';

export default {
  name: 'instant-card-apply',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      working,
      executeForm,
      validateMaxLength,
      validateRequired,
      validatePostalCode,
      validateNeedChecked,
      validateMobileNumber,
      validateYearOfBirth,
    };
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      datePickerMenu: false,
      default_country: 'CA',
      first_name: '',
      last_name: '',
      gender: '',
      year_of_birth: null,
      street: '',
      civic_number: '',
      apartment_number: null,
      city: '',
      province: '',
      postal_code: '',
      mobile: null,
      agreement_consent: '',
      firstNameRules: [this.validateRequired('first_name'), this.validateMaxLength(20)],
      lastNameRules: [this.validateRequired('last_name'), this.validateMaxLength(20)],
      genderRules: [this.validateRequired('gender')],
      streetRules: [this.validateRequired('street'), this.validateMaxLength(30)],
      civicNumberRules: [this.validateRequired('civic_number'), this.validateMaxLength(10)],
      apartmentNumberRules: [this.validateMaxLength(10)],
      cityRules: [this.validateRequired('city'), this.validateMaxLength(50)],
      provinceRules: [this.validateRequired('province')],
      postalCodeRules: [this.validateRequired('postal_code'), this.validatePostalCode()],
      consentRules: [this.validateNeedChecked()],
      mobileRules: [this.validateMobileNumber({ allowEmptyString: true })],
      birthYearRules: [this.validateRequired('birth_year'), this.validateYearOfBirth()],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('account', ['cardAcquiringContextToken', 'genderFieldAllowed', 'yearOfBirthFieldAllowed']),

    genderChoices() {
      const choices = ['female', 'male', 'non_binary'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.shared.genders.${choice}`), abbr: choice };
      });
    },
    provincesChoices() {
      const choices = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.shared.provinces.${choice}`), abbr: choice };
      });
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    close() {
      this.clearError();
      this.$emit('close');
    },
    helloCardholderInstantCardAgreement() {
      return this.currentLocale === 'fr' ? this.$urlProvider.HelloCardholderInstantCardAgreementFr : this.$urlProvider.HelloCardholderInstantCardAgreementEn;
    },
    helloElectronicCommunicationConsentAgreement() {
      return this.currentLocale === 'fr' ? this.$urlProvider.HelloElectronicCommunicationConsentAgreementFr : this.$urlProvider.HelloElectronicCommunicationConsentAgreementEn;
    },
    success() {
      this.$emit('success');
    },
    async submit() {
      if (this.$refs.instantCardApply.validate()) {
        const params = {
          card_acquiring_context_token: this.cardAcquiringContextToken,
          first_name: this.first_name,
          last_name: this.last_name,

          city: this.city,
          state: this.province,
          country: this.default_country,

          postal_code: this.postal_code,
          language: this.currentLocale,
        };

        if (this.yearOfBirthFieldAllowed) {
          params.year_of_birth = this.year_of_birth;
        }

        if (this.genderFieldAllowed) {
          params.gender = this.gender;
        }

        await this.executeForm(
          {
            beforeAction: async () => {
              //Formatting street properly.
              const apartment_number = this.apartment_number ? ` #${_trim(this.apartment_number)}` : '';
              const street = `${_trim(this.civic_number)} ${_trim(this.street)}${apartment_number}`;
              params.street = street;

              params.recaptcha_token = await this.recaptcha('instantCardApply');

              if (this.mobile !== '' && this.mobile !== null) {
                params.mobile = _replace(this.mobile, /[()+]/g, '').replace(/ /g, '-');
              }
            },
            action: 'instantCard/instantCardApply',
            success: () => {
              this.success();
            },
          },
          params,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instant-card-apply-form {
  @media ($sm-min) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    justify-content: space-between;
    > .col-50 {
      flex: 1 1 48%;
      max-width: 48%;
    }

    > .col-20 {
      flex: 1 1 20%;
    }
    > .col-fill {
      flex: 1 1 60%;
    }
  }
}

.point-list {
  li {
    margin: 0;
  }
}

.terms-conditions {
  margin-top: var(--gap);
  margin-bottom: var(--gap-xxlarge);
}

::v-deep .terms-conditions .v-input__slot {
  align-items: flex-start;
}

.checkbox-label {
  color: var(--color-primary);
}
</style>
