<template>
  <div class="h-error-box">
    <p class="h-error-box-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'h-error-box',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: 'Error message',
    },
  },
};
</script>
<style lang="scss" scoped>
.h-error-box {
}
.h-error-box-text {
  color: var(--color-error);
  text-align: center;
  font-size: var(--font-size-regular);
  line-height: 1.1;
  border: var(--border-thin) solid var(--color-error);
  padding: var(--gap);
}
</style>
