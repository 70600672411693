<template>
  <v-tabs-items :reverse="!registerFirst" v-model="tabModel" touchless class="swap-auth-and-register">
    <v-tab-item>
      <swap-register-form @swap="swapToLogin" />
    </v-tab-item>

    <v-tab-item>
      <swap-auth-form @swap="swapToRegister" />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import SwapAuthForm from '@/components/authentication/SwapAuthForm';
import SwapRegisterForm from '@/components/authentication/SwapRegisterForm';

const REGISTER = 0;
const LOGIN = 1;

export default {
  name: 'swap-auth-and-register',
  components: {
    SwapAuthForm,
    SwapRegisterForm,
  },
  props: {
    registerFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabModel: this.registerFirst ? REGISTER : LOGIN,
    };
  },
  methods: {
    swapToLogin() {
      this.tabModel = LOGIN;
    },
    swapToRegister() {
      this.tabModel = REGISTER;
    },
  },
};
</script>
