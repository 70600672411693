<template>
  <div class="all-destination-store">
    <div class="information-container">
      <p class="information">
        {{ stores[0] | translateI18nObject('category_name') }}
        <span v-if="!$vuetify.breakpoint.smAndDown">
          <span> - </span>
          <a @click="toggleDestinationsSection" role="button"> {{ $tc(`components.offer_stores_display.${showDestinations ? 'hide_address_list' : 'show_address_list'}`, stores.length, { number_stores: stores.length }) }}</a>
          <span> ({{ showDestinations ? '-' : '+' }})</span>
        </span>
      </p>
      <div v-if="franchiseUrl && !$vuetify.breakpoint.smAndDown" class="link-container">
        <h-link :href="franchiseUrl" target="_blank">{{ $t('components.offer_stores_display.franchise_website') }}<v-icon>mdi-open-in-new</v-icon></h-link>
      </div>
    </div>
    <div class="store-list" v-show="showDestinations || $vuetify.breakpoint.smAndDown" v-for="(store, index) in stores" :key="index">
      <div class="store-of-list">
        <div class="store-info">
          <v-icon v-if="store.type === 'place'" class="address-icon" color="text-primary" size="22" aria-label="map marker" role="img" aria-hidden="false">mdi-map-marker-radius-outline</v-icon>
          <img v-else-if="store.type === 'web'" src="@/assets/online_shopping.svg" />
          <img v-else-if="store.type === 'custom'" class="custom-icon" src="@/assets/shopping_bag.svg" />

          <p v-if="store.type === 'place'" class="h-text-3 store-list-item">
            <span v-if="store.destination_id">{{ destination(store) }} - </span>{{ information(store) }}
          </p>
          <a v-else-if="store.type === 'web'" :href="storeUrl(store)" target="_blank"
            ><span v-if="store.destination_id">{{ destination(store) }} - </span>{{ storeUrl(store) }}</a
          >
          <p v-else-if="store.type === 'custom'" class="h-text-3 store-list-item store-list-item-custom">
            <span v-if="store.destination_id">{{ destination(store) }} - </span>{{ information(store) }}
          </p>
        </div>

        <div v-if="storeComplementaryInfo(store)" class="store-complementary-info">
          <v-icon color="warning" size="18" role="img" aria-hidden="false">mdi-alert-outline</v-icon>
          <p class="h-text-3">
            <i>{{ storeComplementaryInfo(store) }}</i>
          </p>
        </div>
      </div>
    </div>
    <div v-if="franchiseUrl && $vuetify.breakpoint.smAndDown" class="link-container">
      <h-link :href="franchiseUrl" target="_blank">{{ $t('components.offer_stores_display.franchise_website') }}<v-icon>mdi-open-in-new</v-icon></h-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'franchise-addresses-store',
  props: {
    stores: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDestinations: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('store', ['currentDestinations']),
    franchiseUrl() {
      return _get(this.stores[0], `franchise.url[${this.currentLocale}]`, null);
    },
  },
  methods: {
    toggleDestinationsSection() {
      this.showDestinations = !this.showDestinations;
    },
    destination(store) {
      return this.currentDestinations[store.destination_id][`label_${this.currentLocale}`];
    },
    information(store) {
      if (store.type === 'place') {
        return `${this.$options.filters.address(store.location.address)}`;
      }
      if (store.type === 'web') {
        return `${this.$t('components.store_card.online')} (${store.location.display_url[this.currentLocale]})`;
      }
      if (store.branding.description[this.currentLocale] && store.branding.description[this.currentLocale] !== null) {
        return store.branding.description[this.currentLocale];
      }
      return undefined;
    },
    storeUrl(store) {
      if (store.type === 'web' && store.location.url[this.currentLocale]) {
        return store.location.url[this.currentLocale];
      }
      if (store.branding.url[this.currentLocale]) {
        return store.branding.url[this.currentLocale];
      }
      return undefined;
    },
    storeUrlText(store) {
      if (store.type === 'web') {
        return this.$t('components.store_card.visit_web_site_web');
      }
      if (store.branding.url[this.currentLocale]) {
        return this.$t('components.store_card.visit_web_site_place');
      }
      return '';
    },
    storeComplementaryInfo(store) {
      return _get(store, `complementary_info[${this.currentLocale}]`, null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.store-complementary-info {
  width: 100%;
  display: flex;
  gap: 8px;
  padding-left: 24px;
  padding-top: var(--gap-xxsmall);
  align-items: center;
}

.store-list {
  margin-top: var(--gap-xsmall);
  margin-bottom: var(--gap-xsmall);
}
.store-list:last-child {
  margin-bottom: 0px;
}

.store-list-item {
  margin-bottom: 0;
}

.store-list-item-custom {
  @include cut-after-x-line(2);
}

.store-info {
  display: flex;
}

.store-of-list {
  display: block;

  .v-icon,
  img {
    align-items: baseline;
    margin-right: 4px;
  }
}

.information-container {
  display: flex;

  & > * {
    margin-bottom: 0;
  }
}

.information {
  font-weight: 600;
  flex-grow: 1;
  padding-right: 12px;

  @media ($md-min) {
    padding-right: var(--gap-xsmall);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
