<template>
  <div class="h-error-label">
    <p class="h-text-3">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'h-error-label',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: 'Error message',
    },
  },
};
</script>
<style lang="scss" scoped>
.h-error-label {
  padding: var(--gap);
  text-align: center;
  color: var(--color-error);
}
</style>
