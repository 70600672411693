<template>
  <div class="user-profile">
    <div class="main-content">
      <div class="account">
        <div>{{ $t('components.user_profile.my_account') }}</div>
        <div class="h-heading-h2 email-address">{{ accountEmail }}</div>
      </div>
      <div v-if="showMyMoney" class="money">
        <div>{{ $t('components.user_profile.my_money') }}</div>
        <div class="h-heading-h2">{{ accountMoney | currency_full | currencySymbol }}</div>
      </div>
      <manage-card-modal v-if="hasAnyCard" class="manage-btn" :show-my-money="hasClassicCard" />
    </div>

    <div class="bottom-links">
      <update-email-modal />
      <update-password-modal />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdateEmailModal from '@/components/account/components/UpdateEmailModal';
import UpdatePasswordModal from '@/components/account/components/UpdatePasswordModal';
import ManageCardModal from '@/components/account/components/ManageCardModal';

export default {
  name: 'user-profile',
  components: { UpdateEmailModal, UpdatePasswordModal, ManageCardModal },
  computed: {
    ...mapGetters('account', ['accountEmail', 'accountMoney', 'hasAnyCard', 'accountMyMoneyHasOperations', 'hasClassicCard']),
    showMyMoney() {
      return this.accountMyMoneyHasOperations;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  padding: var(--gap-page-side);
  background-color: var(--color-secondary);
  color: var(--color-ultra-light);
}

.main-content {
  margin-bottom: var(--gap-large);

  @media ($sm-min) {
    display: flex;
    align-items: flex-end;
  }
}

.email-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ($sm-min) {
    max-width: 100%;
  }
}

.account {
  margin-bottom: var(--gap);

  @media ($sm-min) {
    overflow: hidden;
    margin-bottom: 0;
  }
}

.money {
  margin-bottom: var(--gap);

  @media ($sm-min) {
    flex: 0 0 auto;
    margin: 0 var(--gap-xlarge) 0;
  }
}

::v-deep .manage-btn {
  width: 100%;

  @media ($sm-min) {
    width: unset;
    flex: 0 0 auto;
    margin-left: auto;
  }
}

.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ($sm-min) {
    flex-direction: row;
  }

  > :first-child {
    margin-bottom: var(--gap);

    @media ($sm-min) {
      margin-bottom: 0;
      margin-right: var(--gap-xlarge);
    }
  }
}
</style>
