<template>
  <div class="corpo-confirmation">
    <div class="frame">
      <div class="frame-content">
        <h2 class="header">{{ $t('components.corpo_confirmation.title') }}</h2>

        <!--  TODO (PLP): JC Review all progress circular.  No width 100% because funky -->
        <div v-if="!batchReady" class="batch-not-ready-container">
          <v-progress-circular v-if="!pollTimeExceeded" :size="100" :width="7" indeterminate color="primary" />
          <h-btn v-else :href="businessGiftUrl" target="_blank">{{ $t('components.corpo_confirmation.redirect_corporate_gifts') }}</h-btn>
        </div>

        <div v-else>
          <p v-html="$t('components.corpo_confirmation.subtitle', { orderId: corporateOrderId })"></p>

          <div class="links">
            <h-link :disabled="working" bold @click="downloadInvoice" :large="$vuetify.breakpoint.mdAndUp">
              <v-icon>mdi-download</v-icon>
              {{ $t('components.corpo_confirmation.download_invoice') }}
            </h-link>

            <br />

            <h-link :disabled="working" bold :href="businessConsoleUrl" :large="$vuetify.breakpoint.mdAndUp">
              <v-icon>mdi-open-in-new</v-icon>
              <span v-html="$t('components.corpo_confirmation.go_to_account')"></span>
            </h-link>
          </div>

          <p class="h-label-1">{{ $t('components.corpo_confirmation.download_vouchers') }}</p>
          <div class="download-container">
            <span class="downbload-label">{{ $t('components.corpo_confirmation.print_label') }}</span>
            <h-link :disabled="working" bold @click="downloadMultiPDF" :large="$vuetify.breakpoint.mdAndUp">
              <v-icon>mdi-download</v-icon>
              {{ $t('components.corpo_confirmation.print_cta') }}
            </h-link>
          </div>
          <div class="download-container">
            <span class="downbload-label">{{ $t('components.corpo_confirmation.share_email_label') }}</span>
            <h-link :disabled="working" bold @click="downloadZip" :large="$vuetify.breakpoint.mdAndUp">
              <v-icon>mdi-download</v-icon>
              {{ $t('components.corpo_confirmation.share_email_cta') }}
            </h-link>
          </div>
          <div class="download-container">
            <span class="downbload-label">{{ $t('components.corpo_confirmation.share_whatever_label') }}</span>
            <div style="display: block">
              <h-link :disabled="working" bold @click="downloadExcel" :large="$vuetify.breakpoint.mdAndUp">
                <v-icon>mdi-download</v-icon>
                {{ $t('components.corpo_confirmation.share_whatever_cta_excel') }}
              </h-link>
              <br />
              <h-link :disabled="working" bold @click="downloadPDFInfo" :large="$vuetify.breakpoint.mdAndUp">
                <v-icon>mdi-download</v-icon>
                {{ $t('components.corpo_confirmation.share_whatever_cta_pdf') }}
              </h-link>
            </div>
          </div>
        </div>

        <p v-html="$t('components.corpo_confirmation.available_in_account')"></p>

        <hr class="separator" />

        <div>
          <h-link :style="{ fontSize: '1.1em' }" bold @click="$emit('reset')" :large="$vuetify.breakpoint.mdAndUp">{{ $t('components.corpo_confirmation.order_again') }}</h-link>
        </div>

        <hr class="separator" />

        <p v-html="$t('components.corpo_confirmation.question_text')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';

export default {
  name: 'corpo-confirmation-step',
  async created() {
    if (this.canPoll) {
      this.doPoll = true;
    }
  },
  setup(props, { root }) {
    const { execute, executeContent, working } = safeExecute(root.$store);
    return {
      execute,
      executeContent,
      working,
    };
  },
  data() {
    return {
      sleepTimeMs: 5 * 1000,
      maxPollTimeMs: 5 * 60 * 1000,
      pollTimeMs: 0,
      isPolling: false,
      doPoll: false,
      timeoutId: null,
    };
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  watch: {
    canPoll() {
      if (this.canPoll) {
        this.doPoll = true;
      }
    },
    doPoll() {
      if (this.doPoll) {
        this.startPolling();
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('corpo', ['corporateOrderId', 'corporateOrderStatusLoading', 'corporateOrderCompleted', 'corporateOrderBatchId', 'contextQuantity']),
    canPoll() {
      return this.corporateOrderId !== null;
    },
    pollTimeExceeded() {
      return this.pollTimeMs > this.maxPollTimeMs;
    },
    shouldAutoPoll() {
      return this.canPoll && !this.batchReady && !this.pollTimeExceeded;
    },
    batchReady() {
      return this.corporateOrderCompleted === true && this.corporateOrderBatchId !== null;
    },
    businessGiftUrl() {
      return `${process.env.VUE_APP_BUSINESS_CONSOLE}/gifts`;
    },
    businessConsoleUrl() {
      return `${process.env.VUE_APP_BUSINESS_CONSOLE}`;
    },
  },
  methods: {
    async startPolling() {
      this.isPolling = true;
      this.doAutoPoll();
    },
    async doAutoPoll() {
      if (this.canPoll) {
        this.getCorporateOrderStatus(this.autoPollCallback);
        this.pollTimeMs += this.sleepTimeMs;
      }
    },
    async autoPollCallback() {
      if (this.shouldAutoPoll) {
        this.timeoutId = setTimeout(() => {
          this.doAutoPoll();
          this.timeoutId = null;
        }, this.sleepTimeMs);
      } else {
        this.isPolling = false;
      }
    },
    async doManualPoll() {
      this.getCorporateOrderStatus();
    },
    async getCorporateOrderStatus(successCallback) {
      if (this.canPoll) {
        return this.executeContent(
          {
            action: 'corpo/getCorporateOrderStatus',
            success: () => {
              if (successCallback) {
                successCallback();
              }
            },
          },
          this.corporateOrderId,
        );
      }
    },
    async downloadMultiPDF() {
      await this.executeContent(
        { action: 'corpo/downloadActivationCodesBatchPdf' },
        {
          language: this.currentLocale,
          id: this.corporateOrderBatchId,
        },
      );
    },
    async downloadZip() {
      await this.executeContent(
        { action: 'corpo/downloadActivationCodesBatchZip' },
        {
          language: this.currentLocale,
          id: this.corporateOrderBatchId,
        },
      );
    },
    async downloadExcel() {
      await this.executeContent(
        { action: 'corpo/downloadActivationCodesBatchExcel' },
        {
          language: this.currentLocale,
          id: this.corporateOrderBatchId,
        },
      );
    },
    async downloadPDFInfo() {
      await this.executeContent(
        { action: 'corpo/downloadActivationCodesBatchInfosPdf' },
        {
          language: this.currentLocale,
          id: this.corporateOrderBatchId,
        },
      );
    },
    async downloadInvoice() {
      await this.executeContent(
        { action: 'corpo/downloadCorporateOrderInvoice' },
        {
          corporate_order_id: this.corporateOrderId,
          language: this.currentLocale,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.links {
  margin: var(--gap-xlarge) 0;
}
.corpo-confirmation {
  padding: var(--gap) var(--gap) 0;
}

.separator {
  width: 75%;
  margin: var(--gap-xlarge) auto;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--color-border);
}
.download-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  background: var(--color-background-alt);
  border-radius: var(--border-radius);
  margin-bottom: var(--gap-small);
  font-weight: 600;

  @media ($md-min) {
    flex-flow: row wrap;
    align-items: center;
    padding: 12px;
  }
}
.downbload-label {
  margin: 0 0 6px 0;

  @media ($md-min) {
    width: 46%;
    margin: 0;
  }
}
.batch-not-ready-container {
  text-align: center;
  margin: var(--gap);
}

.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading,
.theme--light.v-btn.v-btn--disabled {
  color: #a4a4a4 !important;
}
.header {
  margin-bottom: var(--gap);
  color: var(--color-success);
}
</style>
