import { computed, ref, watch } from '@vue/composition-api';

export default function createSignInNavbarItem(store, router, execute, DefaultNavigationItem, context) {
  const currentRoute = ref(router.currentRoute.name);

  watch(
    () => context.root.$route,
    () => {
      currentRoute.value = context.root.$route.name;
    },
  );
  const isInstantActivationPage = computed(() => currentRoute.value === 'instant-card-activation');
  const isHidden = computed(() => isInstantActivationPage.value);

  function clickAction() {
    store.dispatch('overlay/openHeaderDrawerWithParams', {
      content: 'auth',
      payload: {
        partner_id: store.getters['space/currentPartnerId'],
      },
    });
  }

  return new DefaultNavigationItem('components.navbar.signin', { isEnable: true, isHidden: isHidden }, clickAction);
}
