import Vue from 'vue';

const service = {
  async getOfferStores(offerId, payload, limit) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/search/stores?limit=${limit}`, payload);

    return response.data.data;
  },

  async getOfferStoreCategoryIds(offerId, publisherId) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/search/stores/category-ids`;
    if (publisherId) {
      url = `${url}?publisher_id=${encodeURIComponent(publisherId)}`;
    }
    const response = await Vue.prototype.$axios.get(url);

    return response.data.data.items;
  },

  async getDestinationList(offerId, publisherId) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/v1/offers/${encodeURIComponent(offerId).substr(0, 20)}/search/stores/destinations`;
    if (publisherId) {
      url = `${url}?publisher_id=${encodeURIComponent(publisherId)}`;
    }

    const response = await Vue.prototype.$axios.get(url);

    return response.data.data.items;
  },

  async getDestinationDetail(destination_id) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/v1/destinations/${destination_id}`;

    const response = await Vue.prototype.$axios.get(url);

    return response.data.data;
  },
};

export default service;
