<template>
  <div class="destination-button-list-slider">
    <v-slide-group show-arrows class="mobile-widget-slide-group">
      <v-slide-item v-for="(item, index) in items" :key="index">
        <destination-button-with-modal :item="item"></destination-button-with-modal>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import DestinationButtonWithModal from './DestinationButtonWithModal.vue';

export default {
  name: 'destination-button-list-slider',
  components: { DestinationButtonWithModal },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .destination-button {
  margin: var(--gap-xsmall);
}
</style>
