<template>
  <p class="small-card-base-status-label">{{ textOverride ? textOverride : text }}</p>
</template>

<script>
export default {
  name: 'small-card-base-status-label',
  props: {
    textOverride: {
      type: String,
      default: undefined,
    },
    expiration: {
      type: String,
    },
    start: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  computed: {
    formattedExpirationDate() {
      if (!this.expiration) {
        return null;
      }

      return this.$options.filters.formatShortDate(this.expiration);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(this.start);
    },
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    text() {
      if (this.status === 'coming_soon') {
        return this.$t('components.small_card_base_status_label.coming_soon', { date: this.formattedStartDateWithHour });
      } else if (this.status === 'sold_out') {
        return this.$t('components.small_card_base_status_label.sold_out', { date: this.formattedStartDate });
      } else if (this.status === 'available') {
        if (this.formattedExpirationDate) {
          return this.$t('components.small_card_base_status_label.expiration', { date: this.formattedExpirationDate });
        } else {
          return this.$t('components.small_card_base_status_label.no_expiration');
        }
      } else if (this.status === 'closed') {
        return this.$t('components.small_card_base_status_label.closed', { date: this.formattedExpirationDate });
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.small-card-base-status-label {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}
</style>
