<template>
  <div class="corpo-company-manager">
    <h3>{{ $t('components.corpo_company_manager.title') }}</h3>

    <div v-if="companyList.length === 1" class="company-container">
      <div>
        <div class="company-name">{{ companyList[0].name }}</div>
        <div class="company-info">{{ companyList[0].address.street }}</div>
        <div class="company-info">
          {{ $t('components.corpo_company_step.contact_label') }}: {{ companyList[0].contact.name }} <span style="overflow-wrap: anywhere">({{ companyList[0].contact.email }})</span>
        </div>
      </div>
      <corpo-company-modal :initial-company="companyList[0]" edit-mode />
    </div>

    <h-form v-else>
      <v-radio-group v-model="radios" mandatory>
        <div v-for="(company, index) of companyList" :key="`company-${index}`" class="company-container">
          <!-- NOTE (PLP): Need to specify value because uses the child index and since it is not a direct child it does not work -->
          <v-radio :value="index">
            <template v-slot:label>
              <div>
                <div class="company-name">{{ company.name }}</div>
                <div class="company-info">{{ company.address.street }}</div>
                <div class="company-info">
                  Contact: {{ company.contact.name }} <span style="overflow-wrap: anywhere">({{ company.contact.email }})</span>
                </div>
              </div>
            </template>
          </v-radio>
          <corpo-company-modal :initial-company="company" edit-mode />
        </div>
      </v-radio-group>
    </h-form>

    <!-- Dirty Patch to align the activator when there is more than one company -->
    <div class="d-flex">
      <div v-if="companyList.length > 1" style="width: 32px"></div>
      <corpo-company-modal />
    </div>
    <!-- Dirty Patch end -->

    <div class="btn-container">
      <h-btn :block="$vuetify.breakpoint.smAndDown" @click="proceed">{{ $t('components.corpo_company_step.proceed_text') }}</h-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CorpoCompanyModal from '@/components/corpo/order-process/CorpoCompanyModal';

export default {
  name: 'corpo-company-manager',
  components: { CorpoCompanyModal },
  computed: {
    ...mapGetters('corpo', ['companyList', 'selectedCompanyIndex']),
    radios: {
      get: function () {
        return this.selectedCompanyIndex;
      },
      set: function (index) {
        this.selectCompanyWithDefault(index);
      },
    },
  },
  methods: {
    ...mapActions('corpo', ['selectCompanyWithDefault']),
    proceed() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.company-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap);

  &:last-child {
    margin-bottom: 0;
  }
}
.company-name {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 4px;
  color: var(--color-text-primary);

  @media ($md-min) {
    font-size: 1.25rem;
  }
}
.company-info {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-text-secondary);

  @media ($md-min) {
    font-size: 1rem;
  }
}
.v-radio {
  align-items: flex-start;
}
::v-deep .v-input--selection-controls__input {
  @media ($md-min) {
    margin-top: 4px;
  }
}
.btn-container {
  margin-top: 30px;
  text-align: center;

  @media ($md-min) {
    text-align: right;
  }
}
</style>
