<template>
  <fragment>
    <redeem-flow-info class="mt-5" />
    <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" style="width: -webkit-fill-available" class="mt-5" />
    <auth-and-register v-else class="mt-5" />
  </fragment>
</template>

<script>
import AuthAndRegister from '@/components/authentication/AuthAndRegister.vue';
import SwapAuthAndRegister from '@/components/authentication/SwapAuthAndRegister.vue';
import RedeemFlowInfo from './components/redeem-flow-info.vue';

export default {
  name: 'claim-gift-certificate-auth',
  components: { AuthAndRegister, SwapAuthAndRegister, RedeemFlowInfo },
};
</script>
