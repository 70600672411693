<template>
  <small-card-base :image="publisher.branding.images.showcases[currentLocale][0]" :show-panier-bleu="publisher.branding.features.panier_bleu" :offer-title="publisher.branding.label[currentLocale]">
    <template #content>
      <small-card-base-badge use-gift-icon hide-badge :text="offer.incentive.bonus_display" :region="publisher.branding.region_label[currentLocale]" :category_name="publisher.category_name[currentLocale]" />

      <small-card-base-description :text="publisher.branding.description[currentLocale]" />

      <small-card-base-status-label v-if="!offerIsAvailable" :start="offer.availability.start_date" :expiration="offer.availability.end_date" :status="determineStatus(offer.status, publisher.status)" />

      <small-card-base-status-label v-else :text-override="$t('components.gift_certificate_offer_publisher_small_card.progress_left')" />
    </template>

    <template #call-to-action>
      <small-card-base-cta-link v-for="(action, index) in allowedActions.slice(0, -1)" :text="$t(action.label)" :key="index" @click="executeActionByCode(action.callback_type)" />
      <h-btn v-if="allowedActions.length > 0" class="mt-5" color="var(--color-branding)" block large @click="executeActionByCode(_last(allowedActions).callback_type)">{{ $t(_last(allowedActions).label) }}</h-btn>
    </template>
  </small-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { isStatusEqual, determineStatus } from '@/composables/offer-publisher-helper';

import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseDescription from '@/components/cards/small/SmallCardBaseDescription.vue';
import SmallCardBaseCtaLink from '@/components/cards/small/SmallCardBaseCtaLink.vue';
import SmallCardBaseStatusLabel from '@/components/cards/small/SmallCardBaseStatusLabel.vue';
import _get from 'lodash/get';
import { extractPublisherDisplayInfosFromOffer, PublisherCallbackType } from '@/composables/publisher-action-display-selector';
import _last from 'lodash/last';

export default {
  setup() {
    return {
      isStatusEqual,
      determineStatus,
    };
  },
  components: { SmallCardBase, SmallCardBaseDescription, SmallCardBaseBadge, SmallCardBaseCtaLink, SmallCardBaseStatusLabel },
  name: 'gift-certificate-offer-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId']),
    allowedActions() {
      return extractPublisherDisplayInfosFromOffer(this.offer, this.publisher);
    },
    offerIsAvailable() {
      return this.isStatusEqual(this.offer.status, this.publisher.status, 'available') && _get(this.offer, 'cta.activation', true);
    },
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    _last,
    executeActionByCode(callback_type) {
      switch (callback_type) {
        case PublisherCallbackType.SEE_STORES:
          this.seeOfferAction();
          break;
        case PublisherCallbackType.SEE_OFFER:
          this.seeOfferAction();
          break;
        case PublisherCallbackType.CHOSE_OFFER:
          this.redeemNowAction();
          break;
        default:
          break;
      }
    },
    async redeemNowAction() {
      return this.openHeaderDrawerWithParams({
        content: 'redeem',
        payload: {
          offer_id: this.offer.id,
          publisher_id: this.publisher.publisher_id,
          partner_id: this.currentPartnerId,
        },
      });
    },
    seeOfferAction() {
      this.routeToOffer(this.publisher.publisher_id);
    },
    routeToOffer(publisher_id) {
      let query = {};
      if (this.preview) {
        query.preview = 'true';
      }

      this.$router.pushNoDuplicate({ name: 'spaceOfferDetailSmallUrl', query, params: { space: this.currentSpaceSlug, publisher_id, offer_id: this.offer.id, _position: { x: 0, y: window.scrollY } } });
    },
  },
};
</script>
