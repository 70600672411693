import _get from 'lodash/get';

export default {
  namespaced: true,
  state: {
    backRoute: null,
  },
  getters: {
    getBackRoute: (state) => state.backRoute,
    backRouteIsSpaceHome: (state) => _get(state.backRoute, 'name') === 'spaceHome',
  },
  mutations: {
    setBackRoute: (state, backRoute) => {
      state.backRoute = backRoute;
    },
  },
  actions: {
    setBackRoute({ commit }, backRoute) {
      commit('setBackRoute', backRoute);
    },
  },
};
