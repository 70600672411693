<template>
  <form class="ElementsApp">
    <input ref="cardName" :value="value" @input="onInput" type="text" maxlength="24" class="InputElement" :class="{ 'is-empty': value.length === 0, 'is-invalid': !cardNameValid }" placeholder="Card name" autocorrect="off" />
  </form>
</template>

<script>
export default {
  name: 'stripe-like-card-name',
  props: {
    value: {
      type: String,
    },
  },
  watch: {
    value() {
      if (this.value.length > 24 || this.value.length <= 0 || !this.value) {
        this.cardNameValid = false;
        this.$emit('change', { complete: false, error: false });
      } else {
        this.cardNameValid = true;
        this.$emit('change', { complete: true });
      }
    },
  },
  data() {
    return {
      cardNameValid: true,
    };
  },
  methods: {
    onInput(e) {
      this.$emit('input', this.$refs.cardName.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.ElementsApp {
  position: relative;
}
.ElementsApp,
.ElementsApp .InputElement {
  outline: none;
  color: #32325d;
  font-weight: 500;
  font-family: Source Code Pro, Consolas, Menlo, monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  + .InputContainer-placeholder--ie {
    opacity: 1;
    color: #cfd7df;
  }
  &::-webkit-input-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    color: #cfd7df;
  }
  &::placeholder {
    opacity: 1;
    color: #cfd7df;
  }

  &.is-invalid {
    + .InputContainer-placeholder--ie {
      opacity: 1;
      color: #ffcca5;
    }
    &::-webkit-input-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &::-moz-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: #ffcca5;
    }
    &::placeholder {
      opacity: 1;
      color: #ffcca5;
    }
  }
}

.ElementsApp:not(.is-autofilled) .InputElement:-webkit-autofill {
  color: #32325d;
  -webkit-text-fill-color: #32325d;
}

.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: #e39f48;
  -webkit-text-fill-color: #e39f48;
}

.ElementsApp .InputElement.is-invalid {
  color: #e25950;
}

.ElementsApp:not(.is-autofilled) .InputElement.is-invalid:-webkit-autofill {
  color: #e25950;
  -webkit-text-fill-color: #e25950;
}

.ElementsApp input {
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 1em;
  line-height: 1.2em;
  height: 1.2em;
}
</style>
