<template>
  <div class="overlay-renew-card-wrap">
    <div v-if="renewCardSuccessful" class="overlay-renew-card renew-successful">
      <h2 class="header">{{ $t(`components.overlay_renew_card.success.title`, { date: formattedExpirationDate }) }}</h2>
      <p class="text" v-html="$t('components.overlay_renew_card.success.text')"></p>
    </div>

    <div v-else-if="cardIsDeactivated" class="overlay-renew-card deactivated">
      <h2 class="header">{{ $t(`components.overlay_renew_card.deactivated.title`) }}</h2>
      <p class="text" v-html="$t('components.overlay_renew_card.deactivated.text')"></p>
      <overlay-renew-card-modal :public-token="currentCard.public_token" @success="renewCardSuccess" :card-program-type="currentCard.card_program_type" />
    </div>

    <div v-else-if="cardIsExpired" class="overlay-renew-card expired">
      <h2 class="header">{{ $t(`components.overlay_renew_card.expired.title`) }}</h2>
      <p class="text" v-html="$t('components.overlay_renew_card.expired.text')"></p>

      <overlay-renew-card-modal :public-token="currentCard.public_token" @success="renewCardSuccess" :card-program-type="currentCard.card_program_type" />
    </div>

    <div v-else-if="cardIsLostStolen" class="overlay-renew-card lost-stolen">
      <h2 class="header">{{ $t(`components.overlay_renew_card.lost_stolen.title`) }}</h2>

      <p class="text" v-html="$t('components.overlay_renew_card.lost_stolen.text')"></p>

      <overlay-renew-card-modal :public-token="currentCard.public_token" @success="renewCardSuccess" :card-program-type="currentCard.card_program_type" />
    </div>

    <div v-else-if="cardWillExpireSoon" class="overlay-renew-card expire-soon">
      <h2 class="header">{{ $t(`components.overlay_renew_card.expire_soon.title`, { date: formattedExpirationDate }) }}</h2>
      <p class="text" v-html="$t('components.overlay_renew_card.expire_soon.text')"></p>
      <overlay-renew-card-modal :public-token="currentCard.public_token" @success="renewCardSuccess" :card-program-type="currentCard.card_program_type" />
    </div>
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';

import OverlayRenewCardModal from './OverlayRenewCardModal';

export default {
  name: 'overlay-renew-card',
  components: { OverlayRenewCardModal },
  setup(props, { root }) {
    const { executeContent, execute, working } = safeExecute(root.$store);
    return {
      executeContent,
      execute,
      working,
    };
  },
  props: {
    currentCard: {
      type: Object,
      required: true,
      validator: function (card) {
        return Object.keys(card).includes('public_token', 'card_program_type', 'status', 'is_renewable', 'expiration');
      },
    },
  },
  data() {
    return {
      renewCardSuccessful: false,
    };
  },

  computed: {
    cardIsExpired() {
      return this.currentCard.status === 'expired';
    },
    cardIsLostStolen() {
      return this.currentCard.status === 'lost_stolen';
    },
    cardWillExpireSoon() {
      return this.currentCard.status === 'active' && this.currentCard.is_renewable;
    },
    cardIsDeactivated() {
      return this.currentCard.status === 'deactivated';
    },
    formattedExpirationDate() {
      return this.$options.filters.formatShortDate(this.currentCard.expiration);
    },
  },
  methods: {
    renewCardSuccess() {
      this.executeContent({
        action: 'account/getAccount',
      });

      this.renewCardSuccessful = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay-renew-card-wrap {
  overflow: hidden;
}

.overlay-renew-card.renew-successful {
  @media ($sm-min) {
    &:before {
      background-image: url('~@/assets/ask_bg_check.svg');
    }
  }
}

.overlay-renew-card.expired,
.overlay-renew-card.deactivated {
  @media ($sm-min) {
    &:before {
      background-image: url('~@/assets/ask_renew_bg.svg');
    }
  }
}

.overlay-renew-card.lost-stolen,
.overlay-renew-card.expire-soon {
  @media ($sm-min) {
    &:before {
      background-image: url('~@/assets/ask_bg.svg');
    }
  }
}

.overlay-renew-card {
  position: relative;
  padding: var(--gap-xxlarge) var(--gap-page-side);
  color: var(--color-ultra-light);
  background-color: var(--color-primary);

  @media ($sm-min) {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 115%;
      top: 0;
      left: 50%;

      background-size: auto 100%;
      background-position: left;
    }
  }

  @media ($md-min) {
    &:before {
      top: 0;
      left: 50%;
      right: unset;
      background-position: 0 0;
    }
  }
}

.header {
  margin-bottom: var(--gap-small);
  @media ($sm-min) {
    max-width: 50%;
  }

  @media ($md-min) {
    max-width: 400px;
  }
}

.text {
  margin-bottom: var(--gap-large);
  opacity: 0.65;

  @media ($sm-min) {
    max-width: 50%;
  }

  @media ($md-min) {
    max-width: 400px;
  }
}
</style>
