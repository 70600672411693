<template>
  <div class="consumer-gift-card-store-marketing">
    <div class="info-part">
      <h1 style="margin-bottom: var(--gap-xxlarge)">{{ title }}</h1>
      <p>{{ $t('components.consumer_gift_card_store_marketing.text', { closed_loop_size: closedLoopSize }) }}</p>
    </div>

    <div v-if="!hasMultipleDestinations" class="categories-list" :class="{ 'single-category': sanitizedCategory.length <= 2, 'multiple-category': sanitizedCategory.length > 2 }">
      <v-hover v-for="(el, index) of sanitizedCategory" :key="index" v-ripple v-slot="{ hover }">
        <div class="category-card" :class="{ 'category-card-hover': hover, [`${el}`]: true }" @click="seeMore({ category: el })">
          <div class="category-card-text">{{ $t(`components.home_search_bar.category.${el}`) }}</div>
        </div>
      </v-hover>

      <h-btn outlined class="see-more" color="var(--color-branding)" large min-width="336" :block="$vuetify.breakpoint.smAndDown" @click="seeMore()">{{
        $t('components.consumer_gift_card_store_marketing.see_more_cta', { closed_loop_size: closedLoopSize })
      }}</h-btn>
    </div>

    <div v-if="hasMultipleDestinations" class="categories-list multiple-category">
      <v-hover v-for="(destination, index) of destinationList" :key="index" v-ripple v-slot="{ hover }">
        <div class="category-card" :class="{ 'category-card-hover': hover }" :style="parseDestinationBackgroundImageClass(destination)" @click="seeMore({ destination_id: destination.id })">
          <div class="category-card-text">{{ parseDestinationLabel(destination) }}</div>
        </div>
      </v-hover>

      <h-btn outlined class="see-more" color="var(--color-branding)" large min-width="336" :block="$vuetify.breakpoint.smAndDown" @click="seeMore()">{{
        $t('components.consumer_gift_card_store_marketing.see_more_cta', { closed_loop_size: closedLoopSize })
      }}</h-btn>
    </div>
  </div>
</template>
<script>
import _intersection from 'lodash/intersection';
import { marketingCategoryImageHelepr } from '@/composables/image-helper';
import { mapGetters } from 'vuex';
export default {
  props: {
    closedLoopSize: {
      type: Number,
    },
    offer_id: {
      type: String,
    },
    publisher_id: {
      type: String,
    },
    categoryList: {
      type: Array,
      default: () => ['food_groceries', 'restaurants_bars', 'fashion_style', 'home_cars', 'miscellaneous', 'leisure_getaways'],
    },
    destinationList: {
      type: Array,
      default: null,
    },
  },
  name: 'consumer-gift-card-store-marketing',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    sanitizedCategory() {
      return _intersection(this.categoryList, ['food_groceries', 'restaurants_bars', 'fashion_style', 'home_cars', 'miscellaneous', 'leisure_getaways']);
    },
    title() {
      return this.closedLoopSize > 9 ? this.$t('components.consumer_gift_card_store_marketing.title_9_more') : this.$t('components.consumer_gift_card_store_marketing.title_2_9', { closed_loop_size: this.closedLoopSize });
    },
    hasMultipleDestinations() {
      return false; // NOTE (SG) Disable for now   this.destinationList && this.destinationList.length > 1;
    },
  },
  methods: {
    parseDestinationBackgroundImageClass(destination) {
      const imageUrl = marketingCategoryImageHelepr(destination[`showcase_${this.currentLocale}`]);

      return { 'background-image': `var(--elgradian), url(${imageUrl})` };
    },
    parseDestinationLabel(destination) {
      return destination[`label_${this.currentLocale}`];
    },
    seeMore({ category = null, destination_id = null } = {}) {
      this.$emit('seeMore', { category, destination_id });
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-store-marketing {
  background-color: var(--color-ultra-light);

  @media ($sm-min) {
    display: flex;
  }
}
.info-part {
  color: var(--color-ultra-light);
  background-color: black;
  padding: var(--gap-xxlarge) var(--gap);
  font-size: var(--font-size-text-1);

  @media ($sm-min) {
    flex-basis: 57%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}

.categories-list {
  padding: var(--gap-xxlarge) var(--gap);
  text-align: center;
  @media ($sm-min) {
    padding: var(--gap) var(--gap);
  }
  & > .category-card {
    color: var(--color-ultra-light);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.single-category {
    @media ($md-min) {
      flex-basis: 43%;
    }
    & > *:not(:last-child) {
      margin-bottom: var(--gap-xxlarge);
    }
    & > .category-card {
      font-size: 1.625rem;
      @media ($sm-min) {
        padding-right: 15px;
        padding-left: 15px;
      }
      border-radius: 16px;
      padding-right: 60px;
      padding-left: 60px;

      // This is the future
      // Remove this padding-top, padding-bottom and width property when you are no longer in the past.
      padding-top: calc(23% + 3px + calc(var(--font-size-text-2) / 2));
      padding-bottom: calc(23% + 3px + calc(var(--font-size-text-2) / 2));
    }
  }
  &.multiple-category {
    display: flex;
    gap: var(--gap);
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media ($sm-min) {
      flex-basis: 49%;
    }

    & > *:last-child {
      margin-top: var(--gap);
    }

    & > .category-card {
      flex-basis: 48%;
      @media ($sm-min) {
        flex-basis: 47%;
        font-size: var(--font-size-text-2);
        padding-right: 15px;
        padding-left: 15px;
      }
      font-size: var(--font-size-text-3);
      border-radius: var(--border-radius-cards);
      padding-right: 30px;
      padding-left: 30px;
    }
    & > .see-more {
      flex-basis: 100%;
      @media ($sm-min) {
        flex-basis: auto;
      }
    }
  }
}
.category-card {
  position: relative;
  --elgradian: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;

  // This is the future
  // aspect-ratio: 2/1;
  // Remove this padding-top, padding-bottom and width property when you are no longer in the past.
  // width: max-content;
  padding-top: calc(10% + 3px + calc(var(--font-size-text-2) / 2));
  padding-bottom: calc(10% + 3px + calc(var(--font-size-text-2) / 2));
}

.category-card-text {
  position: absolute;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.2);
  padding: 6px;
  line-height: 1;
  margin-right: 30px;
  margin-left: 30px;
}

.category-card-hover {
  --elgradian: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  cursor: pointer;
}

.food_groceries {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/food_groceries.jpeg');
}
.restaurants_bars {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/restaurants_bars.jpeg');
}
.fashion_style {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/fashion_style.jpeg');
}
.leisure_getaways {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/leisure_gateways.jpeg');
}
.home_cars {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/home_cars.jpeg');
}
.miscellaneous {
  background-image: var(--elgradian), url('https://cdn.hellocard.ca/categories/miscellaneous.jpeg');
}
</style>
