<template>
  <big-card-base
    :offer-title="currentOfferName"
    :offer-label="currentOfferCategory | formatCategoryRegion(currentOfferRegion)"
    :offer-description="currentOfferDescription"
    :carousel-images="currentOfferShowcasesImage"
    :show-panier-bleu="currentOfferBrandingFeaturePanierBleu"
  >
    <template #extraContent>
      <big-card-voucher-status-label :status="currentStatus" :allow-sold-out-display="true" :start="currentOfferStartDate" />
    </template>

    <template #actionSection>
      <h-btn v-if="currentOfferCtaActivation" :disabled="currentStatus !== 'available' || currentOfferPublisherStatus !== 'available'" color="var(--color-branding)" block large @click="openOverlayForRedeem">{{
        $t('components.gift_certificate_offer_single_publisher_big_card.call_to_action')
      }}</h-btn>

      <h-btn v-if="currentOfferConsumerEnabled" :disabled="!currentOfferConsumerPurchasable" color="var(--color-branding)" block large @click="openOverlayForConsumerGiftCard">{{
        $t('components.gift_certificate_offer_single_publisher_big_card.call_to_action_consumer_gift_card')
      }}</h-btn>

      <consumer-gift-card-info-modal v-if="currentOfferConsumerEnabled" :consumer-purchasable="currentOfferConsumerPurchasable" use-branding-color @action="openOverlayForConsumerGiftCard" />
    </template>
  </big-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';
import BigCardVoucherStatusLabel from '@/components/cards/big/BigCardVoucherStatusLabel.vue';
import { determineStatus } from '@/composables/offer-publisher-helper';
import ConsumerGiftCardInfoModal from '@/components/consumer-gift-card/components/ConsumerGiftCardInfoModal.vue';

export default {
  name: 'single-publisher-gift-certificate-offer',
  components: { BigCardBase, BigCardVoucherStatusLabel, ConsumerGiftCardInfoModal },
  setup(props, { root }) {
    return {
      determineStatus,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentPartnerId', 'currentSpaceSlug']),
    ...mapGetters('offer', [
      'currentOfferId',
      'currentOfferName',
      'currentOfferDescription',
      'currentOfferEndDate',
      'currentOfferStartDate',
      'currentOfferIncentiveBonusDisplay',
      'currentOfferShowcasesImage',
      'currentOfferBrandingFeaturePanierBleu',
      'currentOfferCtaActivation',
      'currentOfferCategory',
      'currentOfferStatus',
      'currentOfferRegion',
      'currentOfferPublisherStatus',
      'currentOfferConsumerEnabled',
      'currentOfferConsumerPurchasable',

      'currentOfferPublisherOfferId',
      'currentOfferPublisherPublisherId',
    ]),
    currentStatus() {
      return this.determineStatus(this.currentOfferStatus, this.currentOfferPublisherStatus);
    },
    offerIsNotAvailableOrPurchasable() {
      return !_get(this.currentOffer, 'consumer.purchasable') || this.currentStatus !== 'available';
    },
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    openOverlayForRedeem() {
      this.$router.pushNoDuplicate({ name: 'spaceOfferActivation', params: { space: this.currentSpaceSlug, offer_id: this.currentOfferId } });
    },
    openOverlayForConsumerGiftCard() {
      this.openHeaderDrawerWithParams({
        content: 'purchaseGiftCardOverlay',
        payload: {
          partner_id: this.currentPartnerId,
          offer_id: this.currentOfferId,
        },
      });
    },
    closeErrorModal() {
      this.refreshCurrentOfferAndPublisher();
    },
  },
};
</script>
