import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import CustomIcon from '@/components/system/images/CustomIcon';
import Values from '@/scss/export.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: Values.primary,
        secondary: Values.secondary,
        accent: Values.accent,
        error: Values.error,
        success: Values.success,
        warning: Values.warning,
        'ultra-light': Values['ultra-light'],
        'text-primary': Values['text-primary'],
        disabled: Values['disabled'],
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: Values.smBreakpoint,
      sm: Values.mdBreakpoint,
      md: Values.lgBreakpoint,
      lg: Values.xlBreakpoint,
    },
    scrollBarWidth: 0,
    mobileBreakpoint: 'sm',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      allCategories: {
        component: CustomIcon,
        props: {
          file: 'filters/all_categories.svg',
        },
      },
      fashionStyle: {
        component: CustomIcon,
        props: {
          file: 'filters/fashion_style.svg',
        },
      },
      foodGroceries: {
        component: CustomIcon,
        props: {
          file: 'filters/food_groceries.svg',
        },
      },
      homeCars: {
        component: CustomIcon,
        props: {
          file: 'filters/home_cars.svg',
        },
      },
      leisureGetaways: {
        component: CustomIcon,
        props: {
          file: 'filters/leisure_getaways.svg',
        },
      },
      miscellaneous: {
        component: CustomIcon,
        props: {
          file: 'filters/miscellaneous.svg',
        },
      },
      mixedCategories: {
        component: CustomIcon,
        props: {
          file: 'filters/mixed.svg',
        },
      },
      restaurantBars: {
        component: CustomIcon,
        props: {
          file: 'filters/restaurants_bars.svg',
        },
      },
    },
  },
});
