<template>
  <fragment>
    <div v-for="(spacePartnerId, index) of voucherPartnerIds" :key="`partner-id-${index}`" class="space-section">
      <account-space-header class="account-space-header" :partner_id="spacePartnerId" />
      <subscription-status v-for="subscription of accountSubscriptions[spacePartnerId]" :key="`subscription-${spacePartnerId}-${subscription.id}`" class="subscription-status" :partner_id="spacePartnerId" :subscription="subscription" />
      <account-voucher v-for="(voucher, index) of accountVouchersBySpace[spacePartnerId]" :key="`voucher-${spacePartnerId}-${index}`" :voucher="voucher" @OfferDetail="navigateOfferDetail(voucher.offer_id, spacePartnerId, voucher.publisher_id)" />
    </div>

    <div v-if="voucherPartnerIds.length < 1 && showEmptySection" class="space-section space-section-empty">{{ $t('components.account_space_section.empty') }}</div>
  </fragment>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import AccountVoucher from './components/AccountVoucher.vue';
import AccountSpaceHeader from './components/AccountSpaceHeader.vue';
import SubscriptionStatus from '../subscription/SubscriptionStatus.vue';
import _keys from 'lodash/keys';

export default {
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
    };
  },
  components: { AccountVoucher, AccountSpaceHeader, SubscriptionStatus },
  name: 'account-space-section',
  props: {
    accountVouchersBySpace: {
      type: Object,
      required: false,
    },
    accountSubscriptions: {
      type: Object,
      required: true,
    },
    showEmptySection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    voucherPartnerIds() {
      return _keys(this.accountVouchersBySpace);
    },
  },
  methods: {
    navigateOfferDetail(offer_id, partner_id, publisher_id) {
      if (partner_id) {
        this.executeContent(
          {
            action: 'space/getSpaceByPartnerId',
            success: (s) => {
              this.$router.pushNoDuplicate({ name: 'spaceOfferDetailSmallUrl', params: { space: s.slug, offer_id, publisher_id, _position: { x: 0, y: window.scrollY } } });
            },
          },
          partner_id,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.space-section {
  display: flex;
  flex-direction: column;
  padding: var(--gap-large) var(--gap) var(--gap);
  border: var(--border-thin) solid var(--color-border);
  margin: var(--gap) var(--gap-page-side);
}

.account-space-header {
  margin-bottom: var(--gap-xlarge);
}

.account-voucher {
  margin-bottom: var(--gap-large);

  &:last-child {
    margin-bottom: 0;
  }
}

.subscription-status {
  margin-bottom: var(--gap-large);
}

.space-section-empty {
  text-align: center;
  padding: var(--gap-large);
}
</style>
