import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
  name: 'recaptcha',
  init: async ({ Vue }) => {
    Vue.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        useRecaptchaNet: true,
      },
    });

    Vue.prototype.$recaptchaLoaded().then(() => Vue.prototype.$recaptchaInstance.hideBadge());
  },
};
