<template>
  <div class="account-voucher">
    <v-overlay v-if="isTransferredOrCanceled" :absolute="true">
      <div class="voucher-overlay h-heading-h1">{{ $t(`components.account_voucher_base.${voucher.status}`) }}</div>
    </v-overlay>

    <img class="main-img" :src="mainImgSrc" :alt="$t('components.account_voucher_base.img_alt')" />

    <div class="voucher-information">
      <h3 class="voucher-header h-heading-h4">{{ headerText }}</h3>

      <div v-if="!voucher.is_single_publisher" class="h-caption offer-label">{{ voucher.offer_branding.label[currentLocale] }}</div>

      <merchants-modal v-if="!isTransferredOrCanceled" :offer_id="voucher.offer_id" :publisher_id="voucher.publisher_id" :modal-title-text="$t('components.account_voucher.merchants_modal_title')">
        <template #activator="{ on, attrs }">
          <h-btn class="merchants-button" v-bind="attrs" v-on="on" outlined small>{{ $t('components.account_voucher.merchants_link') }} </h-btn>
        </template>
      </merchants-modal>
    </div>

    <voucher-amount :voucher="voucher" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showcaseSmall } from '@/composables/image-helper';
import VoucherAmount from '@/components/vouchers/VoucherAmount.vue';
import MerchantsModal from '@/components/modals/MerchantsModal';

export default {
  name: 'account-voucher',
  components: {
    VoucherAmount,
    MerchantsModal,
  },
  setup() {
    return {
      showcaseSmall,
    };
  },
  props: {
    voucher: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    isTransferredOrCanceled() {
      return this.voucher.status === 'transferred' || this.voucher.status === 'canceled';
    },
    mainImgSrc() {
      const rawSource = !this.voucher.is_single_publisher ? this.voucher.publisher_branding.images.showcases[this.currentLocale][0] : this.voucher.offer_branding.images.showcases[this.currentLocale][0];
      return showcaseSmall(rawSource);
    },
    headerText() {
      return !this.voucher.is_single_publisher ? this.voucher.publisher_branding.label[this.currentLocale] : this.voucher.offer_branding.label[this.currentLocale];
    },
  },
};
</script>

<style lang="scss" scoped>
.account-voucher {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: var(--gap);
  padding-top: var(--gap-large);
  border-top: var(--border-thin) solid var(--color-border);

  @media ($sm-min) {
    flex-flow: row wrap;
  }
}

.voucher-overlay {
  font-weight: bold;
  text-transform: uppercase;
}

.main-img {
  display: block;
  width: 100%;
  max-height: 300px;

  @media ($sm-min) {
    width: 45%;
    max-width: 340px;
    max-height: 170px;
  }
}

.voucher-information {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ($sm-min) {
    height: 170px;
  }
}

.merchants-modal {
  justify-content: flex-end;
}

.merchants-button {
  @media ($md-min) {
    margin-top: auto;
  }
}

.voucher-header {
  margin-bottom: var(--gap-xxsmall);
}

.offer-label {
  font-style: italic;
  margin-bottom: var(--gap-large);
}

.voucher-amount {
  width: 100%;

  @media ($md-min) {
    width: auto;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.v-overlay {
  top: var(--gap-large);
}
</style>
