import _pick from 'lodash/pick';
import errorTypes from '@/utils/error/error-types';
import { useFakeStripe } from '@/utils/fake-plugin-helper';

export default {
  name: 'fake-stripe',

  init: async ({ Vue }) => {
    if (!useFakeStripe()) {
      return;
    }

    const realStripe = Vue.prototype.$stripe;
    console.warn('WARNING - OVERRIDING STRIPE CALLS'); // eslint-disable-line no-console
    const allowCallingRealStripe = ['elements'];
    const fakeStripeCallbacks = {
      createToken: fakeCreateToken,
      createPaymentMethod: fakeStripeCreatePaymentMethod,
      confirmCardPayment: fakeStripeConfirmCardPayment,
    };

    async function fakeStripeConfirmCardPayment(client_secret, payload) {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_ADMIN_API_URL}/test-portal-console/fake-stripe/card-payment/${client_secret}/confirm`, payload);
      return response.data.data;
    }

    async function fakeStripeCreatePaymentMethod({ type, card: { token } }) {
      const payload = { type, token };
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_ADMIN_API_URL}/test-portal-console/fake-stripe/payment-method`, payload);
      return response.data.data;
    }

    async function fakeCreateToken(card, params) {
      const token = await realStripe.createToken(card, params);

      if (token && token.error) {
        return token;
      }

      const usefulToken = {
        id: token.token.id,
        created: token.token.created,
        livemode: token.token.livemode,
      };
      usefulToken.card = _pick(token.token.card, ['address_zip', 'brand', 'exp_month', 'exp_year', 'last4', 'funding', 'country']);

      const tokenAsString = JSON.stringify(usefulToken);
      const encoded = Buffer.from(tokenAsString, 'utf8').toString('base64');
      return {
        token: {
          id: encoded,
        },
      };
    }

    const fakeStripeHandler = {
      get: function (obj, prop) {
        if (fakeStripeCallbacks[prop]) {
          console.warn(`OVERRIDING $stripe.${prop}`); // eslint-disable-line no-console
          return fakeStripeCallbacks[prop];
        }

        if (allowCallingRealStripe.includes(prop)) {
          console.warn(`ALLOWING CALL TO REAL STRIPE FOR $stripe.${prop}`); // eslint-disable-line no-console
          return obj[prop];
        }

        console.error(`BLOCKING CALL TO REAL STRIPE FOR $stripe.${prop}`); // eslint-disable-line no-console
        throw new errorTypes.HttpError(400, {
          error: {
            code: 'attempting_real_stripe_call',
          },
        });
      },
    };

    const stripeProxy = new Proxy(realStripe, fakeStripeHandler);
    Vue.prototype.$stripe = stripeProxy;
  },
};
