import { computed, reactive, ref, watch } from '@vue/composition-api';
import createNavigationItemMap from './navbar-items/index';
import _findIndex from 'lodash/findIndex';

class DefaultNavigationItem {
  constructor(name, { href = undefined, to = undefined, isEnable = false, isBadgeEnable = false, snackbar = false, isHidden = false, snackbar_close_action = () => {}, snackbar_text_key = '' }, clickAction) {
    this.name = name;
    this.isEnable = isEnable;
    this.clickAction = clickAction || (() => {});
    this.isBadgeEnable = isBadgeEnable;
    this.snackbar = snackbar;
    this.snackbar_close_action = snackbar_close_action;
    this.snackbar_text_key = snackbar_text_key;
    this.isHidden = isHidden;
    this.to = to;
    this.href = href;
  }
}

export default function setupNavigation(store, router, execute, context) {
  const navigationItems = ref([]);
  const navigationItemsMap = createNavigationItemMap(store, router, execute, DefaultNavigationItem, context);
  const computedIsAuthenticated = computed(() => store.getters['security/authenticated']);

  function initDefault() {
    navigationItems.value.push(reactive(navigationItemsMap.localeNavigationItem));
    navigationItems.value.push(reactive(navigationItemsMap.aboutNavigationItem));
    navigationItems.value.push(reactive(navigationItemsMap.faqNavigationItem));
    if (store.getters['security/authenticated']) {
      navigationItems.value.push(reactive(navigationItemsMap.myAccountNavigationItem));
      navigationItems.value.push(reactive(navigationItemsMap.businessConsoleNavigationItem));
      navigationItems.value.push(reactive(navigationItemsMap.logoutNavigationItem));
    } else {
      navigationItems.value.push(reactive(navigationItemsMap.signInNavigationItem));
    }

    watch(computedIsAuthenticated, async (isAuth) => {
      if (isAuth) {
        const i2 = _findIndex(navigationItems.value, (e) => e.name === navigationItemsMap.signInNavigationItem.name);
        navigationItems.value.splice(i2, 1, reactive(navigationItemsMap.myAccountNavigationItem), reactive(navigationItemsMap.businessConsoleNavigationItem), reactive(navigationItemsMap.logoutNavigationItem));
      } else {
        const i1 = _findIndex(navigationItems.value, (e) => e.name === navigationItemsMap.myAccountNavigationItem.name);
        navigationItems.value.splice(i1, 1);
        const i2 = _findIndex(navigationItems.value, (e) => e.name === navigationItemsMap.businessConsoleNavigationItem.name);
        navigationItems.value.splice(i2, 1);
        const i3 = _findIndex(navigationItems.value, (e) => e.name === navigationItemsMap.logoutNavigationItem.name);
        navigationItems.value.splice(i3, 1, reactive(navigationItemsMap.signInNavigationItem));
      }
    });
  }

  function addNavigationItem(navigationItem) {
    navigationItems.value.push(reactive(navigationItem));
  }

  return { navigationItems, navigationItemsMap, initDefault, addNavigationItem, DefaultNavigationItem };
}
