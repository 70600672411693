<template>
  <small-card-base :image="publisher.branding.images.showcases[currentLocale][0]" :show-panier-bleu="publisher.branding.features.panier_bleu" :url="publisherUrl" :offer-title="publisher.branding.label[currentLocale]">
    <template #chip>
      <h-chip-small
        :plus-action="plus"
        :minus-action="minus"
        :pay="payAmount | currency | currencySymbol"
        :obtain="offerObtain | currency | currencySymbol"
        :disable-plus="disablePlus(payAmount, offer.incentive.step, offer.incentive.user_payment_limit, amountLeft)"
        :disable-minus="disableMinus(payAmount, offer.incentive.step, offer.incentive.minimum_payment, amountLeft)"
      />
    </template>

    <template #content>
      <small-card-base-badge :text="offer.incentive.bonus_display | bonusSymbol" :region="publisher.branding.region_label[currentLocale]" :category_name="publisher.category_name[currentLocale]" />

      <small-card-base-description :text="publisher.branding.description[currentLocale]" />

      <small-card-base-status-label v-if="!offerIsAvailable" :start="offer.availability.start_date" :expiration="offer.availability.end_date" :status="determineStatus(offer.status, publisher.status)" />

      <small-card-base-status-label v-else :text-override="$t('components.bonus_offer_publisher_small_card.progress_left', { currentNumber: formattedCurrentNumber })" />
      <offer-card-error-modal v-model="errorModalActive" @close="closeErrorModal" :error-message-code="errorMessageCode" />
    </template>

    <template #call-to-action>
      <small-card-base-cta-link v-for="(action, index) in allowedActions.slice(0, -1)" :text="$t(action.label)" :key="index" @click="executeActionByCode(action.callback_type)" />
      <h-btn v-if="allowedActions.length > 0" class="mt-5" color="var(--color-branding)" block large @click="executeActionByCode(_last(allowedActions).callback_type)">{{ $t(_last(allowedActions).label) }}</h-btn>
    </template>
  </small-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import { addAmount, determineStatus, isStatusEqual, disableMinus, disablePlus, remainingAmount, removeAmount } from '@/composables/offer-publisher-helper';
import SmallCardBase from '@/components/cards/small/SmallCardBase.vue';
import SmallCardBaseBadge from '@/components/cards/small/SmallCardBaseBadge.vue';
import SmallCardBaseCtaLink from '@/components/cards/small/SmallCardBaseCtaLink.vue';
import SmallCardBaseDescription from '@/components/cards/small/SmallCardBaseDescription.vue';
import _get from 'lodash/get';
import SmallCardBaseStatusLabel from '@/components/cards/small/SmallCardBaseStatusLabel.vue';
import OfferCardErrorModal from '@/components/cards/components/OfferCardErrorModal.vue';
import _last from 'lodash/last';
import { extractPublisherDisplayInfosFromOffer, PublisherCallbackType } from '@/composables/publisher-action-display-selector';

export default {
  setup(props, { root }) {
    const { execute, executeContent, working } = safeExecute(root.$store);
    return {
      execute,
      executeContent,
      working,
      determineStatus,
      isStatusEqual,
      remainingAmount,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
    };
  },
  components: { SmallCardBase, SmallCardBaseBadge, SmallCardBaseCtaLink, SmallCardBaseDescription, SmallCardBaseStatusLabel, OfferCardErrorModal },
  name: 'bonus-offer-publisher-small-card',
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payAmount: this.offer.incentive.default_payment,
      errorModalActive: false,
      errorMessageCode: null,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId']),
    allowedActions() {
      return extractPublisherDisplayInfosFromOffer(this.offer, this.publisher);
    },
    isCtaActivation() {
      return _get(this.offer, 'cta.activation', true);
    },
    offerObtain() {
      return (this.offer.incentive.bonus_percent + 1) * this.payAmount;
    },
    formattedCurrentNumber() {
      if (this.isStatusEqual(this.offer.status, this.publisher.status, 'sold_out') || this.isStatusEqual(this.offer.status, this.publisher.status, 'closed')) {
        return this.$options.filters.currencySymbol(this.$options.filters.currency(0));
      }
      return this.$options.filters.currencySymbol(
        this.$options.filters.currency(
          this.remainingAmount(
            this.offer.availability.inventory.total_amount,
            this.offer.availability.inventory.used_amount,
            this.offer.incentive.bonus_display,
            _get(this.publisher, 'inventory.total_amount', undefined),
            _get(this.publisher, 'inventory.used_amount', undefined),
          ),
        ),
      );
    },
    publisherUrl() {
      if (this.publisher.branding.url && this.publisher.branding.url[this.currentLocale]) {
        return this.publisher.branding.url[this.currentLocale];
      }
      return '';
    },
    amountLeft() {
      return this.remainingAmount(
        this.offer.availability.inventory.total_amount,
        this.offer.availability.inventory.used_amount,
        this.offer.incentive.bonus_display,
        _get(this.publisher, 'inventory.total_amount', undefined),
        _get(this.publisher, 'inventory.used_amount', undefined),
      );
    },
    offerIsAvailable() {
      return this.isStatusEqual(this.offer.status, this.publisher.status, 'available') && this.isCtaActivation;
    },
  },
  methods: {
    _last,
    ...mapActions('payment', ['setCurrentPaymentAmount', 'setPaymentAmountForPurchase']),
    executeActionByCode(callback_type) {
      switch (callback_type) {
        case PublisherCallbackType.SEE_STORES:
          this.seeOfferAction();
          break;
        case PublisherCallbackType.SEE_OFFER:
          this.seeOfferAction();
          break;
        case PublisherCallbackType.CHOSE_OFFER:
          this.chooseOfferAction();
          break;
        default:
          break;
      }
    },

    async seeOfferAction() {
      this.routeToOffer(this.publisher.publisher_id);
    },
    async chooseOfferAction() {
      await this.getOfferPublisher();
      this.setCurrentPaymentAmount(this.payAmount);
      this.setPaymentAmountForPurchase(this.payAmount);
      this.openHeaderDrawerForPayment();
    },
    async getOfferPublisher() {
      const _this = this;

      this.dataReady = false;
      if (!this.offer) {
        this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
      } else {
        await this.execute(
          {
            action: 'offer/getOfferPublisher',
          },
          {
            offer_id: _this.offer.id,
            publisher_id: _this.publisher.publisher_id,
          },
        );
        this.dataReady = true;
      }
    },
    routeToOffer(publisher_id) {
      let query = {};
      if (this.preview) {
        query.preview = 'true';
      }

      this.$router.pushNoDuplicate({ name: 'spaceOfferDetailSmallUrl', query, params: { space: this.currentSpaceSlug, publisher_id, offer_id: this.offer.id, _position: { x: 0, y: window.scrollY } } });
    },
    plus() {
      this.payAmount = this.addAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    minus() {
      this.payAmount = this.removeAmount(this.payAmount, this.offer.incentive.step, this.offer.incentive.default_payment);
    },
    async openHeaderDrawerForPayment() {
      const _this = this;
      this.executeContent(
        {
          action: 'overlay/openHeaderDrawerForPayment',
          afterError: (err) => {
            if (err.errorType === 'http') {
              this.errorModalActive = true;
              this.errorMessageCode = err.code;
            }
          },
        },
        {
          payload: {
            offer_id: _this.offer.id,
            publisher_id: _this.publisher.publisher_id,
            partner_id: _this.currentPartnerId,
            offer_restricted_to_member: _this.offer.features.restricted_to_members || false,
          },
        },
      );
    },
    async refreshCurrentOfferAndPublishers() {
      return this.executeContent(
        {
          action: 'offer/refreshCurrentOfferAndPublishers',
        },
        {
          offer_id: this.offer.id,
        },
      );
    },
    closeErrorModal() {
      this.errorMessageCode = null;
      this.refreshCurrentOfferAndPublishers();
    },
  },
};
</script>
