<template>
  <div class="consumer-gift-card-buy-activate-info">
    <h1>{{ $t('components.consumer_gift_card_swap_how_it_works.title') }}</h1>
    <div class="tab-content">
      <ol class="info-list-new">
        <li v-for="(val, index) in new Array(3)" :key="index">
          <div class="circle">{{ index + 1 }}</div>
          <div class="content">{{ $t(`components.consumer_gift_card_swap_how_it_works.buy_instruction_${index + 1}`) }}</div>
        </li>
      </ol>
    </div>
    <h-btn v-if="showBuy" :disabled="disableBuy" :block="$vuetify.breakpoint.smAndDown" style="display: block; margin: 0 auto 12px auto" min-width="311" large color="var(--color-branding)" @click="buy">{{
      $t('components.consumer_gift_card_swap_how_it_works.buy_cta')
    }}</h-btn>
    <h-link v-if="showActivate" :disabled="disableActivate" style="font-size: 16px" text @click="activate" bold>{{ $t('components.consumer_gift_card_swap_how_it_works.activate_cta') }}</h-link>
  </div>
</template>

<script>
export default {
  name: 'consumer-gift-card-buy-activate-info',
  props: {
    useBrandingColor: {
      type: Boolean,
      default: true,
    },
    showActivate: {
      type: Boolean,
      default: true,
    },
    showBuy: {
      type: Boolean,
      default: true,
    },
    disableBuy: {
      type: Boolean,
      default: true,
    },
    disableActivate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    activate() {
      if (!this.disableActivate) {
        this.$emit('activate');
      }
    },
    buy() {
      if (!this.disableBuy) {
        this.$emit('buy');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-list-new {
  padding: 0;
  --circle-radius: 15px;
  --circle-diameter: calc(2 * var(--circle-radius));
  & > * {
    display: grid;

    grid-template-columns: var(--circle-diameter) var(--gap-small) 1fr;
    grid-template-rows: var(--circle-diameter) 1fr;

    .circle {
      width: var(--circle-diameter);
      height: var(--circle-diameter);
      border: 2px solid var(--color-branding);
      border-radius: 50%;
      text-align: center;
      color: var(--color-branding);
      display: inline-block;
      line-height: 1.4;
      font-size: 18px;
      margin-right: var(--gap-small);
      margin-left: var(--circle-offset);
    }

    .content {
      grid-column: 3;
      grid-row: 1 / span 2;
      text-align: left;
      margin-bottom: var(--gap-xxlarge);
      @media ($md-min) {
        margin-bottom: 0;
      }
    }
  }

  @media ($md-min) {
    display: flex;
    gap: 40px;
    & > * {
      width: 33%;
    }
  }
}

.consumer-gift-card-buy-activate-info {
  text-align: center;
  background-color: var(--color-ultra-light);
  padding: var(--gap-xxlarge) var(--gap-page-side);

  @media ($sm-min) {
    padding: calc(var(--gap) * 2.5) var(--gap-page-side);
  }
  & > *:not(:last-child) {
    margin-bottom: calc(var(--gap) * 2.5);
  }
}

.activation-link {
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.tab-content {
  margin-bottom: calc(var(--gap) * 2.5);
  padding: 0 var(--gap);
}
</style>
