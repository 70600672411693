<template>
  <div class="corpo-explanation">
    <div class="explanation-content">
      <h2>{{ $t('components.corpo_explanation.title') }}</h2>

      <ol class="info-list">
        <li><span class="circle">1</span>{{ $t('components.corpo_explanation.step_1') }}</li>
        <li><span class="circle">2</span>{{ $t('components.corpo_explanation.step_2') }}</li>
        <li><span class="circle">3</span>{{ $t('components.corpo_explanation.step_3') }}</li>
        <li><span class="circle">4</span>{{ $t('components.corpo_explanation.step_4') }}</li>
      </ol>
      <h-btn large :disabled="isCurrentOfferClosedOrComingSoon" :block="$vuetify.breakpoint.smAndDown" @click="() => $emit('done')" min-width="300">{{ $t('components.corpo_explanation.action') }}</h-btn>

      <p class="h-text-2" v-html="$t('components.corpo_explanation.faqUrl', { faqUrl })"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'corpo-explanation',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('offer', ['currentOfferStatus', 'currentOfferCtaPurchase']),

    faqUrl() {
      return this.currentLocale === 'fr' ? this.$urlProvider.HelloCorpoFaqFr : this.$urlProvider.HelloCorpoFaqEn;
    },
    isCurrentOfferClosedOrComingSoon() {
      return !this.currentOfferCtaPurchase || this.currentOfferStatus === 'closed' || this.currentOfferStatus === 'coming_soon';
    },
  },
};
</script>

<style lang="scss" scoped>
.corpo-explanation {
  margin: 0 auto;
  max-width: 996px;
  border-radius: 20px;
  border: var(--border-thin) solid var(--color-border);

  padding: var(--gap-xlarge);

  .info-list {
    list-style-type: none;
    margin-left: var(--gap-large);

    --circle-radius: 11px;
    --circle-diameter: calc(2 * var(--circle-radius));
    --circle-offset: calc((var(--circle-diameter) + var(--border-thin) + var(--border-thin)) * -1 - var(--gap-xsmall));

    .circle {
      background-color: var(--color-primary);
      width: var(--circle-diameter);
      height: var(--circle-diameter);
      border: var(--border-thin) solid var(--color-primary);
      border-radius: 50%;
      text-align: center;
      color: var(--color-ultra-light);
      display: inline-block;
      line-height: 1.2;
      font-size: 16px;
      margin-right: var(--gap-xsmall);
      margin-left: var(--circle-offset);
      &.branding-color {
        border-color: var(--color-branding);
        background-color: var(--color-branding);
      }
    }

    li:not(:last-child) {
      margin-bottom: var(--gap);
    }
  }
}

.explanation-content {
  max-width: 794px;
  margin: 0 auto;
  & > * {
    margin-bottom: var(--gap-xlarge);
  }
}
</style>
