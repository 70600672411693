<template>
  <div class="consumer-gift-card-totals">
    <h4 class="header">{{ $t('components.consumer_gift_card_totals.title') }}</h4>

    <payment-detail-item :text="offerName" :amount="paymentAmount === '' ? 0 : paymentAmount" />

    <div class="total-container">
      <span class="h-label-1">{{ $t('components.consumer_gift_card_totals.total') }}</span>
      <span class="total-value">{{ paymentAmount | currency_full | currencySymbol }}</span>
    </div>

    <p v-html="$t('components.consumer_gift_card_totals.subtext')" class="subtext h-text-3"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentDetailItem from '@/components/pay-and-get/components/PaymentDetailItem.vue';

export default {
  props: {
    offerName: {
      type: String,
    },
  },
  name: 'consumer-gift-card-totals',
  components: { PaymentDetailItem },
  computed: {
    ...mapGetters('consumerGiftCard', ['paymentAmount']),
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-totals {
  margin: var(--gap) 0;
  @media ($md-min) {
    margin: 0;
  }

  & > * {
    margin-bottom: var(--gap);
  }
}
.header {
  margin-bottom: var(--gap);
}

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-value {
  font-size: 2rem;
}

.subtext {
  color: var(--color-text-secondary);
}

.payment-detail-item {
  padding: var(--gap-small) 0;
  border-bottom: 2px solid var(--color-border);
  border-top: 2px solid var(--color-border);
}
</style>
