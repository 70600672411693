<template>
  <div>
    <div class="d-flex justify-space-between">
      <!-- Left section -->
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'coming_soon'" class="h-progress-bar-text">{{ $t('components.h_progress_bar.progress_coming_soon', { totalNumber: formattedTotalNumber }) }}</span>
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status !== 'coming_soon'" class="h-progress-bar-text">{{ $t('components.h_progress_bar.progress_left', { currentNumber: formattedCurrentNumber, totalNumber: formattedTotalNumber }) }}</span>
      <!-- Right section -->
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'coming_soon'" class="h-progress-bar-text">{{ $t('components.h_progress_bar.coming_soon', { date: formattedStartDateWithHour }) }}</span>
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'sold_out'" class="h-progress-bar-text accent--text">{{ $t('components.h_progress_bar.sold_out', { date: formattedStartDate }) }}</span>
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'available'" class="h-progress-bar-text">{{ $t('components.h_progress_bar.expiration', { date: formattedExpirationDate }) }}</span>
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'closed'" class="h-progress-bar-text accent--text">{{ $t('components.h_progress_bar.closed', { date: formattedExpirationDate }) }}</span>
    </div>

    <v-progress-linear reverse class="h-progress-bar" :value="progress" height="15" color="var(--color-branding)" />

    <div :style="{ fontSize: `${fontSize - 1}px` }" class="h-progress-bar-restriction-label">{{ $t('components.h_progress_bar.condition', { minPurchase: formattedMinPurchase, maxPurchase: formattedMaxPurchase }) }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-progress-bar',
  inheritAttrs: false,
  props: {
    expiration: {
      type: String,
    },
    start: {
      type: String,
    },
    status: {
      type: String,
    },
    currentNumber: {
      type: Number,
    },
    totalNumber: {
      type: Number,
    },
    minPurchase: {
      type: Number,
    },
    maxPurchase: {
      type: Number,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
  },
  computed: {
    progress() {
      if (this.status === 'sold_out' || this.status === 'closed') {
        return 0;
      }
      return this.totalNumber > 0 ? (this.currentNumber / this.totalNumber) * 100 : 100;
    },
    formattedExpirationDate() {
      return this.$options.filters.formatShortDate(this.expiration);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(this.start);
    },
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    formattedCurrentNumber() {
      if (this.status === 'sold_out' || this.status === 'closed') {
        return this.$options.filters.currencySymbol(this.$options.filters.currency(0));
      }
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.currentNumber));
    },
    formattedTotalNumber() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.totalNumber));
    },
    formattedMinPurchase() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.minPurchase));
    },
    formattedMaxPurchase() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.maxPurchase));
    },
  },
};
</script>

<style lang="scss" scoped>
.h-progress-bar-text {
  font-weight: 600;
  line-height: 1.5;
}

.h-progress-bar {
  margin-top: 6px;
  margin-bottom: 6px;
}

.h-progress-bar-restriction-label {
  font-style: italic;
  line-height: 1.2;
  color: var(--color-text-secondary);
  font-weight: 400;
}
</style>
