<template>
  <v-list dense elevation="0" color="secondary" class="main-navigation">
    <v-list-item v-for="(i, index) in filteredNavItems" :key="index" :to="i.to" :href="i.href" :target="i.href ? '_blank' : ''" @click="i.clickAction" tag="button">
      {{ $t(i.name) }}
    </v-list-item>
  </v-list>
</template>
<script>
import setupNavigation from '@/composables/navbar-items';
import safeExecute from '@/composables/safe-execute';

export default {
  name: 'main-navigation',
  setup(props, context) {
    const { execute } = safeExecute(context.root.$store);
    const { navigationItems, initDefault } = setupNavigation(context.root.$store, context.root.$router, execute, context);
    initDefault();
    return {
      navigationItems,
    };
  },
  computed: {
    filteredNavItems() {
      return this.navigationItems.filter((e) => !e.isHidden);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  text-transform: uppercase;
  text-decoration: none !important;
  text-align: center;
}
</style>
