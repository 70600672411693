<template>
  <!--
    Do not use the Vuetify "content-class" prop on this v-dialog tag!
    It would overwrite the content-class used on any component that use the h-dialog.
  -->
  <v-dialog ref="dialog" v-bind="$attrs" v-on="listeners" @input="onInput" :max-width="maxWidth">
    <template v-slot:activator="data">
      <slot name="activator" v-bind="data" />
    </template>

    <div class="h-dialog-container" :class="{ negative: error, positive: success, warn: warning, 'has-icon': icon }">
      <v-btn v-if="closingX" @click="close" small icon color="primary" class="x-btn" :aria-label="$t('components.h_dialog.close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-icon v-if="icon" :large="$vuetify.breakpoint.mdAndUp" :color="iconColor" class="dialog-icon">{{ icon }}</v-icon>
      <div v-if="$slots['pre-header']" class="pre-header">
        <slot name="pre-header" />
      </div>
      <h2 v-if="title" class="header" :class="{ 'h-heading-h1': !icon, 'h-typo-modal-h2': icon, 'no-closing-x': !closingX, 'centered-title': centerTitle }">{{ title }}</h2>
      <slot />

      <div v-if="$slots['footer']" class="footer">
        <slot name="footer" />
      </div>

      <h-action-layout v-if="$slots['action-footer']" class="footer">
        <slot name="action-footer" />
      </h-action-layout>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'h-dialog',
  props: {
    title: {
      type: String,
      default: undefined,
    },
    closingX: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    maxWidth: {
      type: String,
      default: '460',
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    iconColor() {
      if (this.error) {
        return 'error';
      }
      if (this.success) {
        return 'success';
      }
      return 'var(--color-text)';
    },
  },
  methods: {
    close() {
      this.$refs.dialog.isActive = false;
    },
    onInput(e) {
      this.$emit('input', e);
      if (e === false) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h-dialog-container {
  position: relative;
  padding: var(--gap);
  background-color: white;

  &.negative {
    border: 2px solid var(--color-error);

    .header {
      color: var(--color-error);
    }
  }

  &.positive {
    .header {
      color: var(--color-success);
    }
  }

  &.warn {
    .header {
      color: var(--color-warning);
    }
  }

  &.has-icon {
    padding-left: calc(var(--gap) + 50px);

    > .footer {
      padding-right: 50px;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.header {
  margin-bottom: var(--gap);
  padding-right: 24px;

  &.no-closing-x {
    padding-right: 0;
  }

  &.centered-title {
    text-align: center;
  }
}

.footer {
  margin-top: var(--gap-xxlarge);
}

.pre-header {
  margin-bottom: var(--gap);
  padding-right: 24px;
  position: relative;
  width: fit-content;
}

.dialog-icon {
  position: absolute;
  top: var(--gap);
  left: var(--gap);
}

.x-btn {
  position: absolute;
  top: var(--gap);
  right: var(--gap);
}

::v-deep ul {
  margin: var(--gap) 0;
}

::v-deep li {
  margin: var(--gap-xsmall) 0;
}
</style>
