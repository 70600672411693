<template>
  <div class="payment-saved-cards">
    <v-radio-group class="card-selection" v-model="radios" mandatory :disabled="disabled" hide-details>
      <fragment v-for="(p, index) in paymentMethods" :key="p.id">
        <v-radio class="v-radio-elem" :key="p.id" :value="index">
          <template v-slot:label>
            <div class="label-container">
              <img v-if="p.network === 'visa'" src="@/assets/main_visa_white.png" class="cc-img" />
              <img v-else-if="p.network === 'mastercard'" src="@/assets/mc_vrt_pos.svg" class="cc-img" />
              <v-icon v-else size="40" :style="{ paddingRight: '8px', paddingLeft: '8px' }">mdi-credit-card-outline</v-icon>

              <span :style="{ alignSelf: 'center', textTransform: 'none' }">**** **** **** {{ p.last4 }} - Exp. {{ p.exp_month }}/{{ p.exp_year }}</span>
            </div>
          </template>
        </v-radio>
        <payment-delete-saved-card-modal :card="p" />
      </fragment>
    </v-radio-group>

    <h-link @click="useOtherCard">{{ $t('components.payment_saved_card.other_card') }}</h-link>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentDeleteSavedCardModal from './PaymentDeleteSavedCardModal.vue';

export default {
  components: { PaymentDeleteSavedCardModal },
  name: 'payment-saved-cards',
  props: {
    otherCardFunction: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('payment', ['paymentMethods']),
  },
  watch: {
    radios() {
      this.setReadyToPay(true);
    },
    paymentMethods() {
      if (this.paymentMethods[this.radios]) {
        this.setReadyToPay(true);
      }
    },
  },
  data() {
    this.setReadyToPay(true);
    return {
      radios: 0,
    };
  },
  methods: {
    ...mapActions('payment', ['setReadyToPay']),
    useOtherCard() {
      this.setReadyToPay(false);
      this.otherCardFunction();
    },
    getPaymentMethod() {
      return this.paymentMethods[this.radios].id;
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-saved-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 40px;
}

.card-selection {
  max-height: 184px;
  width: 100%;
  margin-bottom: 12px;

  // NOTE (PLM) we use alternance of radio and remove button in HTML for handicapped persons
  ::v-deep .v-input--radio-group__input {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: var(--gap-xxsmall);
  }
}

.label-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.cc-img {
  max-height: 40px;
  max-width: 56px;
}
</style>
