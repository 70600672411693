<template>
  <inline-svg :src="require('@/assets/custom-icons/' + file)" />
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'custom-icon',
  components: { InlineSvg },
  props: {
    file: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep line,
::v-deep polyline,
::v-deep rect {
  stroke: currentColor;
  fill: currentColor;
}

::v-deep polygon,
::v-deep path {
  fill: currentColor;
}

::v-deep g {
  fill: none;
}
</style>
