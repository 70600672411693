<template>
  <div class="overlay-space-section">
    <img class="image" :src="horizontalLogoImageHelper(horizontalLogo)" :alt="name" />
    <div class="description h-text-3">{{ description }}</div>
    <h-label-action v-if="spaceUrl" class="my-4" :label="$t('components.overlay_space_section.website_label')" :value="spaceUrl" />
    <h-label v-if="spacePhone" class="my-4" :label="$t('components.overlay_space_section.phone_label')" :value="spacePhone | phone" />
  </div>
</template>

<script>
import { horizontalLogoImageHelper } from '@/composables/image-helper';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  props: {
    space: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { horizontalLogoImageHelper };
  },
  name: 'overlay-space-section',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    name() {
      return _get(this.space, `branding.label.${this.currentLocale}`);
    },
    description() {
      return _get(this.space, `branding.description.${this.currentLocale}`);
    },
    spacePhone() {
      return _get(this.space, `branding.phone.${this.currentLocale}`);
    },
    spaceUrl() {
      return _get(this.space, `branding.url.${this.currentLocale}`);
    },
    horizontalLogo() {
      return _get(this.space, `branding.images.horizontal_logo.${this.currentLocale}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: block;
  max-height: 64px;
  max-width: 100%;
}
.description {
  color: var(--color-text-secondary);
  margin: var(--gap) 0;
}
</style>
