<template>
  <div class="destination-button" v-on="$listeners">
    <v-icon v-if="withWarning" class="warning-icon" color="white" size="18" role="img" aria-hidden="false">mdi-alert-outline</v-icon>
    <v-btn :width="imageWidth" :height="imageHeight" :min-width="imageWidth" class="image-btn" :style="`padding: 0; background-image: url(${imageUrl})`"></v-btn>
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'destination-button',
  inheritAttrs: false,
  props: {
    imageUrl: {
      type: String,
    },
    text: {
      type: String,
    },
    withWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageWidth() {
      return this.$vuetify.breakpoint.smAndDown ? 75 : 124;
    },
    imageHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 75 : 124;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.destination-button {
  width: 75px;
  @media ($md-min) {
    width: 124px;
  }
  text-align: center;
  position: relative;
}
.image-btn {
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.text {
  font-size: calc(var(--font-size-label-2) * 0.62);
  text-transform: uppercase;
  line-height: 120%;
  font-weight: bold;
  margin-top: 8px;
  text-wrap: wrap;
  max-width: 100%;
  @include cut-after-x-line(2);
}
.warning-icon {
  width: 24px;
  height: 24px;
  @media ($sm-min) {
    width: 36px;
    height: 36px;
  }
  position: absolute;
  background-color: var(--color-error);
  border-radius: 50%;
  right: 0;
  top: 0;
  z-index: 1;

  &.mdi-alert-outline::before {
    margin-top: -3px;
  }
}
</style>
