<template>
  <div class="h-pay-and-get-label">
    <div class="amount">{{ amount }}</div>
    <div class="text">{{ label }}</div>
    <div class="notice">{{ notice }}</div>
    <h-pay-and-get-input-modal :title="$t('components.account_voucher.bonus_hints.title')" v-if="isUsingHints" :html-content="$t('components.account_voucher.bonus_hints.message', { date: $options.filters.formatTextDate(bonusExpiringDate) })" />
  </div>
</template>

<script>
export default {
  name: 'h-pay-and-get-label',
  props: {
    amount: {
      type: String,
      default: 'XX$%',
    },
    isUsingHints: {
      type: Boolean,
      default: false,
    },
    bonusExpiringDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Label',
    },
    notice: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.h-pay-and-get-label {
  min-width: 120px;
  text-align: center;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  height: 68px;
  font-weight: 600;
  font-size: 48px;
  line-height: 1;
  color: var(--color-primary);

  @media ($md-min) {
    height: 75px;
    font-size: 52px;
  }
}

.text {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--color-text-secondary);
}

.notice {
  margin-bottom: 12px;
  font-style: italic;
  font-size: 13px;
  color: var(--color-text-secondary);
}

::v-deep .activator {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
</style>
