<template>
  <destination-modal
    class="destination-button-with-modal"
    :destination-id="item.destination_id"
    :modal-title="item.modalTitle"
    :store-name="item.store"
    :store-complementary-info="item.storeComplementaryInfo"
    :show-store-link="item.showStoreLink"
    @seeMore="seeMore"
  >
    <template v-slot:activator="{ on, attrs }">
      <destination-button v-on="on" v-bind="attrs" :with-warning="!!item.storeComplementaryInfo" :text="item.buttonText" :image-url="item.buttonImageUrl"></destination-button>
    </template>
  </destination-modal>
</template>

<script>
import DestinationModal from './DestinationModal.vue';
import DestinationButton from './DestinationButton.vue';

export default {
  name: 'destination-button-with-modal',
  components: { DestinationModal, DestinationButton },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    seeMore(args) {
      this.$emit('seeMore', args);
    },
  },
};
</script>

<style lang="scss" scoped></style>
