var Joi = require('joi');
import moment from 'moment-timezone';
import { tlds } from '@hapi/tlds';

const POSTAL_CODE_FORMAT_REGEX_INSENSITIVE = /^[A-Z][0-9][A-Z][ ]{0,1}[0-9][A-Z][0-9]$/i;
const POSTAL_CODE_REGEX = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]{0,1}[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;
const POSTAL_CODE_REGEX_INSENSITIVE = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]{0,1}[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;

function validateTwoFieldsMatchErrorMessageBinding(fieldOne, fieldTwo, errorMessage) {
  return fieldOne !== fieldTwo ? [errorMessage] : [];
}

function hasNoValue(value, allowEmptyString = false) {
  return value === undefined || value === null || (value === '' && allowEmptyString);
}

export function validateRequired(field) {
  return (value) => (value !== undefined && value !== null && value !== '') || this.$t(`validation.required`, { field: this.$t(`validation.fields.${field}`), value: value, error: '' });
}

export function validateRequiredGivenFieldLabel(fieldLabel) {
  return (value) => (value !== undefined && value !== null && value !== '') || this.$t(`validation.required`, { field: fieldLabel, value: value, error: '' });
}

export function validateInvalidEmail() {
  const schema = Joi.string().email({ tlds: { allow: tlds } });
  return (value) => {
    try {
      Joi.attempt(value, schema);
      return true;
    } catch (err) {
      return this.$t(`validation.invalid.email`, { field: '', value: value, error: '' });
    }
  };
}

export function validateInvalidPassword() {
  return (value) => {
    if (hasNoValue(value)) {
      return true;
    } else if (value.length >= 8) {
      return true;
    }
    return this.$t('validation.invalid.password');
  };
}

export function validateInvalidActivationCode() {
  return (value) => /^[0-9]{6}$/.test(value) || this.$t('validation.invalid.activation_code');
}

export function validateInvalidCardPinCode() {
  return (value) => /^[0-9]{4}$/.test(value) || this.$t('validation.invalid.card_pin');
}

export function validateTwoEmailMatchErrorMessageBinding(fieldOne, fieldTwo) {
  return validateTwoFieldsMatchErrorMessageBinding(fieldOne, fieldTwo, this.$t('validation.invalid.email_match'));
}

export function validatePostalCode() {
  return (value) => POSTAL_CODE_REGEX.test(value) || this.$t('validation.invalid.postal_code');
}

export function validatePostalCodeInsensitive() {
  return (value) => POSTAL_CODE_REGEX_INSENSITIVE.test(value) || this.$t('validation.invalid.postal_code');
}

export function validatePostalCodeFormatInsensitive() {
  return (value) => POSTAL_CODE_FORMAT_REGEX_INSENSITIVE.test(value) || this.$t('validation.invalid.postal_code_format');
}

export function validateNeedChecked() {
  return (value) => !!value || this.$t('validation.consent');
}

export function validateLastFourDigits() {
  return (value) => /^\*\*\*\*-\*\*\*\*-\*\*\*\*-[0-9]{4}$/.test(value) || this.$t('validation.invalid.last_4_digits');
}

export function validatePublicToken() {
  return (value) => /^[0-9]{3}[- ]{0,1}[0-9]{3}[- ]{0,1}[0-9]{3}$/.test(value) || this.$t('validation.invalid.token');
}

export function validateMobileNumber(options = { allowEmptyString: false }) {
  return (value) => {
    if (hasNoValue(value, options.allowEmptyString)) {
      return true;
    }
    return /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value) || this.$t('validation.invalid.mobile');
  };
}

export function validatePhoneNumber(options = { allowEmptyString: false }) {
  return (value) => {
    if (hasNoValue(value, options.allowEmptyString)) {
      return true;
    }
    return /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value) || this.$t('validation.invalid.phone');
  };
}

export function validateMaxNumLines(maxLines) {
  return (value) => {
    if (hasNoValue(value)) {
      return true;
    } else if (value.split(/\r\n|\r|\n/).length <= maxLines) {
      return true;
    }

    return this.$t('validation.invalid.maxLines', { maxLines });
  };
}

export function validateMaxLength(maxLength) {
  return (value) => {
    if (hasNoValue(value)) {
      return true;
    } else if (value.length <= maxLength) {
      return true;
    }

    return this.$t('validation.invalid.maxLength', { maxLength });
  };
}

export function validateMinValue(minValue) {
  return (value) => {
    const nValue = stringToNumber(value);
    const valid = nValue >= minValue;
    return valid || this.$t('validation.invalid.minValue', { minValue: minValue.toString() });
  };
}

export function validateMaxValue(maxValue) {
  return (value) => {
    const nValue = stringToNumber(value);
    const valid = nValue <= maxValue;
    return valid || this.$t('validation.invalid.maxValue', { maxValue: maxValue.toString() });
  };
}

export function validatePositiveInteger() {
  return (value) => {
    if (hasNoValue(value)) {
      return true;
    } else if (/^[1-9][0-9]*$/.test(value)) {
      return true;
    }

    return this.$t('validation.invalid.positive_integer');
  };
}

export function validateNoRepeat(values) {
  return (value) => {
    const count = values.reduce((acc, v) => (v === value ? acc + 1 : acc), 0);
    return count <= 1 || this.$t('validation.no_repeat');
  };
}

export function validateYearOfBirth() {
  return (value) => {
    if (hasNoValue(value)) {
      return true;
    }

    if (value.toString().length !== 4) {
      return this.$t('validation.invalid.year');
    }

    const currentDateYear = Number(moment().format('YYYY'));
    if (currentDateYear - Number(value) > 150) {
      return this.$t('validation.invalid.year_too_archaic');
    }

    if (currentDateYear - Number(value) < 0) {
      return this.$t('validation.invalid.year_in_future');
    }

    return true;
  };
}

function stringToNumber(value) {
  const valueAsNumber = Number(value);
  return isNaN(valueAsNumber) ? undefined : valueAsNumber;
}
