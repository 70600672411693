const _ = require('lodash');

function organizeStore(stores, filter = '', currentLocale = 'en') {
  const franchiseGroupe = {};
  const newList = [];
  let franchiseKey;
  _.forEach(stores, (store) => {
    franchiseKey = _.get(store, 'franchise.id');
    if (_.get(store, 'franchise.id')) {
      if (!franchiseGroupe[franchiseKey]) {
        franchiseGroupe[franchiseKey] = {
          type: 'FRANCHISE',
          title: {
            fr: store.franchise.label_fr,
            en: store.franchise.label_en,
          },
          all_have_destination: !!store.destination_id,
          destinations: [],
          stores: [store],
        };
        if (store.destination_id) {
          franchiseGroupe[franchiseKey].destinations.push(store.destination_id);
        }
      } else {
        franchiseGroupe[franchiseKey].stores.push(store);
        // NOTE (SG) - If found the same destination more thane once in the franchise, go to address list
        if (franchiseGroupe[franchiseKey].destinations.includes(store.destination_id)) {
          franchiseGroupe[franchiseKey].all_have_destination = false;
        } else {
          if (store.destination_id) {
            franchiseGroupe[franchiseKey].destinations.push(store.destination_id);
          }
        }
        franchiseGroupe[franchiseKey].all_have_destination = franchiseGroupe[franchiseKey].all_have_destination && !!store.destination_id;
      }
    } else {
      newList.push({
        type: 'STORE',
        title: _.get(store, 'branding.label'),
        store,
      });
    }
  });

  _.forEach(Object.keys(franchiseGroupe), (fKey) => {
    newList.push(franchiseGroupe[fKey]);
  });

  if (filter) {
    return _.filter(newList, (e) => {
      return _.includes(_.deburr(_.lowerCase(e.title[currentLocale])), _.deburr(_.lowerCase(filter)));
    }).sort(function (a, b) {
      return a.title[currentLocale].localeCompare(b.title[currentLocale]);
    });
  }

  return newList.sort(function (a, b) {
    return a.title[currentLocale].localeCompare(b.title[currentLocale]);
  });
}

function reSort(newList, currentLocale = 'en') {
  return newList.sort(function (a, b) {
    return a.title[currentLocale].localeCompare(b.title[currentLocale]);
  });
}

module.exports = {
  organizeStore,
  reSort,
};
