<template>
  <fragment>
    <div ref="test"></div>

    <h-label-box id="labeltest" class="filters-section-1" :style="{ top: `${inModal ? 0 : $vuetify.application.top}px` }" v-if="!inModal" :text="$t('components.store_list.info_label')" />
    <div id="filterbar" class="filters-section-2" :style="{ top: `${inModal ? 0 : $vuetify.application.top}px` }">
      <div>
        <div v-if="$vuetify.breakpoint.smAndDown && displayFilterBar" class="mobile-search-bar" :class="{ cfbnormal: isFocusMobileTextField || !displayCategoriesBar }">
          <category-filter-bar v-if="displayCategoriesBar" :initial-category="currentCategory" @change="storeCategoryChange" :category-list="currentStoreCategoryIds" />
          <h-search-field @focus="focusMobileTextField" @blur="blurMobileTextField" :placeholder="$t('components.store_list.search_store_placeholder')" hide-details class="help" color="text-primary" v-model="storeTextFilter" clearable></h-search-field>
          <h-select-branding-color v-if="displayDestinationBar" class="h-select-branding-color" hide-details v-model="selectedDestination" :items="destinationChoices" :item-text="`label_${currentLocale}`" item-value="id"></h-select-branding-color>
        </div>

        <div v-else-if="displayFilterBar" class="search-bar">
          <category-filter-bar v-if="displayCategoriesBar" :initial-category="currentCategory" @change="storeCategoryChange" :category-list="currentStoreCategoryIds" />

          <div class="filters">
            <h-select-branding-color v-if="displayDestinationBar" hide-details v-model="selectedDestination" :items="destinationChoices" :item-text="`label_${currentLocale}`" item-value="id"></h-select-branding-color>
            <h-search-field color="text-primary" :placeholder="$t('components.store_list.search_store_placeholder')" hide-details v-model="storeTextFilter" clearable></h-search-field>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDisplayStoreList" class="store-section">
      <div v-if="(storeTextFilter || '').length > 0" class="h-label-2" style="color: var(--color-text-secondary); padding: var(--gap) var(--gap-page-side) 0">
        {{ $tc('components.store_list.store_filter_count', currentStoresFormatted.length, { count: currentStoresFormatted.length }) }}
      </div>
      <p v-if="currentStoresFormatted.length === 0" class="h-label-1" style="padding: var(--gap) var(--gap-page-side) 0">{{ $t('components.store_list.store_filter_empty') }}</p>
      <v-expansion-panels v-model="responsivePanelModel" multiple flat :readonly="$vuetify.breakpoint.mdAndUp ? true : false" tag="ul">
        <li class="store-item" v-for="({ store, stores, type, title, all_have_destination }, index) in currentStoresFormatted" :key="index">
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions>
              <template v-slot:default="{ open }">
                <div class="header-container">
                  <span class="header-content">
                    <span class="header-text">{{ title | translateI18nObject() }}</span>
                  </span>
                  <v-icon v-if="$vuetify.breakpoint.smAndDown">{{ open ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                </div>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content :title="title | translateI18nObject()">
              <base-store v-if="type === 'STORE'" :store="store" />
              <base-store v-else-if="type === 'FRANCHISE' && stores.length === 1" :store="{ destination_id: stores[0].destinations ? stores[0].destinations[0] : undefined, ...stores[0] }" />
              <franchise-all-destination-store v-else-if="type === 'FRANCHISE' && all_have_destination" :stores="stores" />
              <franchise-addresses-store v-else-if="type === 'FRANCHISE' && !all_have_destination" :stores="stores" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </li>
      </v-expansion-panels>
    </div>
    <div v-else class="no-store-title">
      <h2>{{ $t('components.offer_stores_display.title') }}</h2>
      <p>{{ $t('components.offer_stores_display.sub_title') }}</p>
    </div>
  </fragment>
</template>

<script>
import BaseStore from '@/components/stores/types/BaseStore.vue';
import FranchiseAllDestinationStore from '@/components/stores/types/FranchiseAllDestinationStore.vue';
import FranchiseAddressesStore from '@/components/stores/types/FranchiseAddressesStore.vue';
import CategoryFilterBar from '@/components/search/bar/CategoryFilterBar.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'store-list',
  components: {
    BaseStore,
    FranchiseAllDestinationStore,
    FranchiseAddressesStore,
    CategoryFilterBar,
  },
  props: {
    showStoreList: {
      type: Boolean,
      default: true,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prevScrollpos: window.pageYOffset,
      scrollDelta: 0,
      openPanelsMobile: [],
      isFocusMobileTextField: false,
    };
  },
  destroyed() {
    this.resetOfferCategoriesAndStores();
    if (!this.inModal) {
      window.removeEventListener('scroll', this.windowHandleScroll);
    }
  },
  created() {
    if (!this.inModal) {
      window.addEventListener('scroll', this.windowHandleScroll);
    }
  },
  computed: {
    ...mapGetters('store', ['initialNumStores', 'currentCategory', 'currentStoresFormatted', 'currentStoreCategoryIds', 'currentDestinationList', 'currentDestination', 'currentKeyword']),
    ...mapGetters('ui', ['currentLocale']),
    destinationChoices() {
      return [{ id: null, label_fr: this.$t('components.store_list.destination_all', 'fr'), label_en: this.$t('components.store_list.destination_all', 'en') }, ...this.currentDestinationListSorted];
    },
    currentDestinationListSorted() {
      return this.currentDestinationList.toSorted((a, b) => {
        const label_a = this.currentLocale === 'en' ? a.label_en : a.label_fr;
        const label_b = this.currentLocale === 'en' ? b.label_en : b.label_fr;

        return (label_a || '').toLowerCase().localeCompare((label_b || '').toLowerCase());
      });
    },
    selectedDestination: {
      get() {
        return this.currentDestination;
      },
      set(newDestinationId) {
        this.scrollToTop();
        this.updateQuery({ destination_id: newDestinationId });
      },
    },
    storeTextFilter: {
      get() {
        return this.currentKeyword;
      },
      set(newKeyword) {
        this.scrollToTop();
        this.updateQuery({ keyword: newKeyword });
      },
    },
    displayCategoriesBar() {
      return this.currentStoreCategoryIds.length > 2 || this.currentCategory;
    },
    displayDestinationBar() {
      return this.currentDestinationList.length > 1 || this.currentDestination;
    },
    displayFilterBar() {
      return this.initialNumStores > 10 || this.currentCategory || this.currentDestination;
    },
    isDisplayStoreList() {
      return this.showStoreList && this.initialNumStores.length !== 0;
    },
    storesList() {
      if (this.isDisplayStoreList) {
        return this.currentStoresFormatted;
      }
      return [];
    },
    responsivePanelModel: {
      get() {
        return this.$vuetify.breakpoint.mdAndUp ? this.currentStoresFormatted.map((store, i) => i) : this.openPanelsMobile;
      },
      set(val) {
        this.openPanelsMobile = val;
      },
    },
  },
  watch: {
    currentStoresFormatted: {
      handler() {
        this.setOpenPanel();
      },
      immediate: true,
    },
    currentLocale() {
      this.sortAgain();
    },
  },
  methods: {
    ...mapActions('store', ['updateQuery', 'resetOfferCategoriesAndStores', 'sortAgain']),
    windowHandleScroll() {
      // Get the filterbar
      const filterBar = document.getElementById('filterbar');
      const elemRect = filterBar.getBoundingClientRect();
      // Get the label
      const labeltest = document.getElementById('labeltest').offsetHeight;
      const currentScrollPos = window.pageYOffset;

      // If not scroll passed the filter bar do nothing
      if (elemRect.top > filterBar.offsetHeight) {
        this.prevScrollpos = currentScrollPos;
        return;
      }

      if (this.prevScrollpos > currentScrollPos) {
        // if scrolling up
        filterBar.style.top = `${this.inModal ? 0 + labeltest : this.$vuetify.application.top + labeltest - 1}px`;
        this.scrollDelta = 0;
      } else if (this.scrollDelta > 200) {
        // If scrolling down pass 200
        filterBar.style.top = `-${filterBar.offsetHeight}px`;
        this.scrollDelta = 0;
      } else {
        // if scrolling down
        this.scrollDelta = this.scrollDelta + currentScrollPos - this.prevScrollpos;
      }
      this.prevScrollpos = currentScrollPos;
    },

    setOpenPanel() {
      const panelsShouldBeOpen = this.initialNumStores <= 10;
      if (panelsShouldBeOpen) {
        this.openPanelsMobile = this.currentStoresFormatted.map((store, i) => i);
      } else {
        this.openPanelsMobile = [];
      }
    },
    focusMobileTextField() {
      this.isFocusMobileTextField = true;
    },
    blurMobileTextField() {
      this.isFocusMobileTextField = false;
    },
    scrollToTop() {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.test.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 9;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
    },
    storeCategoryChange(newCategory) {
      this.scrollToTop();

      this.updateQuery({ category: newCategory });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-store-title {
  text-align: center;
  color: var(--color-text-primary);
  padding: var(--gap) 0px;
  background-color: var(--color-ultra-light);

  p {
    margin: var(--gap) 0 0;
  }
}

.search-bar {
  padding: 0 0 var(--gap-xxsmall);
}

.store-section {
  padding: 0 0 var(--gap);
  background-color: var(--color-ultra-light);
  > * {
    padding: 0;
  }
}

.filters-section {
  background-color: var(--color-ultra-light);
  z-index: var(--hello-z-index-filter-bar);
  border-bottom: 1px solid var(--color-branding);
  @media ($md-min) {
    border-bottom: unset;
  }
}
.filters-section-1 {
  position: sticky;
  z-index: calc(var(--hello-z-index-filter-bar) + 1);
}
.filters-section-2 {
  position: sticky;
  z-index: var(--hello-z-index-filter-bar);
  transition: top 0.3s;
  background-color: var(--color-ultra-light);
}

.v-expansion-panel:before {
  content: none;
}

.v-expansion-panel-header {
  @media ($md-min) {
    cursor: default;

    ::v-deep .v-expansion-panel-header__icon {
      display: none;
    }
  }

  .header-container {
    display: flex;
    align-items: flex-start;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @media ($md-min) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .header-text {
    margin-bottom: 8px;
    color: var(--color-branding);
    font-size: 1.25rem;
    font-weight: 600;

    &:last-child {
      margin: 0;
    }

    @media ($md-min) {
      margin-bottom: 0;
      margin-right: 16px;
      font-size: 1.625rem;
    }
  }
}

.store-item {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-child):after {
    content: '';
    display: block;
    margin: 0 var(--gap-page-side);
    border-top: var(--border-thin) solid var(--color-border);
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  padding: 0 var(--gap-page-side);

  & > * {
    max-width: 300px;
  }

  .v-text-field {
    z-index: 1;
    width: 100%;
    &:only-child {
      margin-left: auto;
    }
    // justify-content: flex-end;
    ::v-deep .v-input__slot {
      border-color: var(--color-text-primary);
    }
  }
}

.mobile-search-bar {
  padding: 0 var(--gap) var(--gap);
  display: grid;
  align-items: center;
  transition: 300ms;
  grid-template-columns: 80% 20%;

  & .help {
    width: 55px;
  }

  &.cfbnormal {
    transition: 300ms;
    grid-template-columns: 0% 100%;
    & .v-text-field {
      z-index: 1;
      width: 100%;
      ::v-deep .v-input__slot {
        border-color: var(--color-text-primary);
      }
    }
  }
  & .v-text-field {
    justify-self: end;
    background-color: var(--color-ultra-light);
    ::v-deep .v-input__slot {
      border-color: transparent;
    }
  }

  & .category-filter-bar {
    min-width: 1px;
    ::v-deep .mobile-bar {
      padding: 0;
    }
  }
  .h-select-branding-color {
    grid-column: 1 / span 2;
    grid-row: 2;
    justify-self: start;
    // max-width: 80%;
    width: 100%;
  }
}
</style>
