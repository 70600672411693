import { render, staticRenderFns } from "./AboutOverlay.vue?vue&type=template&id=1044fc6c&scoped=true"
import script from "./AboutOverlay.vue?vue&type=script&lang=js"
export * from "./AboutOverlay.vue?vue&type=script&lang=js"
import style0 from "./AboutOverlay.vue?vue&type=style&index=0&id=1044fc6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1044fc6c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCarousel,VCarouselItem,VIcon})
