<template>
  <div class="consumer-gift-card-faq">
    <div style="max-width: 780px; margin: 0 auto">
      <h1 class="faq-title">{{ $t('components.consumer_gift_card_faq.title') }}</h1>

      <p v-if="showActivate">
        <span>{{ $t('components.consumer_gift_card_faq.activation') }} </span>
        <a class="faq-link" :class="{ disabled: disableActivate }" @click="activateGiftCard">{{ $t('components.consumer_gift_card_faq.activation_cta') }}</a>
      </p>

      <v-expansion-panels flat dark multiple>
        <v-expansion-panel v-for="(question, index) of questions" :key="index">
          <v-expansion-panel-header hide-actions>
            <template v-slot:default="{ open }">
              <div class="header-container">
                <span class="header-text">{{ $t(question.question) }}</span>
                <v-icon>{{ open ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span v-html="$t(question.answer, { faqUrl })"></span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <p style="margin-top: var(--gap)">
        <span>{{ $t('components.consumer_gift_card_faq.faq') }}</span>
        <a class="faq-link" :href="faqUrl" target="_blank">{{ $t('components.consumer_gift_card_faq.faq_cta') }}</a>
        <span>{{ $t('components.consumer_gift_card_faq.faq_cta_suffix') }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'consumer-gift-card-faq',
  props: {
    disableActivate: {
      type: Boolean,
      default: true,
    },
    showActivate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      questions: [
        {
          question: 'components.consumer_gift_card_faq.question_1',
          answer: 'components.consumer_gift_card_faq.answer_1',
        },
        {
          question: 'components.consumer_gift_card_faq.question_3',
          answer: 'components.consumer_gift_card_faq.answer_3',
        },
        {
          question: 'components.consumer_gift_card_faq.question_2',
          answer: 'components.consumer_gift_card_faq.answer_2',
        },
        {
          question: 'components.consumer_gift_card_faq.question_4',
          answer: 'components.consumer_gift_card_faq.answer_4',
        },
        {
          question: 'components.consumer_gift_card_faq.question_5',
          answer: 'components.consumer_gift_card_faq.answer_5',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    faqUrl() {
      return this.currentLocale === 'fr' ? this.$urlProvider.HelloFaqFr : this.$urlProvider.HelloFaqEn;
    },
  },
  methods: {
    activateGiftCard() {
      if (!this.disableActivate) {
        this.$emit('activate');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-faq {
  background-color: var(--color-secondary);
  color: var(--color-ultra-light);
  padding: var(--gap-xxlarge) var(--gap);
  font-size: var(--font-size-text-1);
  @media ($sm-min) {
    padding: calc(var(--gap) * 2.5) var(--gap);
  }
}

.faq-title {
  text-align: center;
  margin-bottom: var(--gap-xxlarge);
  @media ($md-min) {
    margin-bottom: var(--gap-xxlarge);
  }
}

.faq-link {
  color: var(--color-ultra-light);
  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.26) !important;
  }
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-content {
  font-size: var(--font-size-text-3);
  ::v-deep .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: var(--gap-xxlarge);
    @media ($md-min) {
      padding-right: var(--gap-xlarge);
    }
  }
}

.v-expansion-panel-header {
  padding: var(--gap) 0;
  @media ($md-min) {
    padding: var(--gap-small) 0;
  }
  .header-container {
    display: flex;
    align-items: flex-start;
  }

  .header-text {
    margin-bottom: 8px;
    margin-right: 16px;
    font-size: var(--font-size-text-1);
    font-weight: 600;
    flex: 1 1 auto;

    @media ($md-min) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}
</style>
