import VuePageTitle from 'vue-page-title';

export default {
  name: 'page-title',
  init: async ({ Vue }) => {
    Vue.use(VuePageTitle, {
      suffix: ' | Hello',
    });
  },
};
