import VueCurrencyFilter from 'vue-currency-filter';

export default ({ Vue, app }) => {
  Vue.use(VueCurrencyFilter, [
    // default_name 'currency'. avoids empty decimals
    {
      symbol: '',
      thousandsSeparator: ',',
      fractionCount: 2,
      fractionSeparator: '.',
      symbolPosition: 'front',
      symbolSpacing: false,
      avoidEmptyDecimals: '',
    },

    {
      // default name 'currency_2'
      name: 'currency_full',
      symbol: '',
      thousandsSeparator: ',',
      fractionCount: 2,
      fractionSeparator: '.',
      symbolPosition: 'front',
      symbolSpacing: false,
      avoidEmptyDecimals: undefined,
    },
  ]);

  Vue.filter('bonusSymbol', (amount) => {
    if (amount === undefined || amount === null || amount === '') return '';
    return `+${amount}%`;
  });

  Vue.filter('currencySymbol', (amount) => {
    if (amount === undefined || amount === null || amount === '') return '';
    return app.i18n.t('filters.money', { number: amount });
  });
};
