<template>
  <div>
    <p v-if="cardProgramType === 'gpr'">{{ $t('components.renew_card_modal.description_classic') }}</p>
    <p v-if="cardProgramType === 'instant'">{{ $t('components.renew_card_modal.description_instant') }}</p>

    <h-form ref="form" v-model="valid" @submit.prevent="submit()">
      <div class="mt-8" v-if="cardProgramType === 'gpr'">
        <div class="section-header">
          {{ $t('components.renew_card_modal.separator_your_card') }}
        </div>

        <h-text-field ref="pinCard" :label="$t('components.renew_card_modal.pin_card.label')" :hint="$t('components.renew_card_modal.pin_card.hints')" type="text" maxlength="4" v-model="pinCard" :rules="pinCardRules" required></h-text-field>
      </div>

      <div class="mt-4">
        <div class="section-header">
          {{ $t('components.renew_card_modal.separator_your_address') }}
        </div>

        <div class="formRow">
          <h-text-field class="col-20" ref="civicNumber" :label="$t('components.renew_card_modal.civic_number.label')" type="text" maxlength="50" v-model="civic_number" required />
          <h-text-field
            class="col-fill"
            ref="street"
            :label="$t('components.renew_card_modal.street_name.label')"
            :hint="$t('components.renew_card_modal.street_name.hints')"
            type="text"
            maxlength="50"
            v-model="street"
            :rules="streetAddressRules"
            required
          />
        </div>

        <div class="formRow">
          <h-text-field class="col-20" ref="apartmentNumber" :label="$t('components.renew_card_modal.apartment_number.label')" type="text" maxlength="50" v-model="apartment_number" required />
          <h-text-field class="col-fill" ref="city" v-model="city" :label="$t('components.renew_card_modal.city.label')" :hint="$t('components.renew_card_modal.city.hints')" type="text" maxlength="50" :rules="cityRules" required />
        </div>

        <div class="formRow">
          <h-select
            ref="province"
            class="col-50"
            v-model="province"
            :label="$t('components.renew_card_modal.province.label')"
            :items="provincesChoices"
            item-text="name"
            item-value="abbr"
            :rules="provinceRules"
            hide-details
            dense
            style="margin-bottom: 20px"
          />

          <h-text-field
            ref="postalCode"
            class="col-50"
            :label="$t('components.renew_card_modal.postal_code.label')"
            :hint="$t('components.renew_card_modal.postal_code.hints')"
            type="text"
            v-maska="{ mask: 'A#A #A#' }"
            maxlength="7"
            v-model="postalCode"
            :rules="postalCodeRules"
            required
          />
        </div>

        <div class="formRow">
          <h-text-field ref="mobile" class="col-50" v-model="mobile" v-maska="'+1 (###) ###-####'" :hint="$t('components.renew_card_modal.mobile.hints')" :label="$t('components.renew_card_modal.mobile.label')" :rules="mobileRules" />
        </div>

        <hello-form-error />
      </div>

      <h-action-layout class="h-action-layout">
        <h-btn type="submit" :disabled="!valid || working" data-cy="renewCardSubmitBtn" :loading="working">{{ $t('components.renew_card_modal.action') }}</h-btn>
        <h-btn @click="cancelRenewCardModal" outlined>{{ $t('components.instant_card_apply.cancel_action') }}</h-btn>
      </h-action-layout>
    </h-form>
  </div>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import { splitAddress } from '@/utils/address-splitter';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _trim from 'lodash/trim';
import _replace from 'lodash/replace';

import { validateInvalidCardPinCode, validateMaxLength, validatePostalCode, validateRequired, validateMobileNumber } from '@/composables/validations';
export default {
  name: 'renew-card-form',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateRequired,
      validateMaxLength,
      validatePostalCode,
      validateInvalidCardPinCode,
      validateMobileNumber,
    };
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      street: undefined,
      civic_number: undefined,
      apartment_number: undefined,
      pinCard: undefined,
      city: undefined,
      province: undefined,
      postalCode: undefined,
      renewCardModal: undefined,
      mobile: null,
      pinCardRules: [this.validateRequired('pinCard'), this.validateInvalidCardPinCode()],
      streetAddressRules: [this.validateRequired('streetAddress'), this.validateMaxLength(50)],
      cityRules: [this.validateRequired('city'), this.validateMaxLength(50)],
      provinceRules: [this.validateRequired('province')],
      postalCodeRules: [this.validateRequired('postal_code'), this.validatePostalCode()],
      mobileRules: [this.validateMobileNumber({ allowEmptyString: true })],
    };
  },
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    cardProgramType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('account', ['accountAddress', 'accountMobile', 'accountClassicCardAddress', 'accountPublicToken']),
    provincesChoices() {
      const choices = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.shared.provinces.${choice}`), abbr: choice };
      });
    },
  },
  mounted() {
    if (this.cardProgramType === 'gpr') {
      this.fillFormAddressFromAddress(this.accountClassicCardAddress);
    } else if (this.cardProgramType === 'instant') {
      this.fillFormAddressFromAddress(this.accountAddress);
    }

    this.setMobile(this.accountMobile);
  },
  methods: {
    cancelRenewCardModal() {
      this.$emit('cancel');
    },
    setMobile(mobile) {
      this.mobile = mobile;
    },
    fillFormAddressFromAddress(address) {
      if (address.street) {
        const splittedAddress = splitAddress(address.street);
        this.street = splittedAddress.street;
        this.civic_number = splittedAddress.civicNumber;
        this.apartment_number = splittedAddress.apartmentNumber;
      }

      this.city = _get(address, 'city', undefined);
      this.province = _get(address, 'state', undefined);
      this.postalCode = _get(address, 'zip', undefined);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        //Formatting street properly.
        const apartment_number = this.apartment_number ? ` #${_trim(this.apartment_number)}` : '';
        const street = `${_trim(this.civic_number)} ${_trim(this.street)}${apartment_number}`;

        await this.executeForm(
          {
            name: 'renewCard',
            action: 'account/renewCard',
            success: () => {
              this.$emit('success');
            },
          },
          {
            card_public_token: this.publicToken,
            card_pin: this.pinCard,
            mobile: _replace(this.mobile, /[()+]/g, '').replace(/ /g, '-'),
            address: {
              street,
              city: this.city,
              state: this.province,
              country: 'CA',
              zip: this.formatPostalCodeWithMiddleSpace(this.postalCode),
            },
          },
        );
      }
    },
    formatPostalCodeWithMiddleSpace(postalCode) {
      if (postalCode.length !== 6) {
        return postalCode;
      }
      return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  padding-bottom: var(--gap-xxsmall);
  margin-bottom: var(--gap-small);
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-primary);
  border-bottom: 1px solid var(--color-border);
  text-transform: uppercase;
}

.h-action-layout {
  margin-top: var(--gap-large);
}

.formRow {
  @media ($sm-min) {
    display: flex;
    gap: var(--gap);
    > .col-50 {
      flex-grow: 5;
      max-width: 50%;
    }

    > .col-20 {
      flex-grow: 2;
      max-width: 20%;
    }

    > .col-fill {
      flex-grow: 10;
    }
  }
}
</style>
