import { computed, ref, watch } from '@vue/composition-api';

export default function createBusinessConsoleNavbarItem(store, router, execute, DefaultNavigationItem, context) {
  const currentRoute = ref(router.currentRoute.name);

  watch(
    () => context.root.$route,
    () => {
      currentRoute.value = context.root.$route.name;
    },
  );
  const isInstantActivationPage = computed(() => currentRoute.value === 'instant-card-activation');
  const isHidden = computed(() => !store.getters['account/canAccessBusinessConsole'] || isInstantActivationPage.value);

  return new DefaultNavigationItem('components.navbar.business_console', {
    isEnable: true,
    isHidden: isHidden,
    href: process.env.VUE_APP_BUSINESS_CONSOLE,
  });
}
