<template>
  <h-dialog ref="dialog" v-model="dialog" @open="onOpen" @close="onClose" :title="$t('components.forgot_password_modal.title')" class="forgot-password-modal">
    <template v-slot:activator="{ on }">
      <h-link v-on="on" class="forgot-password-link">{{ $t('components.auth_form.forgot_password_action') }}</h-link>
    </template>

    <p>{{ $t('components.forgot_password_modal.description') }}</p>

    <h-form ref="form" v-model="valid" @submit.prevent="submit()">
      <h-text-field autocomplete="email" ref="email" class="mt-5" maxlength="255" :label="$t('components.forgot_password_modal.email_field')" type="email" v-model="emailModal" :rules="emailRules" required data-cy="signinEmailField" />

      <hello-form-error />

      <h-action-layout class="action-zone">
        <h-btn type="submit" :disabled="!valid || working" data-cy="registerSubmitBtn" :loading="working">{{ $t('components.forgot_password_modal.action') }}</h-btn>
        <h-btn :disabled="working" @click="$refs.dialog.close()" outlined>{{ $t('components.forgot_password_modal.cancel') }}</h-btn>
      </h-action-layout>
    </h-form>
  </h-dialog>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapActions } from 'vuex';
import { validateInvalidEmail, validateRequired, validateMaxLength } from '@/composables/validations';

export default {
  name: 'forgot-password-modal',
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
      validateRequired,
      validateInvalidEmail,
      validateMaxLength,
    };
  },
  mixins: [recaptcha],
  props: {
    initialEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      emailModal: '',
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
    };
  },
  watch: {
    initialEmail() {
      this.emailModal = this.initialEmail;
    },
  },
  mounted() {
    this.recaptcha('forgotPasswordSubmit');
  },
  methods: {
    ...mapActions('error', ['clearError']),
    onClose() {
      this.clearError();
    },
    onOpen() {
      this.clearError();
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.executeForm(
          {
            name: 'forgotPasswordView',
            action: 'security/forgotPassword',
            success: () => {
              this.dialog = false;
            },
          },
          {
            email: this.emailModal,
            recaptcha_token: await this.recaptcha('forgotPasswordSubmit'),
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-zone {
  margin-top: var(--gap);
}
</style>
