<template>
  <div class="grid-small-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'grid-small-card',
};
</script>

<style lang="scss" scoped>
.grid-small-card {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
  grid-template-rows: 1fr;
}
</style>
