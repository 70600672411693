<template>
  <big-card-base :offer-title="currentOfferName" :offer-label="currentOfferCategory | formatCategoryRegion(currentOfferRegion)" :offer-description="currentOfferDescription" :carousel-images="currentOfferShowcasesImage">
    <template #extraContent>
      <big-card-voucher-status-label :status="currentOfferStatus" :start="currentOfferStartDate" />
    </template>

    <template #actionSection>
      <h-btn v-if="currentOfferConsumerEnabled" :disabled="!currentOfferConsumerPurchasable" color="var(--color-branding)" block large @click="openOverlayForConsumerGiftCard">{{
        $t('components.gift_certificate_offer_single_publisher_big_card.call_to_action_consumer_gift_card')
      }}</h-btn>

      <consumer-gift-card-info-modal v-if="currentOfferConsumerEnabled" :consumer-purchasable="currentOfferConsumerPurchasable" use-branding-color @action="openOverlayForConsumerGiftCard" />
    </template>
  </big-card-base>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';
import BigCardVoucherStatusLabel from '@/components/cards/big/BigCardVoucherStatusLabel.vue';
import ConsumerGiftCardInfoModal from '@/components/consumer-gift-card/components/ConsumerGiftCardInfoModal.vue';

export default {
  name: 'publisher-choice-gift-certificate-offer',
  components: { BigCardBase, BigCardVoucherStatusLabel, ConsumerGiftCardInfoModal },
  computed: {
    ...mapGetters('space', ['currentPartnerId']),
    ...mapGetters('offer', [
      'currentOfferName',
      'currentOfferCategory',
      'currentOfferRegion',
      'currentOfferDescription',
      'currentOfferShowcasesImage',
      'currentOfferStatus',
      'currentOfferStartDate',
      'currentOfferId',
      'currentOfferConsumerEnabled',
      'currentOfferConsumerPurchasable',
    ]),
  },
  methods: {
    ...mapActions('overlay', ['openHeaderDrawerWithParams']),
    openOverlayForConsumerGiftCard() {
      this.openHeaderDrawerWithParams({
        content: 'purchaseGiftCardOverlay',
        payload: {
          partner_id: this.currentPartnerId,
          offer_id: this.currentOfferId,
        },
      });
    },
  },
};
</script>
