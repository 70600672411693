<template>
  <div class="big-card-base" :class="{ compact: compactLayout }">
    <!-- Chip -->
    <div v-if="$slots.chip" class="chip-slot">
      <slot name="chip"></slot>
    </div>

    <div v-if="!compactLayout" class="offer-promo">
      <!-- Carousel -->
      <v-carousel height="auto" hide-delimiters :show-arrows="carouselImages[currentLocale].length > 1" next-icon="mdi-dark  mdi-chevron-right" prev-icon="mdi-dark mdi-chevron-left">
        <v-carousel-item v-for="(item, i) in carouselImagesHelpers(carouselImages[currentLocale])" :key="i" :src="item">
          <v-img :src="item" max-height="7586px" />
        </v-carousel-item>
      </v-carousel>

      <!-- Restricted To Members Badge -->
      <restricted-to-members-badge v-if="restrictedToMembers" class="restricted-to-members-badge-large-on-top-left" />

      <!-- Voucher Chip -->
      <div v-if="showVoucherChip" class="voucher-chip">{{ $t('components.big_card.voucher') }}</div>

      <!-- Panier Bleu -->
      <img v-if="showPanierBleu" class="panier-bleu" src="@/assets/panierbleu_htal_vignette_faisonsrouler_rgb.svg" :alt="$t('components.big_card.panier_bleu')" />
    </div>

    <!-- Content -->
    <div class="offer-content">
      <div class="info-section">
        <h1 v-if="offerTitle" class="offer-title">{{ offerTitle }}</h1>
        <p v-if="offerLabel" class="offer-label h-label-2">{{ offerLabel }}</p>
        <p v-if="offerDescription" class="offer-description h-paragraph-1">{{ offerDescription }}</p>

        <slot name="extraContent"></slot>
      </div>

      <div v-if="$slots.actionSection" class="action-section">
        <slot name="actionSection"></slot>
      </div>

      <div v-if="compactLayout" class="compact-img-container">
        <img class="compact-img" :src="showcaseImageHelper(carouselImages[currentLocale][0])" alt="" />
        <img v-if="showPanierBleu" class="panier-bleu" src="@/assets/panierbleu_htal_vignette_faisonsrouler_rgb.svg" :alt="$t('components.big_card.panier_bleu')" />
      </div>
    </div>
  </div>
</template>
<script>
import { carouselImagesHelpers, showcaseImageHelper } from '@/composables/image-helper';
import { mapGetters } from 'vuex';
import RestrictedToMembersBadge from '@/components/utilities/RestrictedToMembersBadge.vue';

export default {
  name: 'big-card-base',
  components: {
    RestrictedToMembersBadge,
  },
  setup() {
    return { carouselImagesHelpers, showcaseImageHelper };
  },
  props: {
    offerTitle: {
      type: String,
      default: undefined,
    },
    offerLabel: {
      type: String,
      default: undefined,
    },
    offerDescription: {
      type: String,
      default: undefined,
    },
    compactLayout: {
      type: Boolean,
      default: false,
    },
    carouselImages: {
      type: Object,
      default: () => [],
    },
    showVoucherChip: {
      type: Boolean,
      default: false,
    },
    showPanierBleu: {
      type: Boolean,
      default: false,
    },
    restrictedToMembers: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
<style lang="scss" scoped>
.big-card-base {
  position: relative;
  background-color: var(--color-ultra-light);
}

.chip-slot {
  position: relative;

  > * {
    position: absolute;
    z-index: 1;
    top: -20px;
    right: 0;

    @media ($md-min) {
      top: -75px;
      right: clamp(-100px, calc((100vw - var(--page-max-width)) * -1 / 2 + 10px), 0px);
    }
  }
}

.offer-promo {
  position: relative;
}

.voucher-chip {
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
  border-radius: 9px;
  padding: 12px;
  background-color: var(--color-branding);
  font-weight: 600;
  font-size: var(--font-size-h2);
  color: white;
  text-transform: uppercase;

  @media ($sm-min) {
    top: 20px;
    right: 20px;
    padding: 18px;
  }

  @media ($md-min) {
    top: 40px;
    right: 40px;
    padding: 30px;
  }
}

.panier-bleu {
  position: absolute;
  bottom: -8px;
  left: var(--gap-page-side);
  height: 40px;

  @media ($sm-min) {
    bottom: -10px;
    height: 50px;
  }
}

.offer-content {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--gap-xxlarge) var(--gap-page-side);

  @media ($sm-min) {
    flex-flow: row nowrap;

    .compact & {
      flex-flow: row wrap;
    }
  }

  @media ($md-min) {
    .compact & {
      flex-flow: row nowrap;
    }
  }
}

.info-section {
  width: 100%;
  flex: 1 1 auto;

  :last-child {
    margin-bottom: 0;
  }

  > * {
    margin-bottom: var(--gap-xxlarge);
    @media ($sm-min) {
      margin-bottom: var(--gap);
    }
  }

  @media ($md-min) {
    width: auto;
  }
}

.offer-title {
  margin-bottom: var(--gap);
  font-weight: 400;
  color: var(--color-branding);
  @media ($sm-min) {
    margin-bottom: 0;
  }
}

.offer-description {
  opacity: 0.65;
}

.action-section {
  margin-top: var(--gap-xxlarge);
  text-align: center;

  ::v-deep > * {
    &:not(:first-child) {
      margin-top: var(--gap-xsmall);
    }
  }

  ::v-deep .offer-label {
    margin-top: var(--gap-small);
    margin-bottom: 0;
    text-align: right;
  }

  @media ($sm-min) {
    margin-left: var(--gap);
    margin-top: 0;
    text-align: left;

    .compact & {
      flex: 1 0 auto;
      margin-left: 0;
      margin-top: var(--gap);
    }
  }

  @media ($md-min) {
    .compact & {
      flex: 0 0 auto;
      margin-left: var(--gap);
      margin-top: 0;
    }
  }
}

.restricted-to-members-badge-large-on-top-left {
  position: absolute;
  top: 0;
  left: 0;

  @media ($sm-min) {
    padding: 12px 24px;
    font-size: 32px;
    line-height: 38px;
  }
}

.compact-img-container {
  position: relative;
  align-self: center;
  margin-top: var(--gap);

  @media ($sm-min) {
    align-self: flex-start;
    margin-left: var(--gap);
  }

  @media ($md-min) {
    margin-left: var(--gap);
    margin-right: calc(var(--gap-page-side) * -1);
    margin-top: calc(var(--gap) * -1);
  }

  .panier-bleu {
    bottom: -6px;
    height: 30px;
    left: var(--gap);
  }
}

.compact-img {
  display: block;
  max-width: 100%;

  @media ($sm-min) {
    max-width: 322px;
    max-height: 209px;
  }
}
</style>
