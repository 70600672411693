<template>
  <fragment>
    <div v-for="(spacePartnerId, index) of consumerGiftCardVoucherPartnerIds" :key="`partner-id-${index}`" class="space-section">
      <account-space-header class="account-space-header" :partner_id="spacePartnerId" />
      <account-consumer-gift-card-voucher
        v-for="(voucher, index) of accountConsumerGiftCardVouchersBySpace[spacePartnerId]"
        :key="`voucher-${consumerGiftCardVoucherPartnerIds}-${index}`"
        :voucher="voucher"
        @refreshGiftCardVouchers="refreshGiftCardVouchers"
        @OfferDetail="navigateOfferDetail(voucher.offer_id, spacePartnerId, voucher.publisher_id)"
      />
    </div>
  </fragment>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import AccountConsumerGiftCardVoucher from './components/AccountConsumerGiftCardVoucher.vue';
import AccountSpaceHeader from './components/AccountSpaceHeader.vue';
import _keys from 'lodash/keys';

export default {
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
    };
  },
  components: { AccountSpaceHeader, AccountConsumerGiftCardVoucher },
  name: 'account-space-consumer-gift-card-section',
  props: {
    accountConsumerGiftCardVouchersBySpace: {
      type: Object,
      required: false,
    },
  },
  computed: {
    consumerGiftCardVoucherPartnerIds() {
      return _keys(this.accountConsumerGiftCardVouchersBySpace);
    },
  },
  methods: {
    refreshGiftCardVouchers() {
      this.executeContent({ action: 'account/getConsumerGiftCardVouchers' });
    },
    navigateOfferDetail(offer_id, partner_id, publisher_id) {
      if (partner_id) {
        this.executeContent(
          {
            action: 'space/getSpaceByPartnerId',
            success: (s) => {
              this.$router.pushNoDuplicate({ name: 'spaceOfferDetailSmallUrl', params: { space: s.slug, offer_id, publisher_id, _position: { x: 0, y: window.scrollY } } });
            },
          },
          partner_id,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.space-section {
  display: flex;
  flex-direction: column;
  padding: var(--gap-large) var(--gap) var(--gap);
  border: var(--border-thin) solid var(--color-border);
  margin: var(--gap) var(--gap-page-side);
}

.account-space-header {
  margin-bottom: var(--gap-xlarge);
}

.account-consumer-gift-card-voucher {
  margin-bottom: var(--gap-large);

  &:last-child {
    margin-bottom: 0;
  }
}

.space-section-empty {
  text-align: center;
  padding: var(--gap-large);
}
</style>
