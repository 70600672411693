<template>
  <div class="success-bonus-offer-no-card">
    <h2>{{ $t('components.success_bonus_offer_no_card.title') }}</h2>
    <p class="h-text-3">{{ $t('components.success_bonus_offer_no_card.sub_title') }}</p>
    <h-link @click="closeHeaderDrawer">{{ $t('components.success_bonus_offer_no_card.cta_close') }}</h-link>
    <overlay-request-card :redirect-link="cardApplyRedirectLink" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OverlayRequestCard from '../../components/OverlayRequestCard.vue';

export default {
  components: { OverlayRequestCard },
  name: 'success-bonus-offer-no-card',
  computed: {
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_offer_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_offer_label', 'en'),
      };
    },
  },
  methods: {
    ...mapActions('overlay', ['closeHeaderDrawer']),
  },
};
</script>

<style lang="scss" scoped>
.success-bonus-offer-no-card {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  .v-btn {
    align-self: flex-start;
  }
}
</style>
