<template>
  <h-dialog ref="dialog" max-width="680" @close="closeActivateCardModal" :title="parseDialogTitle" :closing-x="canCloseDialog" :persistent="!canCloseDialog">
    <template v-slot:activator="{ on, attrs }">
      <h-btn v-on="on" v-bind="attrs" inverted color="primary" class="open-modal-btn">{{ $t('components.account_info_bar_activate_card.action_title') }}</h-btn>
    </template>

    <div v-if="!activationSuccess">
      <p class="h-text-2">{{ $t('components.account_info_bar_activate_card.modal_text') }}</p>

      <h-form ref="activateForm" v-model="valid">
        <hello-form-error />

        <h-text-field
          :rules="cardActivationCodeRules"
          type="tel"
          maxlength="6"
          class="activation-filter"
          v-maska="'######'"
          :label="$t('components.account_info_bar_activate_card.modal_input_label')"
          :hint="$t('components.account_info_bar_activate_card.modal_input_hint')"
          v-model="activationCode"
        />

        <h-action-layout>
          <h-btn large class="activate-card-btn" @click="activateCard" :loading="working" :disabled="working || !valid">{{ $t('components.account_info_bar_activate_card.modal_action') }}</h-btn>
        </h-action-layout>
      </h-form>
    </div>

    <div v-if="activationSuccess">
      <div class="success-message">
        <v-icon class="success-icon" color="success">mdi-check-circle-outline</v-icon>

        <div class="h-text-1">
          <p>{{ $t('components.account_info_bar_activate_card.modal_success_text') }}</p>
        </div>
      </div>

      <v-stepper :value="currentStep" non-linear>
        <v-stepper-header class="stepper-container">
          <v-stepper-step complete :color="parseStepColor(1)" step="1"> </v-stepper-step>

          <v-divider :color="parseStepColor(2)"></v-divider>

          <v-stepper-step complete :color="parseStepColor(2)" step="2"> </v-stepper-step>

          <v-divider :color="parseStepColor(3)"></v-divider>

          <v-stepper-step complete :color="parseStepColor(3)" step="3"> </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="info-content">
              <div class="left-col">
                <img class="theme-image" src="@/assets/activation-pin.svg" :alt="$t('components.account_info_bar_activate_card.pin.alt_image')" />
              </div>

              <div class="right-col">
                <h4>{{ $t('components.account_info_bar_activate_card.pin.title') }}</h4>

                <p class="h-text-1">{{ $t('components.account_info_bar_activate_card.pin.text') }}</p>

                <p>
                  <span class="pin-label h-text-1">{{ $t('components.account_info_bar_activate_card.pin.pin_label') }}</span
                  ><span class="pin-value">1234</span>
                </p>
              </div>
            </div>

            <div class="btn-section">
              <h-btn large @click="nextStep">{{ $t('components.account_info_bar_activate_card.pin.next') }}</h-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="info-content">
              <div class="left-col">
                <img class="theme-image" src="@/assets/activation-available-stores.svg" :alt="$t('components.account_info_bar_activate_card.available_stores.alt_image')" />
              </div>

              <div class="right-col">
                <h4>{{ $t('components.account_info_bar_activate_card.available_stores.title') }}</h4>

                <p class="h-text-1">{{ $t('components.account_info_bar_activate_card.available_stores.text') }}</p>
              </div>
            </div>

            <div class="btn-section">
              <h-btn large outlined @click="previousStep">{{ $t('components.account_info_bar_activate_card.available_stores.previous') }}</h-btn>

              <h-btn large @click="nextStep">{{ $t('components.account_info_bar_activate_card.available_stores.next') }}</h-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="info-content">
              <div class="left-col">
                <img class="theme-image" src="@/assets/activation-recharge.svg" :alt="$t('components.account_info_bar_activate_card.recharge.alt_image')" />
              </div>

              <div class="right-col">
                <h4>{{ $t('components.account_info_bar_activate_card.recharge.title') }}</h4>

                <p class="h-text-1">{{ $t('components.account_info_bar_activate_card.recharge.text') }}</p>
              </div>
            </div>

            <div class="btn-section">
              <h-btn large outlined @click="previousStep">{{ $t('components.account_info_bar_activate_card.recharge.previous') }}</h-btn>
              <h-btn large @click="close">{{ $t('components.account_info_bar_activate_card.recharge.next') }}</h-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { validateInvalidActivationCode, validateRequired } from '@/composables/validations';
import { mapActions } from 'vuex';
import HBtn from '@/components/system/buttons/HBtn.vue';

export default {
  name: 'activate-card-modal',
  components: { HBtn },
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      execute,
      executeForm,
      working,
      validateRequired,
      validateInvalidActivationCode,
    };
  },
  data() {
    return {
      currentStep: 1,

      valid: false,
      activationSuccess: false,
      showPin: false,
      cardPin: '',
      activationCode: null,
      cardActivationCodeRules: [this.validateRequired('activation_code'), this.validateInvalidActivationCode()],
    };
  },
  props: {
    publicToken: {
      type: String,
      required: true,
    },
  },
  computed: {
    parseDialogTitle() {
      if (!this.activationSuccess) {
        return this.$t('components.account_info_bar_activate_card.modal_title');
      } else {
        return this.$t('components.account_info_bar_activate_card.modal_title_activated');
      }
    },
    canCloseDialog() {
      return !this.activationSuccess;
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    setCurrentStep(step) {
      this.currentStep = step;
    },
    nextStep() {
      this.currentStep += 1;
    },
    previousStep() {
      this.currentStep -= 1;
    },
    parseStepColor(step) {
      if (this.currentStep < step) {
        return '#E0E0E0';
      } else {
        return '#2E3191';
      }
    },
    activateCard() {
      this.executeForm(
        {
          action: 'account/activateCard',
          success: (result) => {
            this.cardPin = result;
            this.activationSuccess = true;
          },
        },
        {
          activation_code: this.activationCode,
          card_public_token: this.publicToken,
        },
      );
    },
    close() {
      this.$refs.dialog.close();
    },
    closeActivateCardModal() {
      this.execute(
        {
          action: 'account/getAccount',
        },
        null,
      );
      this.clearError();

      // Try catch required because may be not present because of the v-if
      try {
        this.activationCode = null;
        this.cardPin = '';
        this.showPin = false;
        this.activationSuccess = false;
        this.$refs.activateForm.validationReset();
      } catch (err) {}
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__content {
  padding: 0;

  @media ($sm-min) {
    padding: var(--gap);
    padding-bottom: 0;
    padding-top: 0;
  }
}

::v-deep .v-stepper__header {
  height: 64px;
}

::v-deep .v-stepper__step {
  padding: 10px;
}

::v-deep .v-stepper__step__step {
  height: 10px;
  width: 10px;
  min-width: 10px;
  margin-right: 0px !important;

  font-size: 0;
}

::v-deep .v-stepper__step__step .v-icon::before {
  content: '';
}

.stepper-container {
  width: 100px;
  margin: auto;
}

.pin-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.pin-label {
  color: var(--color-text-tertiary);
}

.pin-value {
  font-size: var(--font-size-h3);
  font-weight: 700;
}

.activation-filter {
  width: 400px;
  margin: auto;
  padding-top: var(--gap);
  padding-bottom: var(--gap);
}

.success-message {
  padding-top: var(--gap);
  display: flex;
  gap: var(--gap-xsmall);
  color: var(--color-success);
}

.success-icon {
  align-items: start;
}
.activate-card-btn {
  min-width: 184px !important;
}

.info-content {
  display: block;
  @media ($sm-min) {
    display: flex;
  }
}

.left-col {
  text-align: center;
}

.left-col img {
  width: 90px;
  height: 90px;

  @media ($sm-min) {
    width: 160px;
    height: 160px;
  }
}

.right-col {
  display: flex;
  flex-direction: column;

  padding-bottom: var(--gap);

  @media ($sm-min) {
    padding-bottom: 0;

    display: block;
    text-align: left;
    flex-grow: 1;
  }
}

h4 {
  align-self: center;
  padding-bottom: var(--gap-xxsmall);
  border-bottom: 2px solid var(--color-text-primary);
  display: inline-block;
  margin-bottom: var(--gap);
}

.btn-section {
  padding-top: var(--gap-large);

  @media ($sm-min) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.btn-section > * {
  margin-top: var(--gap-xsmall);

  width: 100%;

  @media ($sm-min) {
    margin-left: var(--gap-xsmall);
    margin-right: var(--gap-xsmall);
    width: 184px;
  }
}
</style>
