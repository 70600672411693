export default {
  name: 'url-provider',
  init: async ({ Vue }) => {
    Vue.prototype.$urlProvider = {
      HelloFaqFr: 'https://www.hellocard.ca/support?lang=fr',
      HelloFaqEn: 'https://www.hellocard.ca/support?lang=en',
      HelloCorpoFaqFr: 'https://www.hellocard.ca/corpo?lang=fr',
      HelloCorpoFaqEn: 'https://www.hellocard.ca/corpo?lang=en',
      HelloTermAndConditionFr: 'https://cdn.hellocard.ca/legal/tc-fr.html',
      HelloTermAndConditionEn: 'https://cdn.hellocard.ca/legal/tc-en.html',
      HelloCorpoTermAndConditionFr: 'https://cdn.hellocard.ca/legal/corpo-fr.html',
      HelloCorpoTermAndConditionEn: 'https://cdn.hellocard.ca/legal/corpo-en.html',
      HelloPrivacyPolicyFr: 'https://cdn.hellocard.ca/legal/pp-fr.html',
      HelloPrivacyPolicyEn: 'https://cdn.hellocard.ca/legal/pp-en.html',
      HelloCardholderInstantCardAgreementEn: 'https://cdn.hellocard.ca/legal/instant-cha-en.html',
      HelloCardholderInstantCardAgreementFr: 'https://cdn.hellocard.ca/legal/instant-cha-fr.html',
      HelloElectronicCommunicationConsentAgreementEn: 'https://cdn.hellocard.ca/legal/instant-ecca-en.html',
      HelloElectronicCommunicationConsentAgreementFr: 'https://cdn.hellocard.ca/legal/instant-ecca-fr.html',
      HelloBusinessEn: 'https://www.hellonetwork.ca/?lang=en',
      HelloBusinessFr: 'https://www.hellonetwork.ca/?lang=fr',
      HelloCardEn: 'https://www.hellocard.ca/?lang=en',
      HelloCardFr: 'https://www.hellocard.ca/?lang=fr',
      HelloSupportMyMoneyFr: 'https://support.hellocard.ca/fr/articles/3488534-a-quoi-sert-le-compte-mon-argent',
      HelloSupportMyMoneyEn: 'https://support.hellocard.ca/en/articles/3488534-what-is-the-my-cash-account',
      GoogleStoreAppFr: 'https://play.google.com/store/apps/details?id=ca.hellonetwork.hello&hl=fr',
      GoogleStoreAppEn: 'https://play.google.com/store/apps/details?id=ca.hellonetwork.hello',
      AppleStoreAppFr: 'https://apps.apple.com/fr/app/lapplication-mobile-hello/id1484811402',
      AppleStoreAppEn: 'https://apps.apple.com/app/lapplication-mobile-hello/id1484811402',
      GenerateHelloImageUrl: (image) => `${process.env.VUE_APP_HELLO_IMAGE_BASE_URL}/${image}`,
      GenerateHelloApplyUrl: (partner, context, language) => `${process.env.VUE_APP_HELLO_APPLY_CONSOLE_URL}/${partner}/application-context?context=${context}&locale=${language}`,
    };
  },
};
