var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-pay-and-get-editable-input-decimal"},[_c('h-text-field',{directives:[{name:"maska",rawName:"v-maska",value:({
      mask: 'I*.DDE',
      tokens: {
        I: { pattern: /[0-9]/, repeated: true },
        D: { pattern: /[0-9]/, optional: true, repeated: false },
        E: { pattern: /$/, optional: false, repeated: false },
      },
    }),expression:"{\n      mask: 'I*.DDE',\n      tokens: {\n        I: { pattern: /[0-9]/, repeated: true },\n        D: { pattern: /[0-9]/, optional: true, repeated: false },\n        E: { pattern: /$/, optional: false, repeated: false },\n      },\n    }"}],ref:"payAndGetEditableInput",class:_vm.currentLocale === 'en' ? 'amount-field-en' : 'amount-field-fr',attrs:{"label":_vm.label,"hint":_vm.hint,"persistent-hint":"","type":"text","inputmode":"numeric","rules":_vm.rules(),"value":_vm.value},on:{"input":_vm.input,"update":function (val) { return _vm.$emit('update', val); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }