<template>
  <div :style="{ background: backgroundColor }" class="account-info-bar-base">
    <div class="information-section">
      <h-information-label class="element" v-if="displayApplicationId" label-color="white" value-color="white" :label="$t('components.account_info_bar_base.my_application_id')" :value="accountApplicationId"></h-information-label>
      <h-information-label class="element" v-if="displayCardToken" label-color="white" value-color="white" :label="labelPublicToken" :value="publicToken | publicToken"></h-information-label>
      <h-information-label v-if="displayApplicationStatus" label-color="white" value-color="white" :label="$t('components.account_info_bar_base.status')" :value="$t(`components.account_info_bar_base.status_value.${accountApplicationStatus}`)" />
      <h-information-label class="element" v-if="displayCardStatus" label-color="white" value-color="white" :label="$t('components.account_info_bar_base.status')" :value="$t(`components.account_info_bar_base.status_value.${cardStatus}`)" />
    </div>
    <slot />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';

export default {
  setup(props, { root }) {
    const { working } = safeExecute(root.$store);
    return { working };
  },
  name: 'account-info-bar-base',
  props: {
    backgroundColor: {
      type: String,
      default: '#2E3191',
    },
    displayApplicationStatus: {
      type: Boolean,
      default: false,
    },
    displayCardStatus: {
      type: Boolean,
      default: false,
    },
    displayApplicationId: {
      type: Boolean,
      default: false,
    },
    displayCardToken: {
      type: Boolean,
      default: false,
    },
    labelPublicToken: {
      type: String,
      default: '',
    },
    publicToken: {
      type: String,
      default: '',
    },
    cardStatus: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('account', ['accountApplicationStatus', 'accountApplicationId']),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .open-modal-btn {
  width: 100%;
  margin-top: var(--gap-xxlarge);

  @media ($sm-min) {
    width: unset;
    margin-top: unset;
  }
}

.account-info-bar-base {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: var(--gap-page-side);
  background-color: var(--color-primary);
  color: var(--color-ultra-light);
  justify-content: space-between;
}

.information-section {
  display: flex;

  width: 100%;
  @media ($sm-min) {
    width: unset;
  }
}

.element {
  margin-right: var(--gap-xlarge);
  width: 50%;
  @media ($sm-min) {
    width: unset;
  }
}
</style>
