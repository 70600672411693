<template>
  <div class="h-pay-and-get-input">
    <div class="main-control">
      <v-btn v-if="!isPlusMinusControlHidden" @click="minusAction" :disabled="disableMinus" icon class="plus-minus-btn">
        <v-icon color="primary" size="35px">mdi-minus-circle-outline</v-icon>
      </v-btn>

      <div class="amount" :class="{ isInError: isInError }">{{ amount }}</div>

      <v-btn v-if="!isPlusMinusControlHidden" @click="plusAction" :disabled="disablePlus" icon class="plus-minus-btn">
        <v-icon color="primary" size="35px">mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>

    <div class="text">{{ label }}</div>
    <div class="notice">{{ notice }}</div>
    <h-pay-and-get-input-modal v-if="notice" :title="hintsTitle" :html-content="hintsHtml" />
  </div>
</template>

<script>
export default {
  name: 'h-pay-and-get-input',
  props: {
    closingDate: {
      type: String,
      default: null,
    },
    hintsTitle: {
      type: String,
      default: 'a hints title',
    },
    hintsHtml: {
      type: String,
      default: 'a hints html content',
    },
    amount: {
      type: String,
      default: 'XX$%',
    },
    label: {
      type: String,
      default: 'Label',
    },
    notice: {
      type: String,
      default: '',
    },
    disablePlus: {
      type: Boolean,
      default: false,
    },
    disableMinus: {
      type: Boolean,
      default: false,
    },
    plusAction: {
      type: Function,
      default: () => {},
    },
    minusAction: {
      type: Function,
      default: () => {},
    },
    isInError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPlusMinusControlHidden() {
      return this.disablePlus && this.disableMinus;
    },
  },
};
</script>

<style lang="scss" scoped>
.h-pay-and-get-input {
  text-align: center;
}

.main-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  gap: 2px;

  @media ($md-min) {
    gap: 8px;
  }
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  height: 68px;
  border: var(--border-thin) solid var(--color-primary);
  border-radius: 7px;
  font-weight: 600;
  font-size: 48px;
  line-height: 1;
  color: var(--color-primary);

  &.isInError {
    border-color: var(--color-error);
  }

  @media ($md-min) {
    min-width: 180px;
    height: 75px;
    font-size: 52px;
  }
}

.plus-minus-btn.v-btn--disabled {
  .v-icon {
    color: var(--color-primary) !important;
    opacity: 0.5;
  }
}

.text {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--color-primary);
}

.notice {
  margin-bottom: 12px;
  font-style: italic;
  font-size: 13px;
  color: var(--color-text-secondary);
}

::v-deep .activator {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
</style>
