<template>
  <div class="consumer-gift-card-amount-selector">
    <h-btn class="amount-selector-button" v-for="(amount, index) of amounts" :key="`amount-${index}`" large color="var(--color-branding)" min-width="86" :outlined="currentSelected !== index" rounded @click="() => clickToggle(index)">
      {{ label(amount) }}
    </h-btn>
  </div>
</template>

<script>
export default {
  name: 'consumer-gift-card-amount-selector',
  props: {
    amounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSelected: null,
    };
  },
  methods: {
    label(amount) {
      if (!isNaN(amount)) {
        return this.$options.filters.currencySymbol(this.$options.filters.currency(amount));
      }
      return this.$t(`components.consumer_gift_card_amount_selector.${amount}`);
    },
    clickToggle(index) {
      if (this.currentSelected !== index) {
        this.currentSelected = index;
        if (!isNaN(this.amounts[index])) {
          this.$emit('update', this.amounts[index]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-amount-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);

  @media ($sm-min) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-xsmall);
  }
}

.amount-selector-button {
  border-radius: var(--border-radius);
}
</style>
