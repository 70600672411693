<template>
  <h-dialog ref="dialog" v-bind="$attrs" v-on="$listeners" :max-width="showApplyInstantCardForm ? '680' : '828'" content-class="card-type-modal" :title="determineTitle" class="card-type-modal" @close="onClose">
    <div v-if="showSelectCardTypes">
      <p class="h-text-1">{{ $t('components.card_type_selection_modal.subtitle') }}</p>

      <hello-target-error class="mb-2 mt-2" target="store_error" />

      <div v-if="$vuetify.breakpoint.smAndDown">
        <div class="nav-wrap">
          <a href="#" :class="{ circle: true, active: swapToClassicActive }" @click="swapToClassic" role="button"></a>
          <a href="#" :class="{ circle: true, active: swapToInstantActive }" @click="swapToInstant" role="button"></a>
        </div>

        <v-tabs-items v-model="tabModel">
          <v-tab-item>
            <card-summary-mobile type="classic" :properties="classicProperties" @choose="chooseClassic" :context-ready="context_ready" />
          </v-tab-item>

          <v-tab-item>
            <card-summary-mobile type="instant" :properties="instantProperties" @choose="chooseInstant" />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div v-else>
        <card-summary-desktop :classic-properties="classicProperties" :instant-properties="instantProperties" :context-ready="context_ready" @chooseInstant="chooseInstant" @chooseClassic="chooseClassic" />
      </div>

      <p>{{ $t('components.card_type_selection_modal.detail') }}</p>
    </div>

    <instant-card-apply v-if="showApplyInstantCardForm" ref="instantCardApply" @close="closeApply" @success="instantCardApplySuccess" />

    <success-instant-card-application v-if="showApplyInstantCardSuccess && displaySuccessInCardTypeSelectionModal" />
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import CardSummaryDesktop from './CardSummaryDesktop.vue';
import CardSummaryMobile from './CardSummaryMobile.vue';
import InstantCardApply from '@/components/instant-card/InstantCardApply';
import SuccessInstantCardApplication from '@/components/instant-card/components/SuccessInstantCardApplication';
import { mapGetters } from 'vuex';

const CLASSIC = 0;
const INSTANT = 1;

export default {
  name: 'card-type-selection-modal',
  components: { CardSummaryDesktop, InstantCardApply, SuccessInstantCardApplication, CardSummaryMobile },
  setup(props, { root }) {
    const { execute, executeTarget } = safeExecute(root.$store);
    return {
      executeTarget,
      execute,
    };
  },
  props: {
    displaySuccessInCardTypeSelectionModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSelectCardTypes: true,
      showApplyInstantCardForm: false,
      showApplyInstantCardSuccess: false,

      tabModel: CLASSIC,
      context_ready: false,

      redirectLink: {
        url: window.location.href,
        label_fr: this.$t('components.card_type_selection_modal.redirect_link.return_to_offer_label', 'fr'),
        label_en: this.$t('components.card_type_selection_modal.redirect_link.return_to_offer_label', 'en'),
      },
      classicProperties: [
        {
          name: 'components.card_type_selection_modal.headings.rechargeable',
          value: 'Y',
        },
        {
          name: 'components.card_type_selection_modal.headings.personalized',
          value: 'Y',
        },
        {
          name: 'components.card_type_selection_modal.headings.open_wallet',
          value: 'Y',
          hint: 'components.card_type_selection_modal.headings.open_wallet_hint',
        },
        {
          name: 'components.card_type_selection_modal.headings.pin',
          value: 'Y',
        },
        {
          name: 'components.card_type_selection_modal.headings.limit',
          value: 23000,
        },
        {
          name: 'components.card_type_selection_modal.headings.replacement_cost',
          value: 5,
        },
        {
          name: 'components.card_type_selection_modal.headings.shipping_time',
          value: this.$t('components.card_type_selection_modal.shipping_time_value'),
        },
      ],
      instantProperties: [
        {
          name: 'components.card_type_selection_modal.headings.rechargeable',
          value: 'Y',
        },
        {
          name: 'components.card_type_selection_modal.headings.personalized',
          value: 'N',
        },
        {
          name: 'components.card_type_selection_modal.headings.open_wallet',
          value: 'N',
          hint: 'components.card_type_selection_modal.headings.open_wallet_hint',
        },
        {
          name: 'components.card_type_selection_modal.headings.pin',
          value: 'N',
        },
        {
          name: 'components.card_type_selection_modal.headings.limit',
          value: 1000,
        },
        {
          name: 'components.card_type_selection_modal.headings.replacement_cost',
          value: 10,
        },
        {
          name: 'components.card_type_selection_modal.headings.shipping_time',
          value: this.$t('components.card_type_selection_modal.shipping_time_value'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    swapToClassicActive() {
      return this.tabModel === CLASSIC;
    },
    swapToInstantActive() {
      return this.tabModel === INSTANT;
    },
    determineTitle() {
      if (this.showSelectCardTypes) {
        return this.$t('components.card_type_selection_modal.title');
      }

      if (this.showApplyInstantCardForm) {
        return this.$t('components.instant_card_apply.title');
      }

      return '';
    },
  },
  methods: {
    reloadAccount() {
      if (this.showApplyInstantCardSuccess) {
        this.execute(
          {
            action: 'account/getAccount',
          },
          null,
        );
      }
    },
    onClose() {
      this.reloadAccount();
      this.showSelectCardTypes = true;
      this.showApplyInstantCardForm = false;
      this.showApplyInstantCardSuccess = false;
      this.tabModel = CLASSIC;
      this.context_ready = false;
    },
    swapToClassic() {
      this.tabModel = CLASSIC;
    },
    swapToInstant() {
      this.tabModel = INSTANT;
    },
    chooseClassic() {
      this.createClassicCardApplicationAndOpenNewTab();
    },
    chooseInstant() {
      this.showSelectCardTypes = false;
      this.showApplyInstantCardForm = true;
      this.showApplyInstantCardSuccess = false;
    },
    closeApply() {
      this.showSelectCardTypes = true;
      this.showApplyInstantCardForm = false;
      this.showApplyInstantCardSuccess = false;
    },
    instantCardApplySuccess() {
      this.showSelectCardTypes = false;
      this.showApplyInstantCardForm = false;
      this.showApplyInstantCardSuccess = true;
      this.$emit('success');
    },
    async createClassicCardApplicationAndOpenNewTab() {
      this.context_ready = true;
      await this.executeTarget(
        {
          target: 'generic',
          action: 'account/createCardApplicationContext',
          success: (context) => {
            switch (context.result_code) {
              case 'application':
                this.context_ready = true;
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              case 'join':
                this.context_ready = true;
                window.open(this.$urlProvider.GenerateHelloApplyUrl(context.partner_id, context.context, this.currentLocale), '_self').focus();
                break;
              default:
                this.errorModalIsOpen = true;
                this.routeToMyAccount = true;
            }
            this.context_ready = true;
          },
        },
        {
          return_url: this.redirectLink.url,
          return_link_label_fr: this.redirectLink.label_fr,
          return_link_label_en: this.redirectLink.label_en,
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-type-modal {
  color: var(--color-text-primary);
}

.card-summary-mobile,
.card-summary-desktop {
  margin-bottom: var(--gap-xxlarge);
}

.success-instant-card-application {
  margin-top: 46px;

  @media ($sm-min) {
    overflow: hidden;
    margin-top: 56px;
  }
}

.nav-wrap {
  text-align: center;
}

.circle {
  background-color: rgba(46, 49, 145, 0.1);
  border-color: rgba(46, 49, 145, 0.1);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
  color: var(--color-ultra-light);
  display: inline-block;
  line-height: 1.2;
  font-size: 16px;
  margin: var(--gap);

  &.branding-color {
    border-color: var(--color-branding);
    background-color: var(--color-branding);
  }

  &.active,
  &:hover {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}
</style>
