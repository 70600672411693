<template>
  <div class="corpo-email-validation-required">
    <div class="frame text-center">
      <div v-if="!emailVerified">
        <inline-svg class="img" :src="require('@/assets/email_validation_required.svg')" />

        <h4 class="header">{{ $t('components.corpo_email_verification_required.title_pending') }}</h4>
        <p class="text">{{ $t('components.corpo_email_verification_required.text') }}</p>

        <div>
          <h-link class="send-link" bold @click="sendVerificationEmail">{{ $t('components.corpo_email_verification_required.send_link') }}</h-link>
        </div>
      </div>

      <div v-if="emailVerified">
        <inline-svg class="img" :src="require('@/assets/email_validation_success.svg')" />

        <h4 class="header">{{ $t('components.corpo_email_verification_required.title_success') }}</h4>

        <div class="pb-4">
          <h-btn large @click="proceedToOrder">{{ $t('components.corpo_email_verification_required.order_voucher') }}</h-btn>
        </div>
      </div>

      <div v-if="pollingTimedOut && !emailVerified" class="btn-container">
        <h-btn @click="doManualPoll" :loading="working">{{ $t('components.corpo_email_verification_required.manual_verify') }}</h-btn>
      </div>

      <account-email-verification-modal v-model="verificationModalIsOpen" />
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import AccountEmailVerificationModal from '@/components/account/components/AccountEmailVerificationModal';

export default {
  setup(props, { root }) {
    const { executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
    };
  },
  name: 'corpo-email-validation-required',
  components: { AccountEmailVerificationModal, InlineSvg },
  async created() {
    if (this.canPoll) {
      this.doPoll = true;
    }
  },
  data() {
    return {
      sleepTimeMs: 5 * 1000,
      maxPollTimeMs: 10 * 60 * 1000,
      pollTimeMs: 0,
      doPoll: false,
      pollingTimedOut: false,
      timeoutId: null,
      verificationModalIsOpen: false,
    };
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  computed: {
    ...mapGetters('account', ['emailVerified', 'verifyingEmail']),
    ...mapGetters('security', ['authenticated']),
    canPoll() {
      return this.authenticated && !this.emailVerified;
    },
    shouldAutoPoll() {
      return this.canPoll && this.pollTimeMs <= this.maxPollTimeMs;
    },
  },
  watch: {
    canPoll() {
      if (this.canPoll) {
        this.doPoll = true;
      }
    },
    doPoll() {
      if (this.doPoll) {
        this.startPolling();
      }
    },
  },

  methods: {
    async startPolling() {
      this.doAutoPoll();
    },
    async doAutoPoll() {
      if (this.canPoll) {
        this.checkEmailVerified(this.autoPollCallback);
        this.pollTimeMs += this.sleepTimeMs;
      }
    },
    async autoPollCallback() {
      if (this.shouldAutoPoll) {
        this.timeoutId = setTimeout(() => {
          this.doAutoPoll();
          this.timeoutId = null;
        }, this.sleepTimeMs);
      } else {
        this.pollingTimedOut = true;
      }
    },
    async doManualPoll() {
      this.checkEmailVerified(() => {});
    },
    proceedToOrder() {
      this.$emit('success');
    },
    async checkEmailVerified(successCallback) {
      if (this.canPoll) {
        return this.executeContent({
          action: 'account/checkEmailVerified',
          success: () => {
            if (successCallback) {
              successCallback();
            }
          },
        });
      }
    },
    sendVerificationEmail() {
      this.executeContent({
        action: 'security/resetEmailVerification',
        success: () => {
          this.verificationModalIsOpen = true;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-link {
  font-size: var(--font-size-text-2);
}

.corpo-email-validation-required {
  padding: var(--gap) var(--gap) 0;
  text-align: center;
}
.img {
  display: block;
  width: 87px;
  margin: 0 auto var(--gap);
}
.header,
.text {
  margin-bottom: var(--gap);
}

.btn-container {
  text-align: center;
  margin-top: var(--gap-xlarge);
}
</style>
