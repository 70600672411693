import Vue from 'vue';
import { secureStorage } from '@/utils/secure-storage';

function generateUUID() {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function generateVisitorUuid() {
  const visitorUuid = generateUUID();
  secureStorage.setItem('hello::visitorUuid', visitorUuid);
  return visitorUuid;
}

export default {
  namespaced: true,
  state: {
    trackingLink: {},
    visitorUuid: secureStorage.getItem('hello::visitorUuid'),
  },
  getters: {
    trackingLink: (state) => state.trackingLink,
    trackingLinkId: (state) => state.trackingLink.id,
    visitorUuid: (state) => state.visitorUuid,
  },
  mutations: {
    trackingLink: (state, { tracking_link_id, offer_id }) => {
      state.trackingLink.id = tracking_link_id;
      state.trackingLink.offerId = offer_id;
    },
    visitorUuid: (state, visitor_uuid) => {
      state.visitorUuid = visitor_uuid;
    },
  },
  actions: {
    setTrackingInfo({ state, commit, dispatch }, { tracking_link_id, offer_id, recaptcha_token }) {
      try {
        let visitorUuid = state.visitorUuid;
        if (!visitorUuid) {
          visitorUuid = generateVisitorUuid();
          commit('visitorUuid', visitorUuid);
        }
        commit('trackingLink', { tracking_link_id, offer_id });

        Vue.prototype.$services.tracking.trackVisit({ tracking_link_id, offer_id, visitor_uuid: visitorUuid, recaptcha_token });
      } catch (error) {
        rg4js('send', {
          error,
          customData: { action: 'trackVisit' },
        });
      }
    },
  },
};
