<template>
  <h-page-not-found-box />
</template>

<script>
import { mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  name: 'page-not-found-view',
  title: (args) => args.$t('page_title.page_not_found'),
  watch: {
    currentLocale() {
      this.$title = this.$t('page_title.page_not_found');
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
