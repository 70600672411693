<template>
  <div class="subscription-status">
    <div class="labels-container">
      <h-label class="h-label" :value="$t('components.subscription_status.plan_fee', { amount: monthlyFee })" :label="$t('components.subscription_status.plan')" />
      <h-label class="h-label" :value="$t(`components.subscription_status.status_options.${subscription.status}`)" :label="$t('components.subscription_status.status')" />
    </div>
    <manage-subscription-modal :space="space" :subscription="subscription" />
    <unsubscribe-modal :space="space" :subscription="subscription" />
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import UnsubscribeModal from '@/components/subscription/UnsubscribeModal';
import ManageSubscriptionModal from '@/components/subscription/ManageSubscriptionModal';

export default {
  setup(props, { root }) {
    const { executeContent, executeTarget, working } = safeExecute(root.$store);

    return {
      executeTarget,
      executeContent,
    };
  },
  name: 'subscription-status',
  components: {
    UnsubscribeModal,
    ManageSubscriptionModal,
  },
  props: {
    subscription: {
      type: Object,
    },
    partner_id: {
      type: String,
    },
  },
  computed: {
    monthlyFee() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.subscriptionPlan.monthly_fee));
    },
  },
  watch: {
    partner_id() {
      if (this.partner_id) {
        const _this = this;
        this.executeContent(
          {
            action: 'space/getSpaceByPartnerId',
            success: (s) => {
              _this.space = s;
            },
          },
          this.partner_id,
        );
      }
    },
  },
  data() {
    if (this.partner_id) {
      const _this = this;
      this.executeContent(
        {
          action: 'space/getSpaceByPartnerId',
          success: (s) => {
            _this.space = s;
          },
        },
        this.partner_id,
      );
    }
    return {
      subscriptionPlan: {},
      space: null,
    };
  },
  async mounted() {
    await this.loadSubscriptionPlan();
  },
  methods: {
    async loadSubscriptionPlan() {
      await this.executeContent(
        {
          action: 'subscription/getPartnerActiveSubscriptionPlan',
          success: (result) => {
            this.subscriptionPlan = result;
          },
        },
        this.subscription.partner_id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.labels-container {
  display: flex;
  margin-bottom: var(--gap-xsmall);
}

.h-label {
  flex-grow: 0;
  margin-right: var(--gap-xxlarge);
}
</style>
