<template>
  <div class="payment-crowdfunding-feature">
    <h-step-header :text="$t('components.payment_crowdfunding_feature.title', { site: currentBrandingName })" />
    <v-radio-group v-model="radios" mandatory :row="$vuetify.breakpoint.smAndUp">
      <v-radio v-for="(option, index) in featuresOptions" :key="index" :value="option.value">
        <template v-slot:label>
          <span>{{ $t(`components.payment_crowdfunding_feature.${option.label_key}.label`) }}</span>
          <span class="caption">{{ $t(`components.payment_crowdfunding_feature.${option.label_key}.text`) }}</span>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'payment-crowdfunding-feature',
  data() {
    return {
      featuresOptions: [
        { label_key: 'option_visible', value: true },
        { label_key: 'option_anonymous', value: false },
      ],
      radios: this.$store.getters['payment/currentPaymentCrowdfundingFeatureVisibleDefaultWhenActive'],
    };
  },
  watch: {
    radios() {
      this.setPaymentCrowdfundingFeatureVisible(!!this.radios);
    },
  },
  computed: {
    ...mapGetters('payment', ['currentPaymentCrowdfundingFeatureVisibleDefaultWhenActive']),
    ...mapGetters('space', ['currentBrandingName']),
  },
  methods: {
    ...mapActions('payment', ['setPaymentCrowdfundingFeatureVisible']),
  },
};
</script>
<style lang="scss" scoped>
.v-radio {
  align-items: flex-start;
  @media ($sm-min) {
    &:not(:last-child) {
      margin-right: var(--gap-xlarge);
    }

    &:last-child {
      margin: 0;
    }
  }
}
::v-deep .v-input--radio-group__input {
  justify-content: center;
}
::v-deep .v-label {
  flex-direction: column;
  align-items: flex-start !important;
}
::v-deep .v-messages {
  min-height: 0;
}
</style>
