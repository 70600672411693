<template>
  <div class="app-store-badges">
    <a class="badge-link" :href="localizedAppleStoreUrl" target="_blank">
      <img class="badge-img" :src="localizedAppleStoreImage" :alt="$t('components.app_stores_badges.download_apple')" />
    </a>
    <a class="badge-link" :href="localizedGooglePlayUrl" target="_blank">
      <img class="badge-img" :src="localizedGooglePlayImage" :alt="$t('components.app_stores_badges.download_google')" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'app-store-badges',
  props: {
    column: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    localizedAppleStoreImage() {
      return this.currentLocale === 'en' ? require('@/assets/apple_store_badge_en.svg') : require('@/assets/apple_store_badge_fr.svg');
    },
    localizedAppleStoreUrl() {
      return this.currentLocale === 'en' ? this.$urlProvider.AppleStoreAppEn : this.$urlProvider.AppleStoreAppFr;
    },
    localizedGooglePlayImage() {
      return this.currentLocale === 'en' ? require('@/assets/google_play_badge_en.png') : require('@/assets/google_play_badge_fr.png');
    },
    localizedGooglePlayUrl() {
      return this.currentLocale === 'en' ? this.$urlProvider.GoogleStoreAppEn : this.$urlProvider.GoogleStoreAppFr;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-store-badges {
  display: flex;
  flex-direction: column;
  margin: var(--gap) 0;
  align-items: flex-start;

  @media ($sm-min) {
    flex-direction: row;
  }
}

.badge-link {
  margin-bottom: var(--gap);

  &:last-child {
    margin-bottom: 0;
  }

  @media ($sm-min) {
    margin-bottom: 0;
    margin-right: var(--gap);

    &:last-child {
      margin-right: 0;
    }
  }
}

.badge-img {
  display: block;
  width: 160px;

  @media ($sm-min) {
    width: auto;
    height: 48px;
  }
}
</style>
