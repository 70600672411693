<template>
  <div>
    <div :style="{ color: labelColor }">{{ label }}</div>
    <div class="h-heading-h2" :style="{ color: valueColor }">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-information-label',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: String,
      default: 'value',
    },
    labelColor: {
      type: String,
    },
    valueColor: {
      type: String,
    },
  },
};
</script>
