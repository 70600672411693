<template>
  <h-dialog class="consumer-gift-card-info-modal" ref="dialog" max-width="680" :title="$t('components.consumer_gift_card_info_modal.modal_title')">
    <template v-slot:activator="{ on, attrs }">
      <h-link :large="largeLink" v-on="on" v-bind="attrs" target="_blank">{{ $t('components.consumer_gift_card_info_modal.action_title') }}</h-link>
    </template>

    <ol class="info-list">
      <li><span class="circle" :class="{ 'branding-color': useBrandingColor }">1</span>{{ $t('components.consumer_gift_card_info_modal.modal_text_1') }}</li>
      <li><span class="circle" :class="{ 'branding-color': useBrandingColor }">2</span>{{ $t('components.consumer_gift_card_info_modal.modal_text_2') }}</li>
      <li><span class="circle" :class="{ 'branding-color': useBrandingColor }">3</span>{{ $t('components.consumer_gift_card_info_modal.modal_text_3') }}</li>
      <li><span class="circle" :class="{ 'branding-color': useBrandingColor }">4</span>{{ $t('components.consumer_gift_card_info_modal.modal_text_4') }}</li>
    </ol>

    <template #action-footer v-if="showAction">
      <h-btn large @click="goToPurchaseGiftCard()" :loading="working" :disabled="!consumerPurchasable" :color="useBrandingColor ? 'var(--color-branding)' : 'primary'">{{
        $t('components.consumer_gift_card_info_modal.offer_consumer_gift_card_action')
      }}</h-btn>
      <h-btn large @click="closeConsumerGiftCardInfoModal" :loading="working" outlined inverted :color="useBrandingColor ? 'var(--color-branding)' : 'primary'">{{ $t('components.consumer_gift_card_info_modal.cancel_action') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions } from 'vuex';

export default {
  name: 'consumer-gift-card-info-modal',
  setup(props, { root }) {
    const { working } = safeExecute(root.$store);
    return {
      working,
    };
  },
  props: {
    consumerPurchasable: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    largeLink: {
      type: Boolean,
      default: false,
    },
    useBrandingColor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    closeConsumerGiftCardInfoModal() {
      this.$refs.dialog.close();
    },
    goToPurchaseGiftCard() {
      this.$refs.dialog.close();
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
.info-list {
  list-style-type: none;
  margin-left: var(--gap-xsmall);

  --circle-radius: 11px;
  --circle-diameter: calc(2 * var(--circle-radius));
  --circle-offset: calc((var(--circle-diameter) + var(--border-thin) + var(--border-thin)) * -1 - var(--gap-xsmall));

  .circle {
    background-color: var(--color-primary);
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    border: var(--border-thin) solid var(--color-primary);
    border-radius: 50%;
    text-align: center;
    color: var(--color-ultra-light);
    display: inline-block;
    line-height: 1.2;
    font-size: 16px;
    margin-right: var(--gap-xsmall);
    margin-left: var(--circle-offset);
    &.branding-color {
      border-color: var(--color-branding);
      background-color: var(--color-branding);
    }
  }

  li {
    margin-bottom: var(--gap);
  }
}
</style>
