<template>
  <div class="h-page-not-found-box">
    <h1 class="header">{{ $t('components.page_not_found_view.message') }}</h1>
    <h-link :to="{ name: 'home' }" bold>{{ $t('components.page_not_found_view.action') }}</h-link>
  </div>
</template>

<script>
export default {
  name: 'h-page-not-found-box',
};
</script>

<style lang="scss" scoped>
.h-page-not-found-box {
  padding: var(--gap-xlarge) var(--gap-page-side);
  background: var(--color-ultra-light);
  text-align: center;
}

.header {
  margin-bottom: var(--gap);
  color: var(--color-error);
}
</style>
