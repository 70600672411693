<template>
  <h-dialog v-model="model" :title="title">
    <p>{{ text }}</p>

    <template #action-footer>
      <h-btn @click="subscribe">{{ actionText }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import _get from 'lodash/get';

export default {
  name: 'subscription-warning-modal',
  setup(props, { root }) {
    const { executeOverlay } = safeExecute(root.$store);

    return {
      executeOverlay,
    };
  },
  computed: {
    ...mapGetters('space', ['currentBrandingName', 'currentPartnerId']),
    ...mapGetters('overlay', ['subscriptionWarningModalIsOpen', 'headerDrawerContentPayload']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('subscription', ['currentSubscriptionPlan']),
    model: {
      get() {
        return this.subscriptionWarningModalIsOpen;
      },
      set() {
        this.closeSubscriptionWarningModal();
      },
    },
    monthlyFee() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(_get(this.currentSubscriptionPlan, 'monthly_fee', 0)));
    },
    title() {
      return this.authenticated ? this.$t('components.subscription_warning_modal.auth_title') : this.$t('components.subscription_warning_modal.no_auth_title');
    },
    text() {
      return this.authenticated
        ? this.$t('components.subscription_warning_modal.auth_text', { space: this.currentBrandingName, amount: this.monthlyFee })
        : this.$t('components.subscription_warning_modal.no_auth_text', { space: this.currentBrandingName, amount: this.monthlyFee });
    },
    actionText() {
      return this.authenticated ? this.$t('components.subscription_warning_modal.auth_cta') : this.$t('components.subscription_warning_modal.no_auth_cta');
    },
  },
  methods: {
    ...mapActions('overlay', ['closeSubscriptionWarningModal', 'openHeaderDrawerWithParams']),
    async subscribe() {
      this.closeSubscriptionWarningModal();
      // Note (PLP): setTimeout is used to give time to the subscription modal to start closing before we start the header drawer
      // This could have been nextTick.  With the subscription delay it is possible that is not needed.
      // This is a timing concerned
      if (this.authenticated) {
        setTimeout(() => this.openHeaderDrawerWithParams({ content: 'subscription', payload: this.headerDrawerContentPayload }), 1);
      } else {
        setTimeout(() => this.openHeaderDrawerWithParams({ content: 'auth', payload: { partner_id: this.currentPartnerId } }), 1);
      }
    },
  },
};
</script>
