<template>
  <h-form>
    <div v-if="includeCardName" class="pay-and-get-offer-payment-stripe formRow">
      <stripe-like-card-name v-model="cardName" @change="onCardNameChange" class="field" />
    </div>
    <div class="pay-and-get-offer-payment-stripe formRow">
      <div id="cardNumber" class="field"></div>
    </div>
    <div class="formRow">
      <div class="pay-and-get-offer-payment-stripe">
        <div id="expiryDate" class="field"></div>
      </div>
      <div class="pay-and-get-offer-payment-stripe">
        <div id="cvv" class="field"></div>
      </div>
      <div class="pay-and-get-offer-payment-stripe">
        <stripe-like-postal-code v-model="cardZip" @change="onZipChange" class="field" />
      </div>
    </div>
    <div aria-live="polite">
      <v-expand-transition>
        <h-error-box class="pb-4" v-if="errorMessage" :text="errorMessage" />
      </v-expand-transition>
    </div>
  </h-form>
</template>
<script>
import StripeLikePostalCode from './StripeLikePostalCode.vue';
import StripeLikeCardName from './StripeLikeCardName.vue';
import OrderType from '@/utils/order-type';

import { mapGetters } from 'vuex';

import _round from 'lodash/round';

export default {
  name: 'payment-form-v2',
  components: { StripeLikePostalCode, StripeLikeCardName },
  props: {
    customId: {
      type: String,
      default: 'paymentForm',
    },
    hasPaymentMethods: {
      type: Boolean,
      default: true,
    },
    includeCardName: {
      type: Boolean,
      default: false,
    },
    allowSaveCard: {
      type: Boolean,
      default: true,
    },
    cardAlwaySaved: {
      type: Boolean,
      default: false,
    },
    savedCardFunction: {
      type: Function,
    },
    orderType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    var options = {
      // You must provide currencyCode to the Paysafe JS SDK to enable the Payment API integration

      fields: {
        cardNumber: {
          selector: '#cardNumber',
          placeholder: '1234 1234 1234 1234',
          separator: ' ',
          optional: false,
        },
        expiryDate: {
          selector: '#expiryDate',
          placeholder: 'MM / YY',
          optional: false,
        },
        cvv: {
          selector: '#cvv',
          placeholder: 'CVC',
          optional: false,
        },
      },
      style: {
        input: {
          color: '#32325D',
          'font-family': 'Source Code Pro, Consolas, Menlo, monospace',
          'font-weight': 500,
          'font-size': '16px',
        },
        '::placeholder': {
          color: '#CFD7DF',
        },
        '.valid': {
          color: '#32325D',
        },
        '.invalid': {
          color: '#E25950',
        },
      },
    };
    this.$emit('readyToPay', false);

    this.$paysafe
      .setup(options)
      .then((instance) => {
        this.instance = instance;
        if (!instance.paymentMethods.card?.error) {
          this.addEventListeners(instance);
        }
        return instance.show();
      })
      .catch((err) => {
        if (err.code === '9028') {
          console.log(err); // eslint-disable-line no-console
          return;
        }
        throw err;
      });
  },
  data() {
    return {
      cardZipIsReady: false,
      cardNameIsReady: false,
      cardZip: '',
      cardName: '',
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters('account', ['accountEmail', 'accountId']),
    paysafeAccountId() {
      if (this.orderType === OrderType.CONSUMER) {
        return process.env.VUE_APP_PAYSAFE_MERCHANT_ACCOUNT_CONSUMER;
      }
      if (this.orderType === OrderType.CORPORATE) {
        return process.env.VUE_APP_PAYSAFE_MERCHANT_ACCOUNT_CORPORATE;
      }
      if (this.orderType === OrderType.BONUS) {
        return process.env.VUE_APP_PAYSAFE_MERCHANT_ACCOUNT_BONUS;
      }

      return null;
    },
  },
  methods: {
    addEventListeners(instance) {
      instance.fields('cvv cardNumber expiryDate').on('FieldValueChange', (instance, event, error) => {
        this.setReadyToPay();
      });
    },
    onZipChange(e) {
      this.onChangeHandler('cardZipIsReady')(e);
    },
    onCardNameChange(e) {
      this.onChangeHandler('cardNameIsReady')(e);
    },
    onChangeHandler(fieldKey) {
      const _this = this;
      return (event) => {
        if (event.complete) {
          _this[fieldKey] = true;
          _this.errorMessage = null;
        } else if (event.error) {
          _this.errorMessage = event.error.message;
        } else {
          _this[fieldKey] = false;
          _this.errorMessage = null;
        }
        this.setReadyToPay();
      };
    },
    setReadyToPay() {
      let readyToPay = this.instance.areAllFieldsValid() && this.cardZipIsReady;
      readyToPay = readyToPay && !this.instance.fields.cardNumber.isEmpty();
      readyToPay = readyToPay && !this.instance.fields.expiryDate.isEmpty();
      readyToPay = readyToPay && !this.instance.fields.cvv.isEmpty();

      if (this.includeCardName) {
        readyToPay = readyToPay && this.cardNameIsReady;
      }

      this.$emit('readyToPay', readyToPay);
    },
    generateGuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    },
    async submit(amount) {
      const merchantRefNum = this.generateGuid();

      try {
        const result = await this.instance.tokenize({
          amount: _round(amount * 100, 0),
          transactionType: 'PAYMENT',
          paymentType: 'CARD',
          merchantRefNum,
          options: {
            accountId: this.paysafeAccountId,
          },
          customerDetails: {
            billingDetails: {
              zip: this.cardZip,
              country: 'CA',
            },
            profile: {
              lastName: this.accountEmail.slice(0, 80),
              email: `${this.accountId}@account.hellocard.ca`.toLowerCase(),
            },
          },
        });

        return { token: result.token, merchantRefNum, postal_code: this.cardZip };
      } catch (err) {
        throw new Error(this.$t('components.payment_form_v2.tokenization_error', { message: err.displayMessage }));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.formRow {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin: var(--gap-small) 0;
  .pay-and-get-offer-payment-stripe {
    width: calc((100% - var(--gap-small) * 2) / 3);
  }
}
.pay-and-get-offer-payment-stripe {
  border: 2px solid var(--color-primary);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: var(--gap-small);
}
.field {
  height: 20px;
  width: 100%;
}
.field input,
.field .field:not(#apple-pay) {
  color: #32325d;
}
</style>
