import Vue from 'vue';

const service = {
  async hybridAccess() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/hybrid`);
    return response.data.data;
  },
  async userAccess() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/security/v1/me`);
    return response.data.data;
  },
  async publicAccess() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/public`);
    return response.data.data;
  },
};

export default service;
