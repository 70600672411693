<template>
  <div class="h-chip-small">
    <div class="top-section">
      <div class="label">{{ $t('components.h_chip_small.pay') }}</div>
      <div class="main-control">
        <v-btn tabindex="-1" aria-label="-" v-if="!isPlusMinusControlHidden" @click="minusAction" :disabled="disableMinus" icon small class="plus-minus-btn">
          <v-icon color="ultra-light">mdi-minus-circle-outline</v-icon>
        </v-btn>

        <div :class="valueSizeClass" class="value top">{{ pay }}</div>

        <v-btn tabindex="-1" aria-label="+" v-if="!isPlusMinusControlHidden" @click="plusAction" :disabled="disablePlus" icon small class="plus-minus-btn">
          <v-icon color="ultra-light">mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="label">{{ $t('components.h_chip_small.obtain') }}</div>
    <div :class="valueSizeClass" class="value">{{ obtain }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-chip-small',
  props: {
    pay: {
      type: String,
      default: 'XXX$',
    },
    obtain: {
      type: String,
      default: 'XXX$',
    },
    disablePlus: {
      type: Boolean,
      default: false,
    },
    disableMinus: {
      type: Boolean,
      default: false,
    },
    plusAction: {
      type: Function,
      default: () => {},
    },
    minusAction: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isPlusMinusControlHidden() {
      return this.disablePlus && this.disableMinus;
    },
    valueSizeClass() {
      if (this.pay.length >= 8) {
        return 'xsmall';
      }
      if (this.pay.length >= 7) {
        return 'small';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.h-chip-small {
  width: 116px;
  height: 116px;
  padding-top: 12px;
  border-radius: 50%;
  background: linear-gradient(180deg, var(--color-branding) 0%, var(--color-branding) 53%, rgba(255, 255, 255, 1) 53%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 1;
}

.top-section {
  margin-bottom: 7px;
  color: var(--color-ultra-light);
}

.main-control {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1px;
  min-height: 32px;
}

.label {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
}

.value {
  margin-top: 4px;
  font-size: 16px;
  font-size: 16px;
  font-weight: 600;

  &.small {
    font-size: 14px;
  }
  &.xsmall {
    font-size: 13px;
  }

  &.top {
    margin-top: 0;
    flex-grow: 1;
  }
}

.plus-minus-btn.v-btn--disabled {
  .v-icon {
    color: var(--color-ultra-light) !important;
    opacity: 0.5;
  }
}
</style>
