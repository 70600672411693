<template>
  <account-info-bar-base display-application-id display-application-status />
</template>
<script>
import AccountInfoBarBase from './components/AccountInfoBarBase.vue';

export default {
  name: 'account-info-bar-new-card',
  components: {
    AccountInfoBarBase,
  },
};
</script>
