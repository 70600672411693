import Vue from 'vue';
import { organizeStore, reSort } from '@/utils/store-helper';

export default {
  namespaced: true,
  state: {
    query_destination_id: null,
    query_keyword: null,
    query_category: null,
    query_offer_id: undefined,
    query_publisher_id: undefined,

    currentStores: [],
    currentStoresFormatted: [],
    currentStoreCategoryIds: [],
    currentDestinations: {},
    currentDestinationList: [],
    mode: undefined,

    initial_store_length: 0,
  },
  getters: {
    currentDestination: (state) => state.query_destination_id,
    currentKeyword: (state) => state.query_keyword,
    currentCategory: (state) => state.query_category,
    initialNumStores: (state) => state.initial_store_length,

    currentStores: (state) => state.currentStores,
    currentStoresFormatted: (state) => state.currentStoresFormatted,
    currentStoreCategoryIds: (state) => state.currentStoreCategoryIds,
    currentDestinations: (state) => state.currentDestinations,
    currentDestinationList: (state) => state.currentDestinationList,
  },
  mutations: {
    reset: (state) => {
      state.currentStoreCategoryIds = [];
      state.currentStores = [];
      state.initial_store_length = 0;

      state.query_destination_id = null;
      state.query_keyword = null;
      state.query_category = null;
      state.query_offer_id = undefined;
      state.query_publisher_id = undefined;

      state.mode = undefined;
    },
    setOfferStoreCategoryIds: (state, categories) => {
      state.currentStoreCategoryIds = categories;
    },
    setOfferStores: (state, stores) => {
      state.currentStores = stores;
    },
    setOfferStoresFormatted: (state, stores) => {
      state.currentStoresFormatted = stores;
      state.initial_store_length = Math.max(state.initial_store_length, stores.length);
    },
    setDestination: (state, destinations) => {
      state.currentDestinationList = destinations;
      state.currentDestinations = destinations.reduce((acc, destination) => {
        acc[destination.id] = destination;
        return acc;
      }, {});
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    updateQuery(state, newQuery) {
      Object.keys(newQuery).forEach((key) => {
        if (state[`query_${key}`] !== newQuery[key]) {
          state[`query_${key}`] = newQuery[key];
        }
      });
    },
  },
  actions: {
    async updateQuery({ commit, dispatch, state, rootGetters }, newQuery) {
      commit('updateQuery', newQuery);

      if (newQuery.offer_id || newQuery.publisher_id || newQuery.category || newQuery.category === null || newQuery.destination_id || newQuery.destination_id === null) {
        const promises = [];
        const categories_to_include = state.query_category ? [state.query_category] : [];

        const destinations_to_include = state.query_destination_id ? [state.query_destination_id] : [];

        promises.push(dispatch('getOfferStores', { offer_id: state.query_offer_id, publisher_id: state.query_publisher_id, categories_to_include, destinations_to_include }));

        if (newQuery.offer_id || newQuery.publisher_id) {
          promises.push(dispatch('getDestinations', { offer_id: state.query_offer_id, publisher_id: state.query_publisher_id }));
          promises.push(dispatch('getOfferStoreCategoryIds', { offer_id: state.query_offer_id, publisher_id: state.query_publisher_id }));
        }

        await Promise.all(promises);
      }

      // const storesFormatted = organizeStore(state.currentStores, state.query_keyword, rootGetters['ui/currentLocale'], state.query_destination);
      const storesFormatted = organizeStore(state.currentStores, state.query_keyword, rootGetters['ui/currentLocale']);
      commit('setOfferStoresFormatted', storesFormatted);

      return { item_count: state.currentStoresFormatted.length };
    },
    sortAgain({ commit, rootGetters, state }) {
      commit('setOfferStoresFormatted', reSort(state.currentStoresFormatted, rootGetters['ui/currentLocale']));
    },
    async resetOfferCategoriesAndStores({ commit }) {
      commit('reset');
    },
    async getOfferStoreCategoryIds({ state, commit, rootGetters }, { offer_id, publisher_id }) {
      let offerCategoryIds;
      if (state.mode === 'offer_publisher_store') {
        offerCategoryIds = await Vue.prototype.$services.offer.getOfferPublisherStoreCategoriesList(publisher_id, rootGetters['ui/currentLocale']);
      } else if (state.mode === 'offers_store') {
        offerCategoryIds = await Vue.prototype.$services.offer.getOffersStoresCategoriesList(state.query_offer_id.split(','), rootGetters['ui/currentLocale']);
      } else {
        offerCategoryIds = await Vue.prototype.$services.store.getOfferStoreCategoryIds(offer_id, publisher_id);
      }
      commit('setOfferStoreCategoryIds', offerCategoryIds);
      return offerCategoryIds;
    },
    setOfferPublisherStoreMode({ commit }) {
      commit('setMode', 'offer_publisher_store');
    },
    setOffersStoreMode({ commit }) {
      commit('setMode', 'offers_store');
    },
    async getOfferStores({ commit, rootGetters, state }, { offer_id, ...payload }) {
      let offerStoresResponse;

      const categories_to_include = state.query_category ? [state.query_category] : null;
      const destinations_to_include = state.query_destination_id ? [state.query_destination_id] : null;

      if (state.mode === 'offer_publisher_store') {
        offerStoresResponse = await Vue.prototype.$services.offer.getOfferPublisherStores(state.query_publisher_id, rootGetters['ui/currentLocale'], 1, 2000, categories_to_include, destinations_to_include);
      } else if (state.mode === 'offers_store') {
        offerStoresResponse = await Vue.prototype.$services.offer.getOffersStores(state.query_offer_id.split(','), rootGetters['ui/currentLocale'], 1, 2000, categories_to_include, destinations_to_include);
      } else {
        offerStoresResponse = await Vue.prototype.$services.store.getOfferStores(state.query_offer_id, payload, 2000);
      }
      const offerStores = offerStoresResponse.items;

      const storesFormatted = organizeStore(offerStores, '', 'fr');

      commit('setOfferStores', offerStores);
      commit('setOfferStoresFormatted', storesFormatted);
      return offerStores;
    },

    async getDestinations({ commit }, { offer_id, publisher_id }) {
      const destinationResponse = await Vue.prototype.$services.store.getDestinationList(offer_id, publisher_id);
      commit('setDestination', destinationResponse);
      return destinationResponse;
    },

    async getDestinationDetail({}, { destination_id }) {
      const destination = await Vue.prototype.$services.store.getDestinationDetail(destination_id);

      return destination;
    },
  },
};
