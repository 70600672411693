<template>
  <div class="about-overlay">
    <div class="top-links">
      <h-link target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloPrivacyPolicyFr : $urlProvider.HelloPrivacyPolicyEn" color="ultra-light">{{ $t('components.about_overlay.politics_link') }}<v-icon>mdi-open-in-new</v-icon></h-link>
      <h-link target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloTermAndConditionFr : $urlProvider.HelloTermAndConditionEn" color="ultra-light">{{ $t('components.about_overlay.condition_link') }}<v-icon>mdi-open-in-new</v-icon></h-link>
      <h-link target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloCardFr : $urlProvider.HelloCardEn" color="ultra-light">{{ $t('components.about_overlay.hello_card_link') }}<v-icon>mdi-open-in-new</v-icon></h-link>
      <h-link target="_blank" :href="currentLocale === 'fr' ? $urlProvider.HelloBusinessFr : $urlProvider.HelloBusinessEn" color="ultra-light">{{ $t('components.about_overlay.merchant_link') }}<v-icon>mdi-open-in-new</v-icon></h-link>
    </div>

    <div class="main-content">
      <div v-if="$vuetify.breakpoint.mdAndUp" class="logo-container">
        <img class="logo" src="@/assets/logo.svg" alt="Hello" />
      </div>

      <div class="carousel-container">
        <v-carousel v-model="step" height="auto" :style="{ minHeight: '240px' }" delimiter-icon="mdi-circle" hide-delimiter-background next-icon="mdi-dark  mdi-chevron-right" prev-icon="mdi-dark mdi-chevron-left">
          <v-carousel-item>
            <img class="carousel-img" :src="$urlProvider.GenerateHelloImageUrl($t(`components.about_overlay.step_1.image`))" />
          </v-carousel-item>
          <v-carousel-item>
            <img class="carousel-img" :src="$urlProvider.GenerateHelloImageUrl($t(`components.about_overlay.step_2.image`))" />
          </v-carousel-item>
        </v-carousel>

        <p class="carousel-text">{{ $t(`components.about_overlay.step_${step + 1}.text`) }}</p>
        <p class="carousel-sub-text">{{ $t(`components.about_overlay.step_${step + 1}.sub_text`) }}</p>
      </div>
    </div>

    <!-- Footer -->
    <div class="footer">
      <p class="legal-text">{{ $t('components.about_overlay.copyright', { year: new Date().getFullYear() }) }}</p>
      <div class="version-text">
        <span class="mr-4">v{{ version }}</span>
        <span>#{{ buildNumber }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  data() {
    return {
      step: 0,
      buildNumber: process.env.VUE_APP_BUILD_NUMBER,
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
<style lang="scss" scoped>
.about-overlay {
  padding: var(--gap-xlarge) var(--gap) var(--gap);
  background-color: var(--color-primary);
}

.top-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 40px;

  @media ($md-min) {
    flex-direction: row;
    justify-content: space-around;
    margin: 0 40px 40px;
  }
}

.main-content {
  @media ($md-min) {
    display: flex;
  }
}
.logo-container {
  width: 50%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 595px;
  background: url('~@/assets/about_bg.png') no-repeat center center;
  background-size: cover;
}
.logo {
  width: 50%;
}
.carousel-container {
  @media ($md-min) {
    padding-top: 24px;
  }
}
.carousel-img {
  display: block;
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
}
.carousel-text {
  font-weight: 300;
  font-size: 29px;
  line-height: 1;
  text-align: center;
  margin: 40px 0;
  color: var(--color-ultra-light);
}
.carousel-sub-text {
  font-weight: 600;
  font-size: 19px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  color: var(--color-ultra-light);
  opacity: 0.65;
}

.footer {
  margin-top: var(--gap);
}

.legal-text,
.version-text {
  font-size: 10px;
  text-align: center;
  color: var(--color-ultra-light);
}
.legal-text {
  margin-bottom: 0;

  @media ($md-min) {
    margin-bottom: var(--gap-xsmall);
  }
}
.version-text {
  display: none;

  @media ($md-min) {
    display: block;
  }
}
</style>
