export default ({ Vue, app }) => {
  Vue.filter('publicToken', (publicToken) => {
    if (publicToken && publicToken.length === 9) return `${publicToken.substring(0, 3)}-${publicToken.substring(3, 6)}-${publicToken.substring(6)}`;
    return publicToken;
  });

  Vue.filter('maskedPan', (maskedPan) => {
    if (maskedPan && maskedPan.length === 16) return `${maskedPan.substring(0, 4)} ${maskedPan.substring(4, 8)} ${maskedPan.substring(8, 12)} ${maskedPan.substring(12)}`;
    return maskedPan;
  });
};
