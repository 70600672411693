<template>
  <div v-if="this.status !== 'available' || !this.ctaPurchase" class="consumer-gift-card-status h-text-1" :style="`color: ${labelColor};`">
    <span>{{ labelText }}</span>
  </div>
</template>
<script>
export default {
  name: 'consumer-gift-card-status',
  props: {
    expiration: {
      type: String,
    },
    start: {
      type: String,
    },
    status: {
      type: String,
    },
    ctaPurchase: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    formattedStartDateText() {
      return this.$options.filters.formatDateText(this.start);
    },
    formattedExpirationDate() {
      return this.$options.filters.formatShortDate(this.expiration);
    },
    formattedStartDateHour() {
      return this.$options.filters.formatHourWithAt(this.start);
    },
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    labelColor() {
      if (this.status === 'coming_soon' || !this.ctaPurchase) {
        return 'var(--color-warning)';
      }
      if (this.status === 'sold_out') {
        return 'var(--color-warning)';
      }
      if (this.status === 'available') {
        return 'var(--color-text-primary)';
      }
      if (this.status === 'closed') {
        return 'var(--color-warning)';
      }
      return 'var(--color-text-primary)';
    },
    labelText() {
      if (!this.ctaPurchase) {
        return this.$t('components.big_card_base_status_label.coming_soon_no_date');
      }

      if (this.status === 'coming_soon') {
        return this.$t('components.big_card_base_status_label.coming_soon', { date: this.formattedStartDateText, hour: this.formattedStartDateHour });
      }
      if (this.status === 'sold_out') {
        return this.$t('components.big_card_base_status_label.sold_out', { date: this.formattedStartDate });
      }
      if (this.status === 'available') {
        return this.$t('components.big_card_base_status_label.expiration', { date: this.formattedExpirationDate });
      }
      if (this.status === 'closed') {
        return this.$t('components.big_card_base_status_label.closed', { date: this.formattedExpirationDate });
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.consumer-gift-card-status {
  // padding: 10px;
  // width: fit-content;
  // border: 1px solid;
  // text-transform: uppercase;
}
</style>
