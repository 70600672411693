<template>
  <div v-if="spacesToUse" class="recently-visited-space-widget">
    <div v-if="$vuetify.breakpoint.smAndDown" class="mobile-widget">
      <h2 class="mobile-widget-title">{{ $t('components.recently_visited_widget.title') }}</h2>
      <v-slide-group class="mobile-widget-slide-group">
        <v-slide-item v-for="(space, index) in spacesToUse" :key="index">
          <router-link class="mobile-widget-slide-item" :to="{ name: 'spaceHome', params: { space: space.slug } }">
            <img class="mobile-widget-img" :src="spaceWidgetImageHelper(getImageUrl(space))" />
          </router-link>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div v-else class="desktop-widget">
      <h2 class="desktop-widget-title">{{ $t('components.recently_visited_widget.title') }}</h2>
      <div class="desktop-widget-images-section">
        <router-link class="desktop-widget-item-wrap" v-for="(space, index) in spacesToShow" :key="index" :to="{ name: 'spaceHome', params: { space: space.slug } }">
          <div class="desktop-widget-item">
            <div class="desktop-widget-image" :style="`background-image: url(${recentlyVisitedImageHelper(getImageUrl(space))}) ;`"></div>
          </div>
        </router-link>
      </div>
      <div v-if="spacesToUse.length > numberOfSpacesPerRow" class="desktop-widget-list-more-section">
        <h-link large @click="listMore()">{{ listMoreOrLessText }}</h-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { recentlyVisitedImageHelper, spaceWidgetImageHelper } from '@/composables/image-helper';
import _slice from 'lodash/slice';
import _get from 'lodash/get';

export default {
  name: 'recently-visited-space-widget',
  setup(props, { root }) {
    return {
      recentlyVisitedImageHelper,
      spaceWidgetImageHelper,
    };
  },
  props: {
    enableDiscover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      numberOfSpacesPerRow: 5,
      numberOfSpacesDisplayed: 5,
    };
  },
  async mounted() {
    await this.listPublicSpaces();
  },
  computed: {
    ...mapGetters('space', ['publicSpaces', 'spaceHistory']),
    ...mapGetters('ui', ['currentLocale']),
    spacesToUse() {
      if (this.spaceHistory.length > 0) {
        return this.spaceHistory;
      }
      if (this.publicSpaces.length > 0 && this.enableDiscover) {
        return this.publicSpaces;
      }
      return undefined;
    },
    spacesToShow() {
      return _slice(this.spacesToUse, 0, this.numberOfSpacesDisplayed);
    },
    listMoreOrLessText() {
      if (this.numberOfSpacesDisplayed < this.spacesToUse.length) {
        return this.$t('components.recently_visited_widget.show_more');
      } else {
        return this.$t('components.recently_visited_widget.show_less');
      }
    },
  },
  methods: {
    ...mapActions('space', ['listPublicSpaces']),
    getImageUrl(space) {
      if (this.spacesToUse === this.spaceHistory) {
        return _get(space, `branding.images.horizontal_logo.${this.currentLocale}`);
      }
      return space.banner_image_url[this.currentLocale];
    },
    listMore() {
      if (this.numberOfSpacesDisplayed < this.spacesToUse.length) {
        this.numberOfSpacesDisplayed += this.numberOfSpacesPerRow;
      } else {
        this.numberOfSpacesDisplayed = this.numberOfSpacesPerRow;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-widget {
  padding: var(--gap);
  padding-bottom: var(--gap-xsmall);
}

.mobile-widget-title {
  text-transform: uppercase;
}

.mobile-widget-slide-group {
  padding-top: var(--gap-xsmall);
}

.mobile-widget-slide-item {
  margin-left: var(--gap);
  outline: none;
  height: 70px;
  width: 140px;
  background: var(--color-ultra-light);
  border-radius: 6.94px;
  box-shadow: var(--card-shadow) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: calc(var(--gap) * 0.4);
  }
  &:last-child {
    margin-right: calc(var(--gap) * 0.4);
  }
}

.mobile-widget-img {
  max-height: 60px;
  display: block;
  max-width: 102px;
}

// Used to not cut off box-shadow
::v-deep .v-slide-group__wrapper {
  padding: var(--gap-xsmall) 0;
}

.desktop-widget {
  padding: var(--gap) 0 var(--gap-xxlarge) 0;
}

.desktop-widget-title {
  text-transform: uppercase;
  padding-bottom: var(--gap-xsmall);
}

.desktop-widget-images-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: var(--gap);
  margin: var(--gap-xsmall) var(--gap);
}

.desktop-widget-item-wrap {
  height: 104px;
  width: auto;
  background-color: var(--color-ultra-light);
  border-radius: 6.94px;
  box-shadow: var(--card-shadow) !important;
  padding: 19px; // For centering and maintaining border-radius on default image
}

.desktop-widget-item {
  height: 66px;
  width: auto;
}

.desktop-widget-image {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.desktop-widget-list-more-section {
  display: flex;
  justify-content: center;
}
</style>
