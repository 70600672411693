<template>
  <h-dialog ref="dialog" :title="$t('components.update_email_modal.title')" @close="closeCleanup" max-width="470">
    <template v-slot:activator="{ on, attrs }">
      <h-link v-on="on" v-bind="attrs" color="ultra-light">{{ $t('components.user_profile.change_email') }}</h-link>
    </template>

    <p>{{ $t('components.update_email_modal.description') }}</p>

    <v-expand-transition>
      <h-success-box v-if="updateEmailSent" :text="$t('components.user_profile.update_email_success')" style="margin-top: 14px" />
    </v-expand-transition>

    <h-form v-show="!updateEmailSent" ref="form" v-model="valid" @submit.prevent="submit()">
      <h-text-field v-model="emailModal" ref="email" maxlength="255" class="mt-4" :label="$t('components.update_email_modal.email_field')" type="email" :rules="emailRules" required validate-on-blur data-cy="signinEmailField" />
      <h-text-field
        onpaste="return false;"
        ondrop="return false;"
        autocomplete="disable-autofill"
        ref="email_confirmation"
        :label="$t('components.update_email_modal.confirm')"
        type="email"
        v-model="emailConfirmationModal"
        maxlength="255"
        :rules="emailRules"
        required
        data-cy="signinEmailFieldConfirmation"
        :error-messages="validateTwoEmailMatchErrorMessageBinding(emailModal, emailConfirmationModal)"
      />

      <hello-form-error />
    </h-form>

    <template #action-footer>
      <h-btn @click="submit()" :disabled="!valid || working || updateEmailSent" data-cy="updateEmailSubmitBtn" :loading="working">{{ $t('components.update_email_modal.action') }}</h-btn>
      <h-btn @click="$refs.dialog.close()" outlined :disabled="working">{{ $t('components.update_email_modal.cancel') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { recaptcha } from '@/mixins';
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import { validateInvalidEmail, validateRequired, validateMaxLength, validateTwoEmailMatchErrorMessageBinding } from '@/composables/validations';

export default {
  name: 'update-email-modal',
  setup(props, { root }) {
    const { executeForm, executeContent, working } = safeExecute(root.$store);
    return {
      executeContent,
      executeForm,
      working,
      validateRequired,
      validateMaxLength,
      validateInvalidEmail,
      validateTwoEmailMatchErrorMessageBinding,
    };
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      emailModal: undefined,
      emailConfirmationModal: undefined,
      emailRules: [this.validateRequired('email'), this.validateMaxLength(255), this.validateInvalidEmail()],
      updateEmailSent: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    closeCleanup() {
      this.updateEmailSent = false;
      this.$refs.form.reset();
      this.$refs.form.validationReset();
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.executeForm(
          {
            name: 'updateEmailView',
            action: 'security/updateEmail',
            success: () => {
              this.executeContent({
                action: 'account/getAccount',
              });
              this.updateEmailSent = true;
            },
          },
          {
            email: this.emailModal,
            language: this.currentLocale,
          },
        );
      }
    },
  },
};
</script>
