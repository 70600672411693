<template>
  <div class="pay-and-get-editable">
    <h-pay-and-get-input
      :is-in-error="paymentExceedMaxPaymentBecauseOfUserRestriction"
      :closing-date="paymentInfo.closure_date"
      :disable-plus="disablePlus(paymentAmount, offerIncentiveStep, paymentMaxPayment, amountLeft)"
      :disable-minus="disableMinus(paymentAmount, offerIncentiveStep, offerIncentiveMinimumPayment, amountLeft)"
      :plus-action="plus"
      :minus-action="minus"
      :label="$t('components.pay_and_get_editable.pay')"
      :amount="paymentAmount | currency | currencySymbol"
      :notice="paymentPayAndGetInputProps.notice"
      :hints-title="paymentPayAndGetInputProps.hints_title"
      :hints-html="paymentPayAndGetInputProps.hints_html"
    />
    <h-pay-and-get-label
      :notice="$t('components.pay_and_get_editable.bonus_notice', { date: $options.filters.formatShortDate(paymentInfo.bonus_expiration_date) })"
      :label="$t('components.pay_and_get_editable.bonus')"
      :amount="offerIncentiveBonusDisplay | bonusSymbol"
      :is-using-hints="true"
      :bonus-expiring-date="paymentInfo.bonus_expiration_date"
    />
    <h-pay-and-get-label :label="$t('components.pay_and_get_editable.receive')" :amount="amountObtained | currency | currencySymbol" />

    <v-expand-transition>
      <h-error-label
        v-if="paymentExceedMaxPaymentBecauseOfUserRestriction"
        :text="
          $t('components.pay_and_get_editable.error_payment_over_allowed_for_user', {
            offer_branding_label: $options.filters.translateI18nObject(offer, 'offer_branding.label'),
            limit_amount: $options.filters.currencySymbol($options.filters.currency(offerIncentivePurchaseLimit)),
            remaining_amount: $options.filters.currencySymbol($options.filters.currency(paymentInfo.user_payment_remaining)),
          })
        "
      />
    </v-expand-transition>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _min from 'lodash/min';
import { mapActions, mapGetters } from 'vuex';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount } from '@/composables/offer-publisher-helper';

export default {
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    paymentInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
      remainingAmount,
    };
  },
  name: 'pay-and-get-editable',
  computed: {
    ...mapGetters('payment', ['paymentAmount']),
    paymentPayAndGetInputProps() {
      if (this.offerHaveUserFeeDormancy) {
        return {
          notice: this.$t('components.pay_and_get_editable.dormancy_notice', { date: this.$options.filters.formatShortDateWithoutDays(this.paymentInfo.dormancy_start_date) }),
          hints_title: this.$t('components.pay_and_get_editable.dormancy_hints.title'),
          hints_html: this.$t('components.pay_and_get_editable.dormancy_hints.html_text', {
            delay: this.paymentInfo.dormancy_last_activity_trigger_in_months,
            amount: this.$options.filters.currency_full(this.paymentInfo.dormancy_fee),
          }),
        };
      }

      if (this.paymentInfo.closure_date) {
        return {
          notice: this.$t('components.pay_and_get_editable.pay_notice', { date: this.$options.filters.formatShortDate(this.paymentInfo.closure_date) }),
          hints_title: this.$t('components.account_voucher.contribution_hints.title'),
          hints_html: this.$t('components.account_voucher.contribution_hints.message', { date: this.$options.filters.formatTextDate(this.paymentInfo.closure_date) }),
        };
      }

      return {
        notice: null,
      };
    },
    offerIncentiveStep() {
      return _get(this.offer, 'incentive.step');
    },
    offerIncentiveBonusDisplay() {
      return _get(this.offer, 'incentive.bonus_display', 0);
    },
    offerIncentiveBonusPercent() {
      return _get(this.offer, 'incentive.bonus_percent', 0);
    },
    offerIncentivePurchaseLimit() {
      return _get(this.offer, 'incentive.user_payment_limit', 0);
    },
    offerHaveUserFeeDormancy() {
      return this.paymentInfo.dormancy_enabled;
    },
    offerIncentiveMinimumPayment() {
      return _get(this.offer, 'incentive.minimum_payment', 0);
    },
    offerTotalInventory() {
      return _get(this.offer, 'availability.inventory.total_amount');
    },
    offerUsedInventory() {
      return _get(this.offer, 'availability.inventory.used_amount');
    },
    offerPublisherTotalInventory() {
      return _get(this.publisher, 'inventory.total_amount');
    },
    offerPublisherUsedInventory() {
      return _get(this.publisher, 'inventory.used_amount');
    },
    offerIncentiveDefaultPayment() {
      return _get(this.offer, 'incentive.default_payment', 0);
    },
    paymentMaxPayment() {
      return _min([this.offerIncentivePurchaseLimit, _get(this.paymentInfo, 'user_payment_remaining')]);
    },
    paymentExceedMaxPaymentBecauseOfUserRestriction() {
      return this.paymentAmount > _get(this.paymentInfo, 'user_payment_remaining');
    },
    amountObtained() {
      return (1 + this.offerIncentiveBonusPercent) * this.paymentAmount;
    },
    amountLeft() {
      return this.remainingAmount(this.offerTotalInventory, this.offerUsedInventory, this.offerIncentiveBonusDisplay, this.offerPublisherTotalInventory, this.offerPublisherUsedInventory);
    },
  },
  mounted() {
    // If paymentAmount greater than available amount in inventory use the minimum buyable amount.
    if (this.paymentAmount > this.amountLeft) {
      this.setPaymentAmountForPurchase(this.offerIncentiveMinimumPayment);
    }
  },
  methods: {
    ...mapActions('payment', ['setPaymentAmountForPurchase']),
    plus() {
      const newAmount = this.addAmount(this.paymentAmount, this.offerIncentiveStep, this.offerIncentiveDefaultPayment);
      this.setPaymentAmountForPurchase(newAmount);
    },
    minus() {
      const newAmount = this.removeAmount(this.paymentAmount, this.offerIncentiveStep, this.offerIncentiveDefaultPayment);
      this.setPaymentAmountForPurchase(newAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-and-get-editable {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: var(--gap-xlarge) 0 var(--gap-large);
  border: var(--border-thin) solid var(--color-border);

  .h-pay-and-get-input {
    width: 100%;
    margin-bottom: var(--gap-large);

    @media ($sm-min) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .h-error-label {
    width: 100%;
    padding-bottom: 0;
  }
}
</style>
