<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" error icon="mdi-alert" :title="$t('components.corpo_order_error_modal.title')">
    <p v-html="$t('components.corpo_order_error_modal.text', { faqUrl })"></p>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.corpo_order_error_modal.ok') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'corpo-order-error-modal',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    faqUrl() {
      return this.currentLocale === 'fr' ? this.$urlProvider.HelloCorpoFaqFr : this.$urlProvider.HelloCorpoFaqEn;
    },
  },
};
</script>
