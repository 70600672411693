<template>
  <div class="auth-and-register">
    <auth-form />
    <register-form />
  </div>
</template>

<script>
import AuthForm from '@/components/authentication/AuthForm';
import RegisterForm from '@/components/authentication/RegisterForm';

export default {
  name: 'auth-and-register',
  components: {
    AuthForm,
    RegisterForm,
  },
};
</script>

<style lang="scss" scoped>
.auth-and-register {
  background: var(--color-ultra-light);

  @media ($sm-min) {
    display: flex;
  }
}

.auth-form {
  margin-bottom: 30px;

  @media ($sm-min) {
    width: 41.6666%;
    margin-bottom: 0;
    padding-right: var(--gap-xlarge);
  }
}

.register-form {
  @media ($sm-min) {
    width: 58.3333%;
  }
}
</style>
