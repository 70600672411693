<template>
  <div class="pay-and-get-purchase-type">
    <h-step-header :text="$t('components.pay_and_get_purchase_type.title')" />
    <v-radio-group v-model="buyForGift" mandatory :row="$vuetify.breakpoint.smAndUp">
      <div class="treat-yourself" :class="{ hasCardSelection: showCardSelection }">
        <v-radio :value="false">
          <template v-slot:label>
            <span>{{ $t('components.pay_and_get_purchase_type.for_yourself.title') }}</span>
            <span class="caption">{{ $t('components.pay_and_get_purchase_type.for_yourself.label') }}</span>
          </template>
        </v-radio>

        <card-selection
          context="purchase"
          :has-application="accountHasApplication && !hasClassicCard"
          v-if="showCardSelection"
          :label-text="$t('components.pay_and_get_purchase_type.for_yourself.select_card')"
          :card-list="cards"
          @change="setCurrentCard"
        />
      </div>

      <div>
        <v-radio :value="true" readonly disabled>
          <template v-slot:label>
            <span>{{ $t('components.pay_and_get_purchase_type.for_someone_else.title') }}</span>
            <span class="caption">{{ $t('components.pay_and_get_purchase_type.for_someone_else.label') }}</span>
            <span class="coming-soon caption">{{ $t('components.pay_and_get_purchase_type.for_someone_else.warning') }}</span>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import CardSelection from '@/components/pay-and-get/CardSelection';
import { mapGetters } from 'vuex';

export default {
  name: 'pay-and-get-purchase-type',
  components: {
    CardSelection,
  },
  props: {
    showCardSelection: {
      type: Boolean,
    },
    cards: {
      type: Array,
    },
  },
  data() {
    return {
      buyForGift: false,
    };
  },
  computed: {
    ...mapGetters('account', ['accountHasApplication', 'hasClassicCard']),
  },
  methods: {
    setCurrentCard(card) {
      this.$emit('cardSelected', card);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-radio {
  align-items: flex-start;
}
::v-deep .v-input--radio-group__input {
  align-items: flex-start;

  @media ($sm-min) {
    flex-direction: column;

    margin: auto;
    width: auto;
  }

  @media ($md-min) {
    .v-input--radio-group__input {
      justify-content: flex-start;
    }
    justify-content: center;
    flex-direction: row;
    margin: 0;
    width: auto;
  }
}
::v-deep .v-label {
  flex-direction: column;
  align-items: flex-start !important;
}

.treat-yourself {
  margin-bottom: var(--gap-xxlarge);

  @media ($sm-min) {
    margin-right: var(--gap-xlarge);
  }

  @media ($md-min) {
    margin-bottom: 0;

    &.hasCardSelection {
      width: calc((100% - var(--gap-large)) / 2);
    }
  }

  .v-radio {
    margin-right: 0;
  }
}

.coming-soon {
  font-style: italic;
  color: var(--color-error);
}

.card-selection {
  margin-top: var(--gap);
  padding-left: 32px;
}

::v-deep .v-messages {
  min-height: 0;
}
</style>
