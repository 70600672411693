<template>
  <h-dialog ref="dialog" :title="$t('components.user_profile.reset_password_modal_title')" @open="onOpen" @close="onClose">
    <template v-slot:activator="{ on, attrs }">
      <h-link v-on="on" v-bind="attrs" color="ultra-light">{{ $t('components.user_profile.change_password') }}</h-link>
    </template>

    <div>
      <p>{{ $t('components.user_profile.reset_password_modal_text') }}</p>

      <v-expand-transition v-if="init">
        <h-success-box v-if="resetPasswordSent" :text="$t('components.user_profile.reset_password_success_title')" />
      </v-expand-transition>

      <div v-if="resetPasswordSent" class="expiration-warning">
        {{ $t('components.user_profile.reset_password_modal_expiration_warning') }}
      </div>

      <hello-form-error />
    </div>

    <template #action-footer v-if="init">
      <h-btn v-if="resetPasswordSent" @click="$refs.dialog.close()" outlined>{{ $t('components.user_profile.reset_password_modal_close') }}</h-btn>
      <h-btn v-if="!resetPasswordSent" :disabled="resetPasswordSent" @click="resetPassword()" :loading="working">{{ $t('components.user_profile.reset_password_modal_action') }}</h-btn>
      <h-btn v-if="!resetPasswordSent" :disabled="working" @click="$refs.dialog.close()" outlined>{{ $t('components.user_profile.reset_password_modal_cancel') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';

export default {
  name: 'update-password-modal',

  setup(props, { root }) {
    const { executeContent, executeForm, working } = safeExecute(root.$store);
    return {
      executeContent,
      working,
      executeForm,
    };
  },

  data: () => {
    return {
      resetPasswordSent: false,
      init: false,
    };
  },

  methods: {
    async resetPassword() {
      await this.executeForm({
        name: 'myAccountView',
        action: 'security/resetPassword',
        success: () => {
          this.resetPasswordSent = true;
        },
      });
    },

    onOpen() {
      this.resetPasswordSent = false;
      this.init = true;
    },

    onClose() {
      this.$nextTick(() => {
        this.init = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.expiration-warning {
  margin-top: var(--gap-xsmall);
  color: var(--color-warning);
}
</style>
