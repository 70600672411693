<template>
  <div class="success-gift-certificate-offer-no-card">
    <h2>{{ $t('components.success_gift_certificate_offer_no_card.title') }}</h2>
    <voucher-amount :style="{ maxWidth: 'fit-content', marginTop: '10px' }" :voucher="currentRedeemResult" />
    <p class="h-text-3">{{ $t('components.success_gift_certificate_offer_no_card.sub_title') }}</p>
    <h-link @click="closeHeaderDrawer">{{ $t('components.success_gift_certificate_offer_no_card.cta_close') }}</h-link>
    <overlay-request-card :redirect-link="cardApplyRedirectLink" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import OverlayRequestCard from '../../components/OverlayRequestCard.vue';
import VoucherAmount from '@/components/vouchers/VoucherAmount.vue';

export default {
  components: { OverlayRequestCard, VoucherAmount },
  name: 'success-gift-certificate-offer-no-card',
  computed: {
    ...mapGetters('overlayRedeem', ['currentRedeemResult']),
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_offer_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_offer_label', 'en'),
      };
    },
  },
  methods: {
    ...mapActions('overlay', ['closeHeaderDrawer']),
  },
};
</script>

<style lang="scss" scoped>
.success-gift-certificate-offer-no-card {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  .v-btn {
    align-self: flex-start;
  }
}
</style>
