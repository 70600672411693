<template>
  <fragment>
    <v-progress-circular v-if="!init" :style="{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" :color="brandingColor" indeterminate />
    <div v-else>
      <account-email-pending-verification v-if="authenticated && emailNeedVerification && !canRequestCard" />
      <account-card-required v-if="authenticated && canRequestCard" :redirect-link="cardApplyRedirectLink" />

      <!-- Header -->
      <h-hero-banner-partner :img-source-mobile="currentBrandingHorizontalLogo" :img-source-desktop="currentBrandingHeroSmall"></h-hero-banner-partner>

      <!-- Back -->
      <h-btn @click="backSpace" color="text-primary" inverted class="back-btn">
        <v-icon>mdi-menu-left</v-icon>
        {{ backButtonText }}
      </h-btn>

      <!-- Spinner -->
      <div class="text-center" v-if="!dataReady">
        <v-progress-circular :size="100" :width="7" color="var(--color-branding)" indeterminate />
      </div>

      <div v-else>
        <component :is="bigCardComponent" class="main-card" />
        <store-list :show-store-list="showStoreList"></store-list>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import AccountEmailPendingVerification from '../../account/AccountEmailPendingVerification.vue';
import AccountCardRequired from '../../account/AccountCardRequired.vue';
import BonusOfferPublisherDetail from '@/components/offer-publisher-detail/BonusOfferPublisherDetail.vue';
import GiftCertificateOfferPublisherDetail from '@/components/offer-publisher-detail/GiftCertificateOfferPublisherDetail.vue';
import CategoryFilterBar from '../../search/bar/CategoryFilterBar.vue';
import StoreList from '@/components/stores/StoreList.vue';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  components: {
    AccountEmailPendingVerification,
    AccountCardRequired,
    BonusOfferPublisherDetail,
    GiftCertificateOfferPublisherDetail,
    CategoryFilterBar,
    StoreList,
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    let offer;

    if (this.$route.params.offer_id) {
      offer = await this.getOfferById(this.$route.params.offer_id);
    } else {
      offer = await this.getOfferBySlug(this.$route.params.slug);
    }

    // If no offer route to not found
    if (!offer) {
      this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
      return;
    }

    this.publisher_id = this.$route.params.publisher_id;
    this.offer_id = offer.id;

    if (this.currentOfferIsBonus) {
      this.bigCardComponent = 'bonus-offer-publisher-detail';
    }
    if (this.currentOfferIsGift) {
      this.bigCardComponent = 'gift-certificate-offer-publisher-detail';
    }

    this.init = true;
    await this.getOfferPublisherAndStore();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.fromRoute = from;
      }
    });
  },
  title: (args) => args.currentBrandingName,
  name: 'offer-publisher-detail-view',
  data() {
    return {
      fromRoute: null,
      init: false,
      dataReady: false,
      bigCardComponent: undefined,
      offer_id: undefined,
      publisher_id: undefined,
    };
  },
  watch: {
    currentLocale() {
      this.$title = this.currentBrandingName;
    },
  },
  computed: {
    ...mapGetters('offer', ['currentOfferShowStoreList', 'currentOfferIsBonus', 'currentOfferIsGift']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId', 'currentBrandingName', 'currentBrandingHeroSmall', 'brandingColor', 'currentBrandingHorizontalLogo']),
    ...mapGetters('account', ['canRequestCard', 'emailNeedVerification']),
    ...mapGetters('security', ['authenticated']),
    showStoreList() {
      return this.preview || this.currentOfferShowStoreList;
    },
    backButtonText() {
      if (this.fromRoute) {
        return this.$t('components.offer_card_big.back_other');
      }
      return this.$t('components.offer_card_big.back_action', { space: this.currentBrandingName });
    },
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_home_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_home_label', 'en'),
      };
    },
  },
  methods: {
    async getOfferBySlug(slug) {
      return this.execute(
        {
          action: 'offer/getOfferDetailBySlug',
        },
        { partnerId: this.currentPartnerId, slug },
      );
    },

    async getOfferById(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferDetail',
        },
        offer_id,
      );
    },
    backSpace() {
      if (this.fromRoute) {
        this.$router.pushNoDuplicate(this.fromRoute);
      } else {
        this.$router.pushNoDuplicate({ name: 'spaceOfferPublisher', params: { offer_id: this.offer_id, space: this.currentSpaceSlug } });
      }
    },
    async getOfferPublisherAndStore() {
      this.dataReady = false;

      const publisher = await this.execute(
        {
          action: 'offer/getOfferPublisher',
        },
        {
          offer_id: this.offer_id,
          publisher_id: this.publisher_id,
        },
      );

      if (!publisher) {
        this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
      } else {
        await this.getOfferPublisherStore(null);
        this.dataReady = true;
      }
    },
    async getOfferPublisherStore(category_to_include) {
      return this.execute(
        {
          action: 'store/updateQuery',
        },
        { publisher_id: this.publisher_id, offer_id: this.offer_id, category: category_to_include },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.h-hero-banner-partner {
  margin-bottom: var(--gap);
}

.v-btn.back-btn {
  min-width: 0 !important;
  height: 60px;
  padding: 0 24px;
}

.main-card {
  margin-bottom: 12px;
}
</style>
