// import {crowFundingFeatureIsActive, isClosed, isComingSoon, isSoldOut} from '@/composables/offer-helper';

import _get from 'lodash/get';

export function crowFundingFeatureIsActive(offer) {
  return _get(offer, 'features.crowdfunding', false);
}

export function isClosed(offer) {
  return _get(offer, 'status') === 'closed';
}

export function isComingSoon(offer) {
  return _get(offer, 'status') === 'coming_soon';
}

export function isSoldOut(offer) {
  return _get(offer, 'status') === 'sold_out';
}
