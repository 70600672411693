<template>
  <div class="consumer-gift-card-carousel">
    <img class="main-image" :src="imagesUrls[currentImageIndex]" alt="" />

    <div class="images-feature" v-if="imagesUrls.length > 1">
      <v-btn
        :large="$vuetify.breakpoint.xsOnly"
        :x-large="$vuetify.breakpoint.smAndUp"
        :class="{ darken: index !== currentImageIndex }"
        v-for="(igm, index) of imagesUrls.slice(0, 3)"
        :key="index"
        class="image-btn"
        :style="`background-image: url(${igm}})`"
        @click="select(index)"
      ></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'carousel',
  props: {
    imagesUrls: {
      type: Array,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
    };
  },
  methods: {
    select(index) {
      this.currentImageIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-image {
  display: block;
  width: 100%;
  height: auto;

  @media ($md-min) {
    height: 220px;
    width: auto;
  }
  border-radius: var(--border-radius);
  margin-bottom: var(--gap);
}

.images-feature {
  display: flex;
  justify-content: space-evenly;
  height: 68px;
  align-items: center;
}

.image-btn {
  border-radius: var(--border-radius);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &.darken {
    filter: grayscale(100%) opacity(50%);
  }
}
</style>
