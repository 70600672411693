<template>
  <div class="account-email-pending-verification">
    <h3 class="header">{{ $t('components.account_email_pending_verification.message') }}</h3>
    <div class="below-header">
      <p class="mb-0">{{ $t('components.account_email_pending_verification.sub_message') }}</p>
      <h-btn @click="sendVerificationEmail" :loading="working" class="btn" outlined color="ultra-light" :disabled="working">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.account_email_pending_verification.action_mobile' : 'components.account_email_pending_verification.action')
      }}</h-btn>
    </div>

    <account-email-verification-modal v-model="modalIsOpen" />
  </div>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import AccountEmailVerificationModal from '@/components/account/components/AccountEmailVerificationModal';

export default {
  name: 'account-email-pending-verification',
  components: { AccountEmailVerificationModal },
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
    };
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    sendVerificationEmail() {
      this.executeForm({
        action: 'security/resetEmailVerification',
        success: () => {
          this.modalIsOpen = true;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-email-pending-verification {
  padding: var(--gap) var(--gap-page-side);
  background-color: var(--color-error);
  color: var(--color-ultra-light);
}

.header {
  margin-bottom: var(--gap-small);
}

.below-header {
  @media ($md-min) {
    display: flex;
  }
}

.btn {
  margin: var(--gap-large) 0 0;

  @media ($md-min) {
    margin: 0 0 0 var(--gap-large);
  }
}
</style>
