import { computed, ref, watch } from '@vue/composition-api';

export default function createLogoutNavbarItem(store, router, execute, DefaultNavigationItem, context) {
  const currentRoute = ref(router.currentRoute.name);

  watch(
    () => context.root.$route,
    () => {
      currentRoute.value = context.root.$route.name;
    },
  );
  const isInstantActivationPage = computed(() => currentRoute.value === 'instant-card-activation');
  const isHidden = computed(() => isInstantActivationPage.value);

  async function clickAction() {
    await execute({
      name: 'global',
      action: 'security/userSignOut',
      success: () => {
        if (store.getters['space/currentSpaceSlug']) {
          router.replace({ name: 'spaceHome', params: { space: store.getters['space/currentSpaceSlug'] } }).catch(() => {});
        } else {
          router.replace({ name: 'home' }).catch(() => {});
        }
      },
    });
  }

  return new DefaultNavigationItem('components.navbar.logout', { isEnable: true, isHidden: isHidden }, clickAction);
}
