<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" error>
    <p>{{ text }}</p>
  </h-dialog>
</template>

<script>
export default {
  name: 'overlay-request-card-error-modal',
  props: {
    text: {
      type: String,
      default: undefined,
    },
  },
};
</script>
