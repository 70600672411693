import _min from 'lodash/min';
import _reduce from 'lodash/reduce';

export function determineStatus(offerStatus, publisherStatus) {
  if (offerStatus === 'available' && publisherStatus === 'available') {
    return 'available';
  }

  if (offerStatus === 'available') {
    return publisherStatus;
  }

  return offerStatus;
}

export function isStatusEqual(offerStatus, publisherStatus, status) {
  const currentStatus = determineStatus(offerStatus, publisherStatus);

  return currentStatus === status;
}

export function remainingAmount(offer_total_amount, offer_used_amount, offer_bonus_display, publisher_total_amount = -1, publisher_used_amount) {
  const offerRemaining = ((offer_total_amount - offer_used_amount) * 100) / offer_bonus_display;

  if (publisher_total_amount < 0 || publisher_total_amount === null || publisher_total_amount === undefined) {
    return offerRemaining;
  }

  const publisherRemaining = ((publisher_total_amount - publisher_used_amount) * 100) / offer_bonus_display;

  return _min([offerRemaining, publisherRemaining]);
}

export function totalAmount(offer_total_amount, offer_bonus_display, publisher_total_amount = -1) {
  const offerTotalAmount = offer_total_amount * (100 / offer_bonus_display);

  if (publisher_total_amount < 0 || publisher_total_amount === null || publisher_total_amount === undefined) {
    return offerTotalAmount;
  }

  const publisherTotalAmount = publisher_total_amount * (100 / offer_bonus_display);
  return _min([offerTotalAmount, publisherTotalAmount]);
}

export function disablePlus(currentAmount, step, maximum, remaining = -1) {
  if (remaining >= 0) {
    return currentAmount + step > _min([remaining, maximum]);
  }
  return currentAmount + step > maximum;
}

export function disableMinus(currentAmount, step, minimum, remaining = -1) {
  if (remaining >= 0) {
    return _min([remaining, currentAmount - step]) < minimum;
  }

  return currentAmount - step < minimum;
}

export function addAmount(paymentAmount, step, default_payment) {
  if (!paymentAmount && paymentAmount !== 0) {
    return default_payment;
  }

  return paymentAmount + step;
}

export function removeAmount(paymentAmount, step, default_payment) {
  if (!paymentAmount && paymentAmount !== 0) {
    return default_payment;
  }

  return paymentAmount - step;
}

export function findSuggestedAmount(amounts, min_amount, max_amount) {
  const selected_category = _reduce(
    amounts,
    (acc, amount) => {
      if (isNaN(amount)) {
        acc.push(amount);
        return acc;
      }
      if (amount < min_amount || amount > max_amount) {
        return acc;
      }
      acc.push(amount);
      return acc;
    },
    [],
  );

  if (selected_category.length === 1) {
    return [];
  }

  return selected_category;
}
