<template>
  <div class="payment-detail-final">
    <span class="h-text-3">{{ $t('components.payment_detail_final.label') }}</span>
    <span class="h-heading-h1">{{ amount | currency_full | currencySymbol }}</span>
  </div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      default: 10.0,
    },
  },
  name: 'payment-detail-final',
};
</script>

<style lang="scss" scoped>
.payment-detail-final {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
.h-text-3 {
  color: var(--color-text-secondary);
  font-weight: 600;
}
</style>
