<template>
  <div>
    <v-overlay :value="paymentWorking">
      <v-progress-circular :size="50" indeterminate color="ultra-light" />
    </v-overlay>
    <pay-and-get-offer v-if="offer" :branding="branding" :support-branding="supportBranding" :restricted="restricted" />
    <pay-and-get-purchase-type @cardSelected="setCurrentCard" class="mt-5" :show-card-selection="showCardSelection" :cards="currentPaymentInformation.cards" />
    <pay-and-get-editable class="mt-5" :offer="offer" :publisher="publisher" :payment-info="currentPaymentInformation" />
    <payment-crowdfunding-feature v-if="crowFundingFeature" :offer="offer" class="mt-5" />
    <pay-and-get-offer-payment
      @completed="$emit('completed')"
      v-if="currentPaymentOfferIsAvailable"
      class="mt-5"
      :partner_id="headerDrawerContentPayload.partner_id"
      :cardholder_id="currentCardholderId"
      :offer="offer"
      :publisher-category-id="publisher.category_id"
      :publisher_id="publisher.publisher_id"
      :need-consent="currentPaymentInformation.need_consent"
      :valid-context="currentPaymentValidContext"
      :payment-info="currentPaymentInformation"
      :space="space"
    />
  </div>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { crowFundingFeatureIsActive } from '@/composables/offer-helper';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';

import PayAndGetEditable from '../../pay-and-get/PayAndGetEditable.vue';
import PayAndGetPurchaseType from '../../pay-and-get/PayAndGetPurchaseType.vue';
import PayAndGetOffer from '../../pay-and-get/PayAndGetOffer.vue';
import PayAndGetOfferPayment from '../../pay-and-get/PayAndGetOfferPayment.vue';
import PaymentCrowdfundingFeature from '../../pay-and-get/components/PaymentCrowdfundingFeature.vue';

export default {
  setup(props, { root }) {
    const { executeTarget, execute, working } = safeExecute(root.$store);
    return {
      execute,
      executeTarget,
      working,
    };
  },
  name: 'purchase-payment',
  components: { PayAndGetEditable, PayAndGetPurchaseType, PayAndGetOffer, PayAndGetOfferPayment, PaymentCrowdfundingFeature },
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    paymentInfo: {
      type: Object,
    },
    space: {
      type: Object,
    },
  },
  watch: {
    offer() {
      this.setPaymentAmountForPurchase(_get(this.offer, 'incentive.default_payment', 0));
    },
  },
  computed: {
    ...mapGetters('payment', ['paymentWorking']),
    ...mapGetters('overlay', ['headerDrawerContentPayload']),
    ...mapGetters('overlayPayment', ['currentCardStatus', 'currentCardholderId', 'currentPaymentValidContext', 'currentPaymentOfferIsAvailable', 'showCardSelection', 'currentPaymentInformation']),
    crowFundingFeature() {
      return crowFundingFeatureIsActive(this.offer);
    },
    branding() {
      return this.offer.is_single_publisher ? this.offer.offer_branding : this.publisher.branding;
    },
    supportBranding() {
      return this.offer.is_single_publisher ? {} : this.offer.offer_branding;
    },
    restricted() {
      return _get(this.offer, 'features.restricted_to_members', false);
    },
  },
  methods: {
    ...mapActions('overlayPayment', ['setCurrentCard']),
    ...mapActions('payment', ['setPaymentAmountForPurchase']),
  },
};
</script>

<style lang="scss" scoped>
.pay-and-get-offer,
.pay-and-get-editable {
  margin-bottom: var(--gap-xlarge);
}

.pay-and-get-purchase-type,
.payment-crowdfunding-feature {
  margin-bottom: var(--gap-large);
}
</style>
