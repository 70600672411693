<template>
  <v-img position="center left" contain :style="{ maxWidth: `${5 * size}px`, maxHeight: `${2 * size}px`, minHeight: `${2 * size}px` }" src="@/assets/logo-lpb-black-label.svg"></v-img>
</template>
<script>
export default {
  name: 'h-local-logo-chip',
  inheritAttrs: false,
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: '#154396',
    },
  },
};
</script>
