<template>
  <v-form v-bind="$attrs" v-on="$listeners" ref="form">
    <slot></slot>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'h-form',
  inheritAttrs: false,
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    currentLocale() {
      this.$refs.form.validate();
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    validationReset() {
      return this.$refs.form.resetValidation();
    },
    reset() {
      return this.$refs.form.reset();
    },
  },
};
</script>
