<template>
  <v-dialog :value="headerDrawerIsOpen" @input="internalClose" max-width="1200" transition="header-drawer-transition" width="100%" content-class="header-drawer">
    <div class="dialogHeader" :class="{ 'has-shadow': addShadowToHeader }" :style="{ height: `${$vuetify.application.top}px` }">
      <v-btn :to="{ name: 'home' }" @click="closeHeaderDrawer" color="secondary" class="logo-btn" depressed :aria-label="$t('components.navbar.logo_alt_text')" />
      <div>
        <v-btn @click="localNavigationItem.clickAction" color="secondary" class="close-btn">{{ $t(localNavigationItem.name) }}</v-btn>
        <v-btn @click="closeHeaderDrawer" color="secondary" class="close-btn">{{ $t('components.navbar.close') }}</v-btn>
      </div>
    </div>

    <div class="header-drawer-content" ref="headerDrawerContentDiv">
      <div class="inner-content">
        <v-overlay :absolute="true" :value="showOverlay">
          <!-- Spinner -->
          <div>
            <v-progress-circular :size="100" :width="7" color="ultra-light" indeterminate />
          </div>
        </v-overlay>
        <component v-if="headerDrawerIsOpen" ref="drawerContent" :is="headerDrawerContentMap[headerDrawerContent]" @startInitialization="() => (this.showOverlay = true)" @initialized="() => (this.showOverlay = false)" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import setupNavigation from '@/composables/navbar-items';
import safeExecute from '@/composables/safe-execute';

import AboutOverlay from '@/components/overlays/AboutOverlay';
import AuthOverlay from '@/components/overlays/AuthOverlay';
import ClaimGiftCertificateSignInOverlay from '@/components/overlays/ClaimGiftCertificateSignInOverlay';
import PurchaseSignInOverlay from '@/components/overlays/PurchaseSignInOverlay';
import PurchaseSignInConsumerGiftCardOverlay from '@/components/overlays/PurchaseSignInConsumerGiftCardOverlay';

export default {
  name: 'header-drawer',
  setup(props, context) {
    const { execute } = safeExecute(context.root.$store);
    const { navigationItemsMap, initDefault } = setupNavigation(context.root.$store, context.root.$router, execute, context);
    const localNavigationItem = navigationItemsMap.localeNavigationItem;
    initDefault();

    const headerDrawerContentMap = {
      about: 'about-overlay',
      auth: 'auth-overlay',
      redeem: 'claim-gift-certificate-sign-in-overlay',
      purchase: 'purchase-sign-in-overlay',
      purchaseGiftCardOverlay: 'purchase-sign-in-consumer-gift-card-overlay',
    };
    return {
      localNavigationItem,
      headerDrawerContentMap,
    };
  },
  components: {
    AboutOverlay,
    AuthOverlay,
    PurchaseSignInOverlay,
    ClaimGiftCertificateSignInOverlay,
    PurchaseSignInConsumerGiftCardOverlay,
  },
  data() {
    return {
      addShadowToHeader: false,
      showOverlay: false,
    };
  },
  computed: {
    ...mapGetters('overlay', ['headerDrawerIsOpen', 'headerDrawerContent']),
    ...mapGetters('security', ['authenticated']),
  },
  updated() {
    /*
    How does the header drawer work. A short explanation:

    The header drawer is a modified native Vuetify dialog component (v-dialog). We use a v-dialog because it features built in mechanics and
    accessibility features such as:

    - Focus redirect (the focus is brought inside the drawer when it opens)
    - Focus return (When the drawer is closed, the focus will be returned to the element that opened it)
    - Focus trap (you can't tab out of the drawer or focus anything outside the drawer while it's open)
    - Scroll management (page scroll is mostly locked while the drawer is open)
    - Close features (click outside and esc key will close the drawer)

    By nature, a v-dialog is displayed on top of "everything else" on the page with a higher z-index. This creates an issue because we want the drawer
    to appear from behind the app header. To achieve the desired effect, we need to augment the z-index of the app header while the drawer appears
    or disapear. We can't let the app header be above the drawer permanently because of the above-mentioned focus trap. Since the app header is not
    part of the drawer the user can not tab in the app header while the drawer is open. This would cause accessibility and usability issues.

    The solution is to temporarily move the app header above the drawer only while it's transitioning into or out of view. We use transitioning events
    to detect when the drawer starts and finishes to appear and disappear from view. We then emit a custom event to notify the parent component of the
    drawer's behaviours. The z-index of the app header is then managed from there to achieve the desired behaviour.
    */
    const headerDrawer = document.querySelector('.header-drawer.v-dialog');
    if (headerDrawer) {
      headerDrawer.ontransitionrun = (event) => {
        if (event.target === headerDrawer) {
          this.$emit('transitioning', true);
          if (!this.headerDrawerIsOpen) {
            this.addShadowToHeader = false;
          }
        }
      };
      headerDrawer.ontransitionend = (event) => {
        if (event.target === headerDrawer) {
          this.$emit('transitioning', false);
          if (this.headerDrawerIsOpen) {
            this.addShadowToHeader = true;
          }
        }
      };
      headerDrawer.ontransitioncancel = (event) => {
        if (event.target === headerDrawer) {
          this.$emit('transitioning', false);
        }
      };
    }
  },
  watch: {
    headerDrawerIsOpen() {
      if (this.headerDrawerIsOpen === true) {
        this.showOverlay = false;
        this.$nextTick(async () => {
          this.$refs.headerDrawerContentDiv.scrollTop = 0;
          if (this.$refs.drawerContent && this.$refs.drawerContent.init) {
            this.$refs.drawerContent.init();
          }
        });
      } else {
        this.clearError();
        if (this.$refs.drawerContent && this.$refs.drawerContent.clear) {
          this.$refs.drawerContent.clear();
        }
      }
    },
    headerDrawerContent(newVal, oldVal) {
      // Used for subscription to payment because the drawer does not close it just switch
      if (oldVal === 'subscription' && newVal === 'purchase') {
        this.$nextTick(async () => {
          this.$refs.headerDrawerContentDiv.scrollTop = 0;
          if (this.$refs.drawerContent && this.$refs.drawerContent.init) {
            this.$refs.drawerContent.init();
          }
        });
      }
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    ...mapActions('overlay', ['closeHeaderDrawer']),
    internalClose(inputValue) {
      if (!inputValue) {
        this.clearError();
        if (this.$refs.drawerContent && this.$refs.drawerContent.clear) {
          this.$refs.drawerContent.clear();
        }
        this.closeHeaderDrawer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .header-drawer-transition {
  &-enter {
    transform: translateY(-100%);
  }

  &-leave-to {
    transform: translateY(-100%);
  }
}

::v-deep .header-drawer.v-dialog {
  align-self: flex-start;
  flex: 0 1 auto;
  margin: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  transition-delay: 15ms;
}

.dialogHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  background-color: var(--color-secondary);

  &.has-shadow {
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    z-index: 1;
  }
}

.logo-btn.v-btn {
  width: 97px;
  height: 46px;
  margin-left: calc(var(--gap-page-side) / 2);
  padding-left: calc(var(--gap-page-side) / 2);
  padding-right: calc(var(--gap-page-side) / 2);
  background: url('~@/assets/logo.svg') center center no-repeat;
  background-size: 97px;
  box-sizing: content-box;

  &:not(:hover):not(:focus)::before {
    opacity: 0;
  }
}

.close-btn {
  padding: 0 var(--gap-xsmall) !important;
  margin-right: calc(var(--gap-page-side) - var(--gap-xsmall));
}

.close-btn ::v-deep .v-btn__content {
  letter-spacing: 0.0892857143em;
  line-height: 1.25;
}

.header-drawer-content {
  overflow-y: auto;
  background-color: white;
  flex: 1 1 auto;
}

.inner-content {
  position: relative;
}
</style>
