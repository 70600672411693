import Vue from 'vue';

const service = {
  async trackVisit(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/track/visit`, payload);

    return response.data.data;
  },
};

export default service;
