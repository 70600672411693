import Vue from 'vue';

/*
// NOTE: Debug
function sleep(ms = 3000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
} */

function extractFileNameFromHeaders(headers) {
  return headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .slice(1, -1);
}

const service = {
  async acquireConsumerGiftCardContext(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/offers/acquiring-consumer-gift-card-context`, payload);
    return response.data.data;
  },

  async createPurchaseConsumerGiftCardOrder(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v2/consumer-gift-card-orders/purchase`, payload);
    return response.data.data;
  },

  // async acquireConsumerGiftCardIntent(payload) {
  //   const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v3/consumer-gift-card-orders/intent`, payload);
  //   return response.data.data;
  // },

  async getOrderDetails(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/consumer-gift-card-orders/${id}`);
    return response.data.data;
  },

  async listConsumerGiftCardVouchersByAccount() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/v1/account/consumer-gift-card-vouchers`);
    return response.data.data.items;
  },

  async sendConsumerGiftCardByEmail(voucher_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/vouchers/${voucher_id}/activation-codes/send-email`, payload);
    return response.data.data;
  },

  async downloadGiftCard({ voucher_id, lang = en }) {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/vouchers/${voucher_id}/activation-codes/export/pdf`, { lang }), { responseType: 'blob' });
    return response.status === 200 ? response.data : null;
  },

  async downloadReceipt(consumer_gift_card_order_id, lang) {
    const response = await Vue.prototype.$axios.get(generatePath(`${process.env.VUE_APP_BASE_API_URL}/v1/consumer-gift-card-orders/${consumer_gift_card_order_id}/invoice`, { lang }), { responseType: 'blob' });
    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
};

function generatePath(startPath, { lang }) {
  let path = startPath;
  if (lang) {
    path += '?';
  }

  const queryStrings = [];
  if (lang) {
    queryStrings.push(['lang', lang]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
