<template>
  <h-dialog @open="resetForm" ref="dialog" :title="modalTitle" class="corpo-company-modal" max-width="834">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="editMode" v-on="on" v-bind="attrs" icon color="primary" :aria-label="$t('components.corpo_company_modal.edit')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <h-link v-else v-on="on" v-bind="attrs" bold>{{ $t('components.corpo_company_modal.add') }}</h-link>
    </template>

    <corpo-company-form ref="form" target="corporate_form" :initial-company="initialCompany" :edit-mode="editMode" @noAccessError="noAccessError" @success="refreshCompanies">
      <template v-slot="{ valid, working }">
        <h-action-layout class="action-zone">
          <h-btn type="submit" :disabled="!valid || working" :loading="working">{{ $t('components.corpo_company_modal.save') }}</h-btn>
          <h-btn @click="$refs.dialog.close()" :disabled="working" outlined>{{ $t('components.corpo_company_modal.cancel') }}</h-btn>
        </h-action-layout>
      </template>
    </corpo-company-form>
  </h-dialog>
</template>

<script>
import CorpoCompanyForm from '@/components/corpo/order-process/CorpoCompanyForm';
import safeExecute from '@/composables/safe-execute';
import { mapActions } from 'vuex';

export default {
  name: 'corpo-company-modal',
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  components: { CorpoCompanyForm },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    initialCompany: {
      type: Object,
      default: () => ({
        name: '',
        contact: {
          name: '',
          email: '',
          phone: '',
        },
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          country: 'CA',
        },
      }),
    },
  },
  computed: {
    modalTitle() {
      return this.editMode ? this.$t('components.corpo_company_modal.edit') : this.$t('components.corpo_company_modal.add');
    },
  },
  methods: {
    ...mapActions('error', ['clearError', 'addError', 'useTargetError']),
    ...mapActions('corpo', ['getCompaniesAndSelectDefault']),
    resetForm() {
      this.clearError();
      if (this.$refs.form) {
        this.$refs.form.resetForm();
        this.$refs.form.validationReset();
      }
    },
    async noAccessError(err) {
      this.closeModal();
      this.useTargetError('companyStep');
      this.addError(err);
      try {
        await this.getCompaniesAndSelectDefault();
      } catch (err) {}
    },
    closeModal() {
      this.$refs.dialog.close();
    },
    async refreshCompanies() {
      this.closeModal();
      await this.executeTarget({
        action: 'corpo/getCompaniesAndSelectDefault',
        target: 'companyStep',
        success: () => {
          this.$emit('next');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-zone {
  margin-top: var(--gap);
}
</style>
