<template>
  <big-card-base
    :offer-title="currentOfferName"
    :offer-label="currentOfferCategory | formatCategoryRegion(currentOfferRegion)"
    :offer-description="currentOfferDescription"
    :carousel-images="currentOfferShowcasesImage"
    :show-panier-bleu="currentOfferBrandingFeaturePanierBleu"
    :restricted-to-members="currentOfferRestrictedToMembers"
  >
    <template #chip>
      <h-chip-small
        v-if="$vuetify.breakpoint.xsOnly"
        :pay="amount | currency | currencySymbol"
        :obtain="amountObtained | currency | currencySymbol"
        :plus-action="plus"
        :minus-action="minus"
        :disable-plus="disablePlus(amount, currentOfferIncentiveStep, currentOfferIncentivePurchaseLimit, amountLeft)"
        :disable-minus="disableMinus(amount, currentOfferIncentiveStep, currentOfferIncentiveMinimumPayment, amountLeft)"
      />
      <h-chip-big
        v-else
        :compact="$vuetify.breakpoint.smOnly"
        :pay="amount | currency | currencySymbol"
        :obtain="amountObtained | currency | currencySymbol"
        :percentage="currentOfferIncentiveBonusDisplay | bonusSymbol"
        :plus-action="plus"
        :minus-action="minus"
        :disable-plus="disablePlus(amount, currentOfferIncentiveStep, currentOfferIncentivePurchaseLimit, amountLeft)"
        :disable-minus="disableMinus(amount, currentOfferIncentiveStep, currentOfferIncentiveMinimumPayment, amountLeft)"
      />
    </template>

    <template #extraContent>
      <h-progress-bar
        :status="currentOfferStatus"
        :current-number="currentOfferAvailabilityAdjustedAvailPaymentAmount"
        :total-number="currentOfferAvailabilityAdjustedTotalPaymentAmount"
        :min-purchase="currentOfferIncentiveMinimumPayment"
        :max-purchase="currentOfferIncentiveAdjustPurchaseLimit"
        :start="currentOfferStartDate"
        :expiration="currentOfferEndDate"
      />
    </template>

    <template #actionSection>
      <h-btn v-if="currentOfferCtaActivation" :disabled="currentOfferStatus !== 'available'" color="var(--color-branding)" block large @click="openOverlayForPayment">{{ $t('components.bonus_offer_single_publisher_big_card.call_to_action') }}</h-btn>

      <offer-card-error-modal v-model="errorModalActive" @close="closeErrorModal" :error-message-code="errorMessageCode" />
    </template>
  </big-card-base>
</template>
<script>
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import BigCardBase from '@/components/cards/big/BigCardBase.vue';
import { addAmount, disableMinus, disablePlus, remainingAmount, removeAmount, totalAmount } from '@/composables/offer-publisher-helper';
import OfferCardErrorModal from '@/components/cards/components/OfferCardErrorModal.vue';

export default {
  name: 'single-publisher-bonus-offer',
  components: { BigCardBase, OfferCardErrorModal },
  setup(props, { root }) {
    const { executeContent } = safeExecute(root.$store);
    return {
      executeContent,
      remainingAmount,
      totalAmount,
      disablePlus,
      disableMinus,
      addAmount,
      removeAmount,
    };
  },
  data() {
    return {
      amount: 0,
      errorModalActive: false,
      errorMessageCode: null,
    };
  },
  mounted() {
    // Used to init if using the same offer
    this.amount = this.paymentAmount;
  },
  watch: {
    paymentAmount() {
      // Used to init if change between offer
      this.amount = this.paymentAmount;
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('payment', ['paymentAmount']),
    ...mapGetters('space', ['currentPartnerId']),
    ...mapGetters('offer', [
      'currentOfferId',
      'currentOfferName',
      'currentOfferPublisherPublisherId',
      'currentOfferStatus',
      'currentOfferRegion',
      'currentOfferAvailabilityAdjustedAvailPaymentAmount',
      'currentOfferAvailabilityAdjustedTotalPaymentAmount',
      'currentOfferEndDate',
      'currentOfferStartDate',
      'currentOfferDescription',
      'currentOfferIncentiveStep',
      'currentOfferIncentiveBonusDisplay',
      'currentOfferIncentiveBonusPercent',
      'currentOfferIncentivePurchaseLimit',
      'currentOfferIncentiveAdjustPurchaseLimit',
      'currentOfferIncentiveMinimumPayment',
      'currentOfferShowcasesImage',
      'currentOfferBrandingFeaturePanierBleu',
      'currentOfferCategory',
      'currentOfferRestrictedToMembers',
      'currentOfferPublisherUsedInventory',
      'currentOfferPublisherTotalInventory',
      'currentOfferTotalInventory',
      'currentOfferUsedInventory',
      'currentOfferCtaActivation',
    ]),
    amountObtained() {
      return (1 + this.currentOfferIncentiveBonusPercent) * this.amount;
    },
    amountLeft() {
      return this.remainingAmount(this.currentOfferTotalInventory, this.currentOfferUsedInventory, this.currentOfferIncentiveBonusDisplay, this.currentOfferPublisherTotalInventory, this.currentOfferPublisherUsedInventory);
    },
  },
  methods: {
    plus() {
      this.amount = this.addAmount(this.amount, this.currentOfferIncentiveStep, this.paymentAmount);
    },
    minus() {
      this.amount = this.removeAmount(this.amount, this.currentOfferIncentiveStep, this.paymentAmount);
    },
    async openOverlayForPayment() {
      this.executeContent(
        {
          action: 'overlay/openHeaderDrawerForPayment',
          afterError: (err) => {
            if (err.errorType === 'http') {
              this.errorModalActive = true;
              this.errorMessageCode = err.code;
            }
          },
        },
        {
          payload: {
            partner_id: this.currentPartnerId,
            offer_id: this.currentOfferId,
            publisher_id: this.currentOfferPublisherPublisherId,
            offer_restricted_to_member: this.currentOfferRestrictedToMembers,
          },
        },
      );
    },
    async refreshCurrentOfferAndPublisher() {
      return this.executeContent(
        {
          action: 'offer/refreshCurrentOfferAndPublisher',
        },
        {
          offer_id: this.currentOfferId,
          publisher_id: this.currentOfferPublisherPublisherId,
        },
      );
    },
    closeErrorModal() {
      this.errorMessageCode = null;
      this.refreshCurrentOfferAndPublisher();
    },
  },
};
</script>
