<template>
  <div class="h-action-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'h-action-layout',
};
</script>

<style lang="scss" scoped>
.h-action-layout {
  display: block;

  @media ($sm-min) {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    gap: var(--gap);
    justify-content: center;
  }

  ::v-deep > * {
    width: 100%;

    &:not(:first-child) {
      margin-top: var(--gap);
      @media ($sm-min) {
        margin-top: 0;
      }
    }

    @media ($sm-min) {
      width: auto;
      flex-basis: 0;
      flex-grow: 1;
      &:only-child {
        flex-grow: 0;
      }
    }
  }
}
</style>
