<template>
  <h-fixed-footer-dialog ref="dialog" :title="$t('components.bonus_offer_terms_and_conditions_modal.modal_title')" max-width="680">
    <template v-slot:activator="{ on, attrs }">
      <div class="terms">
        <div role="label" class="terms-label">
          {{ $t('components.bonus_offer_terms_and_conditions_modal.consent_text') }}
          <h-link v-on="on" v-bind="attrs" bold class="terms-action">{{ $t('components.bonus_offer_terms_and_conditions_modal.consent_text_link') }}</h-link>
        </div>
      </div>
    </template>

    <p class="h-text-1">{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_sub_title') }}</p>
    <ul>
      <li>{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text1') }}</li>
      <li v-if="canRequestCard">{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text3') }}</li>
      <li v-else>{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text2') }}</li>
      <li>{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text4') }}</li>
      <li>{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text5') }}</li>
      <li v-if="categoryIsMixedPlace">{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text6') }}</li>
      <li v-if="dormancyEnabled && dormancyExtensionInMonths > 0">
        {{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text7_with_extension', { dormancyMonths: dormancyTrigger, dormancyFixedFee: formatDormancyFixedFee, dormancyExtendedMonths: dormancyTrigger + dormancyExtensionInMonths }) }}
      </li>
      <li v-else-if="dormancyEnabled">{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text7', { dormancyMonths: dormancyTrigger, dormancyFixedFee: formatDormancyFixedFee }) }}</li>
      <li v-if="isCashFlowOptimization">{{ $t('components.bonus_offer_terms_and_conditions_modal.modal_text8') }}</li>
    </ul>

    <template #action-footer>
      <h-btn @click="closeTermAndConditionsModal" large>{{ $t('components.bonus_offer_terms_and_conditions_modal.action_title') }}</h-btn>
    </template>
  </h-fixed-footer-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  props: {
    publisherCategoryId: {
      type: String,
    },
  },
  name: 'bonus-offer-terms-and-conditions-modal',
  computed: {
    ...mapGetters('account', ['canRequestCard']),
    ...mapGetters('overlayPayment', ['currentPaymentInformation']),
    formatDormancyFixedFee() {
      const dormancyAmount = this.$options.filters.currency_full(_get(this.currentPaymentInformation, 'dormancy_fee', 0));
      return this.$options.filters.currencySymbol(this.$options.filters.currency(dormancyAmount));
    },
    dormancyEnabled() {
      return _get(this.currentPaymentInformation, 'dormancy_enabled', false);
    },
    dormancyTrigger() {
      return _get(this.currentPaymentInformation, 'dormancy_last_activity_trigger_in_months');
    },
    dormancyExtensionInMonths() {
      return _get(this.currentPaymentInformation, 'dormancy_extension_in_months');
    },
    isCashFlowOptimization() {
      return _get(this.currentPaymentInformation, 'offer_cash_flow_optimization', false);
    },
    categoryIsMixedPlace() {
      return this.publisherCategoryId === 'mixed';
    },
  },
  methods: {
    closeTermAndConditionsModal() {
      this.$refs.dialog.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--gap-xxlarge);
  margin-bottom: var(--gap-xxlarge);

  @media ($sm-min) {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
  }
}

.terms-label {
  margin-right: 3px;
}

.terms-action {
  font-size: var(--h-text-2);
  margin-top: -5px;
}
</style>
