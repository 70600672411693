import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _min from 'lodash/min';
import Vue from 'vue';

function getTaxCodeByType(taxItem) {
  switch (taxItem.type) {
    case 'gst':
      return 'components.payment_elements.gst';
    case 'sales_tax':
      return 'components.payment_elements.sales_tax';
    default:
      return taxItem.name;
  }
}

async function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
class PaymentItem {
  constructor(label, amount, isDiscount = false, metadata = {}) {
    this.label_code = label;
    this.amount = amount;
    this.isDiscount = isDiscount;
    this.metadata = metadata;
  }
  calculate(total) {
    return this.isDiscount ? total - this.amount : total + this.amount;
  }
}
export default {
  namespaced: true,
  state: {
    taxes: [],
    payment_amount: 0,
    detail_items: [],
    my_money: null, //new PaymentItem('Credit', 10.12, true),
    using_my_money: false,
    is_ready_to_pay: false,
    is_valid_context: false,

    current_payment_methods: null,
    current_payment_information: null,
    current_payment_amount: 0,
    current_payment_is_successful: false,
    current_payment_crowdfunding_feature_visible: undefined,
    current_payment_crowdfunding_feature_visible_default_when_active: true,
    payment_methods: [],
    payment_working: false,
    show_card_selection: false,
  },
  getters: {
    paymentWorking: (state) => state.payment_working,
    paymentDetails: (state) => state.detail_items,
    paymentTotal: (state) => _reduce(state.detail_items, (acc, item) => item.calculate(acc), 0) + (state.using_my_money ? state.my_money.calculate(0) : 0),

    paymentTaxesTotal: (state) => _reduce(state.taxes, (acc, item) => item.calculate(acc), 0),
    paymentTaxes: (state) => state.taxes,

    paymentUsingMyMoney: (state) => state.using_my_money,
    paymentMyMoney: (state) => state.my_money,
    paymentAmount: (state) => state.payment_amount,
    paymentMyMoneyAmount: (state) => _get(state.my_money, 'amount', 0),
    paymentMethods: (state) => state.payment_methods.slice(0, 3),
    hasPaymentMethods: (state) => state.payment_methods.length !== 0,
    isReadyToPay: (state) => state.is_valid_context && state.is_ready_to_pay,
    creditCardInfoComplete: (state) => state.is_ready_to_pay,
    currentPaymentInformation: (state) => state.current_payment_information || {},
    currentPaymentMaxPayment: (state, getters, rootState, rootGetters) => _min([rootGetters['currentOfferIncentivePurchaseLimit'], _get(state.current_payment_information, 'user_payment_remaining')]),
    currentPaymentExceedMaxPaymentBecauseOfUserRestriction: (state, getters, rootState, rootGetters) => state.payment_amount > _get(state.current_payment_information, 'user_payment_remaining'),
    currentPaymentOfferIsAvailable: (state) => _get(state.current_payment_information, 'status') === 'available',
    currentPaymentOfferIsComingSoon: (state) => _get(state.current_payment_information, 'status') === 'coming_soon',
    currentPaymentOfferIsSoldOut: (state) => _get(state.current_payment_information, 'status') === 'sold_out',
    currentPaymentOfferIsClosed: (state) => _get(state.current_payment_information, 'status') === 'closed',
    currentPaymentIsSuccessful: (state) => state.current_payment_is_successful,
    currentPaymentCrowdfundingFeatureVisibleDefaultWhenActive: (state) => state.current_payment_crowdfunding_feature_visible_default_when_active,
    showCardSelection: (state) => state.show_card_selection,
  },
  mutations: {
    currentPaymentIsSuccessful(state) {
      state.current_payment_is_successful = true;
    },
    setCurrentPaymentAmount(state, amount) {
      state.current_payment_amount = amount;
    },
    setPaymentWorking(state, working) {
      state.payment_working = working;
    },
    removePaymentMethod(state, paymentMethodId) {
      state.payment_methods = state.payment_methods.filter((paymentMethod) => paymentMethod.id !== paymentMethodId);
    },
    useMyPaymentMoney(state) {
      state.using_my_money = true;
    },
    dontUseMyPaymentMoney(state) {
      state.using_my_money = false;
    },
    setPaymentAmount(state, amount) {
      state.payment_amount = amount;
    },
    setReadyToPay(state, readyToPayStatus) {
      state.is_ready_to_pay = readyToPayStatus;
    },
    setPaymentMethod(state, paymentMethod) {
      state.current_payment_methods = paymentMethod;
    },
    setIsValidContext(state, isValidContext) {
      state.is_valid_context = isValidContext;
    },
    setCurrentPaymentInformation(state, currentPaymentInformation) {
      state.current_payment_information = currentPaymentInformation;
      state.is_valid_context = true;
    },
    setCurrentPaymentCrowdfundingFeatureVisible(state, visibleState) {
      state.current_payment_crowdfunding_feature_visible = visibleState;
    },
    reset(state) {
      state.is_valid_context = false;
      state.is_ready_to_pay = false;
      state.current_payment_crowdfunding_feature_visible = undefined;
      state.current_payment_is_successful = false;
      state.current_redeem_result = {};
      state.show_card_selection = false;
      state.current_payment_information = null;
    },
    setPaymentMethods(state, paymentMethods) {
      state.payment_methods = _map(paymentMethods, (pm) => {
        return {
          id: pm.id,
          exp_month: _get(pm, 'card.exp_month'),
          exp_year: _get(pm, 'card.exp_year'),
          last4: _get(pm, 'card.last4'),
          postal_code: _get(pm, 'billing_details.address.postal_code'),
          network: _get(pm, 'card.networks.available[0]'),
        };
      });
    },
    setTaxes(state, taxes = []) {
      state.taxes = taxes;
    },
    setDetailItems(state, detail_items = []) {
      state.detail_items = detail_items;
    },
    setShowCardSelection(state, showCardSelection) {
      state.show_card_selection = showCardSelection;
    },
  },
  actions: {
    decideIfUseMyPaymentMoney({ commit }, useMyMoney) {
      if (useMyMoney) {
        commit('useMyPaymentMoney');
      } else {
        commit('dontUseMyPaymentMoney');
      }
    },
    setPaymentAmount({ commit, state, rootGetters }, paymentAmount) {
      commit('setPaymentAmount', paymentAmount);
      commit('setDetailItems', [new PaymentItem(rootGetters['offer/currentOfferName'], state.payment_amount), ...state.detail_items.slice(1, state.detail_items.length)]);
    },
    setPaymentAmountForPurchase({ commit, state, rootGetters }, paymentAmount) {
      commit('setPaymentAmount', paymentAmount);
      commit('setDetailItems', [new PaymentItem(rootGetters['offer/currentOfferName'], state.payment_amount)]);
    },
    setCurrentPaymentAmount({ commit }, currentPaymentAmount) {
      commit('setCurrentPaymentAmount', currentPaymentAmount);
    },
    setReadyToPay({ commit }, payStatus) {
      commit('setReadyToPay', payStatus);
    },
    setPaymentMethod({ commit }, paymentMethods) {
      commit('setPaymentMethod', paymentMethods);
    },
    setPaymentCrowdfundingFeatureVisible({ commit }, visibilityStatus) {
      commit('setCurrentPaymentCrowdfundingFeatureVisible', visibilityStatus);
    },
    setCurrentCardholderId({ commit }, cardholderId) {
      commit('setCurrentCardholderId', cardholderId);
    },
    async refreshPaymentMethods({ commit }) {
      const { payment_methods } = await Vue.prototype.$services.account.accountPaymentsMethods();

      commit('setPaymentMethods', payment_methods);
    },

    async removePaymentMethod({ commit }, { id }) {
      const result = await Vue.prototype.$services.account.accountDetachPaymentMethod(id);

      commit('removePaymentMethod', id);

      return result;
    },
    async resetPaymentState({ commit }) {
      commit('setCurrentPaymentInformation', {});
    },

    async resetPaymentWorking({ commit }) {
      commit('setPaymentWorking', false);
    },

    async paymentSQUAREDONEFlow({ commit, dispatch }, result) {
      // if card is present in params use the new card
      await dispatch('account/getAccount', undefined, { root: true });
      commit('setPaymentWorking', false);
      if (result.error) {
        console.log(result.error.message); // eslint-disable-line no-console
        const err = new Error(result.error.message);
        err.custom_data = result.error;
        throw err;
      } else {
        commit('currentPaymentIsSuccessful');
      }
    },

    async paymentSubscriptionInformation({ commit, getters, dispatch }, partner_id) {
      commit('reset');
      const result = await Vue.prototype.$services.subscription.getPrePaymentSubscriptionInfo({
        partner_id,
      });

      commit('setCurrentPaymentInformation', { acquiring_token: result.acquiring_token });
      commit('setPaymentAmount', 0);
      commit(
        'setTaxes',
        _map(result.tax_items, (i) => new PaymentItem(getTaxCodeByType(i), i.amount, false, { taxNumber: i.tax_number })),
      );
      commit('setDetailItems', [new PaymentItem('components.payment_elements.subscription', result.monthly_fee), new PaymentItem('components.payment_elements.tax', getters.paymentTaxesTotal)]);
      await dispatch('refreshPaymentMethods');
      commit('setIsValidContext', true);
      return result;
    },

    async subscribe({ dispatch, commit, state, rootState }, { card, postalCode, paymentMethodId, allow_communication, recaptcha_token }) {
      commit('setPaymentWorking', true);

      const { client_secret } = await Vue.prototype.$services.subscription.createSubscriptionIntent({
        subscription_plan_id: rootState.subscription.current_subscription_plan.id,
        allow_communication,
        recaptcha_token,
        acquiring_token: state.current_payment_information.acquiring_token,
      });

      //await dispatch('paymentFlow', { card, postalCode, paymentMethodId, client_secret });

      if (window.hj) {
        hj('event', 'pay_subscription');
      }
    },
  },
};
