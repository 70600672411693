<template>
  <fragment>
    <!-- Spinner -->
    <div class="text-center" v-if="!dataReady">
      <v-progress-circular :size="100" :width="7" color="var(--color-branding)" indeterminate />
    </div>

    <div v-else>
      <component :is="bigCardComponent" class="main-card" :preview="preview" />
      <template v-if="shouldShowStore">
        <store-list :show-store-list="showStoreList"></store-list>
      </template>
    </div>
  </fragment>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import SinglePublisherGiftCertificateOffer from './single-publisher-offer/SinglePublisherGiftCertificateOffer';
import SinglePublisherConsumerGiftCertificateOffer from './single-publisher-offer/SinglePublisherConsumerGiftCertificateOffer';
import SinglePublisherBonusOffer from './single-publisher-offer/SinglePublisherBonusOffer.vue';
import StoreList from '../stores/StoreList.vue';

import CategoryFilterBar from '../search/bar/CategoryFilterBar.vue';
import { mapGetters } from 'vuex';
export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },

  components: {
    SinglePublisherGiftCertificateOffer,
    SinglePublisherConsumerGiftCertificateOffer,
    SinglePublisherBonusOffer,
    CategoryFilterBar,
    StoreList,
  },
  name: 'single-publisher-offer',
  async created() {
    if (this.currentOfferIsBonus) {
      this.bigCardComponent = 'single-publisher-bonus-offer';
      this.shouldShowStore = true;
    }

    if (this.currentOfferIsGift) {
      if (this.currentOfferConsumerEnabled && this.currentOffer.publisher_count !== 0) {
        this.bigCardComponent = 'single-publisher-consumer-gift-certificate-offer';
        this.shouldShowStore = false;
      } else {
        this.bigCardComponent = 'single-publisher-gift-certificate-offer';
        this.shouldShowStore = true;
      }
    }
    this.getOfferPublisherAndStore();
  },
  props: {
    offer_id: String,
    publisher_id: String,
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataReady: false,
      bigCardComponent: undefined,
      hasMoreThan10Store: false,
      shouldShowStore: false,
      selectedDestination: undefined,
      currentDestinationList: [],
    };
  },
  computed: {
    ...mapGetters('offer', ['currentOfferConsumerEnabled', 'currentOfferPublishersList', 'currentOfferShowStoreList', 'currentOfferIsBonus', 'currentOfferIsGift', 'currentOffer']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentBrandingName']),
    ...mapGetters('ui', ['currentLocale']),
    showStoreList() {
      return this.preview || this.currentOfferShowStoreList;
    },
  },
  methods: {
    async getOfferPublisherAndStore() {
      this.dataReady = false;
      if (this.publisher_id) {
        const publisher = await this.execute(
          {
            action: 'offer/getOfferPublisher',
          },
          {
            offer_id: this.offer_id,
            publisher_id: this.publisher_id,
          },
        );

        if (!publisher) {
          this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
        } else {
          await this.execute(
            {
              action: 'store/updateQuery',
            },
            { publisher_id: this.publisher_id, offer_id: this.offer_id },
          );
        }
      }
      this.dataReady = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  margin-bottom: 12px;
}
</style>
