<template>
  <h-form ref="form" class="corpo-company-form" v-model="valid" @submit.prevent="submit">
    <h3 class="section-title">{{ $t('components.corpo_company_step.title_company') }}</h3>
    <div class="company-section">
      <h-text-field autofocus v-model="company.name" :label="$t('components.corpo_company_step.company_name_label')" class="company" :rules="companyNameRules" maxlength="100" />
      <h-select v-model="company.number_of_employees" :label="$t('components.corpo_company_step.number_of_employees.label')" :items="employeesRanges" item-text="name" item-value="abbr" class="number-of-employees" :rules="numberOfEmployees" />
      <h-text-field v-model="company.address.street" :label="$t('components.corpo_company_step.company_address_label')" class="address" :rules="addressRules" maxlength="50" />
      <h-text-field v-model="company.address.city" :label="$t('components.corpo_company_step.city_label')" class="city" :rules="cityRules" maxlength="50" />
      <h-select v-model="company.address.state" :label="$t('components.corpo_company_step.province_label')" :items="provinceList" item-text="name" item-value="abbr" class="province" :rules="provinceRules" />
      <h-text-field v-model="company.address.zip" class="postal-code" maxlength="7" :label="$t('components.corpo_company_step.postal_code_label')" :rules="postalCodeRules" v-maska="{ mask: 'A#A #A#' }" />
    </div>

    <h3 class="section-title">{{ $t('components.corpo_company_step.title_contact') }}</h3>
    <div class="contact-section">
      <h-text-field v-model="company.contact.name" :label="$t('components.corpo_company_step.name_label')" class="contact" :rules="contactNameRules" maxlength="50" />
      <h-text-field v-model="company.contact.email" :label="$t('components.corpo_company_step.email_label')" class="email" :rules="emailRules" maxlength="255" />
      <h-text-field v-model="company.contact.phone" :label="$t('components.corpo_company_step.phone_number_label')" class="phone" :rules="phoneNumberRules" v-maska="'+1 (###) ###-####'" />
      <h-text-field v-model="company.contact.phone_extension" :label="$t('components.corpo_company_step.phone_number_extension_label')" class="extension" maxlength="10" :rules="phoneNumberExtensionRules" />
    </div>

    <hello-form-error style="margin-bottom: var(--gap); width: 100%" />

    <div class="btn-container">
      <slot v-bind:working="working" v-bind:valid="valid" />
    </div>
  </h-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _padStart from 'lodash/padStart';
import _map from 'lodash/map';
import safeExecute from '@/composables/safe-execute';
import { validateInvalidEmail, validateMaxLength, validatePhoneNumber, validatePostalCode, validateRequired, validateRequiredGivenFieldLabel } from '@/composables/validations';
import HelloFormError from '../../system-store/errors/HelloFormError.vue';

export default {
  components: { HelloFormError },
  name: 'corpo-company-form',
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      validateRequired,
      validateRequiredGivenFieldLabel,
      validateInvalidEmail,
      validatePostalCode,
      validatePhoneNumber,
      validateMaxLength,
      executeForm,
      execute,
      working,
    };
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    initialCompany: {
      type: Object,
      default: () => ({
        name: '',
        number_of_employees: undefined,
        contact: {
          name: '',
          email: '',
          phone: '',
          phone_extension: null,
        },
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          country: 'CA',
        },
      }),
    },
  },
  data() {
    return {
      valid: false,
      company: {},
      contactNameRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_company_step.name_label')), this.validateMaxLength(50)],
      emailRules: [this.validateRequired('email'), this.validateInvalidEmail(), this.validateMaxLength(255)],
      phoneNumberRules: [this.validateRequired('phone'), this.validatePhoneNumber()],
      phoneNumberExtensionRules: [this.validateMaxLength(10)],
      provinceRules: [this.validateRequired('province')],
      numberOfEmployees: [this.validateRequired('number_of_employees')],
      companyNameRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_company_step.company_name_label')), this.validateMaxLength(100)],
      addressRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_company_step.company_address_label')), this.validateMaxLength(50)],
      cityRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_company_step.city_label')), this.validateMaxLength(50)],
      postalCodeRules: [this.validateRequired('postal_code'), this.validatePostalCode()],
    };
  },
  computed: {
    ...mapGetters('security', ['currentUserAlias']),
    provinceList() {
      const choices = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.shared.provinces.${choice}`), abbr: choice };
      });
    },
    employeesRanges() {
      const choices = ['1_50', '51_200', '201_500', '500_MORE'];
      return _map(choices, (choice) => {
        return { name: this.$t(`components.corpo_company_step.number_of_employees.choices.${choice}`), abbr: choice };
      });
    },
  },
  watch: {
    initialCompany: {
      handler() {
        this.resetForm();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    validationReset() {
      this.$refs.form.validationReset();
    },
    resetForm() {
      this.company = _cloneDeep(this.initialCompany);
      if (!this.company.contact.email) {
        this.company.contact.email = this.currentUserAlias;
      }
      // TODO (PLP) FIX WITH CORRECT FORMAT OR DELAY FOR FORM INSTANT TO RENDER
      if (this.company.contact.phone) {
        this.company.contact.phone = _padStart(this.company.contact.phone, 15, '+');
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (!this.editMode) {
          this.executeForm(
            {
              action: 'corpo/createCompany',
              success: () => {
                this.$emit('success');
              },
            },
            this.company,
          );
        } else {
          if (!this.company.contact.phone_extension) {
            this.company.contact.phone_extension = null;
          }
          this.executeForm(
            {
              action: 'corpo/updateCompany',
              success: () => {
                this.$emit('success');
              },
              afterError: (err) => {
                if (err.code === 'errors.http.account.no.access') {
                  this.$emit('noAccessError', err);
                }
              },
            },
            this.company,
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: var(--gap-xxsmall);
}

.company-section {
  --small-field-width: 190px;
  display: flex;
  flex-flow: column wrap;
  margin-bottom: var(--gap-xsmall);
  @media ($sm-min) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  @media ($md-min) {
    --small-field-width: 152px;
  }
}
.contact-section {
  --small-field-width: 190px;
  @media ($sm-min) {
    display: flex;
    flex-flow: row wrap;
  }
}

.company {
  width: 100%;
  @media ($md-min) {
    width: calc(100% - 2 * var(--small-field-width) - 2 * var(--gap));
    flex: 0 0 auto;
  }
}

.number-of-employees {
  width: 100%;
  @media ($md-min) {
    width: calc(2 * var(--small-field-width) + var(--gap));
    flex: 0 0 auto;
  }
}

.address {
  width: 100%;
  @media ($md-min) {
    flex: 0 0 auto;
    width: calc(100% - (3 * var(--small-field-width) + 3 * var(--gap)));
  }
}
.city,
.province {
  width: var(--small-field-width);
  flex: 0 0 auto;
  @media ($sm-min) {
    width: calc((100% - var(--gap)) / 2);
  }
  @media ($md-min) {
    width: var(--small-field-width);
  }
}

.postal-code {
  width: var(--small-field-width);
  flex: 0 0 auto;
  margin-right: calc(100% - var(--small-field-width));
  @media ($md-min) {
    margin-right: 0;
  }
}

.contact {
  width: 100%;
}
.phone {
  @media ($sm-min) {
    width: 190px;
    flex: 0 0 auto;
    margin-left: var(--gap);
  }
  @media ($md-min) {
    margin-left: 0;
  }
}
.contact,
.email,
.phone {
  @media ($md-min) {
    width: calc((100% - var(--gap)) / 2);
    flex: 0 0 auto;
  }
}
.email {
  @media ($md-min) {
    margin-left: var(--gap);
  }
}
.extension {
  width: var(--small-field-width);
  flex: 0 0 auto;
  margin-right: calc(100% - var(--small-field-width));
  @media ($md-min) {
    width: calc((100% - var(--gap)) / 2);
    margin-left: var(--gap);
    margin-right: 0;
  }
}
.btn-container {
  text-align: right;
}
</style>
