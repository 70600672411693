<template>
  <h-dialog ref="dialog" @close="onClose" :title="$t('components.unsubscribe_modal.title')">
    <template v-slot:activator="{ on, attrs }">
      <h-link v-on="on" v-bind="attrs">{{ $t('components.subscription_status.cancel_cta') }}</h-link>
    </template>

    <p>{{ $t('components.unsubscribe_modal.text', { space: spaceBrandingName }) }}</p>

    <ul>
      <li>{{ $t('components.unsubscribe_modal.info_1', { space: spaceBrandingName }) }}</li>
      <li>{{ $t('components.unsubscribe_modal.info_2', { space: spaceBrandingName }) }}</li>
    </ul>

    <hello-target-error target="modal" style="margin-bottom: var(--gap-large)" />

    <template #action-footer>
      <h-btn class="action-btn" :loading="working" :disabled="working" color="var(--color-warning)" @click="unsubscribe">{{ $t('components.unsubscribe_modal.action') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import HelloTargetError from '../system-store/errors/HelloTargetError.vue';

export default {
  name: 'unsubscribe-modal',
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);

    return {
      executeTarget,
      working,
    };
  },
  components: { HelloTargetError },
  props: {
    space: {
      type: Object,
    },
    subscription: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    spaceBrandingName() {
      return _get(this.space, `branding.label[${this.currentLocale}]`, '');
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    onClose() {
      this.clearError();
    },
    async unsubscribe() {
      await this.executeTarget(
        {
          action: 'subscription/createStripeClientPortalSessionCancel',
          target: 'modal',
          success: () => {
            this.$refs.dialog.close();
          },
        },
        this.subscription.stripe_subscription_id,
      );
    },
  },
};
</script>
