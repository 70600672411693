<template>
  <div class="consumer-gift-card-enterprise-promotion">
    <h1 class="consumer-gift-card-enterprise-promotion-title">{{ $t('components.consumer_gift_card_corporate_promotion.title') }}</h1>
    <div class="consumer-gift-card-enterprise-more-section">
      <div>{{ $t('components.consumer_gift_card_corporate_promotion.text') }}</div>
      <h-btn :to="{ name: 'corpo', params: { space: currentSpaceSlug, offer_id: currentOfferId } }" target="_blank" min-width="312" large outlined color="var(--color-branding)" :block="$vuetify.breakpoint.smAndDown">{{
        $t('components.consumer_gift_card_corporate_promotion.cta')
      }}</h-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'consumer-gift-card-enterprise-promotion',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug']),
    ...mapGetters('offer', ['currentOfferId']),
  },
};
</script>

<style lang="scss" scoped>
.consumer-gift-card-enterprise-promotion {
  background-color: var(--color-ultra-light);
  text-align: center;
  padding: var(--gap-xxlarge) var(--gap);

  @media ($sm-min) {
    padding: calc(var(--gap) * 2.5) 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.consumer-gift-card-enterprise-more-section {
  font-size: var(--font-size-text-1);
  text-align: left;

  > *:not(:last-child) {
    margin-bottom: calc(var(--gap-xxlarge) * 2);
  }

  @media ($sm-min) {
    flex-basis: 43%;

    > *:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}

.consumer-gift-card-enterprise-promotion-title {
  margin-bottom: var(--gap-xxlarge);
  @media ($sm-min) {
    margin-bottom: 0;
  }
}
</style>
