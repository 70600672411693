<template>
  <h-dialog :title="title">
    <template v-slot:activator="{ on, attrs }">
      <h-link v-on="on" v-bind="attrs" class="activator">{{ $t('components.account_voucher.contribution_hints.link_title') }}</h-link>
    </template>
    <p v-html="htmlContent"></p>
  </h-dialog>
</template>

<script>
export default {
  name: 'h-pay-and-get-input-modal',
  props: {
    title: {
      type: String,
      require: true,
    },
    htmlContent: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.activator {
  font-style: italic;
  text-decoration: none !important;
  font-size: 13px;
  font-weight: 400 !important;
  line-height: 1;
}
</style>
