import { transformGeneralError } from '@/utils/error/error-transformer';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async execute({ dispatch }, { context, params }) {
      let res;
      try {
        let actionResult = await dispatch(context.action, params, { root: true });

        if (context.success) {
          res = (await context.success(actionResult)) || actionResult;
        }
      } catch (err) {
        let error;

        if (!err.errorType) {
          error = transformGeneralError(err, 'store');
        } else {
          error = err;
        }

        if (context.beforeError) {
          res = (await context.beforeError(error)) || error;
        }

        await dispatch('error/addError', error, { root: true });

        if (context.afterError) {
          res = (await context.afterError(error)) || error;
        }
      } finally {
        if (context.finally) {
          res = (await context.finally()) || res;
        }
      }

      return res;
    },
  },
};
