<template>
  <fragment>
    <success-gift-certificate-offer-no-card v-if="canRequestCard" />
    <success-gift-certificate-offer-with-card v-else />
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import SuccessGiftCertificateOfferNoCard from './success/SuccessGiftCertificateOfferNoCard.vue';
import SuccessGiftCertificateOfferWithCard from './success/SuccessGiftCertificateOfferWithCard.vue';

export default {
  name: 'claim-gift-certificate-success',
  components: { SuccessGiftCertificateOfferNoCard, SuccessGiftCertificateOfferWithCard },
  computed: {
    ...mapGetters('account', ['canRequestCard']),
  },
};
</script>
