<template>
  <div class="account-consumer-gift-card-voucher">
    <img class="main-img" :src="mainImgSrc" :alt="$t('components.account_voucher_consumer_gift_card.img_alt')" />

    <div class="voucher-information">
      <h3 class="voucher-header h-heading-h4">{{ headerText }}</h3>
      <merchants-modal :offer_id="voucher.offer_id" :modal-title-text="$t('components.account_voucher_consumer_gift_card.merchants_modal_title')">
        <template #activator="{ on, attrs }">
          <h-btn class="merchants-button" v-bind="attrs" v-on="on" outlined small>{{ $t('components.account_voucher_consumer_gift_card.merchants_link') }} </h-btn>
        </template>
      </merchants-modal>
    </div>

    <voucher-consumer-gift-card-amount-and-links :voucher="voucher" @refreshGiftCardVouchers="refreshGiftCardVouchers" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showcaseSmall } from '@/composables/image-helper';
import VoucherConsumerGiftCardAmountAndLinks from '@/components/vouchers/VoucherConsumerGiftCardAmountAndLinks.vue';
import MerchantsModal from '@/components/modals/MerchantsModal';

export default {
  name: 'account-consumer-gift-card-voucher',
  components: {
    VoucherConsumerGiftCardAmountAndLinks,
    MerchantsModal,
  },
  setup() {
    return {
      showcaseSmall,
    };
  },
  props: {
    voucher: {
      type: Object,
    },
  },
  methods: {
    refreshGiftCardVouchers() {
      this.$emit('refreshGiftCardVouchers');
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    mainImgSrc() {
      const rawSource = this.voucher.offer_branding.images.showcases[this.currentLocale][0];
      return showcaseSmall(rawSource);
    },
    headerText() {
      return this.voucher.offer_branding.label[this.currentLocale];
    },
  },
};
</script>

<style lang="scss" scoped>
.account-consumer-gift-card-voucher {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: var(--gap);
  padding-top: var(--gap-large);
  border-top: var(--border-thin) solid var(--color-border);

  @media ($sm-min) {
    flex-flow: row wrap;
  }
}

.voucher-overlay {
  font-weight: bold;
  text-transform: uppercase;
}

.main-img {
  display: block;
  width: 100%;
  max-height: 300px;

  @media ($sm-min) {
    width: 45%;
    max-width: 340px;
    max-height: 170px;
  }
}

.voucher-information {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ($sm-min) {
    height: 170px;
  }
}

.merchants-modal {
  justify-content: flex-end;
}

.merchants-button {
  @media ($md-min) {
    margin-top: auto;
  }
}

.voucher-header {
  margin-bottom: var(--gap-xxsmall);
}

.offer-label {
  font-style: italic;
  margin-bottom: var(--gap-large);
}

.voucher-consumer-gift-card-amount {
  width: 100%;

  @media ($md-min) {
    width: auto;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.v-overlay {
  top: var(--gap-large);
}
</style>
