import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== 'production',
});

store.helloInit = async () => {
  let currentPromise = [];
  Object.keys(modules).forEach(async (moduleName) => {
    if (modules[moduleName].actions.init) {
      console.log(`Initializing "${moduleName}" module`); // eslint-disable-line no-console
      currentPromise.push(store.dispatch(`${moduleName}/init`));
    }
  });
  await Promise.all(currentPromise);
};

export default store;
