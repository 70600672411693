<template>
  <div class="register-form">
    <div class="register-content">
      <h2 class="register-title">{{ $t('components.register_form.title') }}</h2>

      <p class="register-text" v-html="$t('components.register_form.description')"></p>

      <h-text-field
        v-model="email"
        autocomplete="email"
        maxlength="255"
        :placeholder="$t('components.register_form.email_field')"
        :aria-label="$t('components.register_form.email_field')"
        label=""
        ref="email"
        hide-details="auto"
        type="email"
        data-cy="signinEmailField"
        class="mb-4"
      />

      <register-modal :initial-email="email" />
    </div>
  </div>
</template>

<script>
import { validateInvalidEmail, validateMaxLength, validateRequired } from '@/composables/validations';
import RegisterModal from '@/components/authentication/RegisterModal';
import { mapGetters } from 'vuex';

export default {
  name: 'register-form',
  components: { RegisterModal },
  setup() {
    return {
      validateRequired,
      validateMaxLength,
      validateInvalidEmail,
    };
  },
  data() {
    return {
      valid: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters('security', ['lastFailedSignInEmail']),
  },
  watch: {
    lastFailedSignInEmail(val) {
      if (val) {
        this.email = val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  background: var(--color-primary) url('~@/assets/register_bg.svg') right bottom no-repeat;
  height: 330px;
}

.register-content {
  padding: 30px;
  max-width: 351px;
  color: #ffffff;
}

.register-title {
  margin-bottom: 16px;
  font-size: 2rem;
}

.register-text {
  font-size: 14px;
  line-height: 1.35;
  opacity: 0.65;
  margin-bottom: 4px;
}

// v-deep is used here because this is an exception. We don't have this behaviour anywhere else in the app.
// If another "dark" input is required somewhere else, we should come up with a better solution.
::v-deep .v-input__slot {
  background-color: rgb(23, 26, 104);
  border-color: rgb(23, 26, 104) !important;

  .v-input--is-focused & {
    border-color: white !important;
  }
}

::v-deep input {
  color: white !important;
}

::v-deep ::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
</style>
