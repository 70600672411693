<template>
  <div class="h-pay-and-get-editable-input-decimal">
    <h-text-field
      :class="currentLocale === 'en' ? 'amount-field-en' : 'amount-field-fr'"
      :label="label"
      :hint="hint"
      persistent-hint
      type="text"
      inputmode="numeric"
      v-maska="{
        mask: 'I*.DDE',
        tokens: {
          I: { pattern: /[0-9]/, repeated: true },
          D: { pattern: /[0-9]/, optional: true, repeated: false },
          E: { pattern: /$/, optional: false, repeated: false },
        },
      }"
      ref="payAndGetEditableInput"
      :rules="rules()"
      :value="value"
      @input="input"
      @update="(val) => $emit('update', val)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'h-pay-and-get-editable-input-decimal',
  props: {
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    rules: {
      type: Function,
      default: () => [],
    },
  },
  watch: {
    computedAmount() {
      if (this.$el) {
        this.$el.style.setProperty('--input-display-value', `'${this.computedAmount}'`);
      }
    },
  },
  mounted() {
    // Initial setup of value. The watch doesn't work because $el needs to be in the DOM
    this.$el.style.setProperty('--input-display-value', `'${this.computedAmount}'`);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    computedAmount() {
      return this.value === '' ? '$' : this.$options.filters.currencySymbol(this.value);
    },
  },
  methods: {
    cutInput(newVal) {
      if (!newVal) return '';

      const sub = 3 - (`${newVal}`.includes('.') ? `${newVal}`.length - `${newVal}`.indexOf('.') : 0);

      if (sub < 0) {
        return Number.parseFloat(`${newVal}`.slice(0, sub)).toFixed(2);
      }

      return `${newVal}`;
    },
    input(newValue) {
      this.$refs.payAndGetEditableInput.lazyValue = this.cutInput(newValue);
      return this.$emit('input', this.cutInput(newValue));
    },
  },
};
</script>

<style lang="scss" scoped>
// Used for switching the value of pseudo element
.h-pay-and-get-editable-input-decimal {
  --input-display-value: '';
}

// Used to hide the btn in the input
::v-deep input[type='number']::-webkit-outer-spin-button,
::v-deep input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

// Used for the bigger look of the input box
::v-deep .v-input__slot {
  height: 55px !important;
  padding: 6px !important;
}

// Used to align the cursor to right
::v-deep .v-input input {
  max-height: 65px;
  height: 45px;
  text-align: right;
  color: transparent;
  font-weight: 600;
  width: 150px;
  font-size: var(--font-size-h1);
  line-height: 1;
}

// Used to align the text to right
::v-deep .v-text-field__slot {
  justify-content: right;
  position: relative;

  &:before {
    content: var(--input-display-value);
    color: var(--color-primary);
    font-size: var(--font-size-h1);
    position: absolute;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    transform: translateY(-50%);
    top: 50%;
  }
  .error--text &:before {
    color: var(--color-error);
  }
}

::v-deep .v-input.amount-field-en input {
  padding-left: 30px;
}

::v-deep .v-input.amount-field-fr input {
  padding-right: calc(var(--font-size-h1) / 1.8);
}
</style>
