<template>
  <fragment>
    <h-dialog :closing-x="false" max-width="680" @close="successClose" v-model="showSuccessModal" success :title="$t('components.corpo_verifying_email.success_title')">
      <p class="text">{{ $t('components.corpo_verifying_email.success_text') }}</p>

      <template #action-footer>
        <h-btn large min-width="200" @click="successClose">{{ $t('components.corpo_verifying_email.success_button') }}</h-btn>
      </template>
    </h-dialog>

    <h-dialog :closing-x="false" max-width="680" @close="errorClose" v-model="showErrorModal" error :title="$t('components.corpo_verifying_email.error_title')">
      <p v-if="authenticated" class="text">{{ $t('components.corpo_verifying_email.error_text') }}</p>
      <p v-if="!authenticated" class="text">{{ $t('components.corpo_verifying_email.error_text_no_auth') }}</p>
      <template #footer>
        <div v-if="authenticated" class="d-flex justify-center">
          <h-btn large min-width="350" @click="sendVerificationEmail">{{ $t('components.corpo_verifying_email.error_send_link') }}</h-btn>
        </div>

        <div class="pt-3 d-flex justify-center">
          <h-link class="close-error" bold @click="errorClose">{{ $t('components.corpo_verifying_email.error_goback') }}</h-link>
          <account-email-verification-modal v-model="verificationModalIsOpen" />
        </div>
      </template>
    </h-dialog>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import safeExecute from '@/composables/safe-execute';

import _omit from 'lodash/omit';

import AccountEmailVerificationModal from '@/components/account/components/AccountEmailVerificationModal';

export default {
  name: 'corpo-validating-email',
  components: { AccountEmailVerificationModal },

  setup(props, { root }) {
    const { executeContent } = safeExecute(root.$store);

    return {
      executeContent,
    };
  },
  async created() {
    if (this.$route.query['email-verification-token']) {
      try {
        await this.verifyEmailToken(this.$route.query['email-verification-token']);

        this.showSuccessModal = true;
      } catch (e) {
        this.showErrorModal = true;
      }
    }
  },
  data() {
    return {
      showSuccessModal: false,
      showErrorModal: false,
      verificationModalIsOpen: false,
    };
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters('security', ['authenticated']),
  },
  methods: {
    ...mapActions('security', ['verifyEmailToken']),
    errorClose() {
      this.showErrorModal = false;
      this.$emit('cancel');
    },
    successClose() {
      this.showSuccessModal = false;
      this.$emit('success');
    },
    sendVerificationEmail() {
      this.executeContent({
        action: 'security/resetEmailVerification',
        success: () => {
          this.verificationModalIsOpen = true;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.close-error {
  font-size: var(--font-size-text-2);
}

.text-error {
  color: var(--color-error);
}
</style>
