<template>
  <h-dialog :title="$t('components.open_wallet_hint_modal.title')">
    <template v-slot:activator="data">
      <slot name="activator" v-bind="data" />
    </template>
    <p v-html="text"></p>
  </h-dialog>
</template>

<script>
export default {
  name: 'open-wallet-hint-modal',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
