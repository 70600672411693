<template>
  <fragment>
    <v-progress-circular v-if="!init" :style="{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" :color="brandingColor" indeterminate />
    <div v-else>
      <account-email-pending-verification v-if="authenticated && emailNeedVerification && !canRequestCard" />
      <account-card-required v-if="authenticated && canRequestCard" :redirect-link="cardApplyRedirectLink" />

      <!-- Header -->
      <h-hero-banner-partner :img-source-mobile="currentBrandingHorizontalLogo" :img-source-desktop="currentBrandingHeroSmall"></h-hero-banner-partner>

      <!-- Back -->
      <h-btn @click="backSpace" color="text-primary" inverted class="back-btn">
        <v-icon>mdi-menu-left</v-icon>
        {{ backButtonText }}
      </h-btn>

      <single-publisher-offer v-if="currentOfferIsSinglePublisher" :offer_id="offer_id" :publisher_id="publisher_id" :preview="preview" />
      <publisher-choices v-else :offer_id="offer_id" :preview="preview" />
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import safeExecute from '@/composables/safe-execute';
import AccountEmailPendingVerification from '../../account/AccountEmailPendingVerification.vue';
import AccountCardRequired from '../../account/AccountCardRequired.vue';
import SinglePublisherOffer from '@/components/offer-details/SinglePublisherOffer.vue';
import PublisherChoices from '@/components/offer-details/PublisherChoices.vue';
import { recaptcha } from '@/mixins';

export default {
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);
    return {
      execute,
      working,
    };
  },
  mixins: [recaptcha],
  components: {
    AccountEmailPendingVerification,
    AccountCardRequired,
    SinglePublisherOffer,
    PublisherChoices,
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    let offer;

    if (this.$route.params.offer_id) {
      offer = await this.getOfferById(this.$route.params.offer_id);
    } else {
      offer = await this.getOfferBySlug(this.$route.params.slug);
    }

    // If no offer route to not found
    if (!offer) {
      this.$router.replaceNoDuplicate({ name: 'not-found', params: { space: this.currentSpaceSlug, _position: { x: 0, y: window.scrollY } } });
      return;
    }

    this.publisher_id = offer.publisher_id;
    this.offer_id = offer.id;

    if (this.$route.query.trk) {
      // NOTE (SG) - Do not track information in a sync wait to reduce the time to render the page
      this.setTrackingInfo({
        tracking_link_id: this.$route.query.trk,
        offer_id: this.offer_id,
        recaptcha_token: await this.recaptcha('trackVisit'),
      });
    }

    this.init = true;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'home' || from.name === 'spaceHome' || from.name === 'homeRegion' || from.name === 'homeStatus') {
        vm.setBackRoute(from);
      }
    });
  },
  title: (args) => args.currentBrandingName,
  name: 'offer-detail-view',
  data() {
    return {
      init: false,
      dataReady: false,
      bigCardComponent: undefined,
      listLabel: undefined,
      smallCardComponent: undefined,
      offer_id: undefined,
      publisher_id: undefined,
    };
  },
  watch: {
    currentLocale() {
      this.$title = this.currentBrandingName;
    },
  },
  computed: {
    ...mapGetters('offer', ['currentOfferIsSinglePublisher']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['currentSpaceSlug', 'currentPartnerId', 'currentBrandingName', 'currentBrandingHeroSmall', 'brandingColor', 'currentBrandingHorizontalLogo']),
    ...mapGetters('account', ['canRequestCard', 'emailNeedVerification']),
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('navigation', ['getBackRoute', 'backRouteIsSpaceHome']),

    backButtonText() {
      if (!this.backRouteIsSpaceHome) {
        return this.$t('components.offer_card_big.back_other');
      }
      return this.$t('components.offer_card_big.back_action', { space: this.currentBrandingName });
    },
    cardApplyRedirectLink() {
      return {
        url: window.location.href,
        label_fr: this.$t('components.card_application.redirect_link.return_to_home_label', 'fr'),
        label_en: this.$t('components.card_application.redirect_link.return_to_home_label', 'en'),
      };
    },
  },
  methods: {
    ...mapActions('navigation', ['setBackRoute']),
    ...mapActions('tracking', ['setTrackingInfo']),
    async getOfferBySlug(slug) {
      return this.execute(
        {
          action: 'offer/getOfferDetailBySlug',
        },
        { partnerId: this.currentPartnerId, slug },
      );
    },

    async getOfferById(offer_id) {
      return this.execute(
        {
          action: 'offer/getOfferDetail',
        },
        offer_id,
      );
    },
    backSpace() {
      if (this.getBackRoute) {
        this.$router.pushNoDuplicate(this.getBackRoute);
      } else {
        this.$router.pushNoDuplicate({ name: 'spaceHome', params: { space: this.currentSpaceSlug } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h-hero-banner-partner {
  margin-bottom: var(--gap);
}
.v-btn.back-btn {
  min-width: 0 !important;
  height: 60px;
  padding: 0 24px;
}
</style>
