<template>
  <div class="success-instant-card-application-wrap">
    <div class="success-instant-card-application">
      <div>
        <h2 class="header">{{ $t('components.success_instant_card_application.title') }}</h2>
        <p class="text" v-html="$t('components.success_instant_card_application.description')"></p>
      </div>

      <img class="card-img" src="@/assets/card_and_check.svg" :alt="$t('components.success_instant_card_application.mobile_card_image')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'success-instant-card-application',
};
</script>

<style lang="scss" scoped>
.success-instant-card-application-wrap {
  overflow: hidden;
}
.success-instant-card-application {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  padding: var(--gap-xxlarge) var(--gap-page-side);
  color: var(--color-ultra-light);
  background-color: var(--color-primary);

  @media ($sm-min) {
    min-height: 335px;

    &:before {
      content: '';
      position: absolute;
      min-height: 335px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 46.2%;
      background-image: url('~@/assets/ask_bg_check.svg');
      background-size: auto 100%;
      background-position: 0 0;
    }
  }
}

.card-img {
  margin: var(--gap-xlarge) auto 0;

  @media ($sm-min) {
    display: none;
  }
}

.header {
  margin-bottom: var(--gap-small);
  max-width: 400px;
}

.text {
  margin-bottom: var(--gap-large);
  opacity: 0.65;

  @media ($md-min) {
    max-width: 430px;
  }
}
</style>
