<template>
  <div class="payment-detail-use-my-money">
    <h-checkbox :label="$t('components.payment_detail_use_my_money.label')" :input-value="paymentUsingMyMoney" @change="useMyMoneyForPayment" />
    <span class="final-payment-credit-label">{{ $t('components.payment_detail_use_my_money.credit') }}</span>
    <span class="h-text-3">{{ paymentMyMoneyAmount | currency_full | currencySymbol }} </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'payment-detail-use-my-money',
  computed: {
    ...mapGetters('payment', ['paymentUsingMyMoney', 'paymentMyMoneyAmount']),
  },
  methods: {
    ...mapActions('payment', ['decideIfUseMyPaymentMoney']),
    useMyMoneyForPayment(e) {
      this.decideIfUseMyPaymentMoney(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail-use-my-money {
  display: flex;
  align-items: center;
}
.final-payment-credit-label {
  margin-left: auto;
  margin-right: 10px;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  color: var(--color-text-secondary);
}
.h-text-3 {
  color: var(--color-text-secondary);
  font-weight: 600;
}
</style>
