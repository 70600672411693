import { isBonusOffer, isGiftCertificateOffer, offerStatusIsOneOf, consumerIsEnabled, isConsumerInventoryDepleted, activationButtonIsHidden } from '@/composables/offer-state-informer';

export const PublisherCallbackType = {
  SEE_STORES: 'see_stores',
  SEE_OFFER: 'see_offer',
  CHOSE_OFFER: 'chose_offer',
};

const { SEE_STORES, SEE_OFFER, CHOSE_OFFER } = PublisherCallbackType;

const actions = Object.freeze({
  see_stores_link: {
    callback_type: SEE_STORES,
    label: 'components.publisher_small_card.see_stores_link',
  },
  see_offer_button: {
    callback_type: SEE_OFFER,
    label: 'components.publisher_small_card.see_offer_button',
  },
  chose_offer_button: {
    callback_type: CHOSE_OFFER,
    label: 'components.publisher_small_card.chose_offer_button',
  },
});

export function extractPublisherDisplayInfosFromOffer(offer, publisher) {
  const displayPermissions = [];

  if (isGiftCertificateOffer(offer) && !consumerIsEnabled(offer)) {
    addActionIfConditionTrue('see_stores_link', true);
    addActionIfConditionTrue('chose_offer_button', !activationButtonIsHidden(offer) && !isPublisherInventoryDepleted(publisher) && offerStatusIsOneOf(offer, ['available']));
    addActionIfConditionTrue('see_offer_button', activationButtonIsHidden(offer) || isPublisherInventoryDepleted(publisher) || offerStatusIsOneOf(offer, ['closed', 'sold_out', 'coming_soon']));
    return displayPermissions;
  }

  if (isGiftCertificateOffer(offer) && consumerIsEnabled(offer)) {
    addActionIfConditionTrue('see_stores_link', true);
    addActionIfConditionTrue('chose_offer_button', !isPublisherInventoryDepleted(publisher) && !isConsumerInventoryDepleted(offer) && offerStatusIsOneOf(offer, ['available']));
    addActionIfConditionTrue('see_offer_button', isPublisherInventoryDepleted(publisher) || isConsumerInventoryDepleted(offer) || offerStatusIsOneOf(offer, ['closed', 'coming_soon', 'sold_out']));
    return displayPermissions;
  }

  if (isBonusOffer(offer)) {
    addActionIfConditionTrue('see_stores_link', true);
    addActionIfConditionTrue('chose_offer_button', !isPublisherInventoryDepleted(publisher) && offerStatusIsOneOf(offer, ['available']));
    addActionIfConditionTrue('see_offer_button', isPublisherInventoryDepleted(publisher) || offerStatusIsOneOf(offer, ['sold_out', 'coming_soon', 'closed']));
    return displayPermissions;
  }

  return displayPermissions;

  function addActionIfConditionTrue(actionKey, conditions) {
    if (conditions) {
      displayPermissions.push(actions[actionKey]);
    }
  }
}

function isPublisherInventoryDepleted(publisher) {
  return publisher.status === 'sold_out';
}
