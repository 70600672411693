export default {
  async created() {
    await this.$recaptchaLoaded();
    // const recaptcha = Vue.prototype.$recaptchaInstance;
    //recaptcha.showBadge();  //NOTES: (PLP) Decide if recaptcha badge is shown
  },
  methods: {
    async recaptcha(action) {
      await this.$recaptchaLoaded();
      return await this.$recaptcha(action);
    },
  },
};
