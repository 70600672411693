<template>
  <h-dialog ref="dialog" content-class="destination-modal" :title="modalTitle" class="destination-modal" max-width="680" v-model="modalIsOpen" @close="onClose">
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <h-link v-bind="attrs" v-on="on" bold class="activator">{{ modalTitle }}</h-link>
      </slot>
    </template>

    <hello-target-error class="mb-2 mt-2" target="store_error" />

    <div class="text-center" v-if="!dataReady">
      <v-progress-circular :style="{ marginTop: '50px', marginBottom: '50px' }" :size="100" :width="7" color="var(--color-branding)" indeterminate />
    </div>

    <div v-else>
      <div v-if="showcaseImage.length > 0" class="image-container">
        <v-slide-group v-if="$vuetify.breakpoint.smAndDown" class="mobile-widget-slide-group">
          <v-slide-item v-for="(item, index) in showcaseImage" :key="index">
            <img class="main-image" :src="item" />
          </v-slide-item>
        </v-slide-group>
        <img v-else class="main-image" v-for="(item, index) in showcaseImage.slice(0, 3)" :src="item" :key="index" />
      </div>

      <p v-if="destination.address && destination.address.street" class="h-text-3 address">
        <v-icon class="address-icon" size="20" aria-label="map marker" role="img" aria-hidden="false">mdi-map-marker-radius-outline</v-icon>
        <span>{{ destination.address | address }}</span>
      </p>
      <p class="h-text-1">{{ destination | translateI18nObject('branding.description') }}</p>

      <div v-if="storeComplementaryInfo">
        <h2 class="store-name">{{ storeName }}</h2>
        <div class="store-complementary-info">
          <v-icon color="warning" size="18" role="img" aria-hidden="false">mdi-alert-outline</v-icon>
          <p>
            <i>{{ storeComplementaryInfo }}</i>
          </p>
        </div>
      </div>
      <div class="link-container" v-if="showStoreLink">
        <h-link color="primary" style="font-size: var(--font-size-label-1)" bold @click="seeMore">{{ $t('components.destination_modal.see_store', {}) }} <v-icon color="primary">mdi-arrow-right</v-icon></h-link>
      </div>
    </div>
  </h-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import safeExecute from '@/composables/safe-execute';

export default {
  name: 'destination-modal',
  setup(props, { root }) {
    const { executeTarget, working } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  data() {
    return {
      modalIsOpen: false,
      dataReady: false,
      destination: null,
    };
  },
  props: {
    modalTitle: {
      type: String,
    },
    destinationId: {
      type: String,
    },
    storeName: {
      type: String,
    },
    storeComplementaryInfo: {
      type: String,
    },
    showStoreLink: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    async modalIsOpen(isOpen, wasOpen) {
      if (isOpen && !wasOpen) {
        this.dataReady = false;
        await this.executeTarget(
          {
            target: 'store_error',
            action: 'store/getDestinationDetail',
            success: (result) => {
              this.destination = result;
              this.dataReady = true;
            },
          },
          {
            destination_id: this.destinationId,
          },
        );
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    showcaseImage() {
      return _get(this.destination, 'branding.images', []).reduce((acc, image) => {
        if (image.role === `showcase_${this.currentLocale}`) {
          acc.push(image.url);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    ...mapActions('stores', ['getDestinationDetail']),
    seeMore() {
      this.$emit('seeMore', { destination_id: this.destinationId });
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal() {
      this.modalIsOpen = false;
      this.onClose();
    },
    onClose() {
      this.dataReady = false;
    },
  },
};
</script>
<style lang="scss">
.destination-modal .header {
  color: var(--color-branding);
}
</style>

<style lang="scss" scoped>
.store-name {
  margin-top: var(--gap-xxlarge);
  margin-bottom: var(--gap-small);
}
.store-complementary-info {
  display: flex;
  gap: var(--gap-small);
}
.destination-modal {
  padding: var(--gap);
}

.image-container {
  display: flex;
  gap: var(--gap);
  margin: 24px 0;

  @media ($md-min) {
    gap: var(--gap-small);
  }
}
.link-container {
  margin-bottom: var(--gap);
  @media ($sm-min) {
    margin-bottom: 0;
  }
}

.main-image {
  display: block;
  height: 90px;
  width: 180px;
  border-radius: var(--border-radius);
  margin: 0 var(--gap-xsmall);
  &:first-child {
    margin-left: 0;
  }

  @media ($md-min) {
    width: 200px;
    height: 100px;
    margin: 0;
  }
}

.address {
  .address-icon {
    vertical-align: bottom;
    margin-right: 4px;
  }
}
</style>
