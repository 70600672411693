<template>
  <h-dialog :title="titleText">
    <template v-slot:activator="{ on, attrs }">
      <h-link v-on="on" v-bind="attrs" class="activator">{{ activatorText }}</h-link>
    </template>
    <p v-html="htmlContent"></p>
  </h-dialog>
</template>

<script>
export default {
  name: 'voucher-base-amount-modal',
  props: {
    titleText: {
      type: String,
      required: true,
    },
    activatorText: {
      type: String,
      required: true,
    },
    htmlContent: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.activator {
  font-style: italic;
  text-decoration: none !important;
  font-size: 11px;
  font-weight: 400 !important;
  line-height: 1;
}
</style>
