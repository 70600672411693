<template>
  <div class="home-region-view">
    <category-filter-bar @change="offerCategoryChange" :category-list="homeOfferCategoriesList" dark use-router-params class="category-filter-bar" :style="{ top: `${$vuetify.application.top}px` }" />
    <div ref="hashScrollAnchor"></div>

    <div class="home-content">
      <v-progress-circular v-if="!dataReady" :style="{ marginTop: '50px', marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }" :size="100" :width="7" indeterminate color="primary" />

      <home-offers-by-status v-else :offers-by-status="offersByStatus" :region="region" :category="category" origin="homeRegion" :load-more-action="loadMoreFocusedOffers" :load-more-loading="working" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _sumBy from 'lodash/sumBy';
import _values from 'lodash/values';
import safeExecute from '@/composables/safe-execute';
import CategoryFilterBar from '../search/bar/CategoryFilterBar.vue';
import HomeOffersByStatus from '../home-offers-list/HomeOffersByStatus.vue';

export default {
  name: 'home-region-view',
  setup(props, { root }) {
    const { execute, working } = safeExecute(root.$store);

    return {
      execute,
      working,
    };
  },
  props: {
    region: {
      type: String,
    },
    category: {
      type: String,
    },
  },
  components: {
    CategoryFilterBar,
    HomeOffersByStatus,
  },
  title: (args) => args.$t('page_title.home'),
  data() {
    return {
      offersByStatus: {},
      focusStatus: 'available',
      dataReady: false,
      extraFocusedOffersLoaded: 0,
    };
  },
  async mounted() {
    await this.listPublicSpaces();
  },
  async created() {
    this.verifyRegion();

    if (this.category) {
      if (this.homeOfferCategoriesList.includes(this.category)) {
        await this.listHomeRegionOffersGroupByStatus(this.category);
      } else {
        this.$router.replaceNoDuplicate({ query: {} });
        await this.listHomeRegionOffersGroupByStatus();
      }
    } else {
      await this.listHomeRegionOffersGroupByStatus();
    }

    this.dataReady = true;
  },
  computed: {
    ...mapGetters('offer', ['homeOfferCategoriesList', 'regionOptions', 'statusSectionBaseOfferLimit']),
    ...mapGetters('ui', ['currentLocale']),
    regionCategoryEmpty() {
      return _sumBy(_values(this.offersByStatus), 'item_count') <= 0;
    },
    focusOffers() {
      return this.offersByStatus[this.focusStatus] || { items: [], item_count: 0 };
    },
    loadMoreLimit() {
      return this.focusOffers.items.length < 3 * this.statusSectionBaseOfferLimit ? this.statusSectionBaseOfferLimit : 2 * this.statusSectionBaseOfferLimit;
    },
  },
  methods: {
    ...mapActions('space', ['listPublicSpaces']),
    _get,
    showStayTunedCard(status) {
      const baseNumItems = this.focusStatus === status ? this.statusSectionBaseOfferLimit : 3;
      return status === 'coming_soon' && _get(this.offersByStatus, `${status}.item_count`, 0) < baseNumItems;
    },

    showListAll(status) {
      const baseNumItems = this.focusStatus === status ? this.statusSectionBaseOfferLimit : 3;
      return _get(this.offersByStatus, `${status}.item_count`, 0) > baseNumItems;
    },

    verifyRegion() {
      if (!this.regionOptions.includes(this.region)) {
        this.$router.replaceNoDuplicate({ name: 'not-found', params: { _position: { x: 0, y: window.scrollY } } });
      }
    },

    listAllByStatus(status) {
      this.$router.pushNoDuplicate({ name: 'homeStatus', params: { region: this.region, status }, query: { category: this.category } });
    },

    returnHome() {
      this.$router.pushNoDuplicate({ name: 'home', query: { category: this.category } });
    },

    async offerCategoryChange(categoryChangeEvent) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.$refs.hashScrollAnchor.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      const scrollToPosition = offset - (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 56 : 64) + 9;

      if (window.scrollY > scrollToPosition) {
        window.scrollTo(0, scrollToPosition);
      }
      this.extraFocusedOffersLoaded = 0;
      await this.listHomeRegionOffersGroupByStatus(categoryChangeEvent);
    },

    async listHomeRegionOffersGroupByStatus(category = null, focus_items_limit = this.statusSectionBaseOfferLimit) {
      await this.execute(
        {
          action: 'offer/listHomeRegionOffersGroupByStatus',
          success: (offersByStatus) => {
            this.offersByStatus = offersByStatus;
          },
        },
        { locale: this.currentLocale, category_to_include: category, region_to_include: this.region, focus_status: this.focusStatus, focus_items_limit },
      );
    },

    async loadMoreFocusedOffers() {
      const currentNumFocusedItems = this.statusSectionBaseOfferLimit + this.extraFocusedOffersLoaded;
      const page = Math.floor(currentNumFocusedItems / this.loadMoreLimit) + 1;

      await this.execute(
        {
          action: 'offer/loadMoreOffersByStatus',
          success: (offers) => {
            this.extraFocusedOffersLoaded += this.loadMoreLimit;

            const existingOfferIds = _map(this.focusOffers.items, (offer) => offer.id);
            const incomingOfferIds = _map(offers.items, (offer) => offer.id);
            const intersection = existingOfferIds.filter((id) => incomingOfferIds.includes(id));
            if (intersection.length > 0 || offers.item_count !== this.focusOffers.item_count) {
              return this.listHomeRegionOffersGroupByStatus(this.category, currentNumFocusedItems + this.loadMoreLimit);
            }

            this.offersByStatus[this.focusStatus].items.push(...offers.items);
          },
        },
        { locale: this.currentLocale, category_to_include: this.category, region_to_include: this.region, status_to_include: this.focusStatus, page, limit: this.loadMoreLimit },
      );
    },

    async listConsumerGiftCertificateOffers(category = null) {
      await this.execute(
        {
          action: 'offer/listGiftCertificateConsumerEnabledOffers',
          success: (consumerGiftCertificateOffers) => {
            this.consumerGiftCertificateOffers = consumerGiftCertificateOffers;
          },
        },
        { locale: this.currentLocale, category_to_include: category, region_to_include: this.currentRegion },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.category-filter-bar {
  z-index: var(--hello-z-index-filter-bar);
  position: sticky;
}

.home-content {
  padding: var(--gap);

  @media ($content-min) {
    padding: var(--gap) 0;
  }
}
</style>
