<template>
  <h-form class="consumer-gift-card-amount-and-message" v-model="valid">
    <div class="promotion-input" v-if="offer.current_promotion">
      <v-radio-group v-model="promotionId" style="margin-top: 0" :row="!$vuetify.breakpoint.smAndDown" hide-details>
        <v-radio class="offer-promotion-radio-option" :value="null">
          <template #label>
            <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.consumer_gift_card_promotion_selector.without') }}</p>
          </template>
        </v-radio>
        <v-radio class="offer-promotion-radio-option" :value="offer.current_promotion.id">
          <template #label>
            <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.consumer_gift_card_promotion_selector.with', { reward_percent: offer.current_promotion.incentive.bonus_display }) }}</p>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

    <div class="amount-section">
      <div class="amount-input">
        <h-pay-and-get-editable-input
          :label="$t('components.consumer_gift_card_amount_and_message.label')"
          :hint="$t('components.consumer_gift_card_amount_and_message.min_max', { min_amount: contextMinAmount, max_amount: contextMaxAmount })"
          v-model="amount"
          :rules="amountRules"
        />
      </div>
      <div class="h-text-5" v-if="purchaseLimitNotice">
        <p class="notice">{{ purchaseLimitNotice }}</p>
      </div>
    </div>
    <div class="message-input">
      <h-textarea
        v-model="message"
        :label="$t('components.consumer_gift_card_amount_and_message.msg_label')"
        :placeholder="$t('components.consumer_gift_card_amount_and_message.msg_placeholder')"
        persistent-hint
        persistent-placeholder
        height="140"
        maxlength="150"
        :rules="messageRules"
        counter
      />
    </div>
  </h-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validateMaxLength, validateMaxNumLines, validateMaxValue, validateMinValue, validatePositiveInteger, validateRequiredGivenFieldLabel } from '@/composables/validations';
import HPayAndGetEditableInput from '@/components/system/inputs/HPayAndGetEditableInput.vue';

export default {
  components: { HPayAndGetEditableInput },
  props: {
    offer: {
      type: Object,
    },
  },
  setup() {
    return {
      validateRequiredGivenFieldLabel,
      validateMaxNumLines,
      validateMaxLength,
      validateMinValue,
      validateMaxValue,
      validatePositiveInteger,
    };
  },
  name: 'consumer-gift-card-amount-and-message',
  data() {
    return {
      valid: false,
      messageRules: [this.validateMaxLength(150), this.validateMaxNumLines(6)],
    };
  },
  watch: {
    valid(val) {
      this.setAmountAndMessageAreValid(val);
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('consumerGiftCard', ['offerPromotionId', 'contextDefaultAmount', 'contextMinAmount', 'contextMaxAmount', 'paymentAmount', 'giftCardMessage', 'hasAContextAndContextIsReady', 'consumerPurchaseOverLimitNotice']),
    amountRules() {
      if (!this.hasAContextAndContextIsReady) {
        return () => [];
      }
      return () => [this.validateRequiredGivenFieldLabel(this.$t('components.consumer_gift_card_amount_and_message.label')), this.validateMinValue(this.contextMinAmount), this.validateMaxValue(this.contextMaxAmount), this.validatePositiveInteger()];
    },
    promotionId: {
      get() {
        return this.offerPromotionId;
      },
      set(args) {
        this.setConsumerGiftCardOfferPromotionId(args);
      },
    },
    amount: {
      get() {
        return this.paymentAmount;
      },
      set(args) {
        this.setConsumerGiftCardPaymentAmount(args);
      },
    },
    message: {
      get() {
        return this.giftCardMessage;
      },
      set(args) {
        this.setGiftCardMessage(args);
      },
    },
    purchaseLimitNotice() {
      // (SG) DUPLICATE CODE -> src/components/offer-details/single-publisher-offer/SinglePublisherConsumerGiftCertificateOffer.vue:312
      let notice = '';
      if (this.consumerPurchaseOverLimitNotice[this.currentLocale]) {
        notice = `${this.consumerPurchaseOverLimitNotice[this.currentLocale]}`;

        const amount = this.$options.filters.currencySymbol(this.$options.filters.currency(this.contextMaxAmount));
        notice = notice.replace('{amount}', amount);
      }
      return notice;
    },
  },
  methods: {
    ...mapActions('consumerGiftCard', ['setConsumerGiftCardOfferPromotionId', 'setAmountAndMessageAreValid', 'setConsumerGiftCardPaymentAmount', 'setGiftCardMessage']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--radio-group__input {
  justify-content: center;
}

.notice {
  color: var(--color-text-tertiary);
}

.offer-promotion-radio-option {
  border-radius: 5px;
  border: 2px solid var(--color-border-light);
  padding: 12px;
  min-width: 190px;
}

.h-pay-and-get-editable-input {
  margin-bottom: var(--gap);
}

.amount-section {
  display: block;
  margin-bottom: var(--gap-xxlarge);

  .amount-input {
    display: flex;
    justify-content: space-around;
  }
}

.consumer-gift-card-amount-and-message {
  display: block;
  padding: var(--gap-large);
  border: var(--border-thin) solid var(--color-border);
  margin: var(--gap-large) 0;

  @media ($sm-min) {
    display: flex;

    flex-wrap: wrap;
    justify-content: left;
    & > .amount-section {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 1;
      margin-bottom: 0;
    }
    & > .message-input {
      flex-grow: 1;
      flex-shrink: 1;
    }
    & > .promotion-input {
      flex-basis: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  @media ($md-min) {
    margin: var(--gap) 0;
    gap: var(--gap);
  }
}
</style>
