import _get from 'lodash/get';

export default ({ Vue, app, store }) => {
  Vue.filter('translateI18nObject', (baseObject, key) => {
    if (!key) {
      return _get(baseObject, `${store.getters['ui/currentLocale']}`, '');
    }
    return _get(baseObject, `${key}.${store.getters['ui/currentLocale']}`, '');
  });
};
