<template>
  <h-dialog ref="dialog" @close="clearError" :title="$t('components.delete_a_card_action.modal.title')">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" small icon class="remove-card-action" :aria-label="$t('components.delete_a_card_action.title')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <div class="delete-card-modal">
      <p class="description">{{ $t('components.delete_a_card_action.modal.description') }}</p>
      <div class="label-container">
        <img v-if="card.network === 'visa'" src="@/assets/main_visa_white.png" class="cc-img" />
        <img v-else-if="card.network === 'mastercard'" src="@/assets/mc_vrt_pos.svg" class="cc-img" />
        <v-icon v-else size="40" :style="{ paddingRight: '8px', paddingLeft: '8px' }">mdi-credit-card-outline</v-icon>

        <span :style="{ alignSelf: 'center', textTransform: 'none' }">**** **** **** {{ card.last4 }} - Exp. {{ card.exp_month }}/{{ card.exp_year }}</span>
      </div>
      <hello-target-error target="deleteCardModal" use-specific-error-message use-fallback />
    </div>

    <template #action-footer>
      <h-btn @click="deleteCard" :loading="working">{{ $t('components.delete_a_card_action.modal.actions.confirm') }}</h-btn>
      <h-btn @click="closeModal" :disabled="working" outlined>{{ $t('components.delete_a_card_action.modal.actions.cancel') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import safeExecute from '@/composables/safe-execute';
import { mapActions } from 'vuex';

export default {
  name: 'payment-delete-saved-card-modal',

  setup(props, { root }) {
    const { working, executeTarget } = safeExecute(root.$store);
    return {
      executeTarget,
      working,
    };
  },
  props: {
    card: {
      Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('error', ['clearError']),
    async deleteCard() {
      await this.executeTarget(
        {
          action: 'payment/removePaymentMethod',
          success: function () {
            this.closeModal();
          },
          target: 'deleteCardModal',
        },
        {
          id: this.card.id,
        },
      );
    },
    closeModal() {
      this.clearError();
      this.$refs.dialog.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-card-action {
  align-self: center;
  margin-bottom: 8px;
}

.label-container {
  margin-bottom: var(--gap);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.cc-img {
  max-height: 40px;
  max-width: 56px;
}
</style>
