import _get from 'lodash/get';
import _deburr from 'lodash/deburr';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import Vue from 'vue';
import errorTypes from '@/utils/error/error-types';

async function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

const sortingWeight = {
  CA: 0,
  US: 1,
};

export default {
  namespaced: true,
  state: {
    countryList: [],
    offers: [],
    currentActivationContextToken: '',
    currentActivationContextPartnerId: '',
    instantCardStructure: '',
    instantCardProjectPartnerSpaceUrl: '',
    instantCardOfferPublishers: [],
    account_fields: {
      gender: null,
      year_of_birth: null,
    },
  },
  getters: {
    countryList: (state, getters, rootState, rootGetters) =>
      _reduce(
        _sortBy(state.countryList, [
          (i) => {
            return i.postal_code_required ? 0 : 1;
          },
          (val) => {
            return _get(sortingWeight, val.iso_code, 2);
          },
          (val) => {
            return _deburr(val.name[rootGetters['ui/currentLocale']]);
          },
        ]),
        (acc, item, index) => {
          if (acc.length !== 0 && acc[acc.length - 1].postal_code_required === true && item.postal_code_required === false) {
            acc.push({
              divider: true,
            });
          }
          acc.push({
            text: item.name[rootGetters['ui/currentLocale']],
            value: item.iso_code,
            postal_code_required: item.postal_code_required,
          });

          return acc;
        },
        [],
      ),
    instantCardProjectPartnerSpaceUrl: (state) => state.instantCardProjectPartnerSpaceUrl,
    instantCardOfferPublishers: (state) => state.instantCardOfferPublishers,
    instantCardStructure: (state) => state.instantCardStructure,
    instantCardPartnerId: (state) => state.currentActivationContextPartnerId,
    hasInstantCardOffer: (state) => state.offers.length > 0,
    isMultipleOffersInstantCard: (state) => state.offers.length > 1,
    instantCardActiveOffers: (state, getters, rootState, rootGetters) =>
      _sortBy(state.offers, [
        function (o) {
          return _deburr(o.label[rootGetters['ui/currentLocale']]);
        },
      ]),
    instantCardOffer: (state) => _get(state, 'offers[0]', { partner_label: {}, partner_image: {}, label: {}, description: {}, images: { en: [], fr: [] } }),
    isSinglePublisher: (state) => _get(state, 'offers[0].is_single_publisher', false),

    genderFieldAllowed: (state) => state.account_fields.gender,
    yearOfBirthFieldAllowed: (state) => state.account_fields.year_of_birth,
  },
  mutations: {
    setInstantCardOfferPublishers(state, instantCardOfferPublishers) {
      state.instantCardOfferPublishers = instantCardOfferPublishers;
    },
    instantCardStructure(state, instantCardStructure) {
      state.instantCardStructure = instantCardStructure;
    },
    instantCardProjectPartnerSpaceUrl(state, instantCardProjectPartnerSpaceUrl) {
      state.instantCardProjectPartnerSpaceUrl = instantCardProjectPartnerSpaceUrl;
    },
    countryList(state, list) {
      state.countryList = list;
    },
    offers(state, offers) {
      state.offers = offers;
    },
    currentActivationContextToken(state, activation_context_token) {
      state.currentActivationContextToken = activation_context_token;
    },
    currentActivationContextPartnerId(state, partner_id) {
      state.currentActivationContextPartnerId = partner_id;
    },
    setAccountFields(state, account_fields) {
      state.account_fields.gender = _get(account_fields, 'gender');
      state.account_fields.year_of_birth = _get(account_fields, 'year_of_birth');
    },
  },
  actions: {
    async instantCardApply({}, payload) {
      const response = await Vue.prototype.$services.instantCard.instantCardApply(payload);
      await sleep(2000);
      return response;
    },
    async getInstantCardOfferPublishers({ commit }, { offer_id, locale = 'fr' }) {
      const { items, item_count } = await Vue.prototype.$services.offer.getOfferPublishers(offer_id, locale, 1, 500);

      commit('setInstantCardOfferPublishers', items);
      return { items, item_count };
    },
    async initCountryList({ commit }) {
      const countriesList = await Vue.prototype.$services.instantCard.getInstantCardActivationCountryList();

      commit('countryList', countriesList);
    },
    async sendSmsActivatedInstantCard({ state }, { language, mobile_number, recaptcha_token }) {
      return Vue.prototype.$services.instantCard.sendSmsActivatedInstantCard({
        activation_context_token: state.currentActivationContextToken,
        mobile_number,
        language,
        recaptcha_token,
      });
    },
    async findActivatableInstantCard({ commit, dispatch }, { referenceId, last4Digits, recaptcha_token, partner_id }) {
      try {
        const payload = {
          token: referenceId,
          last_four_pan_digits: last4Digits,
          recaptcha_token,
        };
        if (partner_id) {
          payload.partner_id = partner_id;
        }
        const result = await Vue.prototype.$services.instantCard.findActivatableInstantCard(payload);

        commit('setAccountFields', result.account_fields);

        if (!result) {
          throw new errorTypes.HttpError(400, {
            error: {
              code: 'invalid_instant_card_information',
            },
          });
        }

        commit('currentActivationContextToken', result.activation_context_token);
        commit('instantCardStructure', result.structure);
        commit('currentActivationContextPartnerId', result.partner_id);

        const partner = await dispatch('space/getSpaceByPartnerId', result.partner_id, { root: true });

        if (result.offers.length > 0) {
          commit(
            'offers',
            _map(result.offers, (currentOffer) => {
              return {
                ...currentOffer,
                partner_label: _get(partner, 'branding.label', { fr: '', en: '' }),
                partner_space_slug: _get(partner, 'slug', ''),
                partner_image: _get(partner, 'branding.images.horizontal_logo', { fr: '', en: '' }),
              };
            }),
          );
        }
      } catch (err) {
        // Debug
        // throw new errorTypes.HttpError(400, {
        //   error: {
        //     code: 'invalid_instant_card_information'
        //   }
        // });
        // throw new errorTypes.HttpError(400, {
        //   error: {
        //     code: 'cannot_be_activated'
        //   }
        // });
        // throw new errorTypes.HttpError(400, {
        //   error: {
        //     code: 'already_activated'
        //   }
        // });
        switch (err.code) {
          case 'errors.http.instant.card.status.not.pending':
          case 'errors.http.cannot.activate.no.projects.linked.to.card':
          case 'errors.http.cannot.activate.no.offers.in.project':
          case 'errors.http.amount.out.of.inventory.available.amount':
          case 'errors.http.offer.bad.status.draft':
            throw new errorTypes.HttpError(400, {
              error: {
                code: 'cannot_be_activated',
              },
            });

          default:
            throw err;
        }
      }

      return {};
    },

    async activateInstantCard({ state, commit }, payload) {
      const currentPayload = { activation_context_token: state.currentActivationContextToken, ...payload };

      const result = await Vue.prototype.$services.instantCard.activateInstantCard(currentPayload);

      commit('instantCardProjectPartnerSpaceUrl', result.instant_card_project_partner_space_url);

      return result.card_pin;
    },
  },
};
