<template>
  <div class="h-label-action">
    <div class="label h-text-3">{{ label }}</div>
    <h-link class="value h-text-3" :href="value" target="_blank">
      <span class="value-ellipsis">{{ value }}</span>
    </h-link>
  </div>
</template>

<script>
export default {
  name: 'h-label-action',
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: String,
      default: 'value',
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: 600;
  line-height: 1;
}

.value {
  margin-top: 8px;
  line-height: 1;
  max-width: 100%;
  justify-content: flex-start;

  ::v-deep .v-btn__content {
    max-width: 100%;
  }
}

.value-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
