<template>
  <p class="offer-label">{{ labelText }}</p>
</template>
<script>
export default {
  name: 'big-card-base-status-label',
  props: {
    expiration: {
      type: String,
    },
    start: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  computed: {
    formattedExpirationDate() {
      return this.$options.filters.formatShortDate(this.expiration);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(this.start);
    },
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    labelText() {
      if (this.status === 'coming_soon') {
        return this.$t('components.big_card_base_status_label.coming_soon', { date: this.formattedStartDateWithHour });
      }
      if (this.status === 'sold_out') {
        return this.$t('components.big_card_base_status_label.sold_out', { date: this.formattedStartDate });
      }
      if (this.status === 'available') {
        return this.$t('components.big_card_base_status_label.expiration', { date: this.formattedExpirationDate });
      }
      if (this.status === 'closed') {
        return this.$t('components.big_card_base_status_label.closed', { date: this.formattedExpirationDate });
      }
      return '';
    },
  },
};
</script>
