<template>
  <h-dialog v-on="$listeners" v-bind="$attrs" ref="dialog" error :closing-x="false" icon="mdi-alert" :title="$t('components.offer_card_error_modal.title')">
    <p>{{ errorMessageTranslation }}</p>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.corpo_order_error_modal.ok') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
export default {
  name: 'offer-card-error-modal',
  props: {
    errorMessageCode: {
      type: String,
    },
  },
  computed: {
    errorMessageTranslation() {
      const message = this.$t(this.errorMessageCode);
      return message === this.errorMessageCode ? this.$t('components.offer_card_error_modal.default_text', { error_code: this.errorMessageCode }) : message;
    },
  },
};
</script>
