import Vue from 'vue';

const service = {
  async verifyEmailToken(token) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/verification/email/verify`, { token });
    return response.data.data;
  },
  async resendEmailVerification(language, verification_context) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/verification/email/resend`, { language, verification_context });
    return response.data.data;
  },
  async forgotPassword(recaptcha_token, { email }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/forgot-password`, { recaptcha_token, email });
    return response.data.data;
  },

  async confirmResetPassword(recaptcha_token, { token, password }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/reset-password/complete`, { recaptcha_token, token, password });
    return response.data.data;
  },

  async updateEmail(email, language) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/v1/account/email`, { email, language });
    return response.data.data;
  },

  async resetPassword() {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/v1/account/reset-password`, {});
    return response.data.data;
  },
};

export default service;
