<template>
  <fragment>
    <pay-and-get-offer v-if="offer" :branding="branding" :support-branding="supportBranding" :restricted="restricted" />
    <pay-and-get-purchase-type class="mt-5" />
    <pay-and-get-editable class="mt-5" :offer="offer" :publisher="publisher" :payment-info="currentPaymentInformation" />
    <auth-info-box class="mt-5" />
    <swap-auth-and-register v-if="$vuetify.breakpoint.xsOnly" style="width: -webkit-fill-available" class="mt-5" />
    <auth-and-register v-else class="mt-5" />
  </fragment>
</template>
<script>
import _get from 'lodash/get';
import AuthAndRegister from '../../authentication/AuthAndRegister.vue';
import AuthInfoBox from '../../authentication/AuthInfoBox.vue';
import PayAndGetOffer from '../../pay-and-get/PayAndGetOffer.vue';
import SwapAuthAndRegister from '../../authentication/SwapAuthAndRegister.vue';
import PayAndGetEditable from '../../pay-and-get/PayAndGetEditable.vue';
import PayAndGetPurchaseType from '../../pay-and-get/PayAndGetPurchaseType.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'purchase-auth',
  components: { AuthInfoBox, SwapAuthAndRegister, AuthAndRegister, PayAndGetEditable, PayAndGetPurchaseType, PayAndGetOffer },
  props: {
    offer: {
      type: Object,
    },
    publisher: {
      type: Object,
    },
    paymentInfo: {
      type: Object,
    },
  },
  watch: {
    offer() {
      this.setPaymentAmountForPurchase(_get(this.offer, 'incentive.default_payment', 0));
    },
  },
  computed: {
    ...mapGetters('overlayPayment', ['currentPaymentInformation']),
    branding() {
      return this.offer.is_single_publisher ? this.offer.offer_branding : this.publisher.branding;
    },
    supportBranding() {
      return this.offer.is_single_publisher ? {} : this.offer.offer_branding;
    },
    restricted() {
      return _get(this.offer, 'features.restricted_to_members', false);
    },
  },
  methods: {
    ...mapActions('payment', ['setPaymentAmountForPurchase']),
  },
};
</script>

<style lang="scss" scoped>
.pay-and-get-offer,
.pay-and-get-editable {
  margin-bottom: var(--gap-xlarge);
}

.pay-and-get-purchase-type {
  margin-bottom: var(--gap-large);
}
</style>
