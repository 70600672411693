<template>
  <div class="purchase-sign-in-overlay-content">
    <overlay-space-section :space="space" />

    <div class="right-content-section">
      <div aria-live="polite">
        <v-expand-transition>
          <h-error-box v-if="currentOfferIsComingSoon" :text="$t('components.purchase_sign_in_overlay.error_offer_coming_soon')" />
          <h-error-box v-if="currentOfferIsSoldOut" :text="$t('components.purchase_sign_in_overlay.error_offer_sold_out')" />
          <h-error-box v-if="currentOfferIsClosed" :text="$t('components.purchase_sign_in_overlay.error_offer_closed')" />
        </v-expand-transition>
      </div>

      <hello-generic-error />
      <hello-overlay-error />

      <purchase-auth v-if="currentStep === 'auth'" :offer="offer" :publisher="publisher" />
      <purchase-payment v-if="currentStep === 'payment'" :offer="offer" :publisher="publisher" @completed="isPaymentCompleteCallback" :space="space" />
      <purchase-success v-if="currentStep === 'success'" />
    </div>
  </div>
</template>
<script>
import { recaptcha } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import safeExecute from '@/composables/safe-execute';
import { isClosed, isComingSoon, isSoldOut } from '@/composables/offer-helper';

import OverlaySpaceSection from './components/OverlaySpaceSection';
import PurchaseAuth from './purchase/PurchaseAuth.vue';
import PurchasePayment from './purchase/PurchasePayment.vue';
import PurchaseSuccess from './purchase/PurchaseSuccess.vue';

export default {
  name: 'purchase-sign-in-overlay',
  components: {
    OverlaySpaceSection,
    PurchaseAuth,
    PurchasePayment,
    PurchaseSuccess,
  },
  setup(props, { root }) {
    const { executeTarget, executeOverlay, execute, working } = safeExecute(root.$store);
    return {
      execute,
      executeTarget,
      working,
      executeOverlay,
    };
  },
  mixins: [recaptcha],
  computed: {
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('overlay', ['headerDrawerContentPayload']),
    currentOfferIsComingSoon() {
      return isComingSoon(this.offer);
    },
    currentOfferIsSoldOut() {
      return isSoldOut(this.offer);
    },
    currentOfferIsClosed() {
      return isClosed(this.offer);
    },
    currentStep() {
      if (this.authenticated) {
        if (this.isPaymentComplete) {
          return 'success';
        }
        return 'payment';
      }
      return 'auth';
    },
    restricted() {
      return _get(this.offer, 'features.restricted_to_members', false);
    },
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.$emit('startInitialization');
        this.executeTarget(
          {
            target: 'generic',
            action: 'overlayPayment/getPaymentDetailForOverlay',
            success: () => {
              this.$emit('initialized');
            },
          },
          this.headerDrawerContentPayload,
        );
      }
    },
  },
  data() {
    return {
      isPaymentComplete: false,
      showOverlay: true,
      space: {},
      offer: {},
      publisher: {},
    };
  },
  methods: {
    isPaymentCompleteCallback() {
      this.isPaymentComplete = true;
    },
    async init() {
      this.$emit('startInitialization');
      if (this.headerDrawerContentPayload.partner_id) {
        this.space = await this.execute(
          {
            action: 'overlayContent/getSpaceForOverlay',
          },
          this.headerDrawerContentPayload.partner_id,
        );
      }
      if (this.headerDrawerContentPayload.offer_id) {
        this.offer = await this.execute(
          {
            action: 'overlayContent/getOfferForOverlay',
          },
          this.headerDrawerContentPayload.offer_id,
        );
      }
      if (this.headerDrawerContentPayload.publisher_id && this.headerDrawerContentPayload.offer_id) {
        this.publisher = await this.execute(
          {
            action: 'overlayContent/getOfferPublisherForOverlay',
          },
          {
            offer_id: this.headerDrawerContentPayload.offer_id,
            publisher_id: this.headerDrawerContentPayload.publisher_id,
          },
        );
      }
      if (this.headerDrawerContentPayload.publisher_id && this.headerDrawerContentPayload.offer_id && this.headerDrawerContentPayload.partner_id) {
        await this.executeTarget(
          {
            target: 'generic',
            action: 'overlayPayment/getPaymentDetailForOverlay',
          },
          this.headerDrawerContentPayload,
        );
      }
      this.$emit('initialized');
    },
    async clear() {
      await this.executeOverlay({
        action: 'overlayPayment/clearOverlayPaymentStore',
        success: function () {
          this.isPaymentComplete = false;
          this.showOverlay = true;
          this.space = {};
          this.offer = {};
          this.publisher = {};
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.purchase-sign-in-overlay-content {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding: var(--gap);

  @media ($md-min) {
    flex-flow: row nowrap;
  }
}

.overlay-space-section {
  @media ($md-min) {
    width: 25%;
    padding-right: var(--gap);
    flex-shrink: 0;
  }
}

.right-content-section {
  flex: 1 1 auto;
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-border);
}

::v-deep .h-error-box {
  margin-bottom: var(--gap);
}
</style>
