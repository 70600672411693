import createLocalNavbarItem from './locale-navbar-item';
import createSignInNavbarItem from './sign-in-navigation-item';
import createLogoutNavbarItem from './logout-navbar-item';
import createAboutNavbarItem from './about-navbar-item';
import createFaqNavbarItem from './faq-navbar-item';
import createMyAccountNavbarItem from './my-account-navbar-item';
import createBusinessConsoleNavbarItem from './business-console-navbar-item';

export default function createNavigationItemMap(store, router, execute, DefaultNavigationItem, context) {
  return {
    localeNavigationItem: createLocalNavbarItem(store, router, execute, DefaultNavigationItem, context),
    signInNavigationItem: createSignInNavbarItem(store, router, execute, DefaultNavigationItem, context),
    logoutNavigationItem: createLogoutNavbarItem(store, router, execute, DefaultNavigationItem, context),
    aboutNavigationItem: createAboutNavbarItem(store, router, execute, DefaultNavigationItem, context),
    faqNavigationItem: createFaqNavbarItem(store, router, execute, DefaultNavigationItem, context),
    myAccountNavigationItem: createMyAccountNavbarItem(store, router, execute, DefaultNavigationItem, context),
    businessConsoleNavigationItem: createBusinessConsoleNavbarItem(store, router, execute, DefaultNavigationItem, context),
  };
}
