<template>
  <p class="small-card-base-description">{{ text }}</p>
</template>

<script>
export default {
  name: 'small-card-base-description',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.small-card-base-description {
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-text-tertiary);
  height: 74px;
  margin-bottom: 15px;
  @include cut-after-x-line(4);
}
</style>
