import _merge from 'lodash/merge';
import _concat from 'lodash/concat';
import { i18n } from '@/plugins/i18n';
import Vue from 'vue';
import { secureStorage } from '@/utils/secure-storage';

function generateSnackBar(options) {
  return _merge(
    {
      mode: '',
      color: 'primary',
      text: '',
      show: true,
      timeout: 6000,
    },
    options,
  );
}

export default {
  namespaced: true,
  state: {
    currentLocale: secureStorage.getItem('hello::currentLocale') || 'en',
    infoMessage: null,
    warningMessage: null,
    locales: [
      {
        text: 'EN',
        value: 'en',
      },
      {
        text: 'FR',
        value: 'fr',
      },
    ],
    snackbar: {
      show: false,
    },
    snackbarTimeout: null,
    snackbarText: [],
  },
  getters: {
    infoMessage: (state) => state.infoMessage,
    warningMessage: (state) => state.warningMessage,
    currentLocale: (state) => state.currentLocale,
    locales: (state) => state.locales,
    snackbar: (state) => state.snackbar,
    snackbarText: (state) => state.snackbarText,
  },
  mutations: {
    currentLocale(state, locale) {
      state.currentLocale = locale;
    },
    infoMessage(state, infoMessage) {
      state.infoMessage = infoMessage;
    },
    warningMessage(state, warningMessage) {
      state.warningMessage = warningMessage;
    },
    addSnackbar(state, { freshSnackbar, text }) {
      state.snackbar = freshSnackbar;
      state.snackbarText = _concat(state.snackbarText, text);
    },
    augmentSnackbar(state, text) {
      state.snackbar.mode = 'multi-line';
      state.snackbarText = _concat(state.snackbarText, text);
    },
    setSnackbarTimeout(state, timeout) {
      state.snackbarTimeout = timeout;
    },
    cleanSnackbar(state) {
      state.snackbar = {
        show: false,
      };
      state.snackbarTimeout = 0;
      state.snackbarText = [];
    },
  },
  actions: {
    async init({ dispatch }) {
      let defaultLocale = 'en';
      try {
        defaultLocale = secureStorage.getItem('hello::currentLocale') || (navigator.language || navigator.userLanguage).split('-')[0];

        const parameters = new URLSearchParams(window.location.search);
        const language = parameters.get('lang') || parameters.get('language') || parameters.get('locale');
        if (language) {
          console.log('LANGUAGE Detected', language); // eslint-disable-line no-console
          defaultLocale = language;
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }

      await dispatch('changeLocale', defaultLocale);
    },

    showSuccessSnackBar({ commit, state }, { text }) {
      let timeout = 6000;
      let theTimeout;

      if (state.snackbar.show === false) {
        const freshSnackbar = generateSnackBar({ text, timeout });
        commit('addSnackbar', { freshSnackbar, text });

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, timeout);

        commit('setSnackbarTimeout', theTimeout);
      } else {
        clearTimeout(state.snackbarTimeout);

        const newTimeoutDuration = timeout;
        commit('augmentSnackbar', text);

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, newTimeoutDuration);

        commit('setSnackbarTimeout', theTimeout);
      }
    },

    changeLocale({ rootGetters, commit }, newLocale) {
      let setNewLocale = 'en';
      if (newLocale) {
        setNewLocale = newLocale.toLowerCase();
      }
      if (setNewLocale !== 'fr' && setNewLocale !== 'en') {
        setNewLocale = 'en';
      }

      i18n.locale = setNewLocale;
      Vue.prototype.$firebaseAuth.languageCode = setNewLocale;
      secureStorage.setItem('hello::currentLocale', setNewLocale);
      commit('currentLocale', setNewLocale);

      // Vue.prototype.$square.setLocale(`${setNewLocale}-CA`);

      if (window.hj) {
        const accountId = rootGetters['account/accountId'];
        hj('identify', accountId === '' || !accountId ? null : accountId, { language: rootGetters['ui/currentLocale'] });
      }
    },

    setInfoMessage({ commit }, infoMessage) {
      commit('infoMessage', infoMessage);
      setTimeout(() => commit('infoMessage', null), 10 * 1000);
    },

    setWarningMessage({ commit }, warningMessage) {
      commit('warningMessage', warningMessage);
      setTimeout(() => commit('warningMessage', null), 10 * 1000);
    },
  },
};
